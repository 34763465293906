import { getCookie, deleteCookie } from "../helpers/cookie";
import { splitMulti } from "../helpers/splitMulti";

// DOMAIN , API

// Test
export const DOMAIN = 'https://txng-admin-v1.isopro.vn/';// dm ca1i api chinh1 ne2 tinh

export const DOMAIN_LACO = 'https://lacocenter-be.tracecenter.vn/';// dm ca1i api chinh1 ne2 LACO

export const DOMAIN_PROVINCE = localStorage.getItem('DOMAIN_PROVINCE');// dm ca1i api chinh1 ne2 tinh

export const MAP_KEY = 'AIzaSyAz1kbpa6CB_NQh5vQNJHfqTCSH_xGZFIU'
//AIzaSyAz1kbpa6CB_NQh5vQNJHfqTCSH_xGZFIU

export const LOCATION_DEFAULT = { lat: 13.10419242819848, lng: 109.31281141121397 }
export const ZOOM_DEFAULT = 11;

//LACO
//export const DOMAIN = 'http://manager-be.tracecenter.vn/';

//LongAn
//export const DOMAIN = 'http://longan-admin-be.tracecenter.vn/';

// Local
//export const DOMAIN = 'http://192.168.1.5:5001/';

export const BASE_URL = 'https://lacocenter-ids-v1.tracecenter.vn';

export const GRANT_TYPE = 'refresh_token';

export const CLIENT_ID = 'LACOCenterClient_Local';
export const CLIENT_SECRET = '7280d438-b9ad-1453-238d-841eaa69071b';

export const SCOPE = 'AdministratorClientId_api offline_access Txng_Center_api LACO_Center_api openid profile';
export const DOMAIN_IMG = 'http://txng-cloud-v1.isopro.vn/';

//--------------------HUB--------------------
export const URL_HUBS = {
    //message: 'https://phuyen-admin-v1.tracecenter.vn/messagehub'
    message: DOMAIN_LACO + 'messagehub'
}

export const HUB_EMITS = {
    connect: 'Connect'
}

export const HUB_LISTENS = {
    receiveMessage: 'ReceiveMessage'
}
//--------------------END HUB--------------------


// Test
// export const DOMAIN = 'http://txng-test-api.isopro.vn/';

// Local
//export const DOMAIN = 'http://localhost:44330/'; 

export const API = 'api/';
export const AREA = 'area/'
export const USER = 'user/';
export const ROLE = 'role/';
export const PRICE = 'price/';
export const ZONE = 'zone/';
export const ZONE_ROLE = 'rolezone/';
export const LOG = 'log/';
export const COMPANY = 'company/';
export const FIELD = 'field/';
export const PERMISSION = 'roleperminssion/';
export const ROLE_PERMISSION = 'roleperminssion/';
export const LOCATION = 'location/';
export const REGISTEREDFEE = 'registeredfee/';
export const STAMPFEE = 'stampfee/';
export const STAMP = 'stamp/';
export const PLANTINGTYPE = 'plantingtype/';
export const STAMPPROVIDE = 'stampprovide/';
export const BLOG = 'blog/';
export const MENU = 'menu/';
export const NEWS = 'news/';
export const INFORMATION = 'information/';
export const ACCESS = 'access/';
export const COMPANY_REPORT = 'companyreport/';
export const GROWTH_STAMP_REPORT = 'growthstampreport/';
export const SALE_REGISTER_REPORT = 'saleregisterreport/';
export const SALE_STAMP_REPORT = 'salestampreport/';
export const DASHBOARD = 'dasboard';
export const PRODUCT_GROUP = 'producttype/';
export const PRODUCT_REPORT = 'productreport/';
export const UNIT = 'unit/';
export const STAMPTEMPLATE = 'stamptemplate/';
export const CONFIG = 'config/';
export const TYPE_ZONE_PROPERTY = 'plantingtype/';
export const MATERIAL_GROUP = 'materialgroup/';
export const CONFIG_WEBSITE = 'configwebsite/';
export const PLANTING_ZONE = 'plantingzone/';
export const TRACE = 'trace/';
export const PRODUCTS = 'product/';
export const USERPROVINCE = 'userprovince';
export const ABOUTUS = 'aboutus/';
export const SERVER = 'server/';
export const MASTERSERVER = 'server';
export const PARTNER = 'partner/';


export const AUTHEN_INFO = getCookie('AUTHEN_INFO') ? JSON.parse(getCookie('AUTHEN_INFO')) : null;
export const TOKEN = AUTHEN_INFO !== null ? 'Bearer ' + AUTHEN_INFO.token : null;
export const ACCOUNT_ID = AUTHEN_INFO !== null ? AUTHEN_INFO.id : null;
export const IS_ADMIN = AUTHEN_INFO !== null ? AUTHEN_INFO.isAdmin : null;
export const ACCOUNT_NAME = AUTHEN_INFO !== null ? AUTHEN_INFO.fullName : null;
export const ACCOUNT_AVA = AUTHEN_INFO !== null ? AUTHEN_INFO.avatar : null;
export const ACCOUNT_CLAIM = AUTHEN_INFO !== null ? AUTHEN_INFO.claims : [];
export const ACCOUNT_CLAIM_FF = AUTHEN_INFO !== null ? (splitMulti(ACCOUNT_CLAIM, [',', '[', ']', '"']).filter(x => x != "") || []) : false;

// STATUS
export const SUCCESS_CODE = 200;
export const MESSAGE_CHECK_NET_WORK = 'Chưa thể kết nối hệ thống';

//API
export const GET_DASHBOARD_TYPE = 'GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS_TYPE = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAIL_TYPE = 'GET_DASHBOARD_FAIL';
export const DASHBOARD_GET_ALERTS_TYPE = 'DASHBOARD_GET_ALERTS_TYPE';
export const DASHBOARD_GET_ALERTS_SUCCESS_TYPE = 'DASHBOARD_GET_ALERTS_SUCCESS_TYPE';
export const DASHBOARD_GET_ALERTS_FAIL_TYPE = 'DASHBOARD_GET_ALERTS_FAIL_TYPE';
export const DASHBOARD_GET_TOTAL_ALERTS_TYPE = 'DASHBOARD_GET_ALERTS_TYPE';
export const DASHBOARD_GET_TOTAL_ALERTS_SUCCESS_TYPE = 'DASHBOARD_GET_TOTAL_ALERTS_SUCCESS_TYPE';
export const DASHBOARD_GET_TOTAL_ALERTS_FAIL_TYPE = 'DASHBOARD_GET_TOTAL_ALERTS_FAIL_TYPE';
export const DASHBOARD_READ_TYPE = 'DASHBOARD_GET_ALERTS_TYPE';
export const DASHBOARD_READ_SUCCESS_TYPE = 'DASHBOARD_READ_SUCCESS_TYPE';
export const DASHBOARD_READ_FAIL_TYPE = 'DASHBOARD_READ_FAIL_TYPE';
export const DASHBOARD_READ_ALL_TYPE = 'DASHBOARD_READ_ALL_TYPE';
export const DASHBOARD_READ_ALL_SUCCESS_TYPE = 'DASHBOARD_READ_ALL_SUCCESS_TYPE';
export const DASHBOARD_READ_ALL_FAIL_TYPE = 'DASHBOARD_READ_ALL_FAIL_TYPE';
export const DASHBOARD_GET_INFO_TYPE = 'DASHBOARD_GET_INFO_TYPE';
export const DASHBOARD_GET_INFO_SUCCESS_TYPE = 'DASHBOARD_GET_INFO_SUCCESS_TYPE';
export const DASHBOARD_GET_INFO_FAIL_TYPE = 'DASHBOARD_GET_INFO_FAIL_TYPE';

//LOGIN
export const GET_LOGIN_TYPE = 'GET_LOGIN';
export const GET_LOGIN_SUCCESS_TYPE = 'GET_LOGIN_SUCCESS';
export const GET_LOGIN_FAIL_TYPE = 'GET_LOGIN_FAIL';
export const AUTHENTICATE_LOGIN_SUCCESS = 'AUTHENTICATE_LOGIN_SUCCESS';

//LOGOUT
export const GET_OUT_TYPE = 'GET_LOGIN';

//USER
export const GET_USER_LIST_TYPE = 'GET_USER_LIST';
export const GET_USER_LIST_SUCCESS_TYPE = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAIL_TYPE = 'GET_USER_LIST_FAIL';
export const GET_USER_INFO_TYPE = 'GET_USER_INFO';
export const GET_USER_INFO_SUCCESS_TYPE = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAIL_TYPE = 'GET_USER_INFO_FAIL';
export const UPDATE_USER_INFO_TYPE = 'UPDATE_USER_INFO';
export const UPDATE_USER_INFO_SUCCESS_TYPE = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAIL_TYPE = 'UPDATE_USER_INFO_FAIL';
export const CREATE_USER_INFO_TYPE = 'CREATE_USER_INFO';
export const CREATE_USER_INFO_SUCCESS_TYPE = 'CREATE_USER_INFO_SUCCESS';
export const CREATE_USER_INFO_FAIL_TYPE = 'CREATE_USER_INFO_FAIL';
export const DELETE_USER_INFO_TYPE = 'DELETE_USER_INFO';
export const DELETE_USER_INFO_SUCCESS_TYPE = 'DELETE_USER_INFO_SUCCESS';
export const DELETE_USER_INFO_FAIL_TYPE = 'DELETE_USER_INFO_FAIL';
export const SEND_FORGOT_PASSWORD_SUCCESS = 'SEND_FORGOT_PASSWORD_SUCCESS';
export const CHECK_FORGOT_PASSWORD_SUCCESS = 'CHECK_FORGOT_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FORGOT_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_FORGOT_PASSWORD_SUCCESS';
export const USER_UPDATE_ME_SUCCESS = 'USER_UPDATE_ME_SUCCESS';
export const USER_UPDATE_ME_FAIL = 'USER_UPDATE_ME_FAIL';
export const USER_UPDATE_ME_TYPE = 'USER_UPDATE_ME_TYPE';
export const USER_LIST_LACO_SUCCESS = 'USER_LIST_LACO_SUCCESS';
export const USER_LIST_LACO_FAIL = 'USER_LIST_LACO_FAIL';
export const USER_LIST_LACO_TYPE = 'USER_LIST_LACO_TYPE';

//ROLE
export const GET_ROLE_LIST_TYPE = 'GET_ROLE_LIST_TYPE';
export const GET_ROLE_LIST_SUCCESS_TYPE = 'GET_ROLE_LIST_SUCCESS_TYPE';
export const GET_ROLE_LIST_FAIL_TYPE = 'GET_ROLE_LIST_FAIL_TYPE';
export const CREATE_ROLE_TYPE = 'CREATE_ROLE_TYPE';
export const CREATE_ROLE_SUCCESS_TYPE = 'CREATE_ROLE_SUCCESS_TYPE';
export const CREATE_ROLE_FAIL_TYPE = 'CREATE_ROLE_FAIL_TYPE';
export const UPDATE_ROLE_TYPE = 'UPDATE_ROLE_TYPE';
export const UPDATE_ROLE_SUCCESS_TYPE = 'UPDATE_ROLE_SUCCESS_TYPE';
export const UPDATE_ROLE_FAIL_TYPE = 'UPDATE_ROLE_FAIL_TYPE';
export const DELETE_ROLE_TYPE = 'DELETE_ROLE_TYPE';
export const DELETE_ROLE_SUCCESS_TYPE = 'DELETE_ROLE_SUCCESS_TYPE';
export const DELETE_ROLE_FAIL_TYPE = 'DELETE_ROLE_FAIL_TYPE';

//PRICES
export const GET_PRICE_LIST_TYPE = 'GET_PRICES_LIST_TYPE';
export const GET_PRICE_LIST_SUCCESS_TYPE = 'GET_PRICES_LIST_SUCCESS_TYPE';
export const GET_PRICE_LIST_FAIL_TYPE = 'GET_PRICES_LIST_FAIL_TYPE';
export const CREATE_PRICE_LIST_TYPE = 'CREATE_PRICES_LIST_TYPE';
export const CREATE_PRICE_LIST_SUCCESS_TYPE = 'CREATE_PRICES_LIST_SUCCESS_TYPE';
export const CREATE_PRICE_LIST_FAIL_TYPE = 'CREATE_PRICES_LIST_FAIL_TYPE';
export const GET_PRICE_INFO_TYPE = 'GET_PRICE_INFO_TYPE';
export const GET_PRICE_INFO_SUCCESS_TYPE = 'GET_PRICE_INFO_SUCCESS_TYPE';
export const GET_PRICE_INFO_FAIL_TYPE = 'GET_PRICE_INFO_FAIL_TYPE';
export const UPDATE_PRICE_INFO_TYPE = 'UPDATE_PRICE_INFO_TYPE';
export const UPDATE_PRICE_INFO_SUCCESS_TYPE = 'UPDATE_PRICE_INFO_SUCCESS_TYPE';
export const UPDATE_PRICE_INFO_FAIL_TYPE = 'UPDATE_PRICE_INFO_FAIL_TYPE';
export const DELETE_PRICE_INFO_TYPE = 'DELETE_PRICE_INFO_TYPE';
export const DELETE_PRICE_INFO_SUCCESS_TYPE = 'DELETE_PRICE_INFO_SUCCESS_TYPE';
export const DELETE_PRICE_INFO_FAIL_TYPE = 'DELETE_PRICE_INFO_FAIL_TYPE';

//ZONE
export const GET_ZONE_LIST_TYPE = 'GET_ZONE_LIST_TYPE';
export const GET_ZONE_LIST_SUCCESS_TYPE = 'GET_ZONE_LIST_SUCCESS_TYPE';
export const GET_ZONE_LIST_FAIL_TYPE = 'GET_ZONE_LIST_FAIL_TYPE';
export const CREATE_ZONE_TYPE = 'CREATE_ZONE_TYPE';
export const CREATE_ZONE_SUCCESS_TYPE = 'CREATE_ZONE_SUCCESS_TYPE';
export const CREATE_ZONE_FAIL_TYPE = 'CREATE_ZONE_FAIL_TYPE';
export const UPDATE_ZONE_TYPE = 'UPDATE_ZONE_TYPE';
export const UPDATE_ZONE_SUCCESS_TYPE = 'UPDATE_ZONE_SUCCESS_TYPE';
export const UPDATE_ZONE_FAIL_TYPE = 'UPDATE_ZONE_FAIL_TYPE';
export const DELETE_ZONE_TYPE = 'DELETE_ZONE_TYPE';
export const DELETE_ZONE_SUCCESS_TYPE = 'DELETE_ZONE_SUCCESS_TYPE';
export const DELETE_ZONE_FAIL_TYPE = 'DELETE_ZONE_FAIL_TYPE';

//ZONE ROLE
export const GET_ZONE_ROLE_LIST_TYPE = 'GET_ZONE_ROLE_LIST_TYPE';
export const GET_ZONE_ROLE_LIST_SUCCESS_TYPE = 'GET_ZONE_ROLE_LIST_SUCCESS_TYPE';
export const GET_ZONE_ROLE_LIST_FAIL_TYPE = 'GET_ZONE_ROLE_LIST_FAIL_TYPE';

//LOCATION
export const GET_LOCATION_PROVINCE_TYPE = 'GET_LOCATION_PROVINCE';
export const GET_LOCATION_PROVINCE_SUCCESS_TYPE = 'GET_LOCATION_SUCCESS_PROVINCE';
export const GET_LOCATION_PROVINCE_FAIL_TYPE = 'GET_LOCATION_FAIL_PROVINCE';
export const GET_LOCATION_DISTRICT_TYPE = 'GET_LOCATION_DISTRICT';
export const GET_LOCATION_DISTRICT_SUCCESS_TYPE = 'GET_LOCATION_DISTRICT_SUCCESS';
export const GET_LOCATION_DISTRICT_FAIL_TYPE = 'GET_LOCATION_DISTRICT_FAIL';
export const GET_LOCATION_WARD_TYPE = 'GET_LOCATION_WARD';
export const GET_LOCATION_WARD_SUCCESS_TYPE = 'GET_LOCATION_WARD_SUCCESS';
export const GET_LOCATION_WARD_FAIL_TYPE = 'GET_LOCATION_WARD_FAIL';
export const GET_ZONE_DETAIL_TYPE = 'GET_ZONE_DETAIL';
export const GET_ZONE_DETAIL_SUCCESS_TYPE = 'GET_ZONE_DETAIL_SUCCESS';
export const GET_ZONE_DETAIL_FAIL_TYPE = 'GET_ZONE_DETAIL_FAIL';

//ROLE PERMISSION
export const GET_ROLE_ALL_PERMISSION_TYPE = 'GET_ROLE_PERMISSION';
export const GET_ROLE_ALL_PERMISSION_SUCCESS_TYPE = 'GET_ROLE_PERMISSION_SUCCESS';
export const GET_ROLE_ALL_PERMISSION_FAIL_TYPE = 'GET_ROLE_PERMISSION_FAIL';
export const GET_ROLE_PERMISSION_BY_ROLE_TYPE = 'GET_ROLE_PERMISSION_BY_ROLE';
export const GET_ROLE_PERMISSION_BY_ROLE_SUCCESS_TYPE = 'GET_ROLE_PERMISSION_BY_ROLE_SUCCESS';
export const GET_ROLE_PERMISSION_BY_ROLE_FAIL_TYPE = 'GET_ROLE_PERMISSION_BY_ROLE_FAIL';

//PERMISSION 
export const GET_PERMISSION_LIST_BY_ID_TYPE = 'GET_PERMISSION_LIST_BY_ID';
export const GET_PERMISSION_LIST_BY_ID_SUCCESS_TYPE = 'GET_PERMISSION_LIST_BY_ID_SUCCESS';
export const GET_PERMISSION_LIST_BY_ID_FAIL_TYPE = 'GET_PERMISSION_LIST_BY_ID_FAIL';
export const UPDATE_PERMISSION_TYPE = 'UPDATE_PERMISSION';
export const UPDATE_PERMISSION_SUCCESS_TYPE = 'UPDATE_PERMISSION_SUCCESS';
export const UPDATE_PERMISSION_FAIL_TYPE = 'UPDATE_PERMISSION_FAIL';

//LOG
export const GET_LOG_LIST_TYPE = 'GET_LOG_LIST';
export const GET_LOG_LIST_SUCCESS_TYPE = 'GET_LOG_LIST_SUCCESS_TYPE';
export const GET_LOG_LIST_FAIL_TYPE = 'GET_LOG_LIST_FAIL_TYPE';

//COMPANY  
export const COMPANY_GET_ALL_TYPE = 'COMPANY_GET_ALL_TYPE';
export const COMPANY_GET_ALL_SUCCESS_TYPE = 'COMPANY_GET_ALL_SUCCESS_TYPE';
export const COMPANY_GET_ALL_FAIL_TYPE = 'COMPANY_GET_ALL_FAIL_TYPE';

export const GET_COMPANY_GET_DETAILS_TYPE = 'GET_COMPANY_GET_DETAILS_TYPE';
export const GET_COMPANY_GET_DETAILS_SUCCESS_TYPE = 'GET_COMPANY_GET_DETAILS_SUCCESS_TYPE';
export const GET_COMPANY_GET_DETAILS_FAIL_TYPE = 'GET_COMPANY_GET_DETAILS_FAIL_TYPE';

export const GET_COMPANY_GET_DETAILS_LACO_TYPE = 'GET_COMPANY_GET_DETAILS_LACO_TYPE';
export const GET_COMPANY_GET_DETAILS_LACO_SUCCESS_TYPE = 'GET_COMPANY_GET_DETAILS_LACO_SUCCESS_TYPE';
export const GET_COMPANY_GET_DETAILS_LACO_FAIL_TYPE = 'GET_COMPANY_GET_DETAILS_LACO_FAIL_TYPE';

export const GET_COMPANY_GET_FILEUPDATE_TYPE = 'GET_COMPANY_GET_FILEUPDATE_TYPE';
export const GET_COMPANY_GET_FILEUPDATE_SUCCESS_TYPE = 'GET_COMPANY_GET_FILEUPDATE_SUCCESS_TYPE';
export const GET_COMPANY_GET_FILEUPDATE_FAIL_TYPE = 'GET_COMPANY_GET_FILEUPDATE_FAIL_TYPE';

export const DELETE_COMPANY_TYPE = 'DELETE_COMPANY_TYPE';
export const DELETE_COMPANY_SUCCESS_TYPE = 'DELETE_COMPANY_SUCCESS_TYPE';
export const DELETE_COMPANY_FAIL_TYPE = 'DELETE_COMPANY_FAIL_TYPE';

export const COMPANY_GET_LIST_CERTIFIED_TYPE = 'COMPANY_GET_LIST_CERTIFIED_TYPE';
export const COMPANY_GET_LIST_CERTIFIED_SUCCESS_TYPE = 'COMPANY_GET_LIST_CERTIFIED_SUCCESS_TYPE';
export const COMPANY_GET_LIST_CERTIFIED_FAIL_TYPE = 'COMPANY_GET_LIST_CERTIFIED_FAIL_TYPE';

export const GET_COMPANY_REG_LIST_TYPE = 'GET_COMPANY_REG_LIST';
export const GET_COMPANY_REG_LIST_SUCCESS_TYPE = 'GET_COMPANY_REG_LIST_SUCCESS_TYPE';
export const GET_COMPANY_REG_LIST_FAIL_TYPE = 'GET_COMPANY_REG_LIST_FAIL_TYPE';
export const COMPANY_GET_DELETED_TYPE = 'COMPANY_GET_DELETED_TYPE';
export const COMPANY_GET_DELETED_SUCCESS_TYPE = 'COMPANY_GET_DELETED_SUCCESS_TYPE';
export const COMPANY_GET_DELETED_FAIL_TYPE = 'COMPANY_GET_DELETED_FAIL_TYPE';
export const GET_COMPANY_AWAIT_LIST_TYPE = 'GET_COMPANY_AWAIT_LIST';
export const GET_COMPANY_AWAIT_LIST_SUCCESS_TYPE = 'GET_COMPANY_AWAIT_LIST_SUCCESS_TYPE';
export const GET_COMPANY_AWAIT_LIST_FAIL_TYPE = 'GET_COMPANY_AWAIT_LIST_FAIL_TYPE';
export const GET_COMPANY_COMFIRM_TYPE = 'GET_COMPANY_COMFIRM_TYPE';
export const GET_COMPANY_COMFIRM_FAIL_TYPE = 'GET_COMPANY_COMFIRM_FAIL_TYPE';
export const GET_COMPANY_COMFIRM_SUCCESS_TYPE = 'GET_COMPANY_COMFIRM_SUCCESS_TYPE';
export const GET_COMPANY_UNCOMFIRM_TYPE = 'GET_COMPANY_UNCOMFIRM_TYPE';
export const GET_COMPANY_UNCOMFIRM_FAIL_TYPE = 'GET_COMPANY_UNCOMFIRM_FAIL_TYPE';
export const GET_COMPANY_UNCOMFIRM_SUCCESS_TYPE = 'GET_COMPANY_UNCOMFIRM_SUCCESS_TYPE';
export const GET_COMPANY_NOT_COMFIRM_TYPE = 'GET_COMPANY_NOT_COMFIRM_TYPE';
export const GET_COMPANY_NOT_COMFIRM_FAIL_TYPE = 'GET_COMPANY_NOT_COMFIRM_FAIL_TYPE';
export const GET_COMPANY_NOT_COMFIRM_SUCCESS_TYPE = 'GET_COMPANY_NOT_COMFIRM_SUCCESS_TYPE';
export const GET_COMPANY_LIST_REGISTERED_TYPE = 'GET_COMPANY_LIST_REGISTERED_TYPE';
export const GET_COMPANY_LIST_REGISTERED_SUCCESS_TYPE = 'GET_COMPANY_LIST_REGISTERED_SUCCESS_TYPE';
export const GET_COMPANY_LIST_REGISTERED_FAIL_TYPE = 'GET_COMPANY_LIST_REGISTERED_FAIL_TYPE';
export const GET_VERFY_COMPANY_TYPE = 'GET_VERFY_COMPANY_TYPE';
export const GET_VERFY_COMPANY_SUCCESS_TYPE = 'GET_VERFY_COMPANY_SUCCESS_TYPE';
export const GET_VERFY_COMPANY_FAIL_TYPE = 'GET_VERFY_COMPANY_FAIL_TYPE';
export const GET_COMPANY_LIST_AWAIT_EXPIRED_TYPE = 'GET_COMPANY_LIST_AWAIT_EXPIRED_TYPE';
export const GET_COMPANY_LIST_AWAIT_EXPIRED_SUCCESS_TYPE = 'GET_COMPANY_LIST_AWAIT_EXPIRED_SUCCESS_TYPE';
export const GET_COMPANY_LIST_AWAIT_EXPIRED_FAIL_TYPE = 'GET_COMPANY_LIST_AWAIT_EXPIRED_FAIL_TYPE';
export const GET_COMPANY_LIST_EXPIRING_TYPE = 'GET_COMPANY_LIST_EXPIRING_TYPE';
export const GET_COMPANY_LIST_EXPIRING_SUCCESS_TYPE = 'GET_COMPANY_LIST_EXPIRING_SUCCESS_TYPE';
export const GET_COMPANY_LIST_EXPIRING_FAIL_TYPE = 'GET_COMPANY_LIST_EXPIRING_FAIL_TYPE';
export const GET_COMPANY_LIST_LOCK_TYPE = 'GET_COMPANY_LIST_LOCK_TYPE';
export const GET_COMPANY_LIST_LOCK_SUCCESS_TYPE = 'GET_COMPANY_LIST_LOCK_SUCCESS_TYPE';
export const GET_COMPANY_LIST_LOCK_FAIL_TYPE = 'GET_COMPANY_LIST_LOCK_FAIL_TYPE';
export const GET_COMPANY_EXTEND_TYPE = 'GET_COMPANY_EXTEND_TYPE';
export const GET_COMPANY_EXTEND_SUCCESS_TYPE = 'GET_COMPANY_EXTEND_SUCCESS_TYPE';
export const GET_COMPANY_EXTEND_FAIL_TYPE = 'GET_COMPANY_EXTEND_FAIL_TYPE';
export const GET_COMPANY_COMFIRMED_TYPE = 'GET_COMPANY_COMFIRMED_TYPE';
export const GET_COMPANY_COMFIRMED_SUCCESS_TYPE = 'GET_COMPANY_COMFIRMED_SUCCESS_TYPE';
export const GET_COMPANY_COMFIRMED_FAIL_TYPE = 'GET_COMPANY_COMFIRMED_FAIL_TYPE';
export const GET_COMPANY_DELETED_TYPE = 'GET_COMPANY_DELETED_TYPE';
export const GET_COMPANY_DELETED_SUCCESS_TYPE = 'GET_COMPANY_DELETED_SUCCESS_TYPE';
export const GET_COMPANY_DELETED_FAIL_TYPE = 'GET_COMPANY_DELETED_FAIL_TYPE';
export const GET_COMPANY_LOCK_TYPE = 'GET_COMPANY_LOCK_TYPE';
export const GET_COMPANY_LOCK_SUCCESS_TYPE = 'GET_COMPANY_LOCK_SUCCESS_TYPE';
export const GET_COMPANY_LOCK_FAIL_TYPE = 'GET_COMPANY_LOCK_FAIL_TYPE';
export const GET_COMPANY_UNLOCK_TYPE = 'GET_COMPANY_UNLOCK_TYPE';
export const GET_COMPANY_UNLOCK_SUCCESS_TYPE = 'GET_COMPANY_UNLOCK_SUCCESS_TYPE';
export const GET_COMPANY_UNLOCK_FAIL_TYPE = 'GET_COMPANY_UNLOCK_FAIL_TYPE';
export const GET_COMPANY_LIST_REQUEST_EXTEND_TYPE = 'GET_COMPANY_LIST_REQUEST_EXTEND_TYPE';
export const GET_COMPANY_LIST_REQUEST_EXTEND_SUCCESS_TYPE = 'GET_COMPANY_LIST_REQUEST_EXTEND_SUCCESS_TYPE';
export const GET_COMPANY_LIST_REQUEST_EXTEND_FAIL_TYPE = 'GET_COMPANY_LIST_REQUEST_EXTEND_FAIL_TYPE';
export const GET_COMPANY_COMFIRM_REQUEST_EXTEND_TYPE = 'GET_COMPANY_COMFIRM_REQUEST_EXTEND_TYPE';
export const GET_COMPANY_COMFIRM_REQUEST_EXTEND_SUCCESS_TYPE = 'GET_COMPANY_COMFIRM_REQUEST_EXTEND_SUCCESS_TYPE';
export const GET_COMPANY_COMFIRM_REQUEST_EXTEND_FAIL_TYPE = 'GET_COMPANY_COMFIRM_REQUEST_EXTEND_FAIL_TYPE';
export const GET_COMPANY_UNCOMFIRM_REQUEST_EXTEND_TYPE = 'GET_COMPANY_UNCOMFIRM_REQUEST_EXTEND_TYPE';
export const GET_COMPANY_UNCOMFIRM_REQUEST_EXTEND_SUCCESS_TYPE = 'GET_COMPANY_UNCOMFIRM_REQUEST_EXTEND_SUCCESS_TYPE';
export const GET_COMPANY_UNCOMFIRM_REQUEST_EXTEND_FAIL_TYPE = 'GET_COMPANY_UNCOMFIRM_REQUEST_EXTEND_FAIL_TYPE';
export const GET_COMPANY_LIST_REQUEST_UNLOCK_TYPE = 'GET_COMPANY_LIST_REQUEST_UNLOCK_TYPE';
export const GET_COMPANY_LIST_REQUEST_UNLOCK_SUCCESS_TYPE = 'GET_COMPANY_LIST_REQUEST_UNLOCK_SUCCESS_TYPE';
export const GET_COMPANY_LIST_REQUEST_UNLOCK_FAIL_TYPE = 'GET_COMPANY_LIST_REQUEST_UNLOCK_FAIL_TYPE';
export const GET_COMPANY_COMFIRM_REQUEST_UNLOCK_TYPE = 'GET_COMPANY_COMFIRM_REQUEST_UNLOCK_TYPE';
export const GET_COMPANY_COMFIRM_REQUEST_UNLOCK_SUCCESS_TYPE = 'GET_COMPANY_COMFIRM_REQUEST_UNLOCK_SUCCESS_TYPE';
export const GET_COMPANY_COMFIRM_REQUEST_UNLOCK_FAIL_TYPE = 'GET_COMPANY_COMFIRM_REQUEST_UNLOCK_FAIL_TYPE';
export const GET_COMPANY_UNCOMFIRM_REQUEST_UNLOCK_TYPE = 'GET_COMPANY_UNCOMFIRM_REQUEST_UNLOCK_TYPE';
export const GET_COMPANY_UNCOMFIRM_REQUEST_UNLOCK_SUCCESS_TYPE = 'GET_COMPANY_UNCOMFIRM_REQUEST_UNLOCK_SUCCESS_TYPE';
export const GET_COMPANY_UNCOMFIRM_REQUEST_UNLOCK_FAIL_TYPE = 'GET_COMPANY_UNCOMFIRM_REQUEST_UNLOCK_FAIL_TYPE';
export const COMPANY_CHECK_DAY_TYPE = 'COMPANY_CHECK_DAY_TYPE';
export const COMPANY_CHECK_DAY_SUCCESS_TYPE = 'COMPANY_CHECK_DAY_SUCCESS_TYPE';
export const COMPANY_CHECK_DAY_FAIL_TYPE = 'COMPANY_CHECK_DAY_FAIL_TYPE';

export const DELETE_COMPANY_REGISTERED_TYPE = 'DELETE_COMPANY_REGISTERED_TYPE';
export const DELETE_COMPANY_REGISTERED_SUCCESS_TYPE = 'DELETE_COMPANY_REGISTERED_SUCCESS_TYPE';
export const DELETE_COMPANY_REGISTERED_FAIL_TYPE = 'DELETE_COMPANY_REGISTERED_FAIL_TYPE';

export const CREATE_COMPANY_TYPE = 'CREATE_COMPANY';
export const CREATE_COMPANY_SUCCESS_TYPE = 'CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAIL_TYPE = 'CREATE_COMPANY_FAIL';

export const DELETE_COMPANY_AWAIT_TYPE = 'DELETE_COMPANY_AWAIT_TYPE';
export const DELETE_COMPANY_AWAIT_SUCCESS_TYPE = 'DELETE_COMPANY_AWAIT_SUCCESS_TYPE';
export const DELETE_COMPANY_AWAIT_FAIL_TYPE = 'DELETE_COMPANY_AWAIT_FAIL_TYPE';

export const COMPANY_LIST_VERIFY_TYPE = 'COMPANY_LIST_VERIFY_TYPE';
export const COMPANY_LIST_VERIFY_SUCCESS_TYPE = 'COMPANY_LIST_VERIFY_SUCCESS_TYPE';
export const COMPANY_LIST_VERIFY_FAIL_TYPE = 'COMPANY_LIST_VERIFY_FAIL_TYPE';
export const COMPANY_VERIFY_IMAGE_TYPE = 'COMPANY_VERIFY_IMAGE_TYPE';
export const COMPANY_VERIFY_IMAGE_SUCCESS_TYPE = 'COMPANY_VERIFY_IMAGE_SUCCESS_TYPE';
export const COMPANY_VERIFY_IMAGE_FAIL_TYPE = 'COMPANY_VERIFY_IMAGE_FAIL_TYPE';
export const COMPANY_UPLOAD_VERIFY_TYPE = 'COMPANY_UPLOAD_VERIFY_TYPE';
export const COMPANY_UPLOAD_VERIFY_SUCCESS_TYPE = 'COMPANY_UPLOAD_VERIFY_SUCCESS_TYPE';
export const COMPANY_UPLOAD_VERIFY_FAIL_TYPE = 'COMPANY_UPLOAD_VERIFY_FAIL_TYPE';
export const COMPANY_DELETE_UPLOAD_VERIFY_TYPE = 'COMPANY_DELETE_UPLOAD_VERIFY_TYPE';
export const COMPANY_DELETE_UPLOAD_VERIFY_SUCCESS_TYPE = 'COMPANY_DELETE_UPLOAD_VERIFY_SUCCESS_TYPE';
export const COMPANY_DELETE_UPLOAD_VERIFY_FAIL_TYPE = 'COMPANY_DELETE_UPLOAD_VERIFY_FAIL_TYPE';

//FIELD
export const GET_FIELD_TYPE = 'GET_FIELD';
export const GET_FIELD_SUCCESS_TYPE = 'GET_FIELD_SUCCESS_TYPE';
export const GET_FIELD_FAIL_TYPE = 'GET_FIELD_FAIL_TYPE';

export const GET_FIELD_COMPANYAWAIT_TYPE = 'GET_FIELD_COMPANYAWAIT_TYPE';
export const GET_FIELD_COMPANYAWAIT_SUCCESS_TYPE = 'GET_FIELD_COMPANYAWAIT_SUCCESS_TYPE';
export const GET_FIELD_COMPANYAWAIT_FAIL_TYPE = 'GET_FIELD_COMPANYAWAIT_FAIL_TYPE';
export const GET_FIELD_COMPANYNOTCOMFIRM_TYPE = 'GET_FIELD_COMPANYNOTCOMFIRM_TYPE';
export const GET_FIELD_COMPANYNOTCOMFIRM_SUCCESS_TYPE = 'GET_FIELD_COMPANYNOTCOMFIRM_SUCCESS_TYPE';
export const GET_FIELD_COMPANYNOTCOMFIRM_FAIL_TYPE = 'GET_FIELD_COMPANYNOTCOMFIRM_FAIL_TYPE';
export const GET_FIELD_COMPANYAWAITEXTEND_TYPE = 'GET_FIELD_COMPANYAWAITEXTEND_TYPE';
export const GET_FIELD_COMPANYAWAITEXTEND_SUCCESS_TYPE = 'GET_FIELD_COMPANYAWAITEXTEND_SUCCESS_TYPE';
export const GET_FIELD_COMPANYAWAITEXTEND_FAIL_TYPE = 'GET_FIELD_COMPANYAWAITEXTEND_FAIL_TYPE';
export const GET_FIELD_COMPANYEXPIRING_TYPE = 'GET_FIELD_COMPANYEXPIRING_TYPE';
export const GET_FIELD_COMPANYEXPIRING_SUCCESS_TYPE = 'GET_FIELD_COMPANYEXPIRING_SUCCESS_TYPE';
export const GET_FIELD_COMPANYEXPIRING_FAIL_TYPE = 'GET_FIELD_COMPANYEXPIRING_FAIL_TYPE';
export const GET_FIELD_COMPANYLOCK_TYPE = 'GET_FIELD_COMPANYLOCK_TYPE';
export const GET_FIELD_COMPANYLOCK_SUCCESS_TYPE = 'GET_FIELD_COMPANYLOCK_SUCCESS_TYPE';
export const GET_FIELD_COMPANYLOCK_FAIL_TYPE = 'GET_FIELD_COMPANYLOCK_FAIL_TYPE';
export const GET_FIELD_COMPANYREQUESTEXTEND_TYPE = 'GET_FIELD_COMPANYREQUESTEXTEND_TYPE';
export const GET_FIELD_COMPANYREQUESTEXTEND_SUCCESS_TYPE = 'GET_FIELD_COMPANYREQUESTEXTEND_SUCCESS_TYPE';
export const GET_FIELD_COMPANYREQUESTEXTEND_FAIL_TYPE = 'GET_FIELD_COMPANYREQUESTEXTEND_FAIL_TYPE';
export const GET_FIELD_COMPANYCOMFIRMED_TYPE = 'GET_FIELD_COMPANYCOMFIRMED_TYPE';
export const GET_FIELD_COMPANYCOMFIRMED_SUCCESS_TYPE = 'GET_FIELD_COMPANYCOMFIRMED_SUCCESS_TYPE';
export const GET_FIELD_COMPANYCOMFIRMED_FAIL_TYPE = 'GET_FIELD_COMPANYCOMFIRMED_FAIL_TYPE';
export const GET_FIELD_COMPANYDELETED_TYPE = 'GET_FIELD_COMPANYDELETED_TYPE';
export const GET_FIELD_COMPANYDELETED_SUCCESS_TYPE = 'GET_FIELD_COMPANYDELETED_SUCCESS_TYPE';
export const GET_FIELD_COMPANYDELETED_FAIL_TYPE = 'GET_FIELD_COMPANYDELETED_FAIL_TYPE';

export const GET_CREATE_FIELD_TYPE = 'GET_CREATE_FIELD';
export const GET_CREATE_FIELD_SUCCESS_TYPE = 'GET_CREATE_FIELD_SUCCESS';
export const GET_CREATE_FIELD_FAIL_TYPE = 'GET_CREATE_FIELD_FAIL';
export const GET_UPDATE_FIELD_TYPE = 'GET_UPDATE_FIELD';
export const GET_UPDATE_FIELD_SUCCESS_TYPE = 'GET_UPDATE_FIELD_SUCCESS';
export const GET_UPDATE_FIELD_FAIL_TYPE = 'GET_UPDATE_FIELD_FAIL';
export const GET_DELETE_FIELD_TYPE = 'GET_DELETE_FIELD';
export const GET_DELETE_FIELD_SUCCESS_TYPE = 'GET_DELETE_FIELD_SUCCESS';
export const GET_DELETE_FIELD_FAIL_TYPE = 'GET_DELETE_FIELD_FAIL';
export const GET_FIELD_SELECT_TYPE = 'GET_FIELD_SELECT_TYPE';
export const GET_FIELD_SELECT_SUCCESS_TYPE = 'GET_FIELD_SELECT_SUCCESS_TYPE';
export const GET_FIELD_SELECT_FAIL_TYPE = 'GET_FIELD_SELECT_FAIL_TYPE';
export const FIELD_LOCK_TYPE = 'FIELD_LOCK_TYPE';
export const FIELD_LOCK_SUCCESS_TYPE = 'FIELD_LOCK_SUCCESS_TYPE';
export const FIELD_LOCK_FAIL_TYPE = 'FIELD_LOCK_FAIL_TYPE';

//ACCESS
export const GET_ACCESS_TYPE = 'GET_ACCESS';
export const GET_ACCESS_SUCCESS_TYPE = 'GET_ACCESS_SUCCESS_TYPE';
export const GET_ACCESS_FAIL_TYPE = 'GET_ACCESS_FAIL_TYPE';
export const GET_CREATE_ACCESS_TYPE = 'GET_CREATE_ACCESS';
export const GET_CREATE_ACCESS_SUCCESS_TYPE = 'GET_CREATE_ACCESS_SUCCESS';
export const GET_CREATE_ACCESS_FAIL_TYPE = 'GET_CREATE_ACCESS_FAIL';
export const GET_UPDATE_ACCESS_TYPE = 'GET_UPDATE_ACCESS';
export const GET_UPDATE_ACCESS_SUCCESS_TYPE = 'GET_UPDATE_ACCESS_SUCCESS';
export const GET_UPDATE_ACCESS_FAIL_TYPE = 'GET_UPDATE_ACCESS_FAIL';
export const GET_DELETE_ACCESS_TYPE = 'GET_DELETE_ACCESS';
export const GET_DELETE_ACCESS_SUCCESS_TYPE = 'GET_DELETE_ACCESS_SUCCESS';
export const GET_DELETE_ACCESS_FAIL_TYPE = 'GET_DELETE_ACCESS_FAIL';

//INFORMATION
export const GET_INFORMATION_TYPE = 'GET_INFORMATION';
export const GET_INFORMATION_SUCCESS_TYPE = 'GET_INFORMATION_SUCCESS_TYPE';
export const GET_INFORMATION_FAIL_TYPE = 'GET_INFORMATION_FAIL_TYPE';
export const GET_CREATE_INFORMATION_TYPE = 'GET_CREATE_INFORMATION';
export const GET_CREATE_INFORMATION_SUCCESS_TYPE = 'GET_CREATE_INFORMATION_SUCCESS';
export const GET_CREATE_INFORMATION_FAIL_TYPE = 'GET_CREATE_INFORMATION_FAIL';
export const GET_UPDATE_INFORMATION_TYPE = 'GET_UPDATE_INFORMATION';
export const GET_UPDATE_INFORMATION_SUCCESS_TYPE = 'GET_UPDATE_INFORMATION_SUCCESS';
export const GET_UPDATE_INFORMATION_FAIL_TYPE = 'GET_UPDATE_INFORMATION_FAIL';
export const GET_DELETE_INFORMATION_TYPE = 'GET_DELETE_INFORMATION';
export const GET_DELETE_INFORMATION_SUCCESS_TYPE = 'GET_DELETE_INFORMATION_SUCCESS';
export const GET_DELETE_INFORMATION_FAIL_TYPE = 'GET_DELETE_INFORMATION_FAIL';

//REGISTEREDFEE
export const GET_REGISTEREDFEE_LIST_TYPE = 'GET_REGISTEREDFEE_LIST_TYPE';
export const GET_REGISTEREDFEE_LIST_SUCCESS_TYPE = 'GET_REGISTEREDFEE_LIST_SUCCESS_TYPE';
export const GET_REGISTEREDFEE_LIST_FAIL_TYPE = 'GET_REGISTEREDFEE_LIST_FAIL_TYPE';
export const GET_REGISTEREDFEE_COMFIRM_TYPE = 'GET_REGISTEREDFEE_COMFIRM_TYPE';
export const GET_REGISTEREDFEE_COMFIRM_SUCCESS_TYPE = 'GET_REGISTEREDFEE_COMFIRM_SUCCESS_TYPE';
export const GET_REGISTEREDFEE_COMFIRM_FAIL_TYPE = 'GET_REGISTEREDFEE_COMFIRM_FAIL_TYPE';

//STAMPFEE
export const GET_STAMPFEE_LIST_TYPE = 'GET_STAMPFEE_LIST_TYPE';
export const GET_STAMPFEE_LIST_SUCCESS_TYPE = 'GET_STAMPFEE_LIST_SUCCESS_TYPE';
export const GET_STAMPFEE_LIST_FAIL_TYPE = 'GET_STAMPFEE_LIST_FAIL_TYPE';
export const GET_STAMPFEE_COMFIRM_TYPE = 'GET_STAMPFEE_COMFIRM_TYPE';
export const GET_STAMPFEE_COMFIRM_SUCCESS_TYPE = 'GET_STAMPFEE_COMFIRM_SUCCESS_TYPE';
export const GET_STAMPFEE_COMFIRM_FAIL_TYPE = 'GET_STAMPFEE_COMFIRM_FAIL_TYPE';

//BLOG
export const GET_BLOG_LIST_TYPE = 'GET_BLOG_LIST_TYPE';
export const GET_BLOG_LIST_SUCCESS_TYPE = 'GET_BLOG_LIST_SUCCESS_TYPE';
export const GET_BLOG_LIST_FAIL_TYPE = 'GET_BLOG_LIST_FAIL_TYPE';
export const GET_CREATE_BLOG_LIST_TYPE = 'GET_CREATE_BLOG_LIST_TYPE';
export const GET_CREATE_BLOG_LIST_SUCCESS_TYPE = 'GET_CREATE_BLOG_LIST_SUCCESS_TYPE';
export const GET_CREATE_BLOG_LIST_FAIL_TYPE = 'GET_CREATE_BLOG_LIST_FAIL_TYPE';
export const GET_DELETE_BLOG_LIST_TYPE = 'GET_DELETE_BLOG_LIST_TYPE';
export const GET_DELETE_BLOG_LIST_SUCCESS_TYPE = 'GET_DELETE_BLOG_LIST_SUCCESS_TYPE';
export const GET_DELETE_BLOG_LIST_FAIL_TYPE = 'GET_DELETE_BLOG_LIST_FAIL_TYPE';
export const GET_UPDATE_BLOG_LIST_TYPE = 'GET_UPDATE_BLOG_LIST_TYPE';
export const GET_UPDATE_BLOG_LIST_SUCCESS_TYPE = 'GET_UPDATE_BLOG_LIST_SUCCESS_TYPE';
export const GET_UPDATE_BLOG_LIST_FAIL_TYPE = 'GET_UPDATE_BLOG_LIST_FAIL_TYPE';
export const GET_GETID_BLOG_LIST_TYPE = 'GET_GETID_BLOG_LIST_TYPE';
export const GET_GETID_BLOG_LIST_SUCCESS_TYPE = 'GET_GETID_BLOG_LIST_SUCCESS_TYPE';
export const GET_GETID_BLOG_LIST_FAIL_TYPE = 'GET_GETID_BLOG_LIST_FAIL_TYPE';


//NEWS
export const GET_NEWS_LIST_TYPE = 'GET_NEWS_LIST_TYPE';
export const GET_NEWS_LIST_SUCCESS_TYPE = 'GET_NEWS_LIST_SUCCESS_TYPE';
export const GET_NEWS_LIST_FAIL_TYPE = 'GET_NEWS_LIST_FAIL_TYPE';
export const GET_CREATE_NEWS_LIST_TYPE = 'GET_CREATE_NEWS_LIST_TYPE';
export const GET_CREATE_NEWS_LIST_SUCCESS_TYPE = 'GET_CREATE_NEWS_LIST_SUCCESS_TYPE';
export const GET_CREATE_NEWS_LIST_FAIL_TYPE = 'GET_CREATE_NEWS_LIST_FAIL_TYPE';
export const GET_DELETE_NEWS_LIST_TYPE = 'GET_DELETE_NEWS_LIST_TYPE';
export const GET_DELETE_NEWS_LIST_SUCCESS_TYPE = 'GET_DELETE_NEWS_LIST_SUCCESS_TYPE';
export const GET_DELETE_NEWS_LIST_FAIL_TYPE = 'GET_DELETE_NEWS_LIST_FAIL_TYPE';
export const GET_UPDATE_NEWS_LIST_TYPE = 'GET_UPDATE_NEWS_LIST_TYPE';
export const GET_UPDATE_NEWS_LIST_SUCCESS_TYPE = 'GET_UPDATE_NEWS_LIST_SUCCESS_TYPE';
export const GET_UPDATE_NEWS_LIST_FAIL_TYPE = 'GET_UPDATE_NEWS_LIST_FAIL_TYPE';
export const GET_GETID_NEWS_LIST_TYPE = 'GET_GETID_NEWS_LIST_TYPE';
export const GET_GETID_NEWS_LIST_SUCCESS_TYPE = 'GET_GETID_NEWS_LIST_SUCCESS_TYPE';
export const GET_GETID_NEWS_LIST_FAIL_TYPE = 'GET_GETID_NEWS_LIST_FAIL_TYPE';

//MENU
export const GET_MENU_LIST_TYPE = 'GET_MENU_LIST_TYPE';
export const GET_MENU_LIST_SUCCESS_TYPE = 'GET_MENU_LIST_SUCCESS_TYPE';
export const GET_MENU_LIST_FAIL_TYPE = 'GET_MENU_LIST_FAIL_TYPE';
export const GET_MENU_GET_TYPE = 'GET_MENU_GET_TYPE';
export const GET_MENU_GET_SUCCESS_TYPE = 'GET_MENU_GET_SUCCESS_TYPE';
export const GET_MENU_GET_FAIL_TYPE = 'GET_MENU_GET_FAIL_TYPE';
export const GET_CREATE_MENU_LIST_TYPE = 'GET_CREATE_MENU_LIST_TYPE';
export const GET_CREATE_MENU_LIST_SUCCESS_TYPE = 'GET_CREATE_MENU_LIST_SUCCESS_TYPE';
export const GET_CREATE_MENU_LIST_FAIL_TYPE = 'GET_CREATE_MENU_LIST_FAIL_TYPE';
export const GET_DELETE_MENU_LIST_TYPE = 'GET_DELETE_MENU_LIST_TYPE';
export const GET_DELETE_MENU_LIST_SUCCESS_TYPE = 'GET_DELETE_MENU_LIST_SUCCESS_TYPE';
export const GET_DELETE_MENU_LIST_FAIL_TYPE = 'GET_DELETE_MENU_LIST_FAIL_TYPE';
export const GET_UPDATE_MENU_LIST_TYPE = 'GET_UPDATE_MENU_LIST_TYPE';
export const GET_UPDATE_MENU_LIST_SUCCESS_TYPE = 'GET_UPDATE_MENU_LIST_SUCCESS_TYPE';
export const GET_UPDATE_MENU_LIST_FAIL_TYPE = 'GET_UPDATE_MENU_LIST_FAIL_TYPE';

//STAMP
export const GET_STAMP_LIST_TYPE = 'GET_STAMP_LIST_TYPE';
export const GET_STAMP_LIST_SUCCESS_TYPE = 'GET_STAMP_LIST_SUCCESS_TYPE';
export const GET_STAMP_LIST_FAIL_TYPE = 'GET_STAMP_LIST_FAIL_TYPE';
export const GET_CREATE_STAMP_LIST_TYPE = 'GET_CREATE_STAMP_LIST_TYPE';
export const GET_CREATE_STAMP_LIST_SUCCESS_TYPE = 'GET_CREATE_STAMP_LIST_SUCCESS_TYPE';
export const GET_CREATE_STAMP_LIST_FAIL_TYPE = 'GET_CREATE_STAMP_LIST_FAIL_TYPE';
export const GET_DELETE_STAMP_LIST_TYPE = 'GET_DELETE_STAMP_LIST_TYPE';
export const GET_DELETE_STAMP_LIST_SUCCESS_TYPE = 'GET_DELETE_STAMP_LIST_SUCCESS_TYPE';
export const GET_DELETE_STAMP_LIST_FAIL_TYPE = 'GET_DELETE_STAMP_LIST_FAIL_TYPE';

//STAMPPROVIDE
export const GET_STAMPPROVIDE_LIST_TYPE = 'GET_STAMPPROVIDEP_LIST_TYPE';
export const GET_STAMPPROVIDE_LIST_SUCCESS_TYPE = 'GET_STAMPPROVIDE_LIST_SUCCESS_TYPE';
export const GET_STAMPPROVIDE_LIST_FAIL_TYPE = 'GET_STAMPPROVIDE_LIST_FAIL_TYPE';
export const GET_COMFIRM_STAMPPROVIDE_LIST_TYPE = 'GET_COMFIRM_STAMPPROVIDE_LIST_TYPE';
export const GET_COMFIRM_STAMPPROVIDE_LIST_SUCCESS_TYPE = 'GET_COMFIRM_STAMPPROVIDE_LIST_SUCCESS_TYPE';
export const GET_COMFIRM_STAMPPROVIDE_LIST_FAIL_TYPE = 'GET_COMFIRM_STAMPPROVIDE_LIST_FAIL_TYPE';
export const GET_UNCOMFIRM_STAMPPROVIDE_LIST_TYPE = 'GET_UNCOMFIRM_STAMPPROVIDE_LIST_TYPE';
export const GET_UNCOMFIRM_STAMPPROVIDE_LIST_SUCCESS_TYPE = 'GET_UNCOMFIRM_STAMPPROVIDE_LIST_SUCCESS_TYPE';
export const GET_UNCOMFIRM_STAMPPROVIDE_LIST_FAIL_TYPE = 'GET_UNCOMFIRM_STAMPPROVIDE_LIST_FAIL_TYPE';
export const GET_QRCODE_STAMPID_TYPE = 'GET_QRCODE_STAMPID_TYPE';
export const GET_QRCODE_STAMPID_SUCCESS_TYPE = 'GET_QRCODE_STAMPID_SUCCESS_TYPE';
export const GET_QRCODE_STAMPID_FAIL_TYPE = 'GET_QRCODE_STAMPID_FAIL_TYPE';

//PLANTINGTYPE
export const GET_PLANTINGTYPE_LIST_TYPE = 'GET_PLANTINGTYPE_LIST_TYPE';
export const GET_PLANTINGTYPE_LIST_SUCCESS_TYPE = 'GET_PLANTINGTYPE_LIST_SUCCESS_TYPE';
export const GET_PLANTINGTYPE_LIST_FAIL_TYPE = 'GET_PLANTINGTYPE_LIST_FAIL_TYPE';
export const GET_CREATE_PLANTINGTYPE_LIST_TYPE = 'GET_CREATE_PLANTINGTYPE_LIST_TYPE';
export const GET_CREATE_PLANTINGTYPE_LIST_SUCCESS_TYPE = 'GET_CREATE_PLANTINGTYPE_LIST_SUCCESS_TYPE';
export const GET_CREATE_PLANTINGTYPE_LIST_FAIL_TYPE = 'GET_CREATE_PLANTINGTYPE_LIST_FAIL_TYPE';
export const GET_DELETE_PLANTINGTYPE_LIST_TYPE = 'GET_DELETE_PLANTINGTYPE_LIST_TYPE';
export const GET_DELETE_PLANTINGTYPE_LIST_SUCCESS_TYPE = 'GET_DELETE_PLANTINGTYPE_LIST_SUCCESS_TYPE';
export const GET_DELETE_PLANTINGTYPE_LIST_FAIL_TYPE = 'GET_DELETE_PLANTINGTYPE_LIST_FAIL_TYPE';

// CONFIG
export const FETCH_LIST_MENU_SUCCESS = 'config/fetchListMenuSuccess';
export const FETCH_LIST_MENU_FAILURE = 'config/fetchListMenuFailure';

// COMPANY
export const FETCH_LIST_FIELD_SUCCESS = 'company/fetchListFieldSuccess';
export const FETCH_LIST_FIELD_FAILURE = 'company/fetchListFieldFailure';
export const FETCH_LIST_PROVINCE_SUCCESS = 'company/fetchListProvinceSuccess';
export const FETCH_LIST_PROVINCE_FAILURE = 'company/fetchListProvinceFailure';
export const FETCH_LIST_DISTRICT_BY_PROVINCE_SUCCESS = 'company/fetchListDistrictByProvinceSuccess';
export const FETCH_LIST_DISTRICT_BY_PROVINCE_FAILURE = 'company/fetchListDistrictByProvinceFaliure';
export const FETCH_LIST_WARD_BY_DISTRICT_SUCCESS = 'company/fetchListWardByDistrictSuccess';
export const FETCH_LIST_WARD_BY_DISTRICT_FAILURE = 'company/fetchListWardByDistrictFailure';

// AREA CODE
export const AREA_CODE_FETCH_LIST_PROVINCE_SUCCESS = 'areaCode/fetchListProvinceSuccess';
export const AREA_CODE_FETCH_LIST_PROVINCE_FAILURE = 'areaCode/fetchListProvinceFailure';
export const AREA_CODE_FETCH_LIST_DISTRICT_BY_PROVINCE_SUCCESS = 'areaCode/fetchListDistrictByProvinceSuccess';
export const AREA_CODE_FETCH_LIST_DISTRICT_BY_PROVINCE_FAILURE = 'areaCode/fetchListDistrictByProvinceFailure';
export const AREA_CODE_FETCH_LIST_WARD_BY_DISTRICT_SUCCESS = 'areaCode/fetchListWardByDistrictSuccess';
export const AREA_CODE_FETCH_LIST_WARD_BY_DISTRICT_FAILURE = 'areaCode/fetchListWardByDistrictFailure';
export const AREA_CODE_ADD_SUCCESS = 'areaCode/addSuccess';
export const AREA_CODE_EDIT_SUCCESS = 'areaCode/editSuccess';
export const AREA_CODE_DELETE_SUCCESS = 'areaCode/deleteSuccess';

// AREA ROLE
export const AREA_ROLE_FETCH_LIST_ROLE_SUCCESS = 'areaRole/fetchListRoleSuccess';
export const AREA_ROLE_FETCH_LIST_ZONE_SUCCESS = 'areaRole/fetchListZoneSuccess';
export const AREA_ROLE_FETCH_LIST_ROLE_FAILURE = 'areaRole/fetchListRoleFailure';
export const AREA_ROLE_FETCH_LIST_ZONE_FAILURE = 'areaRole/fetchListZoneFailure';
export const AREA_ROLE_ADD_SUCCESS = 'areaRole/addSuccess';
export const AREA_ROLE_EDIT_SUCCESS = 'areaRole/editSuccess';
export const AREA_ROLE_DELETE_SUCCESS = 'areaRole/deleteSuccess';

// TYPE ZONE PROPERTY
export const TYPE_ZONE_PROPERTY_FETCH_LIST_ZONE_SUCCESS = 'typeZoneProperty/fetchListTypeZonePropertySuccess';
export const TYPE_ZONE_PROPERTY_FETCH_LIST_ZONE_FAILURE = 'typeZoneProperty/fetchListTypeZonePropertyFailure';
export const TYPE_ZONE_PROPERTY_ADD_SUCCESS = 'typeZoneProperty/addSuccess';
export const TYPE_ZONE_PROPERTY_EDIT_SUCCESS = 'typeZoneProperty/editSuccess';
export const TYPE_ZONE_PROPERTY_DELETE_SUCCESS = 'typeZoneProperty/deleteSuccess';

//COMPANY REPORT
export const COMPANY_MONTH_REPORT_TYPE = 'COMPANY_MONTH_REPORT_TYPE';
export const COMPANY_MONTH_REPORT_SUCCESS_TYPE = 'COMPANY_MONTH_REPORT_SUCCESS_TYPE';
export const COMPANY_MONTH_REPORT_FAIL_TYPE = 'COMPANY_MONTH_REPORT_FAIL_TYPE';
export const COMPANY_QUARTER_REPORT_TYPE = 'COMPANY_QUARTER_REPORT_TYPE';
export const COMPANY_QUARTER_REPORT_SUCCESS_TYPE = 'COMPANY_QUARTER_REPORT_SUCCESS_TYPE';
export const COMPANY_QUARTER_REPORT_FAIL_TYPE = 'COMPANY_QUARTER_REPORT_FAIL_TYPE';
export const COMPANY_YEAR_REPORT_TYPE = 'COMPANY_YEAR_REPORT_TYPE';
export const COMPANY_YEAR_REPORT_SUCCESS_TYPE = 'COMPANY_YEAR_REPORT_SUCCESS_TYPE';
export const COMPANY_YEAR_REPORT_FAIL_TYPE = 'COMPANY_YEAR_REPORT_FAIL_TYPE';

//GROWTH STAMP REPORT
export const GROWTH_STAMP_MONTH_REPORT_TYPE = 'GROWTH_STAMP_MONTH_REPORT_TYPE';
export const GROWTH_STAMP_MONTH_REPORT_SUCCESS_TYPE = 'GROWTH_STAMP_MONTH_REPORT_SUCCESS_TYPE';
export const GROWTH_STAMP_MONTH_REPORT_FAIL_TYPE = 'GROWTH_STAMP_MONTH_REPORT_FAIL_TYPE';
export const GROWTH_STAMP_QUARTER_REPORT_TYPE = 'GROWTH_STAMP_QUARTER_REPORT_TYPE';
export const GROWTH_STAMP_QUARTER_REPORT_SUCCESS_TYPE = 'GROWTH_STAMP_QUARTER_REPORT_SUCCESS_TYPE';
export const GROWTH_STAMP_QUARTER_REPORT_FAIL_TYPE = 'GROWTH_STAMP_QUARTER_REPORT_FAIL_TYPE';
export const GROWTH_STAMP_YEAR_REPORT_TYPE = 'GROWTH_STAMP_YEAR_REPORT_TYPE';
export const GROWTH_STAMP_YEAR_REPORT_SUCCESS_TYPE = 'GROWTH_STAMP_YEAR_REPORT_SUCCESS_TYPE';
export const GROWTH_STAMP_YEAR_REPORT_FAIL_TYPE = 'GROWTH_STAMP_YEAR_REPORT_FAIL_TYPE';

//SALE REGISTER REPORT
export const SALE_REGISTER_MONTH_REPORT_TYPE = 'SALE_REGISTER_MONTH_REPORT_TYPE';
export const SALE_REGISTER_MONTH_REPORT_SUCCESS_TYPE = 'SALE_REGISTER_MONTH_REPORT_SUCCESS_TYPE';
export const SALE_REGISTER_MONTH_REPORT_FAIL_TYPE = 'SALE_REGISTER_MONTH_REPORT_FAIL_TYPE';
export const SALE_REGISTER_QUARTER_REPORT_TYPE = 'SALE_REGISTER_QUARTER_REPORT_TYPE';
export const SALE_REGISTER_QUARTER_REPORT_SUCCESS_TYPE = 'SALE_REGISTER_QUARTER_REPORT_SUCCESS_TYPE';
export const SALE_REGISTER_QUARTER_REPORT_FAIL_TYPE = 'SALE_REGISTER_QUARTER_REPORT_FAIL_TYPE';
export const SALE_REGISTER_YEAR_REPORT_TYPE = 'SALE_REGISTER_YEAR_REPORT_TYPE';
export const SALE_REGISTER_YEAR_REPORT_SUCCESS_TYPE = 'SALE_REGISTER_YEAR_REPORT_SUCCESS_TYPE';
export const SALE_REGISTER_YEAR_REPORT_FAIL_TYPE = 'SALE_REGISTER_YEAR_REPORT_FAIL_TYPE';

//SALE STAMP REPORT
export const SALE_STAMP_MONTH_REPORT_TYPE = 'SALE_STAMP_MONTH_REPORT_TYPE';
export const SALE_STAMP_MONTH_REPORT_SUCCESS_TYPE = 'SALE_STAMP_MONTH_REPORT_SUCCESS_TYPE';
export const SALE_STAMP_MONTH_REPORT_FAIL_TYPE = 'SALE_STAMP_MONTH_REPORT_FAIL_TYPE';
export const SALE_STAMP_QUARTER_REPORT_TYPE = 'SALE_STAMP_QUARTER_REPORT_TYPE';
export const SALE_STAMP_QUARTER_REPORT_SUCCESS_TYPE = 'SALE_STAMP_QUARTER_REPORT_SUCCESS_TYPE';
export const SALE_STAMP_QUARTER_REPORT_FAIL_TYPE = 'SALE_STAMP_QUARTER_REPORT_FAIL_TYPE';
export const SALE_STAMP_YEAR_REPORT_TYPE = 'SALE_STAMP_YEAR_REPORT_TYPE';
export const SALE_STAMP_YEAR_REPORT_SUCCESS_TYPE = 'SALE_STAMP_YEAR_REPORT_SUCCESS_TYPE';
export const SALE_STAMP_YEAR_REPORT_FAIL_TYPE = 'SALE_STAMP_YEAR_REPORT_FAIL_TYPE';

// PLANTING AREA
export const PLANTING_ZONE_FETCH_LIST_SUCCESS = 'PLANTING_ZONE_FETCH_LIST_SUCCESS';
export const PLANTING_ZONE_FETCH_LIST_FAILURE = 'PLANTING_ZONE_FETCH_LIST_FAILURE';
export const PLANTING_ZONE_FETCH_LIST_PLANTING_TYPE_SUCCESS = 'PLANTING_ZONE_FETCH_LIST_PLANTING_TYPE_SUCCESS';
export const PLANTING_ZONE_FETCH_LIST_PLANTING_TYPE_FAILURE = 'PLANTING_ZONE_FETCH_LIST_PLANTING_TYPE_FAILURE';
export const PLANTING_ZONE_FETCH_LIST_FOR_COMBOBOX_SUCCESS = 'PLANTING_ZONE_FETCH_LIST_FOR_COMBOBOX_SUCCESS';
export const PLANTING_ZONE_FETCH_LIST_FOR_COMBOBOX_FAILURE = 'PLANTING_ZONE_FETCH_LIST_FOR_COMBOBOX_FAILURE';

// CONFIG SYSTEM
export const CONFIG_FETCH_INFO_COMPANY_SUCCESS = 'CONFIG_FETCH_INFO_COMPANY_SUCCESS';
export const CONFIG_FETCH_CONFIG_SYSTEM_SUCCESS = 'CONFIG_FETCH_CONFIG_SYSTEM_SUCCESS';
export const CONFIG_FETCH_LIST_CONFIG_SERVER_SUCCESS = 'CONFIG_FETCH_LIST_CONFIG_SERVER_SUCCESS';

export const CONFIG_FETCH_LIST_PROVINCE_FOR_INFO_COMPANY_FAIL = 'CONFIG_FETCH_LIST_PROVINCE_FOR_INFO_COMPANY_FAIL';
export const CONFIG_FETCH_LIST_PROVINCE_FOR_INFO_COMPANY_SUCCESS = 'CONFIG_FETCH_LIST_PROVINCE_FOR_INFO_COMPANY_SUCCESS';
export const CONFIG_FETCH_LIST_PROVINCE_FOR_INFO_COMPANY_TYPE = 'CONFIG_FETCH_LIST_PROVINCE_FOR_INFO_COMPANY_TYPE';

export const CONFIG_FETCH_LIST_DISTRICT_FOR_INFO_COMPANY_SUCCESS = 'CONFIG_FETCH_LIST_DISTRICT_FOR_INFO_COMPANY_SUCCESS';
export const CONFIG_FETCH_LIST_WARD_FOR_INFO_COMPANY_SUCCESS = 'CONFIG_FETCH_LIST_WARD_FOR_INFO_COMPANY_SUCCESS';
export const CONFIG_GET_FTP_SUCCESS = 'CONFIG_GET_FTP_SUCCESS';
export const CONFIG_CREATE_FTP_SUCCESS = 'CONFIG_CREATE_FTP_SUCCESS';
export const CONFIG_UPDATE_FTP_SUCCESS = 'CONFIG_UPDATE_FTP_SUCCESS';
export const CONFIG_DELETE_FTP_SUCCESS = 'CONFIG_DELETE_FTP_SUCCESS';

//MATERIAL GROUP
export const MATERIAL_GROUP_LIST_TYPE = 'MATERIAL_GROUP_LIST_TYPE';
export const MATERIAL_GROUP_LIST_SUCCESS_TYPE = 'MATERIAL_GROUP_LIST_SUCCESS_TYPE';
export const MATERIAL_GROUP_LIST_FAIL_TYPE = 'MATERIAL_GROUP_LIST_FAIL_TYPE';
export const MATERIAL_GROUP_LOG_LIST_TYPE = 'MATERIAL_GROUP_LOG_LIST_TYPE';
export const MATERIAL_GROUP_LOG_LIST_SUCCESS_TYPE = 'MATERIAL_GROUP_LOG_LIST_SUCCESS_TYPE';
export const MATERIAL_GROUP_LOG_LIST_FAIL_TYPE = 'MATERIAL_GROUP_LOG_LIST_FAIL_TYPE';
export const MATERIAL_GROUP_CREATE_TYPE = 'MATERIAL_GROUP_CREATE_TYPE';
export const MATERIAL_GROUP_CREATE_SUCCESS_TYPE = 'MATERIAL_GROUP_CREATE_SUCCESS_TYPE';
export const MATERIAL_GROUP_CREATE_FAIL_TYPE = 'MATERIAL_GROUP_CREATE_FAIL_TYPE';
export const MATERIAL_GROUP_GET_TYPE = 'MATERIAL_GROUP_GET_TYPE';
export const MATERIAL_GROUP_GET_SUCCESS_TYPE = 'MATERIAL_GROUP_GET_SUCCESS_TYPE';
export const MATERIAL_GROUP_GET_FAIL_TYPE = 'MATERIAL_GROUP_GET_FAIL_TYPE';
export const MATERIAL_GROUP_UPDATE_TYPE = 'MATERIAL_GROUP_UPDATE_TYPE';
export const MATERIAL_GROUP_UPDATE_SUCCESS_TYPE = 'MATERIAL_GROUP_UPDATE_SUCCESS_TYPE';
export const MATERIAL_GROUP_UPDATE_FAIL_TYPE = 'MATERIAL_GROUP_UPDATE_FAIL_TYPE';
export const MATERIAL_GROUP_DELETE_TYPE = 'MATERIAL_GROUP_DELETE_TYPE';
export const MATERIAL_GROUP_DELETE_SUCCESS_TYPE = 'MATERIAL_GROUP_DELETE_SUCCESS_TYPE';
export const MATERIAL_GROUP_DELETE_FAIL_TYPE = 'MATERIAL_GROUP_DELETE_FAIL_TYPE';
export const MATERIAL_GROUP_LOCK_TYPE = 'MATERIAL_GROUP_LOCK_TYPE';
export const MATERIAL_GROUP_LOCK_SUCCESS_TYPE = 'MATERIAL_GROUP_LOCK_SUCCESS_TYPE';
export const MATERIAL_GROUP_LOCK_FAIL_TYPE = 'MATERIAL_GROUP_LOCK_FAIL_TYPE';

//PRODUCT GROUP
export const PRODUCT_GROUP_LIST_TYPE = 'PRODUCT_GROUP_LIST_TYPE';
export const PRODUCT_GROUP_LIST_SUCCESS_TYPE = 'PRODUCT_GROUP_LIST_SUCCESS_TYPE';
export const PRODUCT_GROUP_LIST_FAIL_TYPE = 'PRODUCT_GROUP_LIST_FAIL_TYPE';
export const PRODUCT_GROUP_CREATE_TYPE = 'PRODUCT_GROUP_CREATE_TYPE';
export const PRODUCT_GROUP_CREATE_SUCCESS_TYPE = 'PRODUCT_GROUP_CREATE_SUCCESS_TYPE';
export const PRODUCT_GROUP_CREATE_FAIL_TYPE = 'PRODUCT_GROUP_CREATE_FAIL_TYPE';
export const PRODUCT_GROUP_GET_TYPE = 'PRODUCT_GROUP_GET_TYPE';
export const PRODUCT_GROUP_GET_SUCCESS_TYPE = 'PRODUCT_GROUP_GET_SUCCESS_TYPE';
export const PRODUCT_GROUP_GET_FAIL_TYPE = 'PRODUCT_GROUP_GET_FAIL_TYPE';
export const PRODUCT_GROUP_UPDATE_TYPE = 'PRODUCT_GROUP_UPDATE_TYPE';
export const PRODUCT_GROUP_UPDATE_SUCCESS_TYPE = 'PRODUCT_GROUP_UPDATE_SUCCESS_TYPE';
export const PRODUCT_GROUP_UPDATE_FAIL_TYPE = 'PRODUCT_GROUP_UPDATE_FAIL_TYPE';
export const PRODUCT_GROUP_DELETE_TYPE = 'PRODUCT_GROUP_DELETE_TYPE';
export const PRODUCT_GROUP_DELETE_SUCCESS_TYPE = 'PRODUCT_GROUP_DELETE_SUCCESS_TYPE';
export const PRODUCT_GROUP_DELETE_FAIL_TYPE = 'PRODUCT_GROUP_DELETE_FAIL_TYPE';
export const PRODUCT_GROUP_LOCK_TYPE = 'PRODUCT_GROUP_LOCK_TYPE';
export const PRODUCT_GROUP_LOCK_SUCCESS_TYPE = 'PRODUCT_GROUP_LOCK_SUCCESS_TYPE';
export const PRODUCT_GROUP_LOCK_FAIL_TYPE = 'PRODUCT_GROUP_LOCK_FAIL_TYPE';

//CONFIG WEBSITE
export const CONFIG_WEBSITE_GET_TYPE = 'CONFIG_WEBSITE_GET_TYPE';
export const CONFIG_WEBSITE_GET_SUCCESS_TYPE = 'CONFIG_WEBSITE_GET_SUCCESS_TYPE';
export const CONFIG_WEBSITE_GET_FAIL_TYPE = 'CONFIG_WEBSITE_GET_FAIL_TYPE';
export const CONFIG_WEBSITE_UPDATE_TYPE = 'CONFIG_WEBSITE_UPDATE_TYPE';
export const CONFIG_WEBSITE_UPDATE_SUCCESS_TYPE = 'CONFIG_WEBSITE_UPDATE_SUCCESS_TYPE';
export const CONFIG_WEBSITE_UPDATE_FAIL_TYPE = 'CONFIG_WEBSITE_UPDATE_FAIL_TYPE';

//AREA
export const AREA_GET_TYPE = 'AREA_GET_TYPE';
export const AREA_GET_SUCCESS_TYPE = 'AREA_GET_SUCCESS_TYPE';
export const AREA_GET_FAIL_TYPE = 'AREA_GET_FAIL_TYPE';
export const AREA_GET_LIST_COMPANY_PLANNING_AREA_SUCCESS_TYPE = 'AREA_GET_LIST_COMPANY_PLANNING_AREA_SUCCESS_TYPE';
export const AREA_GET_LIST_COMPANY_PLANNING_AREA_TYPE = 'AREA_GET_LIST_COMPANY_PLANNING_AREA_TYPE';
export const AREA_GET_LIST_COMPANY_PLANNING_AREA_FAIL_TYPE = 'AREA_GET_LIST_COMPANY_PLANNING_AREA_FAIL_TYPE';
export const AREA_GET_LIST_COMPANY_OUTER_PLANNING_AREA_TYPE = 'AREA_GET_LIST_COMPANY_OUTER_PLANNING_AREA_TYPE';
export const AREA_GET_LIST_COMPANY_OUTER_PLANNING_AREA_SUCCESS_TYPE = 'AREA_GET_LIST_COMPANY_OUTER_PLANNING_AREA_SUCCESS_TYPE';
export const AREA_GET_LIST_COMPANY_OUTER_PLANNING_AREA_FAIL_TYPE = 'AREA_GET_LIST_COMPANY_OUTER_PLANNING_AREA_FAIL_TYPE';

//PRODUCT REPORT
export const PRODUCT_REPORT_GET_TYPE = 'PRODUCT_REPORT_GET_TYPE'
export const PRODUCT_REPORT_GET_SUCCESS_TYPE = 'PRODUCT_REPORT_GET_SUCCESS_TYPE'
export const PRODUCT_REPORT_GET_FAIL_TYPE = 'PRODUCT_REPORT_GET_FAIL_TYPE'
export const PRODUCT_REPORT_GET_BY_GROUP_TYPE = 'PRODUCT_REPORT_GET_BY_GROUP_TYPE'
export const PRODUCT_REPORT_GET_BY_GROUP_SUCCESS_TYPE = 'PRODUCT_REPORT_GET_BY_GROUP_SUCCESS_TYPE'
export const PRODUCT_REPORT_GET_BY_GROUP_FAIL_TYPE = 'PRODUCT_REPORT_GET_BY_GROUP_FAIL_TYPE'
export const PRODUCT_REPORT_GET_LIST_FIELD_COMBOBOX_SUCCESS_TYPE = 'PRODUCT_REPORT_GET_LIST_FIELD_COMBOBOX_SUCCESS_TYPE';
export const PRODUCT_REPORT_GET_LIST_FIELD_COMBOBOX_FAIL_TYPE = 'PRODUCT_REPORT_GET_LIST_FIELD_COMBOBOX_FAIL_TYPE';
export const PRODUCT_REPORT_GET_PRODUCT_REPORT_SUCCESS_TYPE = 'PRODUCT_REPORT_GET_LIST_FIELD_COMBOBOX_SUCCESS_TYPE';
export const PRODUCT_REPORT_GET_PRODUCT_REPORT_FAIL_TYPE = 'PRODUCT_REPORT_GET_LIST_FIELD_COMBOBOX_FAIL_TYPE';

//UNIT
export const UNIT_LIST_TYPE = 'UNIT_LIST_TYPE';
export const UNIT_LIST_SUCCESS_TYPE = 'UNIT_LIST_SUCCESS_TYPE';
export const UNIT_LIST_FAIL_TYPE = 'UNIT_LIST_FAIL_TYPE';
export const UNIT_CREATE_TYPE = 'UNIT_CREATE_TYPE';
export const UNIT_CREATE_SUCCESS_TYPE = 'UNIT_CREATE_SUCCESS_TYPE';
export const UNIT_CREATE_FAIL_TYPE = 'UNIT_CREATE_FAIL_TYPE';
export const UNIT_GET_TYPE = 'UNIT_GET_TYPE';
export const UNIT_GET_SUCCESS_TYPE = 'UNIT_GET_SUCCESS_TYPE';
export const UNIT_GET_FAIL_TYPE = 'UNIT_GET_FAIL_TYPE';
export const UNIT_UPDATE_TYPE = 'UNIT_UPDATE_TYPE';
export const UNIT_UPDATE_SUCCESS_TYPE = 'UNIT_UPDATE_SUCCESS_TYPE';
export const UNIT_UPDATE_FAIL_TYPE = 'UNIT_UPDATE_FAIL_TYPE';
export const UNIT_DELETE_TYPE = 'UNIT_DELETE_TYPE';
export const UNIT_DELETE_SUCCESS_TYPE = 'UNIT_DELETE_SUCCESS_TYPE';
export const UNIT_DELETE_FAIL_TYPE = 'UNIT_DELETE_FAIL_TYPE';
export const UNIT_LOCK_SUCCESS_TYPE = 'UNIT_LOCK_SUCCESS_TYPE';
export const UNIT_LOCK_TYPE = 'UNIT_LOCK_TYPE';
export const UNIT_LOCK_FAIL_TYPE = 'UNIT_LOCK_FAIL_TYPE';

//STAMPTEMPLATE
export const STAMPTEMPLATE_LIST_TYPE = 'STAMPTEMPLATE_LIST_TYPE';
export const STAMPTEMPLATE_LIST_SUCCESS_TYPE = 'STAMPTEMPLATE_LIST_SUCCESS_TYPE';
export const STAMPTEMPLATE_LIST_FAIL_TYPE = 'STAMPTEMPLATE_LIST_FAIL_TYPE';
export const STAMPTEMPLATE_CREATE_TYPE = 'STAMPTEMPLATE_CREATE_TYPE';
export const STAMPTEMPLATE_CREATE_SUCCESS_TYPE = 'STAMPTEMPLATE_CREATE_SUCCESS_TYPE';
export const STAMPTEMPLATE_CREATE_FAIL_TYPE = 'STAMPTEMPLATE_CREATE_FAIL_TYPE';
export const STAMPTEMPLATE_GET_TYPE = 'STAMPTEMPLATE_GET_TYPE';
export const STAMPTEMPLATE_GET_SUCCESS_TYPE = 'STAMPTEMPLATE_GET_SUCCESS_TYPE';
export const STAMPTEMPLATE_GET_FAIL_TYPE = 'STAMPTEMPLATE_GET_FAIL_TYPE';
export const STAMPTEMPLATE_UPDATE_TYPE = 'STAMPTEMPLATE_UPDATE_TYPE';
export const STAMPTEMPLATE_UPDATE_SUCCESS_TYPE = 'STAMPTEMPLATE_UPDATE_SUCCESS_TYPE';
export const STAMPTEMPLATE_UPDATE_FAIL_TYPE = 'STAMPTEMPLATE_UPDATE_FAIL_TYPE';
export const STAMPTEMPLATE_DELETE_TYPE = 'STAMPTEMPLATE_DELETE_TYPE';
export const STAMPTEMPLATE_DELETE_SUCCESS_TYPE = 'STAMPTEMPLATE_DELETE_SUCCESS_TYPE';
export const STAMPTEMPLATE_DELETE_FAIL_TYPE = 'STAMPTEMPLATE_DELETE_FAIL_TYPE';

//TRACE
export const TRACE_LIST_TYPE = 'TRACE_LIST_TYPE';
export const TRACE_LIST_SUCCESS_TYPE = 'TRACE_LIST_SUCCESS_TYPE';
export const TRACE_LIST_FAIL_TYPE = 'TRACE_LIST_FAIL_TYPE';
export const TRACE_GET_TYPE = 'TRACE_GET_TYPE';
export const TRACE_GET_SUCCESS_TYPE = 'TRACE_GET_SUCCESS_TYPE';
export const TRACE_GET_FAIL_TYPE = 'TRACE_GET_FAIL_TYPE';
export const TRACE_GET_HISTORY_FAIL_TYPE = 'TRACE_GET_HISTORY_FAIL_TYPE';
export const TRACE_GET_HISTORY_TYPE = 'TRACE_GET_HISTORY_TYPE';
export const TRACE_GET_HISTORY_SUCCESS_TYPE = 'TRACE_GET_HISTORY_SUCCESS_TYPE'

//PRODUCTS
export const PRODUCTS_LIST_TYPE = 'PRODUCTS_LIST_TYPE';
export const PRODUCTS_LIST_SUCCESS_TYPE = 'PRODUCTS_LIST_SUCCESS_TYPE';
export const PRODUCTS_LIST_FAIL_TYPE = 'PRODUCTS_LIST_FAIL_TYPE';
export const PRODUCTS_GET_TYPE = 'PRODUCTS_GET_TYPE';
export const PRODUCTS_GET_SUCCESS_TYPE = 'PRODUCTS_GET_SUCCESS_TYPE';
export const PRODUCTS_GET_FAIL_TYPE = 'PRODUCTS_GET_FAIL_TYPE';
export const PRODUCTS_LIST_LOCK_TYPE = 'PRODUCTS_LIST_LOCK_TYPE';
export const PRODUCTS_LIST_LOCK_SUCCESS_TYPE = 'PRODUCTS_LIST_LOCK_SUCCESS_TYPE';
export const PRODUCTS_LIST_LOCK_FAIL_TYPE = 'PRODUCTS_LIST_LOCK_FAIL_TYPE';
export const PRODUCTS_CONFIRM_TYPE = 'PRODUCTS_CONFIRM_TYPE';
export const PRODUCTS_CONFIRM_SUCCESS_TYPE = 'PRODUCTS_CONFIRM_SUCCESS_TYPE';
export const PRODUCTS_CONFIRM_FAIL_TYPE = 'PRODUCTS_CONFIRM_FAIL_TYPE';
export const PRODUCTS_UNCONFIRM_TYPE = 'PRODUCTS_UNCONFIRM_TYPE';
export const PRODUCTS_UNCONFIRM_SUCCESS_TYPE = 'PRODUCTS_UNCONFIRM_SUCCESS_TYPE';
export const PRODUCTS_UNCONFIRM_FAIL_TYPE = 'PRODUCTS_UNCONFIRM_FAIL_TYPE';

export const PRODUCTS_LIST_VERIFY_TYPE = 'PRODUCTS_LIST_VERIFY_TYPE';
export const PRODUCTS_LIST_VERIFY_SUCCESS_TYPE = 'PRODUCTS_LIST_VERIFY_SUCCESS_TYPE';
export const PRODUCTS_LIST_VERIFY_FAIL_TYPE = 'PRODUCTS_LIST_VERIFY_FAIL_TYPE';
export const PRODUCTS_VERIFY_IMAGE_TYPE = 'PRODUCTS_VERIFY_IMAGE_TYPE';
export const PRODUCTS_VERIFY_IMAGE_SUCCESS_TYPE = 'PRODUCTS_VERIFY_IMAGE_SUCCESS_TYPE';
export const PRODUCTS_VERIFY_IMAGE_FAIL_TYPE = 'PRODUCTS_VERIFY_IMAGE_FAIL_TYPE';
export const PRODUCTS_UPLOAD_VERIFY_TYPE = 'PRODUCTS_UPLOAD_VERIFY_TYPE';
export const PRODUCTS_UPLOAD_VERIFY_SUCCESS_TYPE = 'PRODUCTS_UPLOAD_VERIFY_SUCCESS_TYPE';
export const PRODUCTS_UPLOAD_VERIFY_FAIL_TYPE = 'PRODUCTS_UPLOAD_VERIFY_FAIL_TYPE';
export const PRODUCTS_DELETE_UPLOAD_VERIFY_TYPE = 'PRODUCTS_DELETE_UPLOAD_VERIFY_TYPE';
export const PRODUCTS_DELETE_UPLOAD_VERIFY_SUCCESS_TYPE = 'PRODUCTS_DELETE_UPLOAD_VERIFY_SUCCESS_TYPE';
export const PRODUCTS_DELETE_UPLOAD_VERIFY_FAIL_TYPE = 'PRODUCTS_DELETE_UPLOAD_VERIFY_FAIL_TYPE';

// USERPROVINCE

export const USERPROVINCE_LIST_TYPE = 'USERPROVINCE_LIST_TYPE';
export const USERPROVINCE_LIST_SUCCESS_TYPE = 'USERPROVINCE_LIST_SUCCESS_TYPE';
export const USERPROVINCE_LIST_FAIL_TYPE = 'USERPROVINCE_LIST_FAIL_TYPE';
export const USERPROVINCE_DELETE_TYPE = 'USERPROVINCE_DELETE_TYPE';
export const USERPROVINCE_DELETE_SUCCESS_TYPE = 'USERPROVINCE_DELETE_SUCCESS_TYPE';
export const USERPROVINCE_DELETE_FAIL_TYPE = 'USERPROVINCE_DELETE_FAIL_TYPE';
export const USERPROVINCE_CREATE_TYPE = 'USERPROVINCE_CREATE_TYPE';
export const USERPROVINCE_CREATE_SUCCESS_TYPE = 'USERPROVINCE_CREATE_SUCCESS_TYPE';
export const USERPROVINCE_CREATE_FAIL_TYPE = 'USERPROVINCE_CREATE_FAIL_TYPE';
export const USERPROVINCE_UPDATE_TYPE = 'USERPROVINCE_UPDATE_TYPE';
export const USERPROVINCE_UPDATE_SUCCESS_TYPE = 'USERPROVINCE_UPDATE_SUCCESS_TYPE';
export const USERPROVINCE_UPDATE_FAIL_TYPE = 'USERPROVINCE_UPDATE_FAIL_TYPE';
export const USERPROVINCE_GET_TYPE = 'USERPROVINCE_GET_TYPE';
export const USERPROVINCE_GET_SUCCESS_TYPE = 'USERPROVINCE_GET_SUCCESS_TYPE';
export const USERPROVINCE_GET_FAIL_TYPE = 'USERPROVINCE_GET_FAIL_TYPE';

//ABOUTUS
export const ABOUTUS_LIST_TYPE = 'ABOUTUS_LIST_TYPE';
export const ABOUTUS_LIST_SUCCESS_TYPE = 'ABOUTUS_LIST_SUCCESS_TYPE';
export const ABOUTUS_LIST_FAIL_TYPE = 'ABOUTUS_LIST_FAIL_TYPE';
export const ABOUTUS_UPDATE_TYPE = 'ABOUTUS_UPDATE_TYPE';
export const ABOUTUS_UPDATE_SUCCESS_TYPE = 'ABOUTUS_UPDATE_SUCCESS_TYPE';
export const ABOUTUS_UPDATE_FAIL_TYPE = 'ABOUTUS_UPDATE_FAIL_TYPE';
export const ABOUTUS_GET_LIST_ALERT_ROLES_TYPE = 'ABOUTUS_GET_LIST_ALERT_ROLES_TYPE';
export const ABOUTUS_GET_LIST_ALERT_SUCCESS_TYPE = 'ABOUTUS_GET_LIST_ALERT_SUCCESS_TYPE';
export const ABOUTUS_GET_LIST_ALERT_ROLES_FAIL_TYPE = 'ABOUTUS_GET_LIST_ALERT_ROLES_FAIL_TYPE';
export const ABOUTUS_CREATE_ALERT_ROLES_TYPE = 'ABOUTUS_CREATE_ALERT_ROLES_TYPE';
export const ABOUTUS_CREATE_ALERT_ROLES_SUCCESS_TYPE = 'ABOUTUS_CREATE_ALERT_ROLES_SUCCESS_TYPE';
export const ABOUTUS_CREATE_ALERT_ROLES_FAIL_TYPE = 'ABOUTUS_CREATE_ALERT_ROLES_FAIL_TYPE';
export const ABOUTUS_DELETE_ALERT_ROLES_TYPE = 'ABOUTUS_DELETE_ALERT_ROLES_TYPE';
export const ABOUTUS_DELETE_ALERT_ROLES_SUCCESS_TYPE = 'ABOUTUS_DELETE_ALERT_ROLES_SUCCESS_TYPE';
export const ABOUTUS_DELETE_ALERT_ROLES_FAIL_TYPE = 'ABOUTUS_DELETE_ALERT_ROLES_FAIL_TYPE';

export const ABOUTUS_GET_LIST_ALERT_ROLES_BY_SELECT_TYPE = 'ABOUTUS_GET_LIST_ALERT_ROLES_BY_SELECT_TYPE';
export const ABOUTUS_GET_LIST_ALERT_ROLES_BY_SELECT_SUCCESS_TYPE = 'ABOUTUS_GET_LIST_ALERT_ROLES_BY_SELECT_SUCCESS_TYPE';
export const ABOUTUS_GET_LIST_ALERT_ROLES_BY_SELECT_FAIL_TYPE = 'ABOUTUS_GET_LIST_ALERT_ROLES_BY_SELECT_FAIL_TYPE';

export const ABOUTUS_GET_LIST_ROLES_BY_SELECT_TYPE = 'ABOUTUS_GET_LIST_ROLES_BY_SELECT_TYPE';
export const ABOUTUS_GET_LIST_ROLES_BY_SELECT_SUCCESS_TYPE = 'ABOUTUS_GET_LIST_ROLES_BY_SELECT_SUCCESS_TYPE';
export const ABOUTUS_GET_LIST_ROLES_BY_SELECT_FAIL_TYPE = 'ABOUTUS_GET_LIST_ROLES_BY_SELECT_FAIL_TYPE';

//SERVER
export const SERVER_LIST_TYPE = 'SERVER_LIST_TYPE';
export const SERVER_LIST_SUCCESS_TYPE = 'SERVER_LIST_SUCCESS_TYPE';
export const SERVER_LIST_FAIL_TYPE = 'SERVER_LIST_FAIL_TYPE';

//MASTERSERVER

export const MASTERSERVER_LIST_TYPE = 'MASTERSERVER_LIST_TYPE';
export const MASTERSERVER_LIST_SUCCESS_TYPE = 'MASTERSERVER_LIST_SUCCESS_TYPE';
export const MASTERSERVER_LIST_FAIL_TYPE = 'MASTERSERVER_LIST_FAIL_TYPE';
export const MASTERSERVER_DELETE_TYPE = 'MASTERSERVER_DELETE_TYPE';
export const MASTERSERVER_DELETE_SUCCESS_TYPE = 'MASTERSERVER_DELETE_SUCCESS_TYPE';
export const MASTERSERVER_DELETE_FAIL_TYPE = 'MASTERSERVER_DELETE_FAIL_TYPE';
export const MASTERSERVER_CREATE_TYPE = 'MASTERSERVER_CREATE_TYPE';
export const MASTERSERVER_CREATE_SUCCESS_TYPE = 'MASTERSERVER_CREATE_SUCCESS_TYPE';
export const MASTERSERVER_CREATE_FAIL_TYPE = 'MASTERSERVER_CREATE_FAIL_TYPE';
export const MASTERSERVER_UPDATE_TYPE = 'MASTERSERVER_UPDATE_TYPE';
export const MASTERSERVER_UPDATE_SUCCESS_TYPE = 'MASTERSERVER_UPDATE_SUCCESS_TYPE';
export const MASTERSERVER_UPDATE_FAIL_TYPE = 'MASTERSERVER_UPDATE_FAIL_TYPE';
export const MASTERSERVER_GET_TYPE = 'MASTERSERVER_GET_TYPE';
export const MASTERSERVER_GET_SUCCESS_TYPE = 'MASTERSERVER_GET_SUCCESS_TYPE';
export const MASTERSERVER_GET_FAIL_TYPE = 'MASTERSERVER_GET_FAIL_TYPE';

//PARTNER
export const PARTNER_LIST_TYPE = 'PARTNER_LIST_TYPE';
export const PARTNER_LIST_SUCCESS_TYPE = 'PARTNER_LIST_SUCCESS_TYPE';
export const PARTNER_LIST_FAIL_TYPE = 'PARTNER_LIST_FAIL_TYPE';
export const PARTNER_CREATE_TYPE = 'PARTNER_CREATE_TYPE';
export const PARTNER_CREATE_SUCCESS_TYPE = 'PARTNER_CREATE_SUCCESS_TYPE';
export const PARTNER_CREATE_FAIL_TYPE = 'PARTNER_CREATE_FAIL_TYPE';
export const PARTNER_GET_TYPE = 'PARTNER_GET_TYPE';
export const PARTNER_GET_SUCCESS_TYPE = 'PARTNER_GET_SUCCESS_TYPE';
export const PARTNER_GET_FAIL_TYPE = 'PARTNER_GET_FAIL_TYPE';
export const PARTNER_UPDATE_TYPE = 'PARTNER_UPDATE_TYPE';
export const PARTNER_UPDATE_SUCCESS_TYPE = 'PARTNER_UPDATE_SUCCESS_TYPE';
export const PARTNER_UPDATE_FAIL_TYPE = 'PARTNER_UPDATE_FAIL_TYPE';
export const PARTNER_DELETE_TYPE = 'PARTNER_DELETE_TYPE';
export const PARTNER_DELETE_SUCCESS_TYPE = 'PARTNER_DELETE_SUCCESS_TYPE';
export const PARTNER_DELETE_FAIL_TYPE = 'PARTNER_DELETE_FAIL_TYPE';

export const PARTNER_LIST_VERIFY_TYPE = 'PARTNER_LIST_VERIFY_TYPE';
export const PARTNER_LIST_VERIFY_SUCCESS_TYPE = 'PARTNER_LIST_VERIFY_SUCCESS_TYPE';
export const PARTNER_LIST_VERIFY_FAIL_TYPE = 'PARTNER_LIST_VERIFY_FAIL_TYPE';
export const PARTNER_LIST_MANIFEST_VERIFY_TYPE = 'PARTNER_LIST_MANIFEST_VERIFY_TYPE';
export const PARTNER_LIST_MANIFEST_VERIFY_SUCCESS_TYPE = 'PARTNER_LIST_MANIFEST_VERIFY_SUCCESS_TYPE';
export const PARTNER_LIST_MANIFEST_VERIFY_FAIL_TYPE = 'PARTNER_LIST_MANIFEST_VERIFY_FAIL_TYPE';
export const PARTNER_LIST_TRANSPORTS_VERIFY_TYPE = 'PARTNER_LIST_TRANSPORTS_VERIFY_TYPE';
export const PARTNER_LIST_TRANSPORTS_VERIFY_SUCCESS_TYPE = 'PARTNER_LIST_TRANSPORTS_VERIFY_SUCCESS_TYPE';
export const PARTNER_LIST_TRANSPORTS_VERIFY_FAIL_TYPE = 'PARTNER_LIST_TRANSPORTS_VERIFY_FAIL_TYPE';

export const PARTNER_VERIFY_IMAGE_TYPE = 'PARTNER_VERIFY_IMAGE_TYPE';
export const PARTNER_VERIFY_IMAGE_SUCCESS_TYPE = 'PARTNER_VERIFY_IMAGE_SUCCESS_TYPE';
export const PARTNER_VERIFY_IMAGE_FAIL_TYPE = 'PARTNER_VERIFY_IMAGE_FAIL_TYPE';
export const PARTNER_UPLOAD_VERIFY_TYPE = 'PARTNER_UPLOAD_VERIFY_TYPE';
export const PARTNER_UPLOAD_VERIFY_SUCCESS_TYPE = 'PARTNER_UPLOAD_VERIFY_SUCCESS_TYPE';
export const PARTNER_UPLOAD_VERIFY_FAIL_TYPE = 'PARTNER_UPLOAD_VERIFY_FAIL_TYPE';
export const PARTNER_DELETE_UPLOAD_VERIFY_TYPE = 'PARTNER_DELETE_UPLOAD_VERIFY_TYPE';
export const PARTNER_DELETE_UPLOAD_VERIFY_SUCCESS_TYPE = 'PARTNER_DELETE_UPLOAD_VERIFY_SUCCESS_TYPE';
export const PARTNER_DELETE_UPLOAD_VERIFY_FAIL_TYPE = 'PARTNER_DELETE_UPLOAD_VERIFY_FAIL_TYPE';

export const PLEASE_CHECK_CONNECT = (value) => {
    return typeof (value) !== 'undefined' ? (
        value === null ? 'Không thành công' : value
    ) : 'Không thành công';
}

export const PLEASE_CHECK_CONNECT_SUCCESS = (value) => {
    return typeof (value) !== 'undefined' ? (
        value === null ? 'Thành công' : value
    ) : 'Thành công';
}