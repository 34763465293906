import React, { Component } from "react";
import SelectTree from "components/SelectTree";
import classes from './index.module.css';
import Validate from "react-validate-form";
import PlusImg from "../../../assets/img/buttons/chonhinh.svg";
import CloseIcon from "../../../assets/img/buttons/xoahinh.svg";
import { rules, validations, checkFieldName, checkFieldNameBool } from "../../../helpers/validation";
import { DATA_SORTODER_LIST } from "../../../helpers/constant";
import Select from "components/Select";
import NoImg from "../../../assets/img/NoImg/NoImg.jpg"

import compose from 'recompose/compose';
import { actionAccess } from "../../../actions/AccessActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { handleGenTree } from "../../../helpers/trees";
import { PLEASE_CHECK_CONNECT } from "../../../services/Common";

// reactstrap components
import {
	Input,
	InputGroup,
	Button
} from "reactstrap";

class UpdateModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			newData: {
				"ID": "",
				"FieldID": "",
				"InformID": "",
				"Name": "",
				"files": "",
				"isRequired": true,
				"isQuarantine": false,
				"isHarvest": false
			},
			activeSubmit: false,
			checkFieldName: '',
			fileView: null,
			file: null,
			dataSortOder: DATA_SORTODER_LIST,
			dataInPopup: [],
			field: [],
			dataAllFromUpdate: []
		}
		// this.handleChangeIMG = this.handleChangeIMG.bind(this);
		this.refEditor = null;
		this.refFileImage = null;
	}

	componentDidMount() {
		const { detail } = this.props;
		let { newData } = this.state;

		newData = {
			"ID": detail.id,
			"FieldID": detail.fieldID,
			"InformID": detail.informID,
			"Name": detail.name,
			"files": "",
			"Image": detail.image,
			"sortOrder": detail.sortOrder === null ? null : detail.sortOrder,
			"isRequired": detail.isRequired === null ? false : detail.isRequired,
			"isQuarantine": detail.isQuarantine === null ? false : detail.isQuarantine,
			"isHarvest": detail.isHarvest === null ? false : detail.isHarvest,
		}

		this.setState({ newData, file: detail.image });
		this.handleCheckValidation();
	}

	componentWillReceiveProps(nextProp) {
		let { data } = nextProp.access;
		let { dataAllFromUpdate } = this.state
		let newData = [];
		let collapseList = [];
		if (data !== this.state.dataInPopup) {
			if (data.accessPopup !== null) {
				if (typeof (data) !== 'undefined') {
					if (typeof (data.accessPopup) !== 'undefined') {
						if (data.accessPopup !== null) {
							if (typeof (data.accessPopup.informations) !== 'undefined') {
								newData = data.accessPopup.informations;
								dataAllFromUpdate = data.accessPopup.informations;
								newData.map(item => (
									collapseList.push({ id: item.id, collapse: false })
								));
								newData.map((item, key) => {
									item['parentID'] = item.informID === null ? '' : item.informID
								});

								newData = handleGenTree(data.accessPopup.informations, 'name');
								newData.map((item, key) => {
									item['index'] = key + 1
								});

								this.setState({ data: [] });
								this.setState({
									dataInPopup: newData,
									collapseList: collapseList,
									dataAllFromUpdate: data.accessPopup.informations,
									listLength: newData.length,
									isLoaded: false,
									status: data.status,
									message: PLEASE_CHECK_CONNECT(data.message)
								});
							} else {
								this.setState({
									dataInPopup: [],
									isLoaded: data.isLoading,
									status: data.status,
									message: PLEASE_CHECK_CONNECT(data.message)
								});
							}
						}
					}
				}
			}
		}
		this.handleCheckValidation(dataAllFromUpdate);
	}

	componentWillMount() {
		const { field, currentFilter } = this.props;
		this.setState({ field, currentFilter })
		const { requestAccessPopupStore } = this.props;
		requestAccessPopupStore(JSON.stringify({
			"search": "",
			"filter": currentFilter == "" ? 0 : currentFilter,
			"orderBy": "",
			"page": null,
			"limit": null
		}))
	}

	handleChangeIMG = event => {

		if (event.target.files[0] != undefined) {
			this.setState({
				fileView: URL.createObjectURL(event.target.files[0]),
				file: event.target.files[0],
			})
		} else {
			this.setState({
				fileView: null,
				file: null,
			})
		}
		let { newData } = this.state;
		const ev = event.target.files[0];
		newData.files = ev;
		this.setState({ newData });
		this.handleCheckValidation();
	}

	onUpdateFileImage = () => {
		this.refFileImage.click();
	}

	onDeleImg = () => {
		this.setState(previousState => {
			return {
				...previousState,
				file: null,
				fileView: null
			}
		}
		)
	}

	handleChange = (event) => {
		let { newData } = this.state;
		let { field } = this.props;
		const ev = event.target;
		newData[ev['name']] = ev['value'];
		this.setState({ newData });

		// Check Validation 
		this.handleCheckValidation();
	}

	handleSelect = (value, name) => {
		const { handleSelect } = this.props;
		let { newData } = this.state;
		if (name == 'FieldID') {
			this.setState({ currentFilter: value });
		}
		if (name == 'FieldID') {
			this.setState({ dataAllFromUpdate: [] })
			if (value == "") { value = null }
			const { requestAccessPopupStore } = this.props;
			requestAccessPopupStore(JSON.stringify({
				"search": "",
				"filter": value == "" ? 0 : value,
				"orderBy": "",
				"page": null,
				"limit": null
			}))
		}

		if (name === 'sortOrder') {
			let valsortOrder = value;
			if (valsortOrder == null) {
				this.setState({ sortOrderChange: null })
			}
		}

		if (value === null) value = "";
		newData[name] = value;

		this.setState({ newData });

		// Check Validation 
		this.handleCheckValidation();

		// handleSelect(value, name);
	}

	handleStatus = (event) => {
		let { newData } = this.state;
		const ev = event.target;

		newData[ev['name']] = ev['checked'];

		this.setState({ newData });
	}

	handleCheckValidation = (dataVl = null) => {
		const { handleCheckValidation, handleNewData } = this.props;
		let { newData, dataAllFromUpdate } = this.state;
		let { field } = this.props;
		if (dataVl !== null) { dataAllFromUpdate = dataVl };
		// Check Validation 
		handleCheckValidation(true);
		// Handle New Data
		handleNewData(newData, dataAllFromUpdate);

	}

	render() {
		const { detail, informData, handleOpenSelectTree, errorUpdate, fieldAll } = this.props;
		const { newData, dataSortOder, sortOrderChange, field, dataInPopup, currentFilter } = this.state;

		let _dataSortOder
		let _dataSortOderName

		if (newData.sortOrder != null) {
			_dataSortOder = dataSortOder.filter(x => newData.sortOrder == x.number)
			if (sortOrderChange === null) {
				_dataSortOderName = null
			} else {
				_dataSortOderName = _dataSortOder[0].number
			}
		}

		let fieldName = null;
		let dataMapth = [];

		if (currentFilter) {
			fieldName = fieldAll.filter(item => item.id == currentFilter)
		}
		if (currentFilter) {
			dataMapth = dataInPopup.filter((item) =>
				(item.fieldName.trim().toUpperCase() == fieldName[0].fieldName.trim().toUpperCase()))
		}

		return (
			<div className={classes.formControl}>
				<div className={classes.rowItem}>
					<label
						className="form-control-label"
					>
						Ngành nghề&nbsp;<b style={{ color: 'red' }}>*</b>
					</label>
					<div className={classes.inputArea}>
						<SelectTree
							name="FieldID"
							title='Chọn ngành nghề'
							data={field}
							// selected={detail.fieldID}
							selected={currentFilter}
							labelName='fieldName'
							fieldName='fieldName'
							val='id'
							handleChange={this.handleSelect}
							handleOpenSelectTree={handleOpenSelectTree}

						/>
						<p className='form-error-message margin-bottom-0'>{errorUpdate['FieldID'] || ''}</p>
					</div>
				</div>

				<div className={classes.rowItem}>
					<label
						className="form-control-label"
					>
						Thuộc truy xuất
					</label>

					<SelectTree
						name="InformID"
						title='Chọn truy xuất'
						data={dataMapth}
						selected={detail.informID}
						labelName='name'
						fieldName='name'
						val='id'
						handleChange={this.handleSelect}
						handleOpenSelectTree={handleOpenSelectTree}
					/>
				</div>

				<div className={`${classes.rowItem}`}>
					<label
						className="form-control-label"
					>
						Tên truy xuất&nbsp;<b style={{ color: 'red' }}>*</b>
					</label>

					<Validate
						validations={validations}
						rules={rules}
					>
						{({ validate, errorMessages }) => (
							<div className={classes.inputArea}>
								<InputGroup className="input-group-alternative">
									<Input
										type="text"
										name='Name'
										defaultValue={detail.name}
										placeholder='Tên truy xuất'
										required
										onChange={validate}
										onKeyUp={(event) => this.handleChange(event)}
									/>
								</InputGroup>
								<p className='form-error-message margin-bottom-0'>{errorUpdate['Name'] || ''}</p>
							</div>
						)}
					</Validate>
				</div>
				<div className={`${classes.rowItem} `}>
					<label
						className="form-control-label"
					>
						Sắp xếp
					</label>
					<div className={classes.inputArea}>
						<Select
							name='sortOrder'
							labelName='number'
							data={dataSortOder}
							defaultValue={detail.sortOrder}
							labelMark={_dataSortOderName}
							val='number'
							title='Chọn'
							handleChange={this.handleSelect}
						/>
						{/* <p className='form-error-message margin-bottom-0'>{errorInsert['sortOrder'] || ''}</p> */}
					</div>
				</div>
				<div className={classes.rowItem}>
					<label
						className="form-control-label"
					>
						Hình ảnh
					</label>
					<div className={classes.inputArea}>
						<InputGroup className="input-group-alternative">
							<input
								ref={ref => this.refFileImage = ref}
								type="file"
								name='files'
								style={{ display: 'none' }}
								required
								onChange={this.handleChangeIMG}
								accept="image/*"
							//onKeyUp={(event) => this.handleChangeIMG(event)}
							/>

							{
								this.state.fileView === null ? (
									<img
										src={this.state.file ? this.state.file : NoImg}
										style={{ width: '100%', height: '100%', maxWidth: 320, maxHeight: 320 }} />
								) : (
									<img
										src={this.state.fileView ? this.state.fileView : NoImg}
										style={{ width: '100%', height: '100%', maxWidth: 320, maxHeight: 320 }} />
								)
							}
							<div className="row" style={{ marginLeft: 0, marginRight: 0, marginTop: 5 }}>
								<Button type="button" size="lg" className='btn-primary-cs'
									onClick={this.onUpdateFileImage}>
									<img src={PlusImg} alt='Thêm mới' />
									<span>Chọn hình</span>
								</Button>
								{this.state.file != null ? (
									<Button
										color="default"
										data-dismiss="modal"
										type="button"
										className={`btn-danger-cs`}
										onClick={this.onDeleImg}
									>
										<img src={CloseIcon} alt='Thoát ra' />
										<span>Xóa hình</span>
									</Button>
								) : null}
							</div>
						</InputGroup>

					</div>

				</div>
				<div className={`${classes.rowItem} ${classes.checkboxItem}`} style={{ justifyContent: 'space-between' }}>
					<div className="row" style={{ alignContent: 'center', alignItems: 'center' }}>
						<input
							name="isRequired"
							type="checkbox"
							checked={newData.isRequired}
							className="checkbox-status"
							onClick={(event) => this.handleStatus(event)}
						/>
						<label style={{ width: 'auto' }}>Bắt buộc</label>
					</div>
					<div className="row" style={{ alignContent: 'center', alignItems: 'center' }}>
						<input
							name="isQuarantine"
							type="checkbox"
							checked={newData.isQuarantine}
							className="checkbox-status"
							onClick={(event) => this.handleStatus(event)}
						/>
						<label style={{ width: 'auto' }}>Kiểm tra thời hạn cách ly</label>
					</div>
					<div className="row" style={{ alignContent: 'center', alignItems: 'center' }}>
						<input
							name="isHarvest"
							type="checkbox"
							checked={newData.isHarvest}
							className="checkbox-status"
							onClick={(event) => this.handleStatus(event)}
						/>
						<label style={{ width: 'auto' }}>Thu hoạch</label>
					</div>
				</div>
			</div>
		);
	}
};

// export default UpdateModal;
const mapStateToProps = (state) => {
	return {
		access: state.AccessStore,

	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		...bindActionCreators(actionAccess, dispatch),

	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(UpdateModal);
