import {
    GET_COMPANY_AWAIT_LIST_SUCCESS_TYPE,
    GET_COMPANY_AWAIT_LIST_FAIL_TYPE,
    GET_COMPANY_UNCOMFIRM_SUCCESS_TYPE,
    GET_COMPANY_UNCOMFIRM_FAIL_TYPE,
    GET_COMPANY_COMFIRM_FAIL_TYPE,
    GET_COMPANY_COMFIRM_SUCCESS_TYPE, 
    DELETE_COMPANY_AWAIT_SUCCESS_TYPE,
    DELETE_COMPANY_AWAIT_FAIL_TYPE
} from "../services/Common";

const initialState = { data: [], isLoading: false, status: null, message: '' };

export const companyAwaitReducer = (state, action) => {
    state = state || initialState;

    if (action.type === GET_COMPANY_AWAIT_LIST_SUCCESS_TYPE) {
      return {
        data: action.data,
        isLoading: action.isLoading,
        status: action.status,
        message: action.message,
      };
    }
    if (action.type === GET_COMPANY_AWAIT_LIST_FAIL_TYPE) {
      return {
        data: action.data,
        isLoading: action.isLoading,
        status: action.status,
        message: action.message,
      };
    }
    if (action.type === GET_COMPANY_UNCOMFIRM_SUCCESS_TYPE) {
      return {
        data: action.data,
        isLoading: action.isLoading,
        status: action.status,
        message: action.message,
      };
    }
    if (action.type === GET_COMPANY_UNCOMFIRM_FAIL_TYPE) {
      return {
        data: action.data,
        isLoading: action.isLoading,
        status: action.status,
        message: action.message,
      };
    }
    if (action.type === GET_COMPANY_COMFIRM_SUCCESS_TYPE) {
      return {
        data: action.data,
        isLoading: action.isLoading,
        status: action.status,
        message: action.message,
      };
    }
    if (action.type === GET_COMPANY_COMFIRM_FAIL_TYPE) {
      return {
        data: action.data,
        isLoading: action.isLoading,
        status: action.status,
        message: action.message,
      };
    }
    if (action.type === DELETE_COMPANY_AWAIT_SUCCESS_TYPE) {
      return {
        data: action.data,
        isLoading: action.isLoading,
        status: action.status,
        message: action.message,
      };
    }
    if (action.type === DELETE_COMPANY_AWAIT_FAIL_TYPE) {
      return {
        data: action.data,
        isLoading: action.isLoading,
        status: action.status,
        message: action.message,
      };
    }
  
    return state;
};