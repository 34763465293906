import React, { Component } from 'react'
import { bindActionCreators } from 'redux';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Bar, Line, Doughnut } from 'react-chartjs-2';
import { actionCompanyReports } from '../../../../actions/CompanyReportsActions';
import { actionLocationCreators } from '../../../../actions/LocationListAction';
import { LOADING_TIME } from '../../../../helpers/constant';
import { Row, Nav, NavItem, Input } from 'reactstrap';
import { PLEASE_CHECK_CONNECT } from '../../../../services/Common'

class ChartCompany extends Component {
    constructor(pros) {
        super(pros);
        this.state = {
            yearM: new Date().getFullYear(),
            number1Y: [],
            number2Y: [],
            number3Y: [],
            displayNameY: [],
            displayName: []
        }
    }
    componentWillReceiveProps(nextProp) {
        let { data } = nextProp.companyReport;
        const { limit } = this.state;
        let displayName = [];
        let number1 = [];
        let number2 = [];
        let number3 = [];
        let displayNameQ = [];
        let number1Q = [];
        let number2Q = [];
        let number3Q = [];
        let displayNameY = [];
        let number1Y = [];
        let number2Y = [];
        let number3Y = [];

        let locationData = nextProp.location.data;

        if (data !== this.state.data) {
            if (typeof (data) !== 'undefined') {
                if (typeof (data.month) !== 'undefined') {
                    if (data.month !== null) {
                        if (typeof (data.month) !== 'undefined') {
                            data.month = data.month.sort((a, b) => a['displayName'] - b['displayName']);
                            for (const dataMap of data.month) {
                                displayName.push('Tháng ' + parseInt(dataMap.displayName));
                                number1.push(parseInt(dataMap.number1));
                                number2.push(parseInt(dataMap.number2));
                                number3.push(parseInt(dataMap.number3));
                            }

                            this.setState({
                                displayName: displayName,
                                number1: number1,
                                number2: number2,
                                number3: number3,
                            });

                            this.setState({
                                data: data.month,
                                month: data.month,
                                listLength: data.month.total,
                                totalPage: Math.ceil(data.month.length / limit),
                                isLoaded: data.isLoading, status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });

                        } else {
                            this.setState({
                                data: data.month,
                                month: data.month,
                                isLoaded: data.isLoading,
                                status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });
                        }
                    }
                }
                if (typeof (data.quarter) !== 'undefined') {
                    if (data.quarter !== null) {
                        if (typeof (data.quarter) !== 'undefined') {
                            data.quarter = data.quarter.sort((a, b) => a['displayName'] - b['displayName']);
                            for (const dataMap of data.quarter) {
                                displayNameQ.push('Quý ' + parseInt(dataMap.displayName));
                                number1Q.push(parseInt(dataMap.number1));
                                number2Q.push(parseInt(dataMap.number2));
                                number3Q.push(parseInt(dataMap.number3));
                            }

                            this.setState({
                                displayNameQ: displayNameQ,
                                number1Q: number1Q,
                                number2Q: number2Q,
                                number3Q: number3Q,
                            });

                            this.setState({
                                //data: data.quarter,
                                quarter: data.quarter,
                                listLength: data.quarter.total,
                                totalPage: Math.ceil(data.quarter.length / limit),
                                isLoaded: data.isLoading, status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });

                        } else {
                            this.setState({
                                //data: data.quarter,
                                quarter: data.quarter,
                                isLoaded: data.isLoading,
                                status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });
                        }
                    }
                }
                if (typeof (data.year) !== 'undefined') {

                    if (data.year !== null) {
                        if (typeof (data.year) !== 'undefined') {
                            data.year = data.year.sort((a, b) => a['displayName'] - b['displayName']);
                            for (const dataMap of data.year) {
                                displayNameY.push(parseInt(dataMap.displayName));
                                number1Y.push(parseInt(dataMap.number1));
                                number2Y.push(parseInt(dataMap.number2));
                                number3Y.push(parseInt(dataMap.number3));
                            }

                            this.setState({
                                displayNameY: displayNameY,
                                number1Y: number1Y,
                                number2Y: number2Y,
                                number3Y: number3Y,
                            });

                            this.setState({

                                yearY: data.year,
                                listLength: data.year.total,
                                totalPage: Math.ceil(data.year.length / limit),
                                isLoaded: data.isLoading, status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });

                        } else {
                            this.setState({

                                yearY: data.year,
                                isLoaded: data.isLoading,
                                status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });
                        }
                    }
                }
            }
        }

        if (locationData !== this.state.district) {
            if (typeof (locationData) !== 'undefined') {
                if (typeof (locationData.district) !== 'undefined') {
                    if (locationData.district !== null) {
                        this.setState({
                            district: locationData.district,
                            isLoaded: false,
                        });
                    } else {
                        this.setState({
                            district: [],
                            isLoaded: false,
                        });
                    }
                }
            }
        }

        if (locationData !== this.state.ward) {

            if (typeof (locationData) !== 'undefined') {

                // if (typeof (locationData.ward) !== 'undefined') {
                if (locationData.ward !== null) {

                    this.setState({ ward: [] })
                    this.setState({
                        ward: locationData.ward,
                    });
                } else {
                    this.setState({
                        ward: [],
                        isLoaded: false,
                    });
                }
                //}
            }
        }
    }
    componentWillMount() {

    }
    componentDidMount() {
        const { getDistrictList } = this.props;
        let { yearM, yearQ, yearfromY, yeartoY } = this.state;
        getDistrictList();
        this.fetchSummary(yearM);
        // this.fetchSummaryQuarter(yearQ);
        // this.fetchSummaryYear(yearfromY, yeartoY)
    }
    componentDidUpdate() {
        this.closeStatusModal();
    }
    fetchSummary = (data) => {
        const { requestMonthReport } = this.props;

        requestMonthReport(data);
    }
    fetchSummaryQuarter = (data) => {
        const { requestQuarterReport } = this.props;

        requestQuarterReport(data);
    }
    fetchSummaryYear = (fromYear, toYear) => {
        const { requestYearReport } = this.props;

        requestYearReport(fromYear, toYear);
    }

    closeStatusModal = () => {
        const { status } = this.state;

        if (status || !status) {
            setTimeout(() => {
                this.setState({ status: null, isLoaded: false });
            }, LOADING_TIME);
        }
    }

    handleChange = (event) => {
        let { yearM } = this.state;
        const ev = event.target;
        yearM = ev['value'];
        this.setState({ yearM })
        this.fetchSummary(yearM);
    }
    render() {
        const { yearM, number1Y, number2Y, number3Y, displayNameY, displayName } = this.state
        return (
            <>
                <div className='header-box-list box-list-border display-box'>
                    <h4>Tăng trưởng doanh nghiệp</h4>
                    <Input
                        type='number'
                        name='year'
                        defaultValue={yearM}
                        onBlur={event => {
                            this.handleChange(event);
                        }}
                        onKeyUp={event => {
                            if (event.which == 13) {
                                this.handleChange(event);
                            }
                        }}
                    />
                </div>
                <div style={{ padding: 10 }}>
                    {
                        displayName ? (<>
                            <Bar
                                data={{
                                    data: displayName,
                                    datasets: [{
                                        label: "Đăng kí mới",
                                        type: "bar",
                                        borderColor: "#4caf50",
                                        backgroundColor: "#4caf50",
                                        data: number1Y,
                                        fill: false
                                    },
                                    {
                                        label: "Ngưng sử dụng",
                                        type: "bar",
                                        borderColor: "#f44336",
                                        backgroundColor: "#f44336",
                                        data: number3Y,
                                        fill: false
                                    },
                                    {
                                        label: "Đang sử dụng",
                                        type: "bar",
                                        borderColor: "#ffeb3b",
                                        backgroundColor: "#ffeb3b",
                                        data: number2Y,
                                        fill: false
                                    },
                                    ]
                                }}
                                options={{
                                    responsive: true,
                                    title: {
                                        display: true,
                                        text: 'BIỂU ĐỒ TĂNG TRƯỞNG DOANH NGHIỆP NĂM ' + yearM,
                                    },
                                    legend: {
                                        position: 'bottom',
                                        display: true
                                    },
                                    scales: {
                                        yAxes: [{
                                            display: true,
                                            ticks: {
                                                suggestedMin: 0,
                                            }
                                        }]
                                    }
                                }}

                            />
                        </>) : (<>
                            <div className="product-report-list-empty"><p className="product-report-list-empty-text">Hiện chưa có dữ liệu</p></div>
                        </>)
                    }

                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        companyReport: state.CompanyReportsStore,
        location: state.LocationStore,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCompanyReports, dispatch),
        ...bindActionCreators(actionLocationCreators, dispatch),
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ChartCompany)