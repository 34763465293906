import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Guid } from 'guid-typescript';

import { areaDataAction } from "../../../actions/AreaDataAction";
import { typeZonePropertyAction } from "../../../actions/TypeZonePropertyAction";

import '../../../assets/css/page/insert_or_update_type_zone_property.css';

import IconAdd from '../../../assets/img/buttons/add.png';
import IconDelete from '../../../assets/img/buttons/delete.png';

import Select from "components/Select";
import { actionProductGroup } from "../../../actions/ProductGroupActions";


class InsertOrUpadte extends Component {
    constructor(props) {
        super(props);

        this.state = {
            typeManage: 1,
            districtId: null,
            provinceId: null,
            provinceName: '',
            wardId: null,
            zones: [],
            isDisableSelectDistrict: true,
            isDisableSelectWard: true,
            isShowArea: false,
            id: null,
            productTypeId: '',
            productTypeId2: ''
        }

        this.refInputName = null;
    }

    componentWillUnmount() {
        this.setState(previousState => {
            return {
                ...previousState,
                typeManage: 1,
                districtId: null,
                provinceId: null,
                provinceName: '',
                wardId: null,
                zones: [],
                productTypeId: '',
                isDisableSelectDistrict: true,
                isDisableSelectWard: true,
                isShowArea: false,
                id: null
            }
        });
    }

    async componentDidMount() {
        const { getDetailTypeZoneProperty, onHandleChangeValue, id, requestListProductGroup } = this.props;

        requestListProductGroup(JSON.stringify({
            "search": "",
            "filter": "",
            "orderBy": "",
            "page": null,
            "limit": null
        })).then(res => {
            if (res.data.status == 200) {
                this.setState({ dataProduct: res.data.data.productGroups })
            }
        })

        if (onHandleChangeValue) {
            onHandleChangeValue(this.state);
        }

        if (id) {
            const result = await getDetailTypeZoneProperty({ id });

            const data = ((result || {}).data || {}).data || null;

            if (!data) {
                alert('Không tìm thấy loại vùng sản xuất và thuộc tính này');

                return;
            }

            const attributes = JSON.parse(data.attributes || '[]');
            const zones = (attributes || []).map(item => {
                return {
                    id: Guid.create().toString(),
                    name: item.Name,
                    dataType: item.DataType
                }
            });

            this.setState(previousState => {
                return {
                    ...previousState,
                    id: data.id,
                    name: data.name,
                    zones,
                    productTypeId: data.productTypeName
                }
            }, () => {
                if (onHandleChangeValue) {
                    onHandleChangeValue(this.state);
                }
            });
        }

        this.focusInput();
    }

    focusInput = () => {
        if (this.refInputName) {
            const timeOut = setTimeout(() => {
                this.refInputName.focus();

                clearTimeout(timeOut);
            }, 100);
        }
    }

    onAddArea = () => {
        const zones = [...this.state.zones];

        zones.push({
            id: Guid.create().toString(),
            name: '',
            dataType: 1
        }
        );
        this.setState(previousState => {
            return {
                ...previousState,
                zones
            }
        }, () => {
            if (this.props.onHandleChangeValue) {
                this.props.onHandleChangeValue(this.state);
            }
        });
    }

    onDeleteArea = id => () => {
        const zones = [...this.state.zones];

        const zone = zones.find(p => p.id == id);

        if (zone) {
            const zoneNews = zones.filter(p => p.id != id);

            this.setState(previousState => {
                return {
                    ...previousState,
                    zones: zoneNews
                }
            }, () => {
                if (this.props.onHandleChangeValue) {
                    this.props.onHandleChangeValue(this.state);
                }
            });
        } else {
            alert('Không tìm thấy dữ liệu này');
        }
    }

    onChangeValue = name => e => {
        const value = e.target.value;

        this.setState(previousState => {
            return {
                ...previousState,
                [name]: value
            }
        }, () => {
            if (this.props.onHandleChangeValue) {
                this.props.onHandleChangeValue(this.state);
            }
        });
    }

    handleSelect = (value) => {
        let productTypeId = [...this.state.productTypeId];

        productTypeId = value;
     
        this.setState(previousState => {
            return {
                ...previousState,
                productTypeId
            }
        }, () => {
            if (this.props.onHandleChangeValue) {
                this.props.onHandleChangeValue(this.state);
            }
        });  
    }

    onChangeNameProperty = id => e => {
        const zones = [...this.state.zones];

        const value = e.target.value;

        const zone = zones.find(p => p.id == id);

        if (zone) {
            zone.name = value;

            this.setState(previousState => {
                return {
                    ...previousState,
                    zones
                }
            }, () => {
                if (this.props.onHandleChangeValue) {
                    this.props.onHandleChangeValue(this.state);
                }
            });
        }
    }

    onChangeTypeProperty = id => e => {
        const zones = [...this.state.zones];

        const value = e.target.value;

        const zone = zones.find(p => p.id == id);

        if (zone) {
            zone.dataType = parseInt(value);

            this.setState(previousState => {
                return {
                    ...previousState,
                    zones
                }
            }, () => {
                if (this.props.onHandleChangeValue) {
                    this.props.onHandleChangeValue(this.state);
                }
            });
        }
    }

    render() {
        const { name, zones, dataProduct, productTypeId } = this.state;
        const { errors, id } = this.props;

        return (
            <div className='wrap-insert-or-update-type-zone-property'>
                <div className='wrap-insert-or-update-type-zone-property-item'>
                    <label className='wrap-insert-or-update-type-zone-property-item-label'>Tên loại &nbsp;<b style={{ color: 'red' }}>*</b></label>
                    <div className='wrap-insert-or-update-type-zone-property-item-box'>
                        <input ref={ref => this.refInputName = ref} autoFocus={true} value={name} onChange={this.onChangeValue('name')} type='text' className='wrap-insert-or-update-type-zone-property-item-input' />
                        <p className='form-error-message'>{errors.name || ''}</p>
                    </div>
                </div>

                {id ? (
                    <div>
                        {dataProduct ? (
                            <div className='wrap-insert-or-update-type-zone-property-item'>
                                <label className='wrap-insert-or-update-type-zone-property-item-label'>Loại sản phẩm&nbsp;<b style={{ color: 'red' }}>*</b></label>
                                <div className='wrap-insert-or-update-type-zone-property-item-box'>
                                    <Select
                                        name="productTypeId"
                                        title='Chọn loại sản phẩm'
                                        data={dataProduct}
                                        defaultValue={productTypeId}
                                        labelName='name'
                                        val='id'
                                        isHideSelectAll={true}
                                        isMulti={true}
                                        handleChange={this.handleSelect}
                                    />
                                    <p className='form-error-message'>{errors.productTypeId || ''}</p>
                                </div>
                            </div>) : null}
                    </div>) :
                    <div className='wrap-insert-or-update-type-zone-property-item'>
                        <label className='wrap-insert-or-update-type-zone-property-item-label'>Loại sản phẩm&nbsp;<b style={{ color: 'red' }}>*</b></label>
                        <div className='wrap-insert-or-update-type-zone-property-item-box'>
                            <Select
                                name="productTypeId"
                                title='Chọn loại sản phẩm'
                                data={dataProduct}
                                labelName='name'
                                val='id'
                                isHideSelectAll={true}
                                isMulti={true}
                                handleChange={this.handleSelect}
                            />
                            <p className='form-error-message'>{errors.productTypeId || ''}</p>
                        </div>
                    </div>
                }

                <p className='form-error-message'>{errors.zone || ''}</p>
                <div className='wrap-insert-or-update-type-zone-property-table'>
                    <table className='wrap-insert-or-update-type-zone-property-list'>
                        <thead className='wrap-insert-or-update-type-zone-property-list-header'>
                            <tr className='wrap-insert-or-update-type-zone-property-list-header-row'>
                                <th className='wrap-insert-or-update-type-zone-property-list-header-row-col-1'>STT</th>
                                <th className='wrap-insert-or-update-type-zone-property-list-header-row-col'>TÊN THUỘC TÍNH</th>
                                <th className='wrap-insert-or-update-type-zone-property-list-header-row-col'>KIỂU DỮ LIỆU</th>
                                <th className='wrap-insert-or-update-type-zone-property-list-header-row-col wrap-insert-or-update-type-zone-property-list-header-row-4'></th>
                            </tr>
                        </thead>
                        <tbody className='wrap-insert-or-update-type-zone-property-list-body'>
                            {(zones || []).map((item, index) => {
                                return (
                                    <tr key={item.id} className='wrap-insert-or-update-type-zone-property-list-body-row'>
                                        <td className='wrap-insert-or-update-type-zone-property-list-body-row-col-1'>{index + 1}</td>
                                        <td className='wrap-insert-or-update-type-zone-property-list-body-row-col'>
                                            <input onChange={this.onChangeNameProperty(item.id)} placeholder='Nhập tên thuộc tính' type='text' value={item.name} className='wrap-insert-or-update-type-zone-property-list-body-row-col-input' />
                                        </td>
                                        <td className='wrap-insert-or-update-type-zone-property-list-body-row-col'>
                                            <select onChange={this.onChangeTypeProperty(item.id)} value={item.dataType} className='wrap-insert-or-update-type-zone-property-list-body-row-col-select'>
                                                <option selected={item.dataType == 1} value='1'>Text</option>
                                                <option selected={item.dataType == 2} value='2'>Number</option>
                                                <option selected={item.dataType == 3} value='3'>Date</option>
                                                <option selected={item.dataType == 4} value='4'>Image</option>
                                            </select>
                                        </td>
                                        <td className='wrap-insert-or-update-type-zone-property-list-body-row-col wrap-insert-or-update-type-zone-property-list-body-row-col-4'>
                                            <button onClick={this.onDeleteArea(item.id)} className='wrap-insert-or-update-type-zone-property-list-body-row-col-delete'>
                                                <img className='wrap-insert-or-update-type-zone-property-list-body-row-col-delete-icon' src={IconDelete} />
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='wrap-insert-or-update-type-zone-property-add'>
                    <button onClick={this.onAddArea} className='wrap-insert-or-update-type-zone-property-add-button' style={{ width: 25, height: 25 }}>
                        <img className='wrap-insert-or-update-type-zone-property-add-button-icon' style={{ width: 20, height: 20 }} src={IconAdd} />
                    </button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.AreaDataStore,
        productGroup: state.ProductGroupStore,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(areaDataAction, dispatch),
        ...bindActionCreators(typeZonePropertyAction, dispatch),
        ...bindActionCreators(actionProductGroup, dispatch),
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(InsertOrUpadte);