import React, { Component } from "react";
import { bindActionCreators } from "redux";
import classes from './index.module.css';
import InputCurrency from '../../../components/InputCurrency';
import Validate from "react-validate-form";
import { rules, validations, checkPasswordConfirm } from "../../../helpers/validation";
import Select from "components/Select";
import { Guid } from 'guid-typescript';
import PopupMessage from "../../../components/PopupMessage";
import { removeDuplicates } from '../../../helpers/common';
import { actionUserProvince } from '../../../actions/UserProvinceActions'
import { actionCreators } from '../../../actions/UserListActions'
import './style.css'
import IconDelete from '../../../assets/img/buttons/delete.png';
import IconAdd from '../../../assets/img/buttons/add.svg';

// reactstrap components
import {
    Input,
    InputGroup
} from "reactstrap";
import { compose } from "recompose";
import { connect } from "react-redux";

class InsertOrUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            userName: '',
            provinces: this.props,
            userList: this.props,
            activeSubmit: false,
            userProvince: '',
            userId: null,
            isShowArea: false,
            zones: [],
            isShowForEdit: false,
        }
        this.redSelect = null

    }

    componentWillUnmount() {
        const { data } = this.props;
        const { userList } = this.state
        const filterUserList = data?.list?.filter((res) =>
            res.userProvinceDtos.length <= 0
        )
        let tesst = []
        userList?.userList?.map(item => filterUserList?.filter(filterUserList => {
            if ((item.id.toString().toUpperCase().trim() || "") == (filterUserList.userID.toString().toUpperCase().trim() || "")) {
                tesst.push(item)
            }
        }))
        this.setState(previousState => {
            return {
                ...previousState,
                userList: filterUserList,
                tesst: tesst
                //districtId: null
            }
        },
            () => {
                if (this.props.onHandleChangeValue) {
                    this.props.onHandleChangeValue(this.state);
                }
            }
        )
        this.setState(previousState => {
            return {
                ...previousState,
                // userList: filterUserList,
                // tesst: tesst,
                userProvince: '',
                userName: '',
                userId: null,
                id: null,
                typeManage: 1,
                isShowArea: false,
                zones: [],
            }
        })
        // const { data } = this.props;
        // const { userList } = this.state
        // const filterUserList = data?.list?.filter((res) =>
        //     res.userProvinceDtos.length <= 0
        // )
        // let tesst = []
        // userList?.userList?.map(item => filterUserList?.filter(filterUserList => {
        //     if ((item.id.toString().toUpperCase().trim() || "") == (filterUserList.userID.toString().toUpperCase().trim() || "")) {
        //         tesst.push(item)
        //     }
        // }))
        // this.setState(previousState => {
        //     return {
        //         ...previousState,
        //         userList: filterUserList,
        //         tesst: tesst
        //         //districtId: null
        //     }
        // },
        //     () => {
        //         if (this.props.onHandleChangeValue) {
        //             this.props.onHandleChangeValue(this.state);
        //         }
        //     }
        // )
    }
    // componentDidMount() {
    //     document.title = `You clicked ${this.state.count} times`;
    // }
    componentDidUpdate(prevProps, prevState) {
        // const { data } = this.props;
        const { userList } = this.state
        // let tesst = []
        // const filterUserList = data?.list?.filter((res) =>
        //     res.userProvinceDtos.length <= 0
        // )
        //if (prevState?.tesst?.length !== this.state?.tesst?.length) {
        // userList?.userList?.map(item => filterUserList?.filter(filterUserList => {
        //     if ((item.id.toString().toUpperCase().trim() || "") == (filterUserList.userID.toString().toUpperCase().trim() || "")) {
        //         tesst.push(item)
        //     }
        // }))
        // this.setState(previousState => {
        //     return {
        //         ...previousState,
        //         userList: filterUserList,
        //         tesst: tesst
        //         //districtId: null
        //     }
        // },
        //     () => {
        //         if (this.props.onHandleChangeValue) {
        //             this.props.onHandleChangeValue(this.state);
        //         }
        //     }
        // )
        //}
    }

    async componentDidMount() {
        const { id, dataProvince, data, getProvinceList, getIdUserProvinceUpdate, getListUserLaco, requestUserListStore } = this.props;
        let { usersLaco, dataLabel } = this.state;

        const resultDataLabel = await requestUserListStore(JSON.stringify({
            "search": "",
            "filter": "",
            "orderBy": "",
            "page": null,
            "limit": null
        }));
        dataLabel = (((resultDataLabel || {}).data || {}).data || {}).users || null;


        const resultLaco = await getListUserLaco();
        usersLaco = (((resultLaco || {}).data || {}).data || {}).users || null;

        this.setState(previousState => {
            return {
                ...previousState,
                usersLaco,
                dataLabel
            }
        })

        // const filterUserList = data?.list?.filter((res) =>
        //     res.userProvinceDtos.length <= 0
        // )
        // let tesst = []

        // userList?.userList?.map(item => filterUserList?.filter(filterUserList => {
        //     if ((item.id.toString().toUpperCase().trim() || "") == (filterUserList.userID.toString().toUpperCase().trim() || "")) {
        //         tesst.push(item);
        //     }
        // }))

        // this.setState(previousState => {
        //     return {
        //         ...previousState,
        //         userList: filterUserList,
        //         tesst: tesst
        //         //districtId: null
        //     }
        // },
        //     () => {
        //         if (this.props.onHandleChangeValue) {
        //             this.props.onHandleChangeValue(this.state);
        //         }
        //     }
        // )

        if (id) {

            getIdUserProvinceUpdate(id).then(res => {
                let userListId = res.data.data || {};
                let findDataCurrent = dataLabel.find(p => p.id == userListId[0].userID)

                usersLaco.push(findDataCurrent)

                this.setState(previousState => {
                    return {
                        ...previousState,
                        userListId,
                        userId: userListId[0].userID,
                        usersLaco
                    }
                }, () => {
                    if (this.props.onHandleChangeValue) {
                        this.props.onHandleChangeValue(this.state);
                    }
                })
            })

            const result = await getIdUserProvinceUpdate(id)
            const data = ((result || {}).data || {}).data || null;
            const zones = (data[0].userProvinceDtos).map(item => {
                return {
                    id: Guid.create().toString(),
                    provinceName: item.provinceName,
                    provinceId: item.provinceID,
                }
            })
            this.setState(previousState => {
                return {
                    ...previousState,
                    zones,

                    //districtId: null
                }
            }, () => {
                // console.log(this.state, 66655);
                if (this.props.onHandleChangeValue) {
                    this.props.onHandleChangeValue(this.state);
                }
            })

        }
    }

    onChangeSelect = name => value => {
        // this.setState({ errMessageND: '', errMessageTH: '' })

        this.setState(previousState => {
            return {
                ...previousState,
                [name]: value
            }
        }, () => {
            if (this.props.onHandleChangeValue) {
                this.props.onHandleChangeValue(this.state);
            }
        })
    }
    onAddArea = () => {
        const { provinceId, districtId, wardId, typeManage, popupMessage, errMessage } = this.state;
        const { dataProvince, districts, wards, userListId } = this.props;

        let provinces = dataProvince;
        const zones = [...this.state.zones];
        const province = provinces.find(p => p.id == provinceId);
        let statusCheck = false;
        if (provinceId) {
            zones.filter(p => p.provinceId == provinceId).map(item => statusCheck = true)

            if (statusCheck == true) {
                this.setState({
                    errMessage: "Tĩnh thành này đã có"
                })
                this.toggleModal('popupMessage')
                return;
                statusCheck = false;
            }
        }
        if (!provinceId) {

            this.setState({
                errMessage: 'Chưa chọn Tỉnh/Thành'
            })
            this.toggleModal('popupMessage')
            return;
        }

        zones.push({
            id: Guid.create().toString(),
            provinceId: province.id,
            provinceName: province.provinceName,

        });

        this.setState(previousState => {
            return {
                ...previousState,
                zones,
                //districtId: null
            }
        }, () => {
            if (this.props.onHandleChangeValue) {
                this.props.onHandleChangeValue(this.state);
            }
        });

    }
    onAddAll = () => {
        const { provinceId } = this.state;
        const { dataProvince } = this.props;
        let provinces = dataProvince;
        let zones = [...this.state.zones];

        const province = provinces.find(p => p.id == provinceId);

        dataProvince.map(item => {
            zones.push({
                id: Guid.create().toString(),
                provinceId: item.id,
                provinceName: item.provinceName,
            })
        })

        zones = removeDuplicates(zones, item => item.provinceId)

        this.setState(previousState => {
            return {
                ...previousState,
                zones,
                //districtId: null
            }
        }, () => {
            if (this.props.onHandleChangeValue) {
                this.props.onHandleChangeValue(this.state);
            }
        });

    }
    onDeleteArea = id => () => {
        const zones = [...this.state.zones];

        const zone = zones.find(p => p.id == id);

        if (zone) {
            const zoneNews = zones.filter(p => p.id != id);

            this.setState(previousState => {
                return {
                    ...previousState,
                    zones: zoneNews
                }
            }, () => {
                if (this.props.onHandleChangeValue) {
                    this.props.onHandleChangeValue(this.state);
                }
            });
        } else {
            this.setState({
                errMessage: 'Không tìm thấy khu vực này'
            })
            this.toggleModal('popupMessage')
            return;
        }
    }

    toggleModal = (state, type) => {
        if (this.state[state] && type == 1) {
            return;
        } else {
            this.setState({
                [state]: !this.state[state],
                detail: null,
                //errMessage:null
            });
        }
    };


    render() {
        const { data, userName, zones, popupMessage, errMessage, userListId, filterUserProvince, tesst, usersLaco, dataLabel } = this.state;
        const { errorInsert, errors, dataProvince, userList, id, userListIds } = this.props;
        return (
            <div className='wrap-insert-or-update-zone'>
                <div className='wrap-insert-or-update-zone-item'>
                    <label className='wrap-insert-or-update-zone-item-label'>Người dùng &nbsp;<b style={{ color: 'red' }}>*</b></label>
                    <div className='wrap-insert-or-update-zone-item-box' >
                        {id ? (
                            <>
                                {userListId && (
                                    <Select
                                        name='fullName'
                                        className='wrap-insert-or-update-zone-item-select'
                                        title='Chọn người dùng'
                                        labelName='fullName'
                                        val='id'
                                        dataLabel={dataLabel}
                                        data={usersLaco && usersLaco}
                                        isDisable={userListId.userName = null ? false : true}
                                        defaultValue={userListId && userListId[0].userID}
                                        handleChange={this.onChangeSelect('userId')}
                                    />
                                )}
                            </>
                        ) : (
                            <Select
                                name='fullName'
                                className='wrap-insert-or-update-zone-item-select'
                                title='Chọn người dùng'
                                labelName='fullName'
                                val='id'
                                data={usersLaco && usersLaco}
                                handleChange={this.onChangeSelect('userId')}
                            />)}

                        <p className='form-error-message'>{errorInsert.userId || ''}</p>
                    </div>
                </div>
                <div className='wrap-insert-or-update-zone-item'>
                    <label className='wrap-insert-or-update-zone-item-label'>Tỉnh/Thành &nbsp;<b style={{ color: 'red' }}>*</b></label>
                    <div className='wrap-insert-or-update-zone-item-box'>
                        {id ? (
                            <>
                                {userListId ? (
                                    <Select
                                        className='wrap-insert-or-update-zone-item-select'
                                        title='Chọn tỉnh/thành'
                                        labelName='provinceName'
                                        val='id'
                                        // name="provinceId"
                                        defaultValue={userListId[0]?.userProvinceDtos?.provinceName}
                                        data={dataProvince}
                                        handleChange={this.onChangeSelect("provinceId")}
                                    />) : null}
                            </>
                        ) : (<Select
                            className='wrap-insert-or-update-zone-item-select'
                            title='Chọn tỉnh/thành'
                            labelName='provinceName'
                            val='id'
                            name="provinceId"
                            data={dataProvince}
                            handleChange={this.onChangeSelect("provinceId")}
                        />)}
                        {/* <Select
                            className='wrap-insert-or-update-zone-item-select'
                            title='Chọn tỉnh/thành'
                            labelName='provinceName'
                            val='id'
                            name="provinceId"
                            defaultValue={userListIds}
                            data={dataProvince}
                            handleChange={this.onChangeSelect("provinceId")}
                        /> */}
                        <p className='form-error-message'>{this.state.errMessageTH || ''}</p>
                    </div>
                </div>
                <div className='wrap-insert-or-update-zone-add'>

                    <button onClick={this.onAddAll} className='wrap-insert-or-update-zone-add-button mr-2'>
                        <img className='wrap-insert-or-update-zone-add-button-icon' src={IconAdd} />
                        Thêm tất cả
                    </button>


                    <button onClick={this.onAddArea} className='wrap-insert-or-update-zone-add-button'>
                        <img className='wrap-insert-or-update-zone-add-button-icon' src={IconAdd} />
                        Thêm
                    </button>
                    {/* ) : null
                    } */}
                </div>
                <div className='wrap-insert-or-update-zone-area'>
                    <label className='wrap-insert-or-update-zone-item-label'>Khu vực được chọn</label>
                    <p className='form-error-message'>{errorInsert.zones || ''}</p>
                    {/* <p className='form-error-message'>{errors.zone || ''}</p> */}
                    <div className='wrap-insert-or-update-zone-area-list'>
                        {zones.map(item => {
                            return (
                                <div key={item.id} className='wrap-insert-or-update-zone-area-list-item'>
                                    <p className='wrap-insert-or-update-zone-area-list-item-label'>
                                        {item.provinceName ? item.provinceName : null}</p>
                                    <button onClick={this.onDeleteArea(item.id)} className='wrap-insert-or-update-zone-area-list-item-delete'>
                                        <img className='wrap-insert-or-update-zone-area-list-item-delete-icon' src={IconDelete} />
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <PopupMessage
                    popupMessage={popupMessage}
                    moduleTitle={'Thông báo'}
                    moduleBody={errMessage}
                    toggleModal={this.toggleModal}
                />

            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        ...state.UserProvinceStore,
        ...state.UserListStore,
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionUserProvince, dispatch),
        ...bindActionCreators(actionCreators, dispatch),
    }
}
export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(InsertOrUpdate);
