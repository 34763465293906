import {
  USERPROVINCE_LIST_FAIL_TYPE,
  USERPROVINCE_LIST_SUCCESS_TYPE,
  USERPROVINCE_DELETE_SUCCESS_TYPE,
  USERPROVINCE_DELETE_FAIL_TYPE,
  USERPROVINCE_CREATE_SUCCESS_TYPE,
  USERPROVINCE_CREATE_FAIL_TYPE,
  USERPROVINCE_UPDATE_FAIL_TYPE,
  USERPROVINCE_UPDATE_SUCCESS_TYPE,
  USERPROVINCE_GET_FAIL_TYPE,
  USERPROVINCE_GET_SUCCESS_TYPE
} from "../services/Common";

const initialState = { data: [], isLoading: false, status: null, message: '' };

export const userProvinceReducer = (state, action) => {
  state = state || initialState;

  if (action.type === USERPROVINCE_LIST_SUCCESS_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === USERPROVINCE_LIST_FAIL_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  if (action.type === USERPROVINCE_DELETE_SUCCESS_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === USERPROVINCE_DELETE_FAIL_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  if (action.type === USERPROVINCE_CREATE_SUCCESS_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  if (action.type === USERPROVINCE_CREATE_FAIL_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  if (action.type === USERPROVINCE_UPDATE_FAIL_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  if (action.type === USERPROVINCE_UPDATE_SUCCESS_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  if (action.type === USERPROVINCE_GET_FAIL_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  if (action.type === USERPROVINCE_GET_SUCCESS_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }


  return state;
};