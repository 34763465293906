import {
    
    GET_STAMPPROVIDE_LIST_SUCCESS_TYPE, 
    GET_STAMPPROVIDE_LIST_FAIL_TYPE,
    
    GET_COMFIRM_STAMPPROVIDE_LIST_SUCCESS_TYPE,
    GET_COMFIRM_STAMPPROVIDE_LIST_FAIL_TYPE,
    
    GET_UNCOMFIRM_STAMPPROVIDE_LIST_SUCCESS_TYPE,
    GET_UNCOMFIRM_STAMPPROVIDE_LIST_FAIL_TYPE,

    GET_QRCODE_STAMPID_SUCCESS_TYPE,
    GET_QRCODE_STAMPID_FAIL_TYPE
    
} from "../services/Common";

const initialState = { data: [], isLoading: false, status: null, message: '' };

export const StampProvideReducer = (state, action) => {
    state = state || initialState;
    
    if (action.type === GET_STAMPPROVIDE_LIST_SUCCESS_TYPE) {
      return {
        data: action.data,
        isLoading: action.isLoading,
        status: action.status,
        message: action.message,
      };
    }
  
    if (action.type === GET_STAMPPROVIDE_LIST_FAIL_TYPE) {
      return {
        data: action.data,
        isLoading: action.isLoading,
        status: action.status,
        message: action.message,
      };
    }
    if (action.type === GET_COMFIRM_STAMPPROVIDE_LIST_SUCCESS_TYPE) {
      return {
        data: action.data,
        isLoading: action.isLoading,
        status: action.status,
        message: action.message,
      };
    }
  
    if (action.type === GET_COMFIRM_STAMPPROVIDE_LIST_FAIL_TYPE) {
      return {
        data: action.data,
        isLoading: action.isLoading,
        status: action.status,
        message: action.message,
      };
    }
    if (action.type === GET_UNCOMFIRM_STAMPPROVIDE_LIST_SUCCESS_TYPE) {
      return {
        data: action.data,
        isLoading: action.isLoading,
        status: action.status,
        message: action.message,
      };
    }
  
    if (action.type === GET_UNCOMFIRM_STAMPPROVIDE_LIST_FAIL_TYPE) {
      return {
        data: action.data,
        isLoading: action.isLoading,
        status: action.status,
        message: action.message,
      };
    }

    if (action.type === GET_QRCODE_STAMPID_SUCCESS_TYPE) {
      return {
        data: action.data,
        isLoading: action.isLoading,
        status: action.status,
        message: action.message,
      };
    }
  
    if (action.type === GET_QRCODE_STAMPID_FAIL_TYPE) {
      return {
        data: action.data,
        isLoading: action.isLoading,
        status: action.status,
        message: action.message,
      };
    }
    
    return state;
};