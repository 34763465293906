import React, { Component } from "react";
import classes from './index.module.css';
import moment from 'moment';
import { handleCurrencyFormat } from "../../helpers/common";
import QRCode from 'qrcode.react';
import './custom.css';
import HeadTitleTable from "components/HeadTitleTable";
import Pagination from "components/Pagination";
import PrintIcon from "assets/img/buttons/printig.svg";
import ViewOneStamp from "../../components/PrintFormStampQR/ViewOneStamp";
import PrintPopup from "../../components/PrintPopup";

import {
	Card,
	Table,
	Container,
	Row,
	Spinner,
	ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button
} from "reactstrap";

const HEADER = [
	'Stt',
	'DẢI TEM',
	'MÃ TEM',
	// 'SỐ LƯỢNG ĐÃ IN'

];

class DefaultForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			beginItem: 0,
			endItem: 10,
			headerTitle: HEADER,
			totalElement: 0,
			listLength: 0,
			currentPage: 0,
			limit: 10,
			checkAll: false
		};
	}

	componentWillMount() {
		const { data } = this.props;
		let { limit } = this.state;
		data.map((item, key) => {
			item['index'] = key + 1
		})
		this.setState({
			totalPage: Math.ceil(data.length / limit),
			listLength: data.length,
			data
		})
	}

	handlePageClick = (data) => {
		let { limit, beginItem, endItem } = this.state;
		let selected = data.selected;
		let offset = Math.ceil(selected * limit);
		let total = 0;

		beginItem = offset;
		endItem = offset + limit;

		this.props.data.map((item, key) => (
			key >= beginItem && key < endItem && total++
		));

		if (selected > 0) {
			total = (selected * limit) + total;
		} else {
			total = total;
		}
		this.setState({ beginItem: beginItem, endItem: endItem, currentPage: selected + 1, totalElement: total });
	};

	toggleModal = (state) => {
		this.setState({
			[state]: !this.state[state],
		});
	};

	render() {
		const { detailPrint } = this.props;
		const {
			headerTitle,
			beginItem,
			endItem,
			listLength,
			totalPage,
			totalElement,
			printModal,
			data,
			checkAll
		} = this.state
		return (
			<>
				<Card className="shadow">
					<Table className="align-items-center tablecs" responsive >
						<HeadTitleTable headerTitle={headerTitle} />
						<tbody>
							<>
								<tr>
									<td></td>
									<td></td>
									<td></td>
									<td>
										<div
											style={{
												backgroundColor: 'blue',
												width: '20px',
												height: '20px',
												cursor: 'pointer'
											}}
											onClick={() => {
												this.setState({ checkAll: true })
												this.toggleModal('printModal');
											}}
										>
											<img src={PrintIcon} alt="In tất cả" />
										</div>
									</td>
								</tr>
								{
									Array.isArray(data)
									&& (
										data.filter((item, key) => key >= beginItem && key < endItem)
											.map(
												(item, key) => (
													<tr key={key}>
														<td style={{ whiteSpace: 'normal' }}>{item.index}</td>
														<td style={{ textAlign: 'left', whiteSpace: 'normal' }}>{item.stampCode}</td>
														<td style={{ textAlign: 'left', whiteSpace: 'normal' }}>{item.stampID}</td>
														{/* <td></td> */}
														<td style={{ whiteSpace: 'normal', }}>
															<div style={{ backgroundColor: 'blue', width: '20px', height: '20px', cursor: 'pointer' }}
																onClick={() => {
																	this.setState({ dataSend: item })
																	this.toggleModal('printModal');
																}}
															>
																<img src={PrintIcon} alt="In" />
															</div>
														</td>
													</tr>
												)
											)
									)
								}
							</>
						</tbody>
					</Table>
				</Card>

				{
					// Page of Table
					Array.isArray(data) && (
						<Pagination
							data={data}
							listLength={listLength}
							totalPage={totalPage}
							totalElement={totalElement}
							handlePageClick={this.handlePageClick}
						/>
					)
				}
				{(this.state.dataSend || data) && (
					<PrintPopup
						moduleTitle="In tem"
						printModal={printModal}
						lableBtnSave="In"
						moduleBody={
							<div>
								<ViewOneStamp
									detailPrint={detailPrint}
									data={this.state.dataSend}
									dataAll={data}
									
									printInfo={data}
									checkAll={checkAll}
								/>
							</div>
						}
						id='printarea'
						toggleModal={this.toggleModal}
					/>
				)
				}
			</>
			// <div className={`${classes.printarea} row`}
			// 	style={{ border: 'none' }}
			// >
			// 	{
			// 		Array.isArray(data) && (
			// 			data.map(
			// 				(item, key) => (
			// 					<div
			// 						className='item'
			// 						style={{
			// 							margin: '0 23px',
			// 							textAlign: 'center'
			// 						}}
			// 					>
			// 						<div className="imageP">
			// 							< QRCode
			// 								id='qrcode'
			// 								value={item.qrCode}
			// 								size={190}
			// 								renderAs='svg'
			// 								level={'H'}
			// 								includeMargin={true}
			// 							/>
			// 						</div>
			// 						<div
			// 							className="labelP"
			// 							style={{
			// 								color: '#000',
			// 								fontSize: 12,
			// 								textAlign: 'center'
			// 							}}>
			// 							{item.stampID}
			// 						</div>
			// 					</div>
			// 				)
			// 			)
			// 		)
			// 	}
			// </div>

		);
	}
};

export default DefaultForm;
