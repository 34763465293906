/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { MenuContext, AlertContext } from "../../helpers/common"
import { deleteCookie } from "../../helpers/cookie";
import { actionCreators } from "../../actions/UserListActions.js";
import { connect } from "react-redux";
import compose from 'recompose/compose';
import ChangeAvatar from "./ChangeAvatar";
import UpdatePopup from "../../components/UpdatePopup";
import UpdatePopup2 from "../../components/UpdatePopup2";
import UpdatePopup3 from "../../components/UpdatePopup3";
import { actionDashboardCreators } from "../../actions/DashboardActions.js";
import { actionCompanyGetDetails } from "../../actions/CompanyGetDetailsActions";
import { actionGetListCompanyAwait } from "../../actions/CompanyAwaitActions";
import moment from 'moment';
import Select from "../../components/Select";
import ViewModal from "./ViewModal";
import ViewModalAwait from "./ViewModalAwait";
import ViewPopup from "../../components/ViewPopup";
import ViewPopup2 from "../../components/ViewPopup2";
import PopupMessage from "../../components/PopupMessage";
import Kduyet from "assets/img/buttons/KhongDuyet.svg";
import Duyet from "assets/img/buttons/Duyet.svg";
import { PLEASE_CHECK_CONNECT, ACCOUNT_NAME, ACCOUNT_AVA, ACCOUNT_CLAIM_FF, ACCOUNT_ID, IS_ADMIN } from "../../services/Common";
import UnComfirmModal from "./UnComfirmModal";
import ExtendModal from "./ExtendModal";
import ExtendPopup from "../../components/ExtendPopup";
import { actionPriceCreators } from "../../actions/PricesListActions";
import { actionListRequestExtend } from "../../actions/CompanyListRequestExtendActions";
import WarningPopup from "../../components/WarningPopup";
import UnComfirmModalAwait from "./UnComfirmModalAwait";

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
  Button
} from "reactstrap";
import classes from './index.module.css';
import NoImg from "assets/img/NoImg/NoImg.jpg"
import { VARIABLES } from "../../helpers/constant";

class AdminNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      details: null,
      notChang: false,
      fileView: null,
      totalAlert: 0,
      alertAll: [],
      price: [],
      fileUpdate: [],
      errorMessagesUn: {}
    }
    this.refFileImage = null;
    this.isFetchingAlert = false;
  }

  componentWillReceiveProps(nextProp) {
    let detailsData = nextProp.details.data;
    let priceDate = nextProp.price.data;
    let { data } = nextProp.dashboard;

    if (detailsData.details !== null) {
      if (typeof (detailsData.details) !== 'undefined') {
        this.setState({
          xem: detailsData.details,
          detail: detailsData.details,
          detailView: detailsData.details,
          isLoaded: false,

        });
      } else {
        this.setState({
          xem: detailsData.details,
          isLoaded: false,
        });
      }
    }

    if (priceDate !== this.state.price) {
      if (typeof (priceDate) !== 'undefined') {
        if (typeof (priceDate.prices) !== 'undefined') {
          if (priceDate.prices !== null) {
            this.setState({
              price: priceDate.prices.prices,
              isLoaded: false,
            });
          } else {
            this.setState({
              price: [],
              isLoaded: false,
            });
          }
        }
      }
    }
    
    if (data) {
      if (data.getTotalAlert) {
        this.setState({ totalAlert: data.getTotalAlert || 0 })
      }
    }

  }

  componentDidMount() {
    const { getTotalAlerts } = this.props;

    getTotalAlerts().then(res => {
      if (res.status == 200) {
        this.setState({ totalAlert: res.data || 0 })
      }
    })

  }

  handleCloseApp = () => {
    const { userLogout } = this.props;
    userLogout()
      .then(res => (
        res.status === 200 && ((
          deleteCookie('AUTHEN_INFO'),
          window.location.href = '/'
        ))
      ));
  }

  handleCheckValidation = (status) => {
    this.setState({ activeCreateSubmit: status });
  }

  toggleModal = (state, type) => {
    if (this.state[state] && type == 1) {
      return;
    } else {
      this.setState({
        [state]: !this.state[state],
        details: null,
        detailView: null,
        errorUpdate: {},
        errorInsert: {}
      });
    }
  };

  handleNewDataUpdate = (data) => {
    this.setState({ file: data.ThumbnailFile, fileView: data.fileView })
  }

  handleOpenEditEX = () => {
    const { getAllPriceList } = this.props;
    getAllPriceList(JSON.stringify({
      "search": "",
      "filter": "",
      "orderBy": "",
      "page": null,
      "limit": null
    }));
  }

  handleOpenEdit = () => {
    const { getUserInfo } = this.props;
    getUserInfo(ACCOUNT_ID).then(res => {
      if (res.data.status == 200) {
        this.setState({ details: res.data.data });
      }
    })
  }

  handleUpdateData = (val) => {
    const { file, details } = this.state;
    const { updateMe } = this.props;
    const formData = new FormData();
    formData.append('ID', ACCOUNT_ID);
    formData.append('FullName', details.FullName);
    formData.append('PhoneNumber', details.PhoneNumber);
    formData.append('Position', details.Position);
    formData.append('Department', details.Department);
    formData.append('RoleID', details.RoleID);
    formData.append('UserName', details.UserName);
    formData.append('Email', details.Email);
    formData.append('PasswordHash', details.PasswordHash);
    formData.append('PasswordConfirm', "");
    formData.append('AvatarFile', file);
    // formData.append('Avatar', "");
    updateMe(formData).then(res => {
      if (res.data.status == 200) {
        this.setState({ notChang: true });
        //window.location.reload();
        this.toggleModal('updateModal')
      }
    })
  }

  onUpdateFileImage = () => {
    this.refFileImage.click();
  }

  click = () => {
    const { getAlerts } = this.props;
    getAlerts({
      page: 0,
      limit: 100
    }).then((res) => {
      if (res.status == 200) {
        this.setState({ alertAll: res.data })
      }
    }
    )
  }

  Dadoc = () => {
    const { getReadAll } = this.props;
    getReadAll().then((res) => {
      if (res.status == 200) {
        this.click();
      }
    });
  }

  read = (id) => {
    const { getRead } = this.props;
    if (id != "") {
      getRead(id).then((res) => {
        if (res.status == 200) {
          this.click();
        }
      })
    }
  }

  handleSelect = (idServer) => {
    const { dataListServer } = this.state;
    let findServer = dataListServer.find(item => item.id == idServer)
    if (findServer) {
      localStorage.setItem('DOMAIN_PROVINCE', findServer.urlAdmin)
      if (localStorage.getItem('DOMAIN_PROVINCE')) {
        window.location.reload();
      }
      localStorage.setItem('ID_DOMAIN_PROVINCE', findServer.id)
      localStorage.setItem('NAME_DOMAIN_PROVINCE', findServer.description)
    }
  }

  handleOpenEditUp = (id, type) => {
    const { requestCompanyGetDetails, requestCompanyGetFileUpdate, checkDay } = this.props;
    this.setState({ itemIdView: id })
    checkDay(id + '&type=' + type).then(res => {
      if (res.data.status == 200) {
        requestCompanyGetFileUpdate(id).then((res) => {
          if (res.data.status === 200) {
            let listXXX = res.data.data;
            listXXX.map(list => {
              if (list.uploadDate) {
                list.sorted_file = list.uploadDate;
              } else {
                list.sorted_file = list.expiredDate;
              }
            })
            let sorted_file = listXXX.sort((a, b) => {
              return new Date(a.sorted_file).getTime() -
                new Date(b.sorted_file).getTime()
            }).reverse();
            this.setState({ fileUpdate: sorted_file })
          }
        })
        requestCompanyGetDetails(id);
        this.setState({ idRow: id })
        if (type == 2) {
          this.toggleModal('viewModal');
        } else if (type == 4) {
          this.toggleModal('viewModal2');
        }

      } else {
        this.setState({ messageErr: res.data.message })
        this.toggleModal('popupMessage');
      }
    })

  }

  handleNewData = (data) => {
    this.setState({ newData: data });
  }

  handleNewDataAwait = (data) => {
    this.setState({ newDataAwait: data });
  }

  handleNewDataUnconfirm = (data) => {
    this.setState({ newDataUnconfirm: data });
  }

  componentUnComfirmMount = (value) => {
    let { idRow, newDataUnconfirm } = this.state;
    const { requestUnComfirmRequestExtend } = this.props;
    const errorInsert = {};
    if (newDataUnconfirm) {
      if (!newDataUnconfirm.reason) {
        errorInsert['reason'] = 'Lý do không được bỏ trống';
      }

      this.setState(previousState => {
        return {
          ...previousState,
          errorInsert
        }
      });
      if (Object.keys(errorInsert).length > 0) {
        this.setState(previousState => {
          return {
            ...previousState,
            errorInsert
          }
        });

        return;
      }
      this.setState(previousState => {
        return {
          ...previousState,
          errorInsert: {}
        }
      });
      const createData = JSON.stringify({
        id: idRow,
        reason: newDataUnconfirm.reason
      })


      //this.handleCloseUN(true);
      requestUnComfirmRequestExtend(createData).then(res => {

        if (res.data.status == 200) {

          this.toggleModal('updateModal')
        } else {
          this.setState({ messageErr: res.data.message })
          this.toggleModal('popupMessage')
        }
      })
    }
    //window.location.reload(true);
  }
  componentUnComfirmMountAwait = (value) => {
    let { newDataAwait } = this.state;
    const { requestCompanyUnComfirmStore } = this.props;

    const errorMessagesUn = {};

    this.setState(previousState => {
      return {
        ...previousState,
        errorMessagesUn
      }
    });
    const createData = JSON.stringify({
      id: newDataAwait.id,
      reason: newDataAwait.reason
    })
    
    this.toggleModal('updateModal3')

    if (!newDataAwait.reason) {
      errorMessagesUn['reason'] = 'Lý do không được để trống';
    }

    if (Object.keys(errorMessagesUn).length > 0) {
      this.setState(previousState => {
        return {
          ...previousState,
          errorMessagesUn
        }
      });
      return;
    }

    this.setState(previousState => {
      return {
        ...previousState,
        errorUpdate: {},
        errorMessagesUn: {},
        detail: null,
        updateModal: false
      }
    });

    requestCompanyUnComfirmStore(createData)
    this.setState({ fetchingUnComfirm: false })


    //window.location.reload(true);
  }

  handleOpenExten = (id) => {
    const { requestCompanyGetDetails } = this.props;

    requestCompanyGetDetails(id);
  }

  componentExtend = (value) => {
    let { detail } = this.state;

    const { requestComfirmRequestExtend } = this.props;
    const createData = JSON.stringify({
      id: detail.id,
      year: value.year
    });

    requestComfirmRequestExtend(createData);
    this.setState({ fetchingUnExtend: false })
  }

  handleComfirmRow = () => {
    const { requestCompanyComfirmStore, requestCompanyAwaitListStore } = this.props;
    let { itemIdView } = this.state;
    if (itemIdView) {
      requestCompanyComfirmStore(itemIdView)
        .then(res => {
          if (res.status == 200) {
            this.toggleModal('viewModal2')
          } else {
            this.setState({
              comfirm: [],
              isLoading: true,
              status: false,
              message: PLEASE_CHECK_CONNECT(res.message) || 'Duyệt công ty thất bại',
              errmessage: PLEASE_CHECK_CONNECT(res.message) || 'Duyệt công ty thất bại'
            });
            this.toggleModal('popupMessage')
          }
        })
        .catch(err => (
          this.setState({
            comfirm: [],
            isLoading: true,
            status: false,
            message: PLEASE_CHECK_CONNECT(err.message) || 'Duyệt công ty thất bại'
          })
        ));
    }
  }

  render() {
    const {
      newData,
      updateModal,
      details,
      activeCreateSubmit,
      notChang,
      fileView,
      totalAlert,
      alertAll,
      viewModal,
      detail,
      fileUpdate,
      updateModal2,
      updateModal3,
      extendModal,
      price,
      popupMessage,
      messageErr,
      viewModal2,
      idRow,
      xem,
      errorMessagesUn,
      warningPopupModal
    } = this.state;
    let isDisableConfirm = true;
    let isDisableUnconfirm = true;
    if (IS_ADMIN) {
      isDisableConfirm = false;
      isDisableUnconfirm = false;
    } else {
      ACCOUNT_CLAIM_FF.filter(x => x == "RequestExtendCompanies.Confirm").map(y => isDisableConfirm = false)
      ACCOUNT_CLAIM_FF.filter(x => x == "RequestExtendCompanies.Unconfirm").map(y => isDisableUnconfirm = false)
    }

    let isDisableConfirmAwait = true;
    let isDisableUnConfirmAwait = true;
    if (IS_ADMIN) {
      isDisableConfirmAwait = false;
      isDisableUnConfirmAwait = false;

    } else {
      ACCOUNT_CLAIM_FF.filter(x => x == "ConfirmingCompanies.Confirm").map(y => isDisableConfirmAwait = false)
      ACCOUNT_CLAIM_FF.filter(x => x == "ConfirmingCompanies.Unconfirm").map(y => isDisableUnConfirmAwait = false)
    }

    return (
      <>
        {
          <Navbar className={`navbar-top navbar-dark bg-gradient-info ${classes.headerNavbar}`} expand="md" id="navbar-main">
            <Container fluid row>
              {/* <Link
                className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
                to="/"
              >
                {props.brandText}
              </Link> */}
              {/* <div style={{ width: '100%', textAlign: 'left' }}>
                <div style={{ float: 'left', paddingTop: 8 }}>
                  <span style={{ color: '#fff', fontSize: 18, fontWeight: 'bold' }}>SERVER:</span>&nbsp;
                </div>
                <div style={{ float: 'left' }}>
                  <Select
                    data={dataListServer}
                    styleCustom={'minWidth250'}
                    labelName='description'
                    labelMark={localStorage.getItem('NAME_DOMAIN_PROVINCE')}
                    defaultValue={localStorage.getItem('ID_DOMAIN_PROVINCE')}
                    val='id'
                    isHideSelectAll={true}
                    handleChange={(event) => this.handleSelect(event)}
                  />
                </div>
              </div> */}
              <div className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
                <MenuContext.Consumer>
                  {value => (
                    <>
                      {(value || {}).data !== null ? (value || {}).data : ''}
                    </>
                  )}
                </MenuContext.Consumer>
              </div>

              <Nav className="align-items-center d-none d-md-flex" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle nav className="nav-link-icon" style={{ position: 'relative' }}
                    onClick={() => this.click()}
                  >
                    <i className="ni ni-bell-55" style={{ fontSize: 24 }} />
                    {totalAlert > 0 && (
                      <div style={{
                        border: "1px solid red",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        background: "red",
                        color: "#fff",
                        position: "absolute",
                        right: "11px",
                        top: "-3px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "10px"
                      }}>
                        {totalAlert}
                      </div>
                    )}
                  </DropdownToggle>
                  <DropdownMenu
                    aria-labelledby="navbar-default_dropdown_1"
                    className="dropdown-menu-arrow"
                    right
                  >
                    <div style={{
                      padding: "0 10px",
                      borderBottom: "1px solid gray",
                      textAlign: 'right',
                      margin: 0,
                    }}>
                      <a
                        style={{
                          fontWeight: "bold",
                          color: "#000",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          this.Dadoc();
                        }}
                      >
                        Đọc tất cả
                      </a>
                    </div>
                    <div style={{
                      overflowX: 'hidden',
                      overflowY: 'auto',
                      height: 450,
                      width: 400,
                    }}
                      className={classes.hoverNoti}
                    >
                      {alertAll.map((item, index) => {
                        return (
                          <div
                            style={{
                              padding: "0 10px",
                              borderBottom: "1px solid gray",
                            }}
                            onClick={() => {
                              this.read(item.status == 1 ? '' : item.id)
                              if (item.alertTypeID == 2) {
                                this.handleOpenEditUp(item.refID, 2);
                              }
                              if (item.alertTypeID == 4) {
                                this.handleOpenEditUp(item.refID, 4);
                              }
                            }}>
                            <p
                              style={{
                                margin: 0,
                                fontSize: 14,
                                color: item.status == 0 ? "#000" : '#65676B'
                              }}>
                              Bởi: <i style={{ fontWeight: 'bold' }}>{item.sentBy}</i>
                            </p>
                            <div style={{ display: "flex", marginRight: 0, marginLeft: 0 }}>
                              <div style={{
                                fontSize: 14,
                                margin: 0,
                                color: item.status == 0 ? "#000" : '#65676B',
                                flex: 1
                              }}>
                                {item.content}
                              </div>

                              {item.status == 0 && (
                                <div
                                  style={{
                                    backgroundColor: '#09b2fd',
                                    width: 10,
                                    height: 10,
                                    borderRadius: "50%",
                                    verticalAlign: "middle",
                                    alignSelf: "center"
                                  }}>
                                </div>
                              )}

                            </div>
                            <p style={{
                              margin: 0,
                              fontSize: 12,
                              color: item.status == 0 ? "#000" : '#65676B',
                              textAlign: 'right',
                              fontWeight: item.status == 0 ? 'bold' : 'normal',
                            }}>
                              {moment(item.sentDate).format('HH:mm DD/MM/YYYY')}
                            </p>
                          </div>
                        )
                      })}
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="pr-0" nav>
                    <Media className="align-items-center">
                      <div className="avatar avatar-sm rounded-circle">
                        {
                          (ACCOUNT_AVA !== null && fileView !== null) ||
                            (ACCOUNT_AVA == null && fileView !== null) ||
                            (ACCOUNT_AVA !== null && fileView == null) ? (
                            <div id='avatar' className={`${classes.avatar} ${classes.avatarBck}`}
                              style={{
                                background: `url(${notChang == true ? (fileView == null ? NoImg : fileView) : ACCOUNT_AVA})`
                              }} />
                          ) : (
                            // <i className={`ni ni-circle-08 ${classes.avatar}`} />
                            <div id='avatar'
                              className={`${classes.avatar} ${classes.avatarBck}`}
                              style={{
                                background: `url(${NoImg})`
                              }} />
                          )
                        }
                      </div>
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {ACCOUNT_NAME}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem to="/" tag={Link}>
                      <i className="ni ni-single-02" />
                      <span>Đổi mật khẩu</span>
                    </DropdownItem>
                    {ACCOUNT_NAME == "Administrator" ? null : (
                      <DropdownItem onClick={() => {
                        this.toggleModal('updateModal');
                        this.handleOpenEdit();
                      }}>
                        <div className={classes.updateAvaArea}>
                          <div className="upload-btn-wrapper">
                            <div>
                              <i className={`ni ni-circle-08 ${classes.updateAva}`} />
                              <span>Đổi hình đại diện</span>
                            </div>

                          </div>
                        </div>
                      </DropdownItem>
                    )}
                    <DropdownItem divider />
                    <DropdownItem onClick={() => this.handleCloseApp()}>
                      <i className="ni ni-user-run" />
                      <span>Đăng xuất</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>

              </Nav>
            </Container>
          </Navbar>

        }
        {
          details ?
            <UpdatePopup
              moduleTitle='Đổi hình đại diện'
              moduleBody={
                <ChangeAvatar
                  data={details}
                  handleNewData={this.handleNewDataUpdate}
                  handleCheckValidation={this.handleCheckValidation}
                  imgAvatarView={ACCOUNT_AVA}
                />}
              newData={newData}
              updateModal={updateModal}
              toggleModal={this.toggleModal}
              activeSubmit={activeCreateSubmit}
              handleUpdateInfoData={this.handleUpdateData}
            />
            : null
        }
        {detail ? (
          <ViewPopup
            moduleTitle='Xem thông tin'
            moduleBody={
              <ViewModal
                data={detail}
                fileUpdate={fileUpdate}
                handleCheckValidation={this.handleCheckValidation}
                handleNewData={this.handleNewData}
              />}
            newData={newData}
            viewModal={viewModal}
            componentFooter={
              <div>{isDisableConfirm == false && isDisableUnconfirm == false ? (
                <div className="modal-footer" style={{ marginRight: '-20px' }}>
                  {isDisableConfirm == false ? (
                    <div>
                      <Button
                        color="success"
                        type="button"
                        className={`btn-success-cs`}
                        style={{ marginRight: '26px !important', }}
                        onClick={() => {
                          this.toggleModal('extendModal');
                          this.handleOpenExten(this.state.itemIdView);
                          this.toggleModal('viewModal');
                        }}
                      >
                        <img src={Duyet} alt='Duyệt' />
                        <span>Duyệt</span>
                      </Button>
                    </div>
                  ) : null}
                  {isDisableUnconfirm == false ? (
                    <div>
                      <Button
                        color="default"
                        type="button"

                        className={`btn-notyet-cs`}
                        onClick={() => {
                          this.toggleModal('updateModal2');
                          this.handleOpenEditEX(this.state.itemIdView);
                          this.toggleModal('viewModal');
                        }}
                      >
                        <img src={Kduyet} alt='Không duyệt' />
                        <span>Không duyệt</span>
                      </Button>
                    </div>
                  ) : null}
                </div>
              ) : null}</div>
            }
            toggleModal={this.toggleModal}
            activeSubmit={activeCreateSubmit}
            handleUpdateInfoData={this.componentExtend}
          />
        ) : null}

        {detail ? (
          <ViewPopup2
            moduleTitle='Xem thông tin'
            moduleBody={
              <ViewModalAwait
                itemDataView={idRow}
                data={detail}
                fileUpdate={fileUpdate}
                handleCheckValidation={this.handleCheckValidation}
                handleNewData={this.handleNewDataAwait}
              />}
            newData={newData}
            viewModal2={viewModal2}
            componentFooter={
              <div>{(isDisableConfirm == false && isDisableUnConfirmAwait == false) ? (
                <div className="modal-footer" style={{ marginRight: '-20px' }}>
                  {xem && (
                    <>
                      {isDisableConfirm == false && xem.status != 4 ? (
                        <div>
                          <Button
                            color="success"
                            type="button"
                            className={`btn-success-cs`}
                            style={{ marginRight: '26px !important', }}
                            onClick={() => {
                              this.toggleModal('warningPopupModal');
                              this.setState({ comfirmItem: this.state.itemIdView })
                            }}
                          >
                            <img src={Duyet} alt='Duyệt' />
                            <span>Duyệt</span>
                          </Button>
                        </div>
                      ) : null}
                      {isDisableUnConfirmAwait == false && xem.status != 4 ? (
                        <div>
                          <Button
                            color="default"
                            type="button"

                            className={`btn-notyet-cs`}
                            onClick={() => {
                              this.toggleModal('updateModal3');
                              this.handleOpenEdit(this.state.itemIdView);
                              this.toggleModal('viewModal2');
                            }}
                          >
                            <img src={Kduyet} alt='Không duyệt' />
                            <span>Không duyệt</span>
                          </Button>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              ) : null}</div>
            }
            toggleModal={this.toggleModal}
            activeSubmit={activeCreateSubmit}
            handleUpdateInfoData={this.componentExtend}
          />
        ) : null}

        <UpdatePopup2
          moduleTitle='Thông báo'
          resize={'mb'}
          moduleBody={
            <UnComfirmModal
              data={detail}
              handleCheckValidation={this.handleCheckValidation}
              handleNewData={this.handleNewDataUnconfirm}
              errorInsert={this.state.errorInsert}
            />}
          newData={newData}
          updateModal2={updateModal2}
          toggleModal={this.toggleModal}
          activeSubmit={activeCreateSubmit}
          handleUpdateInfoData={this.componentUnComfirmMount}
        />
        <UpdatePopup3
          moduleTitle='Thông báo'
          resize={'mb'}
          moduleBody={
            <UnComfirmModalAwait
              data={detail}
              errorMessagesUn={errorMessagesUn}
              handleCheckValidation={this.handleCheckValidation}
              handleNewData={this.handleNewDataAwait}
            />}
          newData={newData}
          updateModal3={updateModal3}
          toggleModal={this.toggleModal}
          activeSubmit={activeCreateSubmit}
          handleUpdateInfoData={this.componentUnComfirmMountAwait}
        />

        <ExtendPopup
          moduleTitle='Thông báo'
          moduleBody={
            <ExtendModal
              data={detail}
              price={price}
              handleCheckValidation={this.handleCheckValidation}
              handleNewData={this.handleNewData}
            />}
          newData={newData}
          extendModal={extendModal}
          toggleModal={this.toggleModal}
          activeSubmit={activeCreateSubmit}
          handleUpdateInfoData={this.componentExtend}
        />

        <WarningPopup
          moduleTitle='Thông báo'
          moduleBody={
            <p style={{ textAlign: 'center', fontSize: '1.2rem' }}>
              Bạn đồng ý duyệt thông tin này?
            </p>}
          warningPopupModal={warningPopupModal}
          toggleModal={this.toggleModal}
          handleWarning={this.handleComfirmRow}
        />

        <PopupMessage
          popupMessage={popupMessage}
          moduleTitle={'Thông báo'}
          moduleBody={messageErr}
          toggleModal={this.toggleModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.UserListStore,
    dashboard: state.DashboardStore,
    details: state.CompanyGetDetailsStore,
    price: state.PriceStore,
    company: state.CompanyListRequestExtendStore,
    company1: state.CompanyAwaitStore,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(actionListRequestExtend, dispatch),
    ...bindActionCreators(actionCreators, dispatch),
    ...bindActionCreators(actionDashboardCreators, dispatch),
    ...bindActionCreators(actionCompanyGetDetails, dispatch),
    ...bindActionCreators(actionPriceCreators, dispatch),
    ...bindActionCreators(actionGetListCompanyAwait, dispatch),
  }
}

export default
  compose(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )
  )(
    AdminNavbar,
  );
