import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import compose from 'recompose/compose';
import { connect } from "react-redux";
import { actionListRequestExtend } from "../../../../../actions/CompanyListRequestExtendActions";
import { actionField } from "../../../../../actions/FieldActions.js";
import { actionPriceCreators } from "../../../../../actions/PricesListActions";
import { actionCompanyGetDetails } from "../../../../../actions/CompanyGetDetailsActions";
import { LOADING_TIME } from "../../../../../helpers/constant";
import { PLEASE_CHECK_CONNECT } from "../../../../../services/Common";
import moment from 'moment';

import { NavLink } from 'react-router-dom';
import { Spinner } from 'reactstrap'


class ListRequestExtend extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            isLoaded: null,
            limit: 10
        }
    }
    componentWillReceiveProps(nextProp) {
        let { data } = nextProp.company;
        let fieldData = nextProp.field.data;
        let { ngayhethan } = this.state;
        let priceDate = nextProp.price.data;
        const { limit } = this.state;
        const { fetchingUnComfirm, fetchingExtend } = this.state;
        const { requestListRequestExtend } = nextProp;
        let detailsData = nextProp.details.data;

        if (fieldData !== this.state.field) {
            if (typeof (fieldData) !== 'undefined') {
                if (fieldData.field !== null) {
                    if (typeof (fieldData.field) !== 'undefined') {
                        this.setState({
                            field: fieldData.field.fields,
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    } else {
                        this.setState({
                            field: fieldData.field,
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    }
                }
            }
        }

        if (data !== this.state.data) {
            if (typeof (data) !== 'undefined') {
                if (typeof (data.company) !== 'undefined') {
                    if (data.company !== null) {
                        if (typeof (data.company.companies) !== 'undefined') {
                            data.company.companies.map((item, key) => {
                                item['index'] = key + 1;
                                item['collapse'] = false;
                                if (item.status == 0) {
                                    item['statusName'] = 'Chưa duyệt'
                                } else if (item.status == 1) {
                                    item['statusName'] = 'Đã duyệt'
                                } else if (item.status == 2) {
                                    item['statusName'] = 'Không duyệt'
                                } else if (item.status == 3) {
                                    item['statusName'] = 'Mới đăng ký'
                                } else if (item.status == 4) {
                                    item['statusName'] = 'Chờ bổ sung'
                                };
                                // item['requestExtendDate'] = moment(item.requestExtendDate).format('DD/MM/YYYY');
                                // item['expiredDate'] = moment(item.expiredDate).format('DD/MM/YYYY');
                                //item['expiredDays'] = moment(addDays(ngayhethan, item['daysLeft'])).format('DD/MM/YYYY')
                                // item['expiredDays'] = moment(item.expiredDate).format('DD/MM/YYYY');
                            });
                            this.setState({
                                data: data.company.companies,
                                history: data.company.companies,
                                listLength: data.company.companies.length,
                                totalPage: Math.ceil(data.company.companies.length / limit),
                                isLoaded: data.isLoading,
                                status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });
                        } else {
                            this.setState({
                                data: data.company.companies,
                                history: data.company.companies,
                                isLoaded: data.isLoading,
                                status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });
                        }
                    }
                }
            }
        }


        if (detailsData.details !== null) {
            if (typeof (detailsData.details) !== 'undefined') {
                this.setState({
                    xem: detailsData.details,
                    detail: detailsData.details,
                    detailView: detailsData.details,
                    isLoaded: false,
                    status: data.status,
                    message: PLEASE_CHECK_CONNECT(data.message)
                });
            } else {
                this.setState({
                    xem: detailsData.details,
                    isLoaded: false,
                    status: data.status,
                    message: PLEASE_CHECK_CONNECT(data.message)
                });
            }
        }


        if (priceDate !== this.state.price) {
            if (typeof (priceDate) !== 'undefined') {
                if (typeof (priceDate.prices) !== 'undefined') {
                    if (priceDate.prices !== null) {
                        this.setState({
                            price: priceDate.prices.prices,
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    } else {
                        this.setState({
                            price: [],
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    }
                }
            }
        }
        if (typeof (detailsData) !== 'undefined') {
            if (detailsData.details !== null) {
                if (typeof (detailsData.details) !== 'undefined') {
                    this.setState({
                        xem: detailsData.details,
                        detail: detailsData.details,
                        isLoaded: false,
                        status: data.status,
                        message: PLEASE_CHECK_CONNECT(data.message)
                    });
                } else {
                    this.setState({
                        xem: detailsData.details,
                        isLoaded: false,
                        status: data.status,
                        message: PLEASE_CHECK_CONNECT(data.message)
                    });
                }
            }
        }
        if (typeof (data.uncomfirm) !== 'undefined') {
            //console.log(data.uncomfirm)

            if (data.status && !fetchingUnComfirm) {
                this.setState({ data: [] });
                requestListRequestExtend(JSON.stringify({
                    "fieldID": "",
                    "comapanyName": "",
                    "taxCode": "",
                    "phone": "",
                    "email": "",
                    "provinceID": "",
                    "districtID": "",
                    "wardID": "",
                    "orderBy": "",
                    "page": null,
                    "limit": null,
                }))
                this.setState({ fetchingUnComfirm: true });
            }

        }

        if (typeof (data.extend) !== 'undefined') {
            if (data.status && !fetchingExtend) {
                this.setState({ data: [] });
                requestListRequestExtend(JSON.stringify({
                    "fieldID": "",
                    "comapanyName": "",
                    "taxCode": "",
                    "phone": "",
                    "email": "",
                    "provinceID": "",
                    "districtID": "",
                    "wardID": "",
                    "orderBy": "",
                    "page": null,
                    "limit": null,
                }))
                this.setState({ fetchingExtend: true });
            }
        }
    }
    componentDidMount() {
        // This method is called when the component is first added to the document
        // this.filterMapKey();

        /* Fetch Summary */
        this.fetchSummary(JSON.stringify({
            "search": "",
            "filter": "",
            "orderBy": "",
            "page": null,
            "limit": null
        }));
    }
    closeStatusModal = () => {
        const { status } = this.state;

        if (status || !status) {
            setTimeout(() => {
                this.setState({ status: null, isLoaded: false });
            }, LOADING_TIME);
        }
    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        this.closeStatusModal();
    }

    fetchSummary = (data) => {
        const { requestListRequestExtend } = this.props;

        requestListRequestExtend(data);
    }

    render() {
        const { data, isLoaded } = this.state
        return (
            <>
                <div className='header-box-list box-list-border display-box'>
                    <h4>Yêu cầu gia hạn</h4>
                </div>
                {
                    isLoaded ? (<div style={{ display: 'table', margin: 'auto' }}>
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                    </div>) : (<>
                        <div className='body-box-list' style={{ padding: 10 }}>
                            <table className='table-box-list-3'>
                                <thead>
                                    <tr className='table-tr-box-list'>
                                        <th>Doanh nghiệp</th>
                                        {/* <th>Ngành nghề</th> */}
                                        {/* <th>Dải tem</th> */}
                                        <th>Ngày yêu cầu</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {
                                        Array.isArray(data) && (
                                            data.map((item, key) => (
                                                <tr className="table-hover-css" key={key}>
                                                    <td className='text-algin-text'>
                                                        <p className='text-td-3'>
                                                            {item.companyName}
                                                        </p>
                                                    </td>
                                                    {/* <td className='text-td-3' style={{ width: 161 }}>Nhân và chăm sóc cây giống nông nghiệp;Trồng rừng, chăm sóc rừng và ươm giống cây lâm nghiệp</td> */}
                                                    <td className='text-algin-date'>
                                                        {item.requestExtendDate ? moment(item.requestExtendDate).format('DD/MM/YYYY') : null}
                                                        {/* {item.status === 0 ? (<>
                                                            <span className='noActiveStt text-center-status'>Chưa duyệt</span>
                                                        </>) : ''}
                                                        {item.status === 4 ? (<>
                                                            <span className='notyetStt text-center-status'>Chờ bổ sung</span>
                                                        </>) : ''} */}
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    </>)
                }
                <div className='footer-box-list'>
                    <NavLink to="/trang_chu/danh_sach_yeu_cau_gia_han">Xem thêm</NavLink>
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        company: state.CompanyListRequestExtendStore,
        field: state.FieldStore,
        price: state.PriceStore,
        details: state.CompanyGetDetailsStore,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionListRequestExtend, dispatch),
        ...bindActionCreators(actionField, dispatch),
        ...bindActionCreators(actionPriceCreators, dispatch),
        ...bindActionCreators(actionCompanyGetDetails, dispatch),
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ListRequestExtend)
