import React, { Component } from "react";
import { bindActionCreators } from "redux";
import compose from 'recompose/compose';
import { connect } from "react-redux";
import { actionProducts } from "../../../actions/ProductsActions";
import { PRODUCTS } from "../../../helpers/constant";
import { setAlertContext, openAlertContext } from "../../../helpers/common.js";
import { PLEASE_CHECK_CONNECT, ACCOUNT_CLAIM_FF, IS_ADMIN } from "../../../services/Common";
import MenuButton from "../../../assets/img/buttons/menu.png";
import ViewModal from "./ViewModal";
import Pagination from "components/Pagination";
import HeaderTable from "components/HeaderTable";
import HeadTitleTable from "components/HeadTitleTable";
import Select from "components/Select";
import UnconfirmModal from "./UnconfirmModal";
import WarningPopup from "../../../components/WarningPopup";
import classes from './index.module.css';
import PopupMessage from "../../../components/PopupMessage";
import { LIMIT_ITEM_IN_PAGE, LOADING_TIME } from "../../../helpers/constant";
import UpdatePopup from "../../../components/UpdatePopup";
import SelectSearch, { fuzzySearch } from "react-select-search";
import SelectTree from "components/SelectTree";
import SearchImg from "../../../assets/img/buttons/searchig.svg";
import { actionCompanyListRegistered } from "../../../actions/CompanyListRegisteredActions";
import { actionField } from "../../../actions/FieldActions.js";
import { handleGenTree } from "../../../helpers/trees";
import NoImg from "../../../assets/img/NoImg/NoImg.jpg";
import ViewPopup from "../../../components/ViewPopup"
import Kduyet from "assets/img/buttons/KoDuyet.svg";
import Duyet from "assets/img/buttons/Duyet.svg";
import moment from 'moment';

import {
  Card,
  Table,
  Container,
  Row,
  Spinner,
  Button,
  ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      field: [],
      options: [],
      detail: null,
      update: null,
      create: null,
      delete: null,
      isLoaded: null,
      status: null,
      open: false,
      comfirm: null,
      message: '',
      errMesageFail: '',
      newData: null,
      statusList: [
        { name: 'Mới tạo', confirmedStatus: 0 },
        { name: 'Đã duyệt', confirmedStatus: 1 },
        { name: 'Không duyệt', confirmedStatus: 2 },
        { name: 'Yêu cầu duyệt', confirmedStatus: 3 }
      ],
      filter: {
        "fieldID": "",
        "productCode": "",
        "productName": "",
        "companyID": "",
        "confirmedStatus": null,
        "orderBy": "",
        "page": null,
        "limit": null
      },
      headerTitle: PRODUCTS,
      limit: LIMIT_ITEM_IN_PAGE,
      beginItem: 0,
      endItem: LIMIT_ITEM_IN_PAGE,
      totalElement: 0,
      listLength: 0,
      currentPage: 0,
      confirmItem: null,
      warningPopupModal: false,
      warningPopupUnConfirmModal: false,
      activeCreateSubmit: false,
      createNewModal: false,
      isPopupDetail: false,
      reasonUnConfirm: '',
      dataPopupDetail: null,
      dataProductsUnits: [],
      dataProducts: {},
      errorUpdate: {}
    }

  }

  componentWillReceiveProps(nextProp) {
    let { data } = nextProp.products;
    const { limit, fetching } = this.state;
    let fieldData = nextProp.field.data;
    let haveRoot = false;
    let fieldDataParent = [];

    if (data !== this.state.data) {
      if (typeof (data) !== 'undefined') {
        if (typeof (data.list) !== 'undefined') {
          if (data.list !== null) {
            if (typeof (data.list.products) !== 'undefined') {
              data.list.products.map((item, key) => {
                item['thumbnail'] = <img src={item.avatar ? item.avatar : NoImg} style={{ width: 250, height: 200 }} />
                if (item.confirmedStatus == 0) {
                  item['statusName'] = 'Mới tạo'
                }
                else if (item.confirmedStatus == 1) {
                  item['statusName'] = 'Đã duyệt'
                } else if (item.confirmedStatus == 2) {
                  item['statusName'] = 'Không duyệt'
                } else if (item.confirmedStatus == 3) {
                  item['statusName'] = 'Yêu cầu duyệt'
                };
                item['index'] = key + 1;
                item['collapse'] = false;

              });
              this.setState({
                data: data.list.products,
                history: data.list.products,
                listLength: data.list.total,
                totalPage: Math.ceil(data.list.products.length / limit),
                isLoaded: data.isLoading,
                status: data.status,
                message: PLEASE_CHECK_CONNECT(data.message)
              });
            } else {
              this.setState({
                history: data.list.products,
                isLoaded: data.isLoading,
                status: data.status,
                message: PLEASE_CHECK_CONNECT(data.message)
              });
            }
          }
        }
      }
    }

    if (fieldData !== this.state.field) {
      if (typeof (fieldData) !== 'undefined') {
        if (fieldData.field !== null) {
          if (typeof (fieldData.field) !== 'undefined') {
            if (typeof (fieldData.field.fields) !== 'undefined') {
              fieldData.field.fields
                .filter(item => item.parentID === null)
                .map(item => haveRoot = true);

              if (haveRoot) {
                fieldDataParent = handleGenTree(fieldData.field.fields, 'fieldName');

                fieldDataParent.map((item, key) => {
                  item['index'] = key + 1;

                });
              } else {
                // Search Element in tree
                fieldData.field.fields.map(
                  (item, key, array) => (
                    key === 0 && (item.parentID = null)
                  ));

                fieldDataParent = handleGenTree(fieldData.field.fields, 'fieldName');

                fieldDataParent.map((item, key) => {
                  item['index'] = key + 1
                });
              }
            }

            this.setState({
              field: fieldDataParent,
              fieldAll: fieldData.field.fields,
              isLoaded: false,
              status: data.status,
              message: PLEASE_CHECK_CONNECT(data.message)
            });
          } else {
            this.setState({
              field: [],
              isLoaded: false,
              status: data.status,
              message: PLEASE_CHECK_CONNECT(data.message)
            });
          }
        }
      }
    }
  }



  toggle = (el, val) => {
    let { data } = this.state;

    data.filter(item => item.id === val)
      .map(item => item.collapse = !item.collapse);

    this.setState({ data });
  }

  componentDidMount() {
    /* Fetch Summary */
    const { requestCompanyListRegistered, requestFieldStore, requestListProducts, } = this.props;

    const dataCompany = {
      "fieldID": "",
      "comapanyName": "",
      "taxCode": "",
      "phone": "",
      "email": "",
      "provinceID": "",
      "districtID": "",
      "wardID": "",
      "orderBy": "",
      "page": null,
      "limit": null
    }
    requestCompanyListRegistered(dataCompany).then(res => {
      if (res.status == true) {
        const data = (res.data || {}).data || [];
        let oppss = [{
          address: "",
          companyName: "--- Chọn Doanh nghiệp/Người sản xuất ---",
          fieldName: "",
          id: "",
          //isCertified: false,
          phoneNumber: "",
          registeredDate: "",
          taxCode: "",
        }]
        let ccopps = oppss.concat(data.companies)
        if (data) {
          this.setState(previousState => {
            return {
              ...previousState,
              dataCompany: data.companies,
              //valueDr: data.companies,
              options: ccopps,
            }
          });
        }
      }
    })

    requestFieldStore(JSON.stringify({
      "search": "",
      "filter": "",
      "orderBy": "",
      "page": null,
      "limit": null
    })).then(res => {
      this.fetchSummary(JSON.stringify({
        "fieldID": "",
        "productCode": "",
        "productName": "",
        "companyID": "",
        "confirmedStatus": null,
        "orderBy": "",
        "page": null,
        "limit": null
      }));
    })

    this.fetchSummary(JSON.stringify({
      "fieldID": "",
      "productCode": "",
      "productName": "",
      "companyID": "",
      "confirmedStatus": null,
      "orderBy": "",
      "page": null,
      "limit": null
    }));
  }

  componentDidUpdate() {
    // This method is called when the route parameters change
    this.closeStatusModal();
  }

  fetchSummary = (data) => {
    const { requestListProducts } = this.props;

    requestListProducts(data);
  }

  handleClose = (value) => {
    const { open } = this.state;

    this.setState({ open: value });
  }

  handleChange = (event) => {
    let { data } = this.state;
    const ev = event.target;

    data[ev['name']] = ev['value'];

    this.setState({ data });
  }

  closeStatusModal = () => {
    const { status, fetching } = this.state;

    if (status || !status && fetching) {
      setTimeout(() => {
        this.setState({ status: null, isLoaded: false });
      }, LOADING_TIME);
    }
  }

  handlePageClick = (data) => {
    let { limit, beginItem, endItem } = this.state;
    let selected = data.selected;
    let offset = Math.ceil(selected * limit);
    let total = 0;

    beginItem = offset;
    endItem = offset + limit;

    this.state.data.map((item, key) => (
      key >= beginItem && key < endItem && total++
    ));

    if (selected > 0) {
      total = (selected * limit) + total;
    } else total = total;

    this.setState({ beginItem: beginItem, endItem: endItem, currentPage: selected + 1, totalElement: total });
  };

  handleStyleStatus = (status) => {
    if (status === 0) {
      return classes.moitao;
    }
    if (status === 1) {
      return classes.daduyet;
    }
    if (status == 2) {
      return classes.khongduyet;
    }
    if (status == 3) {
      return classes.yeucauduyet;
    };
  }

  handleSelect = (value, name) => {

    if (value) {
      this.fetchSummary(JSON.stringify({
        "fieldID": "",
        "productCode": "",
        "productName": "",
        "companyID": "",
        "confirmedStatus": null,
        "orderBy": "",
        "page": null,
        "limit": null
      }));
    }

  }

  toggleModal = (state, type) => {
    if (this.state[state] && type == 1) {
      return;
    } else {
      this.setState({
        [state]: !this.state[state],
        newData: null,
        errorUpdate: {},
      });
    }
    if (state == 'createNewModal') {
      this.setState({
        [state]: true,
        errorUpdate: {},
        newData: null,
      });
      if (type == 100) {
        this.setState({
          [state]: !this.state[state],
          errorUpdate: {},
          newData: null,
        });
      }
    }
  };

  onOpenPopupView = (id, dataCurent) => () => {
    const { requestGetProducts } = this.props;
    let dataCurentPop = dataCurent;
    this.setState({ dataCurentPop })
    this.toggleModal('viewModal');
    requestGetProducts(id).then(res => {
      const data = res.data || {};

      let dataProducts = (data || {}).product || {};
      let dataProductsUnits = (data || {}).productsUnits || [];

      this.setState(previousState => {
        return {
          ...previousState,
          idCurRow: id,
          dataProducts,
          dataProductsUnits
        }
      });
    });
  }

  handleConfirmProducts = () => {
    const { idCurRow, filter } = this.state;
    const { requestConfirmProducts } = this.props;

    if (typeof (idCurRow) !== 'undefined') {
      requestConfirmProducts(idCurRow).then(res => {
        if (res.status == 200) {
          this.fetchSummary(filter);
        } else {
          this.setState({
            errMesageFail: res.message
          })
          this.toggleModal('popupMessage');
        }
      })

    }
  }

  handleUnConfirmProducts = (value) => {
    const { newData, idCurRow, filter } = this.state;
    const { requestUnConfirmProducts } = this.props;
    const errorUpdate = {};

    this.setState(previousState => {
      return {
        ...previousState,
        errorUpdate
      }
    });

    if (!(newData || {}).reason) {
      errorUpdate['reason'] = 'Lý do không được bỏ trống';
    }

    if (Object.keys(errorUpdate).length > 0) {
      this.setState(previousState => {
        return {
          ...previousState,
          errorUpdate,
        }
      });

      return;
    }

    this.setState(previousState => {
      return {
        ...previousState,
        errorUpdate: {},
      }
    });

    requestUnConfirmProducts(idCurRow + '&reason=' + newData.reason).then(res => {
      if (res.status == 200) {
        this.fetchSummary(filter);
        this.toggleModal('viewModal');
        this.toggleModal('updateModal');
      } else {
        this.setState({ errMesageFail: res.message });
        this.toggleModal('popupMessage')
      }
    })
  }

  handleCheckValidation = (status) => {
    this.setState({ activeCreateSubmit: status });
  }

  clearFilter = () => {
    this.setState({
      filter: {
        "fieldID": "",
        "productCode": "",
        "productName": "",
        "companyID": "",
        "confirmedStatus": null,
        "orderBy": "",
        "page": null,
        "limit": null
      },
    })
  }

  handleNewDataUncomfirm = (data) => {
    this.setState({ newData: data });
  }

  onComfirmSearch = () => {
    const { filter } = this.state;
    this.fetchSummary(JSON.stringify(filter));
  }

  onSelectChange = (value, name) => {
    let { filter } = this.state;

    if (value === null) value = "";
    if (name == 'confirmedStatus') { value = parseInt(value); }
    filter[name] = value;

    this.setState({ filter });

  }

  render() {
    const { screen } = this.props;
    const {
      isLoaded,
      status,
      message,
      data,
      statusList,
      beginItem,
      endItem,
      listLength,
      totalPage,
      totalElement,
      headerTitle,
      warningPopupModal,
      popupMessage,
      errMesageFail,
      filter,
      activeCreateSubmit,
      viewModal,
      options,
      fieldAll,
      field,
      dataProducts,
      dataProductsUnits,
      dataCurentPop,
      updateModal,
      newData,
      errorUpdate
    } = this.state;
    const statusPopup = { status: status, message: message };

    let isDisableConfirm = true;
    let isDisableUnconfirm = true;
    if (IS_ADMIN) {
      isDisableConfirm = false;
      isDisableUnconfirm = false;

    } else {
      ACCOUNT_CLAIM_FF.filter(x => x == "Products.Confirm").map(y => isDisableConfirm = false)
      ACCOUNT_CLAIM_FF.filter(x => x == "Products.Unconfirm").map(y => isDisableUnconfirm = false)
    }

    options.map(option => {
      option.name = option.companyName;
      option.value = option.id;
    })

    return (
      <>
        {
          <div className={classes.wrapper}>
            <Container fluid>
              {
                isLoaded ? (
                  <div style={{ display: 'table', margin: 'auto' }}>
                    <Spinner style={{ width: '3rem', height: '3rem' }} />
                  </div>
                ) : (
                  <Row>
                    <div className="col">
                      {/* Header */}
                      <HeaderTable
                        dataReload={() => {
                          this.fetchSummary(
                            JSON.stringify(filter)
                          )
                        }
                        }
                        hideCreate={true}
                        hideSearch={true}
                      />
                      {/* Table */}
                      <div className="row" style={{ marginBottom: 15 }}>
                        {/* <div className="col-3">
                          <label className="form-control-label" >Doanh nghiệp/Người sản xuất</label>
                          <div style={{ width: '100%', }}>
                            <SelectSearch

                              options={options}
                              value={options.id}
                              name='companyID'
                              //className={`${classes.selectSearchBox} select-search-box`}
                              onChange={(value) => this.onSelectChange(value, 'companyID')}
                              search
                              filterOptions={fuzzySearch}
                              placeholder="Tìm kiếm..."
                            />
                          </div>
                        </div> */}
                        <div className="col-3">
                          <label
                            className="form-control-label"
                          >
                            Ngành nghề
                          </label>
                          <div>
                            <SelectTree
                              //hidenTitle={true}

                              title='Chọn ngành nghề'
                              data={field}
                              dataAll={fieldAll}
                              name='fieldID'
                              // disableParent={true}

                              labelName='fieldName'
                              fieldName='fieldName'
                              val='id'
                              handleChange={this.onSelectChange}
                            />
                          </div>
                        </div>
                        {/* <div className="col-3">
                          <label
                            className="form-control-label"
                          >
                            Tình trạng
                          </label>
                          <div>
                            <Select
                              name='confirmedStatus'
                              labelName='name'
                              data={statusList}
                              val='confirmedStatus'
                              title='Chọn tình trạng'
                              handleChange={this.onSelectChange}
                            />

                          </div>
                        </div> */}
                        <div className="col-3">
                          <label
                            className="form-control-label"
                          >&nbsp;</label>
                          <Button
                            style={{ margin: 0 }}
                            className='btn-warning-cs'
                            color="default" type="button" size="md"
                            onClick={() => this.onComfirmSearch()
                            }
                          >
                            <img src={SearchImg} alt='Tìm kiếm' />
                            <span>Tìm kiếm</span>
                          </Button>
                        </div>
                      </div>
                      <Card className="shadow">
                        <Table className="align-items-center tablecs" responsive >
                          <HeadTitleTable headerTitle={headerTitle} />
                          <tbody>
                            {
                              Array.isArray(data) && (
                                data
                                  .filter((item, key) => key >= beginItem && key < endItem)
                                  .map((item, key) => (
                                    <tr key={key}>
                                      <td style={{ whiteSpace: 'normal' }}>{item.index}</td>
                                      {/* <td style={{ textAlign: 'center', whiteSpace: 'normal' }} className={`table-scale-col cursor-unset`}>
                                        <span className={this.handleStyleStatus(item.confirmedStatus)}>{item.statusName}</span>
                                      </td> */}
                                      <td style={{ textAlign: 'center' }} className='table-scale-col'>{item.thumbnail}</td>
                                      <td style={{ textAlign: 'left', whiteSpace: 'normal' }}>
                                        <span style={{ color: '#09b2fd' }}><b>{item.productName}</b></span><br />
                                        <span>Mã sản phẩm: <b>{item.productCode}</b></span><br />
                                        <span>Đơn vị tính: <b>{item.unitName}</b></span><br />
                                        <span>Xuất xứ: <b>{item.nationName}</b></span>
                                      </td>
                                      <td style={{ textAlign: 'left', whiteSpace: 'normal' }}>{item.createdDate ? (moment(item.createdDate).format('DD/MM/YYYY')) : null}</td>


                                      <td style={{ whiteSpace: 'normal' }}>
                                        {(isDisableConfirm == true && isDisableUnconfirm == true) ? null : (
                                          <ButtonDropdown isOpen={item.collapse} toggle={() => this.toggle(key, item.id)}>
                                            <DropdownToggle>
                                              <img src={MenuButton} />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                              <DropdownItem
                                                onClick={this.onOpenPopupView(item.id, item)}
                                              >
                                                Xem
                                              </DropdownItem>
                                            </DropdownMenu>
                                          </ButtonDropdown>
                                        )}
                                      </td>
                                    </tr>
                                  ))
                              )
                            }
                          </tbody>
                        </Table>
                      </Card>

                      {/* Pagination */}
                      {
                        // Page of Table
                        Array.isArray(data) && (
                          data.length > 0 && (
                            <Pagination
                              data={data}
                              listLength={listLength}
                              totalPage={totalPage}
                              totalElement={totalElement}
                              handlePageClick={this.handlePageClick}
                            />
                          )
                        )
                      }
                    </div>
                  </Row>
                )
              }
              {
                //Set Alert Context
                setAlertContext(statusPopup)
              }

              {
                //Open Alert Context
                openAlertContext(statusPopup)
              }

              <WarningPopup
                moduleTitle='Thông báo'
                moduleBody={<p style={{ textAlign: 'center', fontSize: '1.2rem' }}>Bạn đồng ý duyệt thông tin này?</p>}
                warningPopupModal={warningPopupModal}
                toggleModal={this.toggleModal}
                handleWarning={this.handleConfirmProducts}
              />

              <ViewPopup
                moduleTitle='Thông tin sản phẩm'
                moduleBody={
                  <ViewModal
                    dataProducts={dataProducts}
                    dataProductsUnits={dataProductsUnits}
                    dataCurentPop={dataCurentPop}
                  />}
                viewModal={viewModal}
                toggleModal={this.toggleModal}
                activeSubmit={activeCreateSubmit}
              // componentFooter={
              //   <>
              //     {dataCurentPop && (
              //       <div>
              //         {dataCurentPop.confirmedStatus == 0
              //           || dataCurentPop.confirmedStatus == 3
              //           || isDisableConfirm == true && isDisableUnconfirm == true ? (
              //           <div className="modal-footer" style={{ marginRight: '-20px' }}>
              //             {isDisableConfirm == false ? (
              //               <div>
              //                 <Button
              //                   color="success"
              //                   type="button"
              //                   className={`btn-success-cs`}
              //                   style={{ marginRight: '26px !important', }}
              //                   onClick={() => {
              //                     this.toggleModal('warningPopupModal');
              //                   }}
              //                 >
              //                   <img src={Duyet} alt='Duyệt' />
              //                   <span>Duyệt</span>
              //                 </Button>
              //               </div>
              //             ) : null}
              //             {isDisableUnconfirm == false ? (
              //               <div>
              //                 <Button
              //                   color="default"
              //                   type="button"
              //                   style={{ backgroundColor: '#FF3333' }}
              //                   className={`btn-danger-cs`}
              //                   onClick={() => {
              //                     this.toggleModal('updateModal');
              //                   }}
              //                 >
              //                   <img src={Kduyet} alt='Không duyệt' />
              //                   <span>Không duyệt</span>
              //                 </Button>
              //               </div>
              //             ) : null}
              //           </div>
              //         ) : null}
              //       </div>
              //     )}
              //   </>
              // }
              />

              <UpdatePopup
                moduleTitle='Thông báo'
                moduleBody={
                  <UnconfirmModal
                    errorUpdate={errorUpdate}
                    handleCheckValidation={this.handleCheckValidation}
                    handleNewData={this.handleNewDataUncomfirm}
                  />}
                newData={newData}
                updateModal={updateModal}
                toggleModal={this.toggleModal}
                activeSubmit={activeCreateSubmit}
                handleUpdateInfoData={this.handleUnConfirmProducts}
              />

              {errMesageFail != '' ?
                <PopupMessage
                  popupMessage={popupMessage}
                  moduleTitle={'Thông báo'}
                  moduleBody={message}
                  toggleModal={this.toggleModal}
                /> : null
              }
            </Container>
          </div>
        }
      </>
    )
  }

}
const mapStateToProps = (state) => {
  return {
    products: state.ProductsStore,
    dataCompany: state.CompanyListRegisteredStore,
    field: state.FieldStore,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(actionProducts, dispatch),
    ...bindActionCreators(actionCompanyListRegistered, dispatch),
    ...bindActionCreators(actionField, dispatch),
  }
}
export default compose(
  //withStyles(useStyles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Products);