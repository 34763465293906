import React, { Component } from "react";
import { MenuContext, menuContext } from "../../helpers/common";
import classes from './index.module.css';
import custom from './custom.css';
import CreateNewPopupBN from "../CreateNewPopupBN";
import SearchImg from "../../assets/img/buttons/searchig.svg";
import PlusImg from "../../assets/img/buttons/plus.svg";
import ApplyImg from "../../assets/img/buttons/apply.svg";
import ExitImg from "../../assets/img/buttons/exit.svg";
import ReloadImg from "../../assets/img/buttons/reload.svg";

// reactstrap components
import {
  Button,
  Fade
} from "reactstrap";

class HeaderTableBN extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fadeIn: false,
      createNewModal: false
    };
  }

  handleSearchButton = () => {
    let { fadeIn } = this.state;

    this.setState({ fadeIn: !fadeIn });
  }

  toggleModal = (state, type) => {
    const { isPreventForm, closeForm } = this.props;

    if (type != 1 && isPreventForm && this.state[state]) {

    } else {
      if (closeForm) {
        closeForm();
      }

      this.setState({
        [state]: !this.state[state]
      });
    }
  };

  render() {
    const { fadeIn, createNewModal } = this.state;
    const {
      hideCreate,
      hideSearch,
      searchForm,
      handleSubmitSearchForm,
      moduleTitle,
      moduleBody,
      activeSubmit,
      newData,
      handleCreateInfoData,
      dataReload,
      typeSearch,
    } = this.props;

    return (
      <>
        {/* Header */}
        <div className={classes.headerTable}>
          {/*  Name page */}
          {/* ThangContext */}
          {/* <MenuContext.Consumer>
            {value => (
              <p className={`${classes.pageName} text-primary`}>{value.data}</p>
            )}
          </MenuContext.Consumer> */}
          <div className={`${classes.mainFunc} `}>
            {typeSearch}
          </div>
          <Button type="button" size="lg" className='btn-reload-cs' onClick={dataReload}>
            <img src={ReloadImg} alt='Tải lại' />
            <span>Tải lại</span>
          </Button>
          {
            typeof (hideCreate) !== 'undefined' ? (
              !hideCreate && (
                <Button style={{ width: 110 }} className={`btn-primary-cs`} color="default" type="button" onClick={() => this.toggleModal("createNewModal")}>
                  <img src={PlusImg} alt="Thêm mới" />
                  <span>Thêm mới</span>
                </Button>
                // <img
                //   src={btnThemMoiLocBui} alt="Tạo mới" title="Tạo mới"
                //   onClick={() => this.toggleModal("createNewModal")}
                //   className={`${classes.iconButtonCreate} font-bold`}
                //   width="8%"
                //   height="8%"

                // />
              )
            ) : (
              <Button className={`btn-primary-cs`} color="default" type="button" onClick={() => this.toggleModal("createNewModal")}>
                <img src={PlusImg} alt="Thêm mới" />
                <span>Thêm mới</span>
              </Button>
              // <img
              //   src={btnThemMoiLocBui} alt="Tạo mới" title="Tạo mới"
              //   onClick={() => this.toggleModal("createNewModal")}
              //   className={`${classes.iconButtonCreate} font-bold`}
              //   width="8%"
              //   height="8%"

              // />
            )
          }

          {
            typeof (hideSearch) !== 'undefined' ? (
              !hideSearch && (
                <div className={classes.searchArea} style={{ textAlign: 'right' }}>
                  <Button className={`btn-warning-cs`} color="default" type="button" onClick={() => this.handleSearchButton()}>
                    <img src={SearchImg} alt='Tìm kiếm' />
                    <span>Tìm kiếm</span>
                  </Button>
                  {/* <img
                    src={btnTimKiemLocBui} alt="Tìm kiếm" title="Tìm kiếm"
                    onClick={() => this.handleSearchButton()
                    }
                    className={`${classes.iconButtonSearch} font-bold`}
                    width="30%"
                    height="30%"

                  /> */}

                  {
                    fadeIn && (
                      <Fade in={fadeIn} tag="div" className={classes.searchBox}>
                        <div style={{ textAlign: 'left' }}>
                          {
                            searchForm
                          }
                        </div>

                        <div className={classes.searchButtonArea}>
                          <Button className={`btn-warning-cs`} color="default" type="button" onClick={() => this.handleSearchButton()}>
                            <img src={ExitImg} alt="Trở về" />
                            <span>Trở về</span>
                          </Button>

                          <Button
                            color="default"
                            type="button"
                            className={`btn-success-cs`}
                            onClick={() => {
                              handleSubmitSearchForm();
                              this.handleSearchButton();
                            }}
                          >
                            <img src={ApplyImg} alt="Áp dụng" />
                            <span>Áp dụng</span>
                          </Button>
                          {/* <img
                            className={classes.iconButtonDong}
                            src={btnDongLocBui}
                            alt="Thoát ra" title="Thoát ra"
                            width="40%"
                            height="40%"
                            onClick={() => this.handleSearchButton()}
                          /> */}

                          {/* <img
                            className={classes.iconButtonApdung}
                            src={btnApDungLocBui}
                            alt="Áp dụng" title="Áp dụng"
                            width="40%"
                            height="40%"
                            onClick={() => {
                              handleSubmitSearchForm();
                              this.handleSearchButton();
                            }}
                          /> */}
                        </div>
                      </Fade>
                    )
                  }
                </div>
              )
            ) : (
              <div className={classes.searchArea} style={{ textAlign: 'right' }}>
                <Button className={`btn-warning-cs`} color="default" type="button" onClick={() => this.handleSearchButton()}>
                  <img src={SearchImg} alt='Tìm kiếm' />
                  <span>Tìm kiếm</span>
                </Button>
                {/* <img
                  src={btnTimKiemLocBui} alt="Tìm kiếm" title="Tìm kiếm"
                  onClick={() => this.handleSearchButton()
                  }
                  className={`${classes.iconButtonSearch} font-bold`}
                  width="30%"
                  height="30%"

                /> */}

                {
                  fadeIn && (
                    <Fade in={fadeIn} tag="div" className={classes.searchBox}>
                      <div style={{ textAlign: 'left' }}>
                        {
                          searchForm
                        }
                      </div>

                      <div className={classes.searchButtonArea}>
                        <Button className={`btn-warning-cs`} color="default" type="button" onClick={() => this.handleSearchButton()}>
                          <img src={ExitImg} alt="Trở về" />
                          <span>Trở về</span>
                        </Button>

                        <Button
                          color="default"
                          type="button"
                          className={`btn-success-cs`}
                          onClick={() => {
                            handleSubmitSearchForm();
                            this.handleSearchButton();
                          }}
                        >
                          <img src={ApplyImg} alt="Áp dụng" />
                          <span>Áp dụng</span>
                        </Button>

                        {/* <img
                          className={classes.iconButtonDong}
                          src={btnDongLocBui}
                          alt="Thoát ra" title="Thoát ra"
                          width="40%"
                          height="40%"
                          onClick={() => this.handleSearchButton()}
                        /> */}

                        {/* <img
                          className={classes.iconButtonApdung}
                          src={btnApDungLocBui}
                          alt="Áp dụng" title="Áp dụng"
                          width="40%"
                          height="40%"
                          onClick={() => {
                            handleSubmitSearchForm();
                            this.handleSearchButton();
                          }}
                        /> */}
                      </div>
                    </Fade>
                  )
                }
              </div>
            )
          }
        </div>

        {/* Create New */}
        <CreateNewPopupBN
          newData={newData}
          moduleTitle={moduleTitle}
          moduleBody={moduleBody}
          createNewModal={createNewModal}
          toggleModal={this.toggleModal}
          activeSubmit={activeSubmit}
          handleCreateInfoData={(data, beta, close) => {
            handleCreateInfoData(data, () => {
              this.setState({
                createNewModal: false
              });
            }, close);
          }}
        />
      </>
    );
  }
};

export default HeaderTableBN;
