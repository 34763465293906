import React, { Component } from "react";
import SelectTree from "components/SelectTree";
import Select from "components/Select";
import classes from './index.module.css';
import Validate from "react-validate-form";
import { rules, validations, checkPasswordConfirm } from "../../../helpers/validation";
import { DATA_TYPE_FIELD_LIST } from "../../../helpers/constant";

// reactstrap components
import {
	Input,
	InputGroup
} from "reactstrap";

class AddNewModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: null,
			newData: null,
			activeSubmit: false
		}
	}

	componentDidMount() {
		const { data } = this.props;

		this.setState({ data, newData: data });
		this.handleCheckValidation();
	}

	handleChange = (event) => {
		let { data } = this.state;
		const ev = event.target;

		// data[ev['name']] = ev['value'];

		// Check Validation 
		this.handleCheckValidation(ev['name'], ev['value']);
	}

	handleSelect = (value, name) => {
		let { data } = this.state;

		if (value === null) value = "";

		if (name === 'fieldType') {
			let valfieldType = value;
			if (valfieldType == null) {
				this.setState({ fieldTypeChange: null })
			}
		}
		// data[name] = value;

		// this.setState({ data });

		// Check Validation 
		this.handleCheckValidation([name], value);
	}

	handleCheckValidation = (name = null, value = null) => {
		const { handleCheckValidation, handleNewDetail } = this.props;
		let { data } = this.state;

		if (data !== null) {
			if (data.fieldName.length > 0 && data.fieldName.length < 255) {
				this.setState({ activeSubmit: true });

				// Check Validation 
				handleCheckValidation(true);


				// Handle New Data
				handleNewDetail(name, value);
			} else {
				this.setState({ activeSubmit: false });
				handleCheckValidation(false);

				// Handle New Data
				handleNewDetail(name, value);
			}
		}
	}

	render() {
		const { field, errorUpdate, fieldSelect } = this.props;
		const { data, fieldTypeChange } = this.state;

		// let _dataFieldType = [];

		// if (data) {
		// 	_dataFieldType = DATA_TYPE_FIELD_LIST.filter(item => data.fieldType == item.fieldType)
		// 	if (fieldTypeChange === null) {
		// 		_dataFieldType = null
		// 	} else {
		// 		_dataFieldType = (_dataFieldType[0] || {}).name
		// 	}
		// }

		return (
			data !== null && (
				<div className={classes.formControl}>

					<div className={classes.rowItem}>
						<label
							className="form-control-label"
						>
							Mã ngành nghề&nbsp;<b style={{ color: 'red' }}>*</b>
						</label>
						<div className={classes.inputArea}>
							<InputGroup className="input-group-alternative css-border-input">
								<Input
									type="text"
									name='fieldCode'
									disabled
									defaultValue={data.fieldCode}
									placeholder='Mã ngành nghề'
									autoFocus={true}
									onKeyUp={(event) => this.handleChange(event)}
								/>
							</InputGroup>
							<p className='form-error-message margin-bottom-0'>{errorUpdate['fieldCode'] || ''}</p>
						</div>
					</div>

					<div className={classes.rowItem}>
						<label
							className="form-control-label"
						>
							Tên ngành nghề&nbsp;<b style={{ color: 'red' }}>*</b>
						</label>

						<Validate
							validations={validations}
							rules={rules}
						>
							{({ validate, errorMessages }) => (
								<div className={classes.inputArea}>
									<InputGroup className="input-group-alternative css-border-input">
										<Input
											type="text"
											name='fieldName'
											placeholder='Tên ngành nghề'
											defaultValue={data.fieldName}
											required
											onChange={validate}

											onKeyUp={(event) => this.handleChange(event)}
										/>
									</InputGroup>
									<p className='form-error-message margin-bottom-0'>{errorUpdate['fieldName'] || ''}</p>
								</div>
							)}
						</Validate>
					</div>

					{/* <div className={classes.rowItem}>
						<label
							className="form-control-label"
						>
							Loại ngành nghề<b style={{ color: 'red' }}>*</b>
						</label>
						<Select
							name="fieldType"
							title='Chọn loại ngành nghề'
							data={DATA_TYPE_FIELD_LIST}
							labelName='name'
							val='fieldType'
							selected={data.fieldType}
							labelMark={_dataFieldType}
							handleChange={this.handleSelect}
						/>
					</div> */}
					<p className='form-error-message margin-bottom-0'>{errorUpdate['fieldType'] || ''}</p>

					<div className={classes.rowItem}>
						<label
							className="form-control-label"
						>
							Thuộc ngành nghề<b style={{ color: 'red' }}>*</b>
						</label>

						<SelectTree
							id="field-select"
							name="parentID"
							title='Chọn ngành nghề'
							data={fieldSelect}
							fieldTypeNotNull = {true}
							labelName='fieldName'
							fieldName='fieldName'
							selected={data.parentID}
							val='id'
							handleChange={this.handleSelect}
						/>


					</div>
					<p className='form-error-message margin-bottom-0'>{errorUpdate['parentID'] || ''}</p>

				</div>
			)
		);
	}
};

export default AddNewModal;
