import {
  ABOUTUS_LIST_FAIL_TYPE,
  ABOUTUS_LIST_SUCCESS_TYPE,
  ABOUTUS_UPDATE_FAIL_TYPE,
  ABOUTUS_UPDATE_SUCCESS_TYPE,
  ABOUTUS_GET_LIST_ALERT_SUCCESS_TYPE,
  ABOUTUS_CREATE_ALERT_ROLES_SUCCESS_TYPE,
  ABOUTUS_DELETE_ALERT_ROLES_SUCCESS_TYPE,
  ABOUTUS_GET_LIST_ALERT_ROLES_BY_SELECT_SUCCESS_TYPE,
  ABOUTUS_GET_LIST_ROLES_BY_SELECT_SUCCESS_TYPE,
  ABOUTUS_GET_LIST_ROLES_BY_SELECT_FAIL_TYPE
} from "../services/Common";

const initialState = { data: [], isLoading: false, status: null, message: '' };

export const aboutUsReducer = (state, action) => {
  state = state || initialState;

  if (action.type === ABOUTUS_LIST_SUCCESS_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === ABOUTUS_LIST_FAIL_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  // if (action.type === ABOUTUS_DELETE_SUCCESS_TYPE) {
  //   return {
  //     data: action.data,
  //     isLoading: action.isLoading,
  //     status: action.status,
  //     message: action.message,
  //   };
  // }

  // if (action.type === ABOUTUS_DELETE_FAIL_TYPE) {
  //   return {
  //     data: action.data,
  //     isLoading: action.isLoading,
  //     status: action.status,
  //     message: action.message,
  //   };
  // }
  // if (action.type === ABOUTUS_CREATE_SUCCESS_TYPE) {
  //   return {
  //     data: action.data,
  //     isLoading: action.isLoading,
  //     status: action.status,
  //     message: action.message,
  //   };
  // }
  // if (action.type === ABOUTUS_CREATE_FAIL_TYPE) {
  //   return {
  //     data: action.data,
  //     isLoading: action.isLoading,
  //     status: action.status,
  //     message: action.message,
  //   };
  // }
  if (action.type === ABOUTUS_UPDATE_FAIL_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  if (action.type === ABOUTUS_UPDATE_SUCCESS_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  // if (action.type === ABOUTUS_GET_FAIL_TYPE) {
  //   return {
  //     data: action.data,
  //     isLoading: action.isLoading,
  //     status: action.status,
  //     message: action.message,
  //   };
  // }
  // if (action.type === ABOUTUS_GET_SUCCESS_TYPE) {
  //   return {
  //     data: action.data,
  //     isLoading: action.isLoading,
  //     status: action.status,
  //     message: action.message,
  //   };
  // }

  if (action.type === ABOUTUS_GET_LIST_ALERT_SUCCESS_TYPE) {
    return {
      ...state,
      getFtp: action.data
    };
  }

  if (action.type === ABOUTUS_CREATE_ALERT_ROLES_SUCCESS_TYPE) {
    return {
      ...state,
      getFtp: action.data
    };
  }

  if (action.type === ABOUTUS_DELETE_ALERT_ROLES_SUCCESS_TYPE) {
    return {
      ...state,
      getFtp: action.data
    };
  }

  if (action.type === ABOUTUS_GET_LIST_ALERT_ROLES_BY_SELECT_SUCCESS_TYPE) {
    return {
      ...state,
      getFtp: action.data
    };
  }

  if (action.type === ABOUTUS_GET_LIST_ROLES_BY_SELECT_SUCCESS_TYPE) {
    return {
      ...state,
      getFtp: action.data
    };
  }

  if (action.type === ABOUTUS_GET_LIST_ROLES_BY_SELECT_FAIL_TYPE) {
    return {
      ...state,
      getFtp: action.data
    };
  }


  return state;
};