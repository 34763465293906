import React, { Component, useState } from "react";
import { bindActionCreators } from "redux";
import compose from 'recompose/compose';
import { connect } from "react-redux";
import { actionStampProvideList } from "../../../actions/StampProvideActions";
import { STAMPPROVIDE_LIST_HEADER } from "../../../helpers/constant";
import { setAlertContext, openAlertContext, handleCurrencyFormat } from "../../../helpers/common.js";
import { PLEASE_CHECK_CONNECT, ACCOUNT_CLAIM_FF, ACCOUNT_ID, IS_ADMIN } from "../../../services/Common";
import moment from 'moment';
import MenuButton from "../../../assets/img/buttons/menu.png";
import ComfirmIcon from "../../../assets/img/buttons/confirm.png";
import UnComfirmIcon from "../../../assets/img/buttons/unconfirm.png";
import Pagination from "components/Pagination";
import HeaderTableCustom from "components/HeaderTableCustom";
import HeaderTable from "components/HeaderTable";
import HeadTitleTable from "components/HeadTitleTable";
import Select from "components/Select";
import UpdateModal from "./UpdateModal";
import CreateNewPopup from "../../../components/CreateNewPopup";
import WarningPopup from "../../../components/WarningPopup";
import classes from './index.module.css';
import PopupMessage from "../../../components/PopupMessage";
import { LIMIT_ITEM_IN_PAGE, LOADING_TIME } from "../../../helpers/constant";
import SearchModal from "./SearchModal";
import InputPrintModal from "./InputPrintModal";
import UpdatePopup from "../../../components/UpdatePopup";
import UpdatePopup2 from "../../../components/UpdatePopup2";
import QRCode from 'qrcode.react';
import PrintPopup from "components/PrintPopup";
import PrintFormStampQR from "../../../components/PrintFormStampQR";
import PopupDetail from "./PopupDetail";
import './StampProvide.css';

import {
    Card,
    Table,
    Container,
    Row,
    Spinner,
    ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";

class StampProvide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            field: [],
            detail: null,
            update: null,
            create: null,
            delete: null,
            isLoaded: null,
            status: null,
            open: false,
            comfirm: null,
            stampprovide: null,
            fetching: false,
            message: '',
            errMesageFail: '',
            history: [],
            newData: [],
            statusList: [
                { name: 'Chưa duyệt', status: 1 },
                { name: 'Đã duyệt', status: 2 },
                { name: 'Không duyệt', status: 3 }
            ],
            stampprovide: [],
            formatItemPosition: [
                {
                    position: [1],
                    styleSS: this.handleStatusOfStamp
                }
            ],
            filter: {
                "status": "",
                "fromDate": "",
                "toDate": "",
                "companyName": "",
                "taxCode": "",
                "search": "",
                "filter": "",
                "orderBy": "",
                "page": null,
                "limit": null
            },
            headerTitle: STAMPPROVIDE_LIST_HEADER,
            limit: LIMIT_ITEM_IN_PAGE,
            beginItem: 0,
            endItem: LIMIT_ITEM_IN_PAGE,
            totalElement: 0,
            listLength: 0,
            currentPage: 0,
            confirmItem: null,
            warningPopupModal: false,
            warningPopupUnConfirmModal: false,
            activeCreateSubmit: false,
            createNewModal: false,
            isPopupDetail: false,
            reasonUnConfirm: '',
            dataPopupDetail: null
        }
    }

    componentWillReceiveProps(nextProp) {
        let { data } = nextProp.stampprovide;
        const { limit, fetching } = this.state;

        if (data !== this.state.data) {
            if (typeof (data) !== 'undefined') {
                if (typeof (data.stampprovide) !== 'undefined') {
                    if (data.stampprovide !== null) {
                        if (typeof (data.stampprovide.stamps) !== 'undefined') {
                            data.stampprovide.stamps.map((item, key) => {
                                if (item.status == 1) {
                                    item['statusName'] = 'Chưa duyệt'

                                } else if (item.status == 2) {
                                    item['statusName'] = 'Đã duyệt'
                                } else if (item.status == 3) {
                                    item['statusName'] = 'Không duyệt'
                                };
                                item['index'] = key + 1;
                                item['collapse'] = false;
                                item['requestedDate'] = moment(item.expiredDate).format('DD/MM/YYYY');
                                item['confirmedDate'] = moment(item.expiredDate).format('DD/MM/YYYY');

                            });
                            this.setState({
                                data: data.stampprovide.stamps,
                                history: data.stampprovide.stamps,
                                listLength: data.stampprovide.total,
                                totalPage: Math.ceil(data.stampprovide.stamps.length / limit),
                                isLoaded: data.isLoading,
                                status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });
                        } else {
                            this.setState({
                                history: data.stampprovide.stamps,
                                isLoaded: data.isLoading,
                                status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });
                        }
                    }
                }

                if (typeof (data.confirm) !== 'undefined') {
                    if (fetching) {
                        this.setState({ data: [] });
                        this.fetchSummary(JSON.stringify({ "search": "", "filter": "", "orderBy": "", "page": null, "limit": null }));
                        this.setState({ fetching: false });
                    }

                    setTimeout(() => {
                        this.setState({
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    }, 500);
                }

                if (typeof (data.unconfirm) !== 'undefined') {
                    if (fetching) {
                        this.setState({ data: [] });
                        this.fetchSummary(JSON.stringify({ "search": "", "filter": "", "orderBy": "", "page": null, "limit": null }));
                        this.setState({ fetching: false });
                    }

                    setTimeout(() => {
                        this.setState({
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    }, 500);
                }
            }
        }

    }

    handleStatusOfStamp = (ele) => {

        if (ele.status.indexOf('Chưa duyệt') > -1) return 'not-yet-confirm-style';
        if (ele.status.indexOf('Đã duyệt') > -1) return 'confirm-style';
        if (ele.status.indexOf('Không duyệt') > -1) return 'not-confirm-style';
        else return '';
    }

    handleConfirmStamp = () => {
        const { confirmItem } = this.state;

        if (typeof (confirmItem) !== 'undefined') {
            this.fetchSummaryComfirm(confirmItem);
        }
    }

    onConfirmPopupDetail = () => {
        const { isPopupDetail } = this.state;
        const { requestComfirmStampProvide } = this.props;

        if (!isPopupDetail) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    errMesageFail: 'Bạn vui lòng chọn tem muốn duyệt',
                    message: 'Bạn vui lòng chọn tem muốn duyệt',
                    popupMessage: true
                }
            });

            return;
        }

        requestComfirmStampProvide(isPopupDetail).then(res => {
            const status = (res.data || {}).status;

            if (status == 404) {
                const message = (res.data || {}).message;

                this.setState(previousState => {
                    return {
                        ...previousState,
                        errMesageFail: message,
                        popupMessage: true,
                        message
                    }
                });
            } else {
                this.setState(previousState => {
                    return {
                        ...previousState,
                        isPopupDetail: false,
                        fetching: false,
                        data: [],
                        dataPopupDetail: null
                    }
                }, () => {
                    this.fetchSummary(JSON.stringify({ "search": "", "filter": "", "orderBy": "", "page": null, "limit": null }));
                });
            }
        });
    }

    onUnConfirmPopupDetail = () => {
        const { isPopupDetail, reasonUnConfirm } = this.state;
        const { requestUncomfirmStampProvide } = this.props;

        if (!isPopupDetail) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    errMesageFail: 'Bạn vui lòng chọn tem muốn không duyệt',
                    message: 'Bạn vui lòng chọn tem muốn không duyệt',
                    popupMessage: true
                }
            });

            return;
        }

        if (!reasonUnConfirm) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    errMesageFail: 'Bạn vui lòng nhập lý do không duyệt',
                    message: 'Bạn vui lòng nhập lý do không duyệt',
                    popupMessage: true,
                    dataPopupDetail: null
                }
            });

            return;
        }

        const data = JSON.stringify({
            id: isPopupDetail,
            reason: reasonUnConfirm
        });

        requestUncomfirmStampProvide(data).then(res => {
            const status = (res.data || {}).status;

            if (status == 404) {
                const message = (res.data || {}).message;

                this.setState(previousState => {
                    return {
                        ...previousState,
                        errMesageFail: message,
                        message,
                        popupMessage: true
                    }
                });
            } else {
                this.setState(previousState => {
                    return {
                        ...previousState,
                        isPopupDetail: false,
                        fetching: false,
                        data: []
                    }
                }, () => {
                    this.fetchSummary(JSON.stringify({ "search": "", "filter": "", "orderBy": "", "page": null, "limit": null }));
                });
            }
        })

        this.setState(previousState => {
            return {
                ...previousState,
                isPopupDetail: false
            }
        });
    }

    toggle = (el, val) => {
        let { data } = this.state;

        data.filter(item => item.id === val)
            .map(item => item.collapse = !item.collapse);

        this.setState({ data });
    }

    componentUnComfirmMount = (value) => {
        let { data } = this.state;

        const { requestUncomfirmStampProvide } = this.props;
        const createData = JSON.stringify({
            id: value.id,
            reason: value.reason
        })

        this.handleClose(true);
        this.setState({ fetching: false });
        requestUncomfirmStampProvide(createData);
    }

    componentDidMount() {
        /* Fetch Summary */
        this.fetchSummary(JSON.stringify({
            "search": "",
            "filter": "",
            "orderBy": "",
            "page": null,
            "limit": null
        }));
    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        this.closeStatusModal();
    }

    fetchSummary = (data) => {
        const { requestStampProvideList } = this.props;

        requestStampProvideList(data);
    }

    fetchSummaryComfirm = (id) => {
        const { requestComfirmStampProvide } = this.props;

        requestComfirmStampProvide(id).then(res => {
            if (res.data.status == 404) {
                this.toggleModal('popupMessage')
                this.setState({
                    errMesageFail: res.data.message
                })
            }
        })
        this.setState({ fetching: true, isLoaded: true, status: null });
    }

    handleClose = (value) => {
        const { open } = this.state;

        this.setState({ open: value });
    }

    handleChange = (event) => {
        let { data } = this.state;
        const ev = event.target;

        data[ev['name']] = ev['value'];

        this.setState({ data });
    }

    closeStatusModal = () => {
        const { status, fetching } = this.state;

        if (status || !status && fetching) {
            setTimeout(() => {
                this.setState({ status: null, isLoaded: false });
            }, LOADING_TIME);
        }
    }

    handlePageClick = (data) => {
        let { limit, beginItem, endItem } = this.state;
        let selected = data.selected;
        let offset = Math.ceil(selected * limit);
        let total = 0;

        beginItem = offset;
        endItem = offset + limit;

        this.state.data.map((item, key) => (
            key >= beginItem && key < endItem && total++
        ));

        if (selected > 0) {
            total = (selected * limit) + total;
        } else total = total;

        this.setState({ beginItem: beginItem, endItem: endItem, currentPage: selected + 1, totalElement: total });
    };

    handleStyleStatus = (status) => {
        if (status === 1) {
            return classes.notyetStt;
        }
        if (status == 2) {
            return classes.activeStt;
        }
        if (status == 3) {
            return classes.noActiveStt;
        };
    }

    handleSelect = (value, name) => {

        if (value) {
            this.fetchSummary(JSON.stringify({
                "status": value,
                "search": "",
                "filter": "",
                "orderBy": "",
                "page": null,
                "limit": null
            }));
        }

    }

    toggleModal = (state) => {
        this.setState({
            [state]: !this.state[state],
            detail: null,
        });
    };

    onClosePopupDetail = () => {
        this.setState(previousState => {
            return {
                ...previousState,
                isPopupDetail: false
            }
        });
    }

    onOpenPopupDetail = id => () => {
        const { detail } = this.props;

        detail(id).then(res => {
            const data = ((res.data || {}).data || {}).stamp || null;

            if (!data) {
                this.setState(previousState => {
                    return {
                        ...previousState,
                        errMesageFail: 'Tem này không tồn tại',
                        message: 'Tem này không tồn tại',
                        popupMessage: true
                    }
                });

                return;
            }

            this.setState(previousState => {
                return {
                    ...previousState,
                    isPopupDetail: id,
                    dataPopupDetail: data
                }
            });
        });
    }

    handleUpdateInfoData = (value) => {
        const { newData } = this.state;
        const { requestUncomfirmStampProvide } = this.props;
        const updateData = JSON.stringify({
            id: newData.id,
            reason: newData.reason
        });

        requestUncomfirmStampProvide(updateData);
        this.setState({ createNewModal: false, fetching: true, isLoaded: true, status: null });
    }

    handleCheckValidation = (status) => {
        this.setState({ activeCreateSubmit: status });
    }

    handleNewDetail = (name, data) => {
        const { detail } = this.state;
        const newData = {
            ...detail
        };

        if (name !== null) {
            newData[name] = data;
            this.setState({ newData });
        }
    }

    handleStatus = (event) => {
        for (let i = 0; i < document.getElementsByClassName('checkbox-status').length; i++) {
            document.getElementsByClassName('checkbox-status')[i].checked = false;
        }

        event.target.checked = true;
        this.handleChangeFilter(event);
    }

    handleChangeFilter = (event) => {
        let { filter } = this.state;
        const ev = event.target;

        filter[ev['name']] = ev['value'];

        this.setState({ filter });
    }

    handleSelectCS = (value, name) => {
        let { filter } = this.state;

        if (value === null) value = "";

        filter[name] = value;
        this.setState({ filter });
    }

    handleSubmitSearchForm = () => {
        const { filter } = this.state;
        this.clearFilter();
        this.fetchSummary(JSON.stringify(filter));
    }

    clearFilter = () => {
        this.setState({
            filter: {
                "status": "",
                "fromDate": "",
                "toDate": "",
                "companyName": "",
                "taxCode": "",
                "search": "",
                "filter": "",
                "orderBy": "",
                "page": null,
                "limit": null
            },
        })
    }

    handleChangeStartDate = (e) => {
        let { filter } = this.state;

        filter['startDate'] = new Date(e);
        this.setState({ filter, startDate: new Date(e) });
    }

    handleChangeEndDate = (e) => {
        let { filter } = this.state;

        filter['endDate'] = new Date(e);
        this.setState({ filter, endDate: new Date(e) });
    }

    componentPrint = (value) => {
        let { newDataPrint, reqId } = this.state;
        const { printQR, detail } = this.props;
        detail(value.id).then(res => {
            if (res.data.status) {
                this.setState({
                    detailPrint: res.data.data.stamp
                })
            }
        })

        let dataQRPrint = [];


        printQR("requestID=" + value.id + "&page=" + 1 + "&limit=" + value.requested).then(res => {
            if (res.data.status == 200) {
                dataQRPrint = res.data.data.stamps;

                this.setState({ printInfo: dataQRPrint })
                this.toggleModal('updateModal2');
            } else {
                this.setState({ errMesageFail: res.data.message })
                this.toggleModal('popupMessage')
            }
        })

    }

    handleNewData = (data) => {
        this.setState({ newDataPrint: data });
    }

    onChangeReason = e => {
        const value = e.target.value || '';

        this.setState(previousState => {
            return {
                ...previousState,
                reasonUnConfirm: value
            }
        });
    }

    render() {
        const { screen } = this.props;
        const {
            isLoaded,
            status,
            message,
            data,
            detail,
            statusList,
            beginItem,
            endItem,
            listLength,
            totalPage,
            totalElement,
            headerTitle,
            warningPopupModal,
            createNewModal,
            activeSubmit,
            popupMessage,
            errMesageFail,
            filter,
            updateModal,
            updateModal2,
            activeCreateSubmit,
            newData,
            printModal,
            printInfo,
            dataQRPrint,
            isPopupDetail,
            reasonUnConfirm,
            dataPopupDetail,
            detailPrint

        } = this.state;
        const statusPopup = { status: status, message: message };

        let isDisableConfirm = true;
        let isDisableUnconfirm = true;
        if (IS_ADMIN) {
            isDisableConfirm = false;
            isDisableUnconfirm = false;
        } else {
            ACCOUNT_CLAIM_FF.filter(x => x == "StampRequests.Confirm").map(y => isDisableConfirm = false)
            ACCOUNT_CLAIM_FF.filter(x => x == "StampRequests.Unconfirm").map(y => isDisableUnconfirm = false)
        }

        return (
            <>
                {
                    <div className={classes.wrapper}>
                        <Container fluid>
                            {
                                isLoaded ? (
                                    <div style={{ display: 'table', margin: 'auto' }}>
                                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                                    </div>
                                ) : (
                                    <Row>
                                        <div className="col">
                                            {/* Header */}
                                            <HeaderTable
                                                dataReload={() => {
                                                    this.fetchSummary(
                                                        JSON.stringify({
                                                            "search": "",
                                                            "filter": "",
                                                            "orderBy": "",
                                                            "page": null,
                                                            "limit": null
                                                        }))

                                                }
                                                }
                                                hideCreate={true}
                                                searchForm={
                                                    <SearchModal
                                                        filter={filter}
                                                        handleChangeFilter={this.handleChangeFilter}
                                                        handleStatus={this.handleStatus}
                                                        handleSelect={this.handleSelect}
                                                        handleSelectCS={this.handleSelectCS}
                                                        handleChangeStartDate={this.handleChangeStartDate}
                                                        handleChangeEndDate={this.handleChangeEndDate}
                                                    />
                                                }
                                                handleSubmitSearchForm={() => this.handleSubmitSearchForm()}
                                                customComponent={
                                                    <div className={classes.filterArea}
                                                        style={{
                                                            width: 310,
                                                            padding: 3,

                                                        }}
                                                    >
                                                        <label
                                                            className="form-control-label"
                                                            style={{
                                                                marginRight: 5,
                                                                width: 100
                                                            }}
                                                        >
                                                            Trạng thái
                                                        </label>

                                                        <Select
                                                            name="status"
                                                            title='Chọn trạng thái'
                                                            data={statusList}
                                                            labelName='name'
                                                            val='status'
                                                            handleChange={this.handleSelect}
                                                        />
                                                    </div>
                                                }
                                            />

                                            {/* Table */}
                                            <Card className="shadow">
                                                <Table className="align-items-center tablecs table-css-stamp-provide" responsive >
                                                    <HeadTitleTable headerTitle={headerTitle} classHeaderColumns={{
                                                        0: 'table-scale-col table-user-col-1'
                                                    }} />

                                                    <tbody>
                                                        {
                                                            Array.isArray(data) && (
                                                                data
                                                                    .filter((item, key) => key >= beginItem && key < endItem)
                                                                    .map((item, key) => (
                                                                        <tr key={key}>
                                                                            <td style={{ whiteSpace: 'normal' }}>{item.index}</td>
                                                                            <td style={{ textAlign: 'center', whiteSpace: 'normal' }} className={`table-scale-col cursor-unset`}>
                                                                                <span className={this.handleStyleStatus(item.status)}>{item.statusName}</span>
                                                                                {/* <td style={{ textAlign: 'center' }} className={this.handleStyleStatus(item.status)}>{item.statusName}</td> */}
                                                                            </td>
                                                                            <td style={{ textAlign: 'left', whiteSpace: 'normal' }}>{item.companyName}</td>
                                                                            <td style={{ textAlign: 'right', whiteSpace: 'normal' }}>{handleCurrencyFormat(item.requested)}</td>
                                                                            <td style={{ textAlign: 'left', whiteSpace: 'normal' }}>{item.description}</td>
                                                                            <td style={{ textAlign: 'center', whiteSpace: 'normal' }}>{item.requestedDate}</td>
                                                                            <td style={{ textAlign: 'center', whiteSpace: 'normal' }}>{item.status == 2 ? item.confirmedDate : ''}</td>
                                                                            <td style={{ textAlign: 'left', whiteSpace: 'normal' }}>{item.confirmedBy}</td>
                                                                            <td style={{ textAlign: 'left', whiteSpace: 'normal' }}>{item.reason}</td>

                                                                            <td style={{ whiteSpace: 'normal' }}>
                                                                                {(isDisableConfirm == true && isDisableUnconfirm == true) ? null : (
                                                                                    <ButtonDropdown isOpen={item.collapse} toggle={() => this.toggle(key, item.id)}>
                                                                                        <DropdownToggle>
                                                                                            <img src={MenuButton} />
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem
                                                                                                onClick={this.onOpenPopupDetail(item.id)}
                                                                                            >
                                                                                                Xem
                                                                                            </DropdownItem>
                                                                                            {/* {item.status !== 1 || isDisableConfirm == true ? null : (
                                                                                                <DropdownItem
                                                                                                    onClick={() => {
                                                                                                        this.toggleModal('warningPopupModal');
                                                                                                        this.setState({ confirmItem: item.id });
                                                                                                    }}
                                                                                                >
                                                                                                    Duyệt
                                                                                                </DropdownItem>
                                                                                            )}
                                                                                            {item.status !== 1 || isDisableConfirm == true || isDisableUnconfirm == true ? null : (
                                                                                                <DropdownItem divider />
                                                                                            )}
                                                                                            {item.status !== 1 || isDisableUnconfirm == true ? null : (
                                                                                                <DropdownItem
                                                                                                    onClick={() => {
                                                                                                        this.setState({ detail: item });
                                                                                                        this.toggleModal('createNewModal');
                                                                                                    }}
                                                                                                >
                                                                                                    Không duyệt
                                                                                                </DropdownItem>
                                                                                            )}
                                                                                            {item.status !== 1 || item.status !== 2 || isDisableUnconfirm == true ? null : (
                                                                                                <DropdownItem divider />
                                                                                            )} */}
                                                                                            {item.status === 2 ? (
                                                                                                <DropdownItem
                                                                                                    onClick={() => {
                                                                                                        this.setState({ reqId: item.id });
                                                                                                        // this.toggleModal('updateModal2');
                                                                                                        this.componentPrint(item);
                                                                                                    }}
                                                                                                >
                                                                                                    In
                                                                                                </DropdownItem>
                                                                                            ) : null}
                                                                                        </DropdownMenu>
                                                                                    </ButtonDropdown>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                            )
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Card>

                                            {/* Pagination */}
                                            {
                                                // Page of Table
                                                Array.isArray(data) && (
                                                    data.length > 0 && (
                                                        <Pagination
                                                            data={data}
                                                            listLength={listLength}
                                                            totalPage={totalPage}
                                                            totalElement={totalElement}
                                                            handlePageClick={this.handlePageClick}
                                                        />
                                                    )
                                                )
                                            }
                                        </div>
                                    </Row>
                                )
                            }

                            {
                                //Set Alert Context
                                setAlertContext(statusPopup)
                            }

                            {
                                //Open Alert Context
                                openAlertContext(statusPopup)
                            }

                            <WarningPopup
                                moduleTitle='Thông báo'
                                moduleBody={<p style={{ textAlign: 'center', fontSize: '1.2rem' }}>Bạn đồng ý duyệt thông tin này?</p>}
                                warningPopupModal={warningPopupModal}
                                toggleModal={this.toggleModal}
                                handleWarning={this.handleConfirmStamp}
                            />

                            {(isPopupDetail && dataPopupDetail) &&
                                <PopupDetail
                                    data={dataPopupDetail}
                                    isShow={isPopupDetail}
                                    reason={reasonUnConfirm}
                                    onChangeReason={this.onChangeReason}
                                    onClose={this.onClosePopupDetail}
                                    onConfirm={this.onConfirmPopupDetail}
                                    onUnConfirm={this.onUnConfirmPopupDetail}
                                />}

                            {
                                printInfo !== null && (
                                    <UpdatePopup2
                                        moduleTitle='Danh sách mã tem'
                                        updateModal2={updateModal2}
                                        hiddenBtnSave={true}
                                        moduleBody={
                                            <PrintFormStampQR
                                                data={printInfo}
                                                detailPrint={detailPrint} />
                                        }
                                        toggleModal={this.toggleModal}
                                        newData={newData}
                                        handleUpdateInfoData={this.componentPrint}
                                        activeSubmit={activeCreateSubmit}
                                    />
                                )
                            }

                            {/* {

                                <UpdatePopup
                                    moduleTitle='Nhập tem theo thứ tự'
                                    moduleBody={
                                        <InputPrintModal
                                            handleCheckValidation={this.handleCheckValidation}
                                            handleNewData={this.handleNewData}
                                        />}
                                    newData={newData}
                                    updateModal={updateModal}
                                    toggleModal={this.toggleModal}
                                    activeSubmit={activeCreateSubmit}
                                    handleUpdateInfoData={this.componentPrint}
                                />

                            } */}

                            {/* Create New */}
                            <CreateNewPopup
                                screen={screen}
                                moduleTitle={"Thông báo"}
                                moduleBody={
                                    <UpdateModal
                                        data={detail}
                                        handleCheckValidation={this.handleCheckValidation}
                                        handleNewDetail={this.handleNewDetail}
                                    />
                                }
                                createNewModal={createNewModal}
                                toggleModal={this.toggleModal}
                                activeSubmit={activeSubmit}
                                handleCreateInfoData={this.handleUpdateInfoData}
                            />
                            {errMesageFail != '' ?
                                <PopupMessage
                                    popupMessage={popupMessage}
                                    moduleTitle={'Thông báo'}
                                    moduleBody={message}
                                    toggleModal={this.toggleModal}
                                /> : null
                            }
                        </Container>
                    </div>
                }
            </>
        )
    }

}
const mapStateToProps = (state) => {
    return {
        stampprovide: state.StampProvideStore,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionStampProvideList, dispatch),


    }
}
export default compose(
    //withStyles(useStyles),
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(StampProvide);