import {
    COMPANY_GET_LIST_DELETED,
    COMPANY_GET_DELETED
} from "../apis";
import {
    get, post, del
} from "../services/Dataservice";
import {
    SUCCESS_CODE,
    GET_COMPANY_DELETED_TYPE,
    GET_COMPANY_DELETED_SUCCESS_TYPE,
    GET_COMPANY_DELETED_FAIL_TYPE,
    COMPANY_GET_DELETED_TYPE,
    COMPANY_GET_DELETED_SUCCESS_TYPE,
    COMPANY_GET_DELETED_FAIL_TYPE
} from "../services/Common";

const initialState = { data: [], isLoading: false, status: false, message: '' };

export const actionCompanyListDeleted = {
    requestCompanyListDeleted: (data) => async (dispatch, getState) => {
        dispatch({
            type: GET_COMPANY_DELETED_TYPE, data: initialState
        });

        await post(COMPANY_GET_LIST_DELETED, data)
            .then(res => {
                if (res.status === SUCCESS_CODE) {
                    dispatch({ type: GET_COMPANY_DELETED_SUCCESS_TYPE, data: { company: res.data, isLoading: true, status: true, message: res.message } });
                } else {
                    dispatch({ type: GET_COMPANY_DELETED_FAIL_TYPE, data: { company: [], isLoading: true, status: false, message: res.message } });
                }
            })
            .catch(err => {
                dispatch({ type: GET_COMPANY_DELETED_FAIL_TYPE, data: { company: [], isLoading: true, status: false, message: err.message } });
            })
    },
    requestGetCompanyDeleted: (id) => async (dispatch, getState) => {
        return new Promise(async resolve => {
            dispatch({
                type: COMPANY_GET_DELETED_TYPE, data: initialState
            });

            await get(COMPANY_GET_DELETED + id)
                .then(res => {
                    if (res.status === SUCCESS_CODE) {
                        dispatch({ type: COMPANY_GET_DELETED_SUCCESS_TYPE, data: { get: res.data, isLoading: true, status: true, message: res.message } });
                    } else {
                        dispatch({ type: COMPANY_GET_DELETED_FAIL_TYPE, data: { get: [], isLoading: true, status: false, message: res.message } });
                    }
                    resolve({
                        status: true,
                        data: res
                    });
                })
                .catch(err => {
                    dispatch({ type: COMPANY_GET_DELETED_FAIL_TYPE, data: { get: [], isLoading: true, status: false, message: err.message } });
                    resolve({
                        status: false,
                        error: err
                    });
                })
        })
    },
}