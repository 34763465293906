import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import compose from 'recompose/compose';
import { connect } from "react-redux";
import { LIMIT_ITEM_IN_PAGE, LOADING_TIME } from "../../../../../helpers/constant";
import { actionGetListCompanyAwait } from "../../../../../actions/CompanyAwaitActions";
import { actionCompanyGetDetails } from "../../../../../actions/CompanyGetDetailsActions";
import { actionField } from "../../../../../actions/FieldActions.js";
import { actionLocationCreators } from "../../../../../actions/LocationListAction";
import { actionGetListNewly } from "../../../../../actions/NewRegBusAction"
import { PLEASE_CHECK_CONNECT } from "../../../../../services/Common";
import { NavLink } from 'react-router-dom';

import { Spinner } from 'reactstrap'

class ListAwait extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: null,
        }
    }
    componentWillReceiveProps(nextProp) {
        let { data } = nextProp.company;
        const { fetchingUnComfirm } = this.state;
        const { requestCompanyAwaitListStore } = nextProp;

        let fieldData = nextProp.field.data;
        let detailsData = nextProp.details.data;

        let locationData = nextProp.location.data;
        const { limit } = this.state;

        if (data !== this.state.data) {
            if (typeof (data) !== 'undefined') {
                if (typeof (data.company) !== 'undefined') {
                    if (data.company !== null) {
                        if (typeof (data.company.companies) !== 'undefined') {
                            data.company.companies.map((item, key) => {
                                item['index'] = key + 1
                                item['collapse'] = false
                                // item['confirmedDate']=moment(item.confirmedDate).format('DD/MM/YYYY')

                            });
                            let totalElement = 0;

                            if (data.company.companies.length > limit) {
                                totalElement = limit;
                            } else {
                                totalElement = data.company.companies.length;
                            }

                            this.setState({
                                totalElement,
                                data: data.company.companies,
                                company: data.company.companies,
                                listLength: data.company.companies.length,
                                totalPage: Math.ceil(data.company.companies.length / limit),
                                isLoaded: data.isLoading, status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });
                        } else {
                            this.setState({
                                data: data.company.companies,
                                company: data.company.companies,
                                isLoaded: data.isLoading,
                                status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });
                        }
                    }
                }

            }
        }

        if (typeof (detailsData) !== 'undefined') {
            if (detailsData.details !== null) {
                if (typeof (detailsData.details) !== 'undefined') {
                    this.setState({
                        xem: detailsData.details,
                        detail: detailsData.details,
                        detailView: detailsData.details,
                        isLoaded: false,
                        status: data.status,
                        message: PLEASE_CHECK_CONNECT(data.message)
                    });
                } else {
                    this.setState({
                        xem: detailsData.details,
                        isLoaded: false,
                        status: data.status,
                        message: PLEASE_CHECK_CONNECT(data.message)
                    });
                }
            }
            // if (detailsData.fileUpdate !== null) {
            //   if (typeof (detailsData.fileUpdate) !== 'undefined') {
            //     this.setState({
            //       fileUpdate: detailsData.fileUpdate,
            //       isLoaded: false,
            //       status: data.status,
            //       message: PLEASE_CHECK_CONNECT(data.message)
            //     });
            //   } else {
            //     this.setState({
            //       fileUpdate: detailsData.fileUpdate,
            //       isLoaded: false,
            //       status: data.status,
            //       message: PLEASE_CHECK_CONNECT(data.message)
            //     });
            //   }
            // }
        }


        if (typeof (data.uncomfirm) !== 'undefined') {


            if (data.status && !fetchingUnComfirm) {
                this.setState({ data: [] });
                requestCompanyAwaitListStore(JSON.stringify({
                    "fieldID": "",
                    "comapanyName": "",
                    "taxCode": "",
                    "phone": "",
                    "email": "",
                    "provinceID": "",
                    "districtID": "",
                    "wardID": "",
                    "orderBy": "",
                    "page": null,
                    "limit": null,
                }))
                this.setState({ fetchingUnComfirm: true });
            }

        }

        if (typeof (data.detail) !== 'undefined') {
            if (data.detail !== null) {
                if (data.status) {
                    this.setState({ xem: null });
                    this.setState({
                        xem: data.detail,
                        isLoaded: false,
                        status: data.status,
                        message: PLEASE_CHECK_CONNECT(data.message)
                    });
                } else {
                    this.setState({
                        xem: [],
                        isLoaded: false,
                        status: data.status,
                        message: PLEASE_CHECK_CONNECT(data.message)
                    });
                }
            }
        }


        if (fieldData !== this.state.field) {
            if (typeof (fieldData) !== 'undefined') {
                if (fieldData.field !== null) {
                    if (typeof (fieldData.field) !== 'undefined') {
                        this.setState({
                            field: fieldData.field.fields,
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    } else {
                        this.setState({
                            field: fieldData.field,
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    }
                }
            }
        }
        if (locationData !== this.state.province) {
            if (typeof (locationData) !== 'undefined') {
                if (typeof (locationData.province) !== 'undefined') {

                    if (locationData.province !== null) {

                        this.setState({
                            province: locationData.province,
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    } else {
                        this.setState({
                            province: [],
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    }
                }
            }
        }

        if (locationData !== this.state.district) {
            if (typeof (locationData) !== 'undefined') {
                if (typeof (locationData.district) !== 'undefined') {
                    if (locationData.district !== null) {
                        this.setState({
                            district: locationData.district,
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    } else {
                        this.setState({
                            district: [],
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    }
                }
            }
        }

        if (locationData !== this.state.ward) {

            if (typeof (locationData) !== 'undefined') {

                // if (typeof (locationData.ward) !== 'undefined') {
                if (locationData.ward !== null) {

                    this.setState({ ward: [] })
                    this.setState({
                        ward: locationData.ward,
                        isLoaded: false,
                        status: data.status,
                        message: PLEASE_CHECK_CONNECT(data.message)
                    });
                } else {
                    this.setState({
                        ward: [],
                        isLoaded: false,
                        status: data.status,
                        message: PLEASE_CHECK_CONNECT(data.message)
                    });
                }
                //}
            }
        }
    }
    componentDidMount() {
        // This method is called when the component is first added to the document
        //this.filterMapKey();

        /* Fetch Summary */

        this.fetchSummary(JSON.stringify({
            "fieldID": "",
            "comapanyName": "",
            "taxCode": "",
            "phone": "",
            "email": "",
            "provinceID": "",
            "districtID": "",
            "wardID": "",
            "orderBy": "",
            "page": null,
            "limit": null,
        }));
    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        this.closeStatusModal();
    }

    fetchSummary = (data) => {
        const { requestCompanyAwaitListStore, requestFieldStore } = this.props;

        requestCompanyAwaitListStore(data);
        requestFieldStore(data);
    }
    closeStatusModal = () => {
        const { status } = this.state;

        if (status || !status) {
            setTimeout(() => {
                this.setState({ status: null, isLoaded: false });
            }, LOADING_TIME);
        }
    }
    render() {
        const { data, isLoaded } = this.state

        return (
            <>
                <div className='header-box-list box-list-border display-box'>
                    <h4>Chờ duyệt</h4>
                </div>
                {
                    isLoaded ? (<div style={{ display: 'table', margin: 'auto' }}>
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                    </div>) : (<>
                        <div className='body-box-list' style={{ padding: 10 }}>
                            <table className='table-box-list-3'>
                                <thead>
                                    <tr className='table-tr-box-list'>
                                        <th>Doanh nghiệp</th>
                                        {/* <th>Ngành nghề</th> */}
                                        {/* <th>Dải tem</th> */}
                                        <th>Trạng thái</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {
                                        Array.isArray(data) && (
                                            data.map((item, key) => (
                                                <tr className="table-hover-css" key={key}>
                                                    <td  >
                                                        <p className='text-td-3'>
                                                            {item.companyName}
                                                        </p>
                                                    </td>
                                                    {/* <td className='text-td-3' style={{ width: 161 }}>Nhân và chăm sóc cây giống nông nghiệp;Trồng rừng, chăm sóc rừng và ươm giống cây lâm nghiệp</td> */}
                                                    <td >
                                                        {item.status === 0 ? (<>
                                                            <span className='noActiveStt text-center-status'>Chưa duyệt</span>
                                                        </>) : ''}
                                                        {item.status === 4 ? (<>
                                                            <span className='notyetStt text-center-status'>Chờ bổ sung</span>
                                                        </>) : ''}
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    </>)
                }
                <div className='footer-box-list'>
                    <NavLink to="/trang_chu/danh_sach_dang_cho_duyet">Xem thêm</NavLink>
                </div>
            </>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        company: state.CompanyAwaitStore,
        field: state.FieldStore,
        details: state.CompanyGetDetailsStore,
        location: state.LocationStore,
        newregbug: state.NewRegBusStore
    }
}
const mapDispatchToProps = (dispatch) => {
    return {

        ...bindActionCreators(actionGetListNewly, dispatch),
        ...bindActionCreators(actionGetListCompanyAwait, dispatch),
        ...bindActionCreators(actionField, dispatch),
        ...bindActionCreators(actionCompanyGetDetails, dispatch),
        ...bindActionCreators(actionLocationCreators, dispatch)
    }
}

export default compose(
    connect(mapStateToProps,
        mapDispatchToProps)
)(ListAwait)