import {
	ABOUTUS_LIST,
	ABOUTUS_UPDATE,
	ABOUTUS_GET_LIST_ALERT_ROLES,
	ABOUTUS_CREATE_ALERT_ROLES,
	ABOUTUS_DELETE_ALERT_ROLES,
	ABOUTUS_GET_LIST_ALERT_ROLES_BY_SELECT,
	ABOUTUS_GET_LIST_ROLES_BY_SELECT
} from "../apis";
import {
	get, post, del, postFormData, put
} from "../services/Dataservice";
import {
	SUCCESS_CODE,
	ABOUTUS_LIST_FAIL_TYPE,
	ABOUTUS_LIST_TYPE,
	ABOUTUS_LIST_SUCCESS_TYPE,
	ABOUTUS_UPDATE_TYPE,
	ABOUTUS_UPDATE_FAIL_TYPE,
	ABOUTUS_UPDATE_SUCCESS_TYPE,
	ABOUTUS_GET_LIST_ALERT_ROLES_TYPE,
	ABOUTUS_GET_LIST_ALERT_SUCCESS_TYPE,
	ABOUTUS_GET_LIST_ALERT_ROLES_FAIL_TYPE,
	ABOUTUS_CREATE_ALERT_ROLES_TYPE,
	ABOUTUS_CREATE_ALERT_ROLES_SUCCESS_TYPE,
	ABOUTUS_CREATE_ALERT_ROLES_FAIL_TYPE,
	ABOUTUS_DELETE_ALERT_ROLES_TYPE,
	ABOUTUS_DELETE_ALERT_ROLES_SUCCESS_TYPE,
	ABOUTUS_DELETE_ALERT_ROLES_FAIL_TYPE,
	ABOUTUS_GET_LIST_ALERT_ROLES_BY_SELECT_TYPE,
	ABOUTUS_GET_LIST_ALERT_ROLES_BY_SELECT_SUCCESS_TYPE,
	ABOUTUS_GET_LIST_ALERT_ROLES_BY_SELECT_FAIL_TYPE,
	ABOUTUS_GET_LIST_ROLES_BY_SELECT_TYPE,
	ABOUTUS_GET_LIST_ROLES_BY_SELECT_FAIL_TYPE,
	ABOUTUS_GET_LIST_ROLES_BY_SELECT_SUCCESS_TYPE
} from "../services/Common";

const initialState = { data: [], isLoading: false, status: false, message: '' };

export const actionAboutUs = {
	requestAboutUsList: (data) => async (dispatch, getState) => {
		return new Promise(async resolve => {
			dispatch({
				type: ABOUTUS_LIST_TYPE, data: initialState
			});
			await get(ABOUTUS_LIST, data)
				.then(res => {
					if (res.status === SUCCESS_CODE) {
						dispatch({
							type: ABOUTUS_LIST_SUCCESS_TYPE, data: { list: res.data, isLoading: true, status: true, message: res.message }
						});

					} else {
						dispatch({
							type: ABOUTUS_LIST_FAIL_TYPE, data: { list: [], isLoading: true, status: false, message: res.message }
						});

					}
					resolve({
						status: true,
						data: res
					});
				})
				.catch(err => {
					dispatch({ type: ABOUTUS_LIST_FAIL_TYPE, data: { list: [], isLoading: true, status: false, message: err.message } });
					resolve({
						status: false,
						error: err
					});
				})
		});
	},

	updateAboutUs: (data) => async (dispatch, getState) => {
		return new Promise(async resolve => {
			dispatch({
				type: ABOUTUS_UPDATE_TYPE, data: initialState
			});

			await post(ABOUTUS_UPDATE, data)
				.then(res => {
					if (res.status === SUCCESS_CODE) {
						dispatch({
							type: ABOUTUS_UPDATE_SUCCESS_TYPE, data: { update: res.data, isLoading: true, status: true, message: res.message }
						});

					} else {
						dispatch({
							type: ABOUTUS_UPDATE_FAIL_TYPE, data: { update: [], isLoading: true, status: false, message: res.message }
						});

					}
					resolve({
						status: true,
						data: res
					});
				})
				.catch(err => {
					dispatch({ type: ABOUTUS_UPDATE_FAIL_TYPE, data: { update: [], isLoading: true, status: false, message: err.message } });
					resolve({
						status: false,
						error: err
					});
				})
		});
	},

	getListAlertRoles: (data) => async (dispatch, getState) => {
		return new Promise(resolve => {
			dispatch({
				type: ABOUTUS_GET_LIST_ALERT_ROLES_TYPE,
				data: []
			});
			post(ABOUTUS_GET_LIST_ALERT_ROLES, data)
				.then(res => {
					if (res.status == 200) {
						dispatch({
							type: ABOUTUS_GET_LIST_ALERT_SUCCESS_TYPE,
							data: res.data || []
						});
						resolve({
							status: true,
							data: res
						});
						return;
					}
					resolve({
						status: false,
						data: res
					});
				})
				.catch(err => {
					resolve({
						status: false,
						error: err
					});
				})
		});
	},
	createAlertRoles: (data) => async (dispatch, getState) => {
		return new Promise(resolve => {
			dispatch({
				type: ABOUTUS_CREATE_ALERT_ROLES_TYPE,
				data: []
			});
			post(ABOUTUS_CREATE_ALERT_ROLES, data)
				.then(res => {
					if (res.status == 200) {
						dispatch({
							type: ABOUTUS_CREATE_ALERT_ROLES_SUCCESS_TYPE,
							data: res.data || []
						});
						resolve({
							status: true,
							data: res
						});
						return;
					}
					resolve({
						status: false,
						data: res
					});
				})
				.catch(err => {
					resolve({
						status: false,
						error: err
					});
				})
		});
	},
	deleteAlertRoles: (data) => async (dispatch, getState) => {
		return new Promise(resolve => {
			dispatch({
				type: ABOUTUS_DELETE_ALERT_ROLES_TYPE,
				data: []
			});
			del(ABOUTUS_DELETE_ALERT_ROLES + data)
				.then(res => {
					if (res.status == 200) {
						dispatch({
							type: ABOUTUS_DELETE_ALERT_ROLES_SUCCESS_TYPE,
							data: res.data || []
						});
						resolve({
							status: true,
							data: res
						});
						return;
					}
					resolve({
						status: false,
						data: res
					});
				})
				.catch(err => {
					resolve({
						status: false,
						error: err
					});
				})
		});
	},
	getAllAlertBySelect: () => async (dispatch, getState) => {
		return new Promise(resolve => {
			dispatch({
				type: ABOUTUS_GET_LIST_ALERT_ROLES_BY_SELECT_TYPE,
				data: []
			});
			get(ABOUTUS_GET_LIST_ALERT_ROLES_BY_SELECT)
				.then(res => {
					if (res.status == 200) {
						dispatch({
							type: ABOUTUS_GET_LIST_ALERT_ROLES_BY_SELECT_SUCCESS_TYPE,
							data: res.data || []
						});
						resolve({
							status: true,
							data: res
						});
						return;
					}
					resolve({
						status: false,
						data: res
					});
				})
				.catch(err => {
					resolve({
						status: false,
						error: err
					});
				})
		});
	},
	getAllRolesBySelect: () => async (dispatch, getState) => {
		return new Promise(resolve => {
			dispatch({
				type: ABOUTUS_GET_LIST_ROLES_BY_SELECT_TYPE,
				data: []
			});
			get(ABOUTUS_GET_LIST_ROLES_BY_SELECT)
				.then(res => {
					if (res.status == 200) {
						dispatch({
							type: ABOUTUS_GET_LIST_ROLES_BY_SELECT_SUCCESS_TYPE,
							data: res.data || []
						});
						resolve({
							status: true,
							data: res
						});
						return;
					}
					resolve({
						status: false,
						data: res
					});
				})
				.catch(err => {
					resolve({
						status: false,
						error: err
					});
				})
		});
	},

}