export const USER_ACCOUNT_HEADER = [
    'Stt',
    // 'Trạng thái',
    'Hình đại diện',
    'Nhóm quyền',
    'Họ và tên',
    'Tên đăng nhập',
];

export const USER_ACCOUNT_HEADER_SEARCH = 'Tìm kiếm họ và tên';

export const ROLE_ACCOUNT_HEADER = [
    'Stt',
    'Nhóm quyền',

];

export const ROLE_ACCOUNT_HEADER_SEARCH = 'Tìm kiếm nhóm quyền';


export const ZONE_ROLE_ACCOUNT_HEADER = [
    'Stt',
    'Nhóm quyền',
    'Vùng dữ liệu'
];

export const PARTNER = [
    'Stt',
    'Hình ảnh',
    'Đơn vị xác thực',
    // 'Tên đơn vị',
    // 'Mã số thuế',
    // 'Điện thoại'
];

export const PRODUCTS_VERIFY = [
    'Stt',
    'Trạng thái',
    'Hình ảnh',
    'Sản phẩm',
    'Ngành nghề',
    'Đơn vị xác thực',
    'Ngày xác thực'
];

export const COMPANY_VERIFY = [
    'Stt',
    'Trạng thái',
    'Hình ảnh',
    'Doanh nghiệp/Hợp tác xã/Cá nhân',
    'Đơn vị xác thực',
    'Ngày xác thực'
];

export const PARTNER_VERIFY = [
    'Stt',
    'Trạng thái',
    'Hình ảnh',
    'Tên nhà cung cấp',
    'Đơn vị xác thực',
    'Ngày xác thực'
];

export const PARTNER_MANUFACT_VERIFY = [
    'Stt',
    'Trạng thái',
    'Hình ảnh',
    'Tên nhà sản xuất',
    'Đơn vị xác thực',
    'Ngày xác thực'
];

export const PARTNER_TRANFORM_VERIFY = [
    'Stt',
    'Trạng thái',
    'Hình ảnh',
    'Tên nhà vận chuyển',
    'Đơn vị xác thực',
    'Ngày xác thực'
];

export const ZONE_ROLE_ACCOUNT_HEADER_SEARCH = 'Tìm kiếm';

export const PRICES_HEADER_SEARCH = 'Tìm kiếm bảng giá';

export const PRICES_HEADER = [
    'Stt',
    'Số năm',
    'Doanh nghiệp',
    'Cá nhân',
    'Hợp tác xã'
];

export const ZONE_HEADER_SEARCH = 'Tìm kiếm vùng dữ liệu';

export const ZONE_HEADER = [
    'Stt',
    'Tên vùng',
    'Khu vực'
];

export const TYPE_ZONE_PROPERTY_HEADER = [
    'Stt',
    'Tên loại',
    'Loại sản phẩm'
]

export const HISTORY_HEADER_SEARCH = 'Tìm kiếm lịch sử';

export const HISTORY_HEADER = [
    'Stt',
    'Thời gian',
    // 'Giờ',
    'Người dùng',
    'Nội dung'
];
export const COMPANY_REG_HEADER_SEARCH = 'Tìm kiếm danh sách';

export const COMPANY_REG_HEADER = [
    'Stt',
    'Tên doanh nghiệp/ Họ và tên',
    'Mã số thuế/ Số CMND(CCCD)',
    'Điện thoại',
    'Email'
];
export const COMPANY_AWAIT_HEADER_SEARCH = 'Tìm kiếm danh sách chờ';

export const COMPANY_AWAIT_HEADER = [
    'Stt',
    'Trạng thái',
    'Ngành nghề',
    'Tên doanh nghiệp/ Địa chỉ',
    'MST\nCMND/CCCD',
    'Điện thoại',
    //'Địa chỉ'
];

export const FILE_UPLOAD = [
    'Stt',
    'Thời gian',
    'File'
];

export const PERMISSION_HEADER_SEARCH = 'Tìm kiếm nhóm quyền';

export const PERMISSION_AWAIT_HEADER = [
    '',
    '',
    'Xem',
    'Thêm',
    'Sửa',
    'Xoá',
    'Duyệt',
    'Không duyệt',
    'Xác thực',
    'Gia hạn',
    'Khoá',
    'Mở khoá',
    'Upload hồ sơ'
];

export const COMPANY_NOT_COMFIRM_HEADER_SEARCH = 'Tìm kiếm danh sách không duyệt';

export const COMPANY_NOT_COMFIRM_HEADER = [
    'Stt',
    'Thông tin kiểm duyệt',
    'Ngành nghề',
    'Tên doanh nghiệp/ Địa chỉ',
    'MST\nCMND/CCCD',
    'Điện thoại',
    //'Địa chỉ',
    // 'Xử lý',
    // 'Người xử lý'
];

export const COMPANY_COMFIRMED_HEADER = [
    'Stt',
    'Thông tin kiểm duyệt',
    'Ngành nghề',
    'Tên doanh nghiệp/ Địa chỉ',
    'MST\nCMND/CCCD',
    'Điện thoại',
    'Ngày đăng ký'
];

export const COMPANY_All_HEADER = [
    'Stt',
    'Trạng thái',
    'Ngành nghề',
    'Tên doanh nghiệp/ Địa chỉ',
    'MST\nCMND/CCCD',
    'Điện thoại',
    'Ngày đăng ký'
];

export const COMPANY_DELETED_HEADER = [
    'Stt',
    'Thông tin kiểm duyệt',
    'Ngành nghề',
    'Tên doanh nghiệp/ Địa chỉ',
    'Mã số thuế/CMND/CCCD',
    'Điện thoại',
    'Ngày đăng ký'
];
export const COMPANY_LIST_REGISTER_HEADER_SEARCH = 'Tìm kiếm danh sách ';

export const COMPANY_LIST_REGISTER_HEADER = [
    'Stt',
    // 'Trạng thái',
    'Ngành nghề',
    'Tên doanh nghiệp/ Địa chỉ',
    'Mã số thuế',
    'Điện thoại',
    'Ngày đăng ký',

];

export const COMPANY_LIST_CERTIFIED_HEADER = [
    'Stt',
    // 'Trạng thái',
    'Ngành nghề',
    'Tên doanh nghiệp/ Cá nhân',
    'Mã số thuế',
    'Điện thoại',
    'Ngày đăng ký',
    'Ngày xác nhận'
];

export const COMPANY_LIST_AWAIT_EXPIRED_HEADER_SEARCH = 'Tìm kiếm danh sách ';

export const COMPANY_LIST_AWAIT_EXPIRED_HEADER = [
    'Stt',
    'Ngày đăng ký',
    'Còn lại',
    'Ngành nghề',
    'Tên doanh nghiệp/ Địa chỉ',
    'MST\nCMND/CCCD',
    'Điện thoại',
    //'Địa chỉ',  
];
export const COMPANY_LIST_EXPIRING_HEADER_SEARCH = 'Tìm kiếm danh sách ';

export const COMPANY_LIST_EXPIRING_HEADER = [
    'Stt',
    'Ngày đăng ký',
    'Ngày hết hạn',
    'Ngành nghề',
    'Tên doanh nghiệp/ Địa chỉ',
    'MST\nCMND/CCCD',
    'Điện thoại',
    //'Địa chỉ',  
];
export const COMPANY_LIST_LOCK_HEADER_SEARCH = 'Tìm kiếm danh sách ';

export const COMPANY_LIST_LOCK_HEADER = [
    'Stt',
    'Ngày khóa',
    'Người khóa',
    'Ngành nghề',
    'Tên doanh nghiệp/ Địa chỉ',
    'MST\nCMND/CCCD',
    'Điện thoại',
    //'Địa chỉ',  
];

export const COMPANY_REPORTS_PLANNING = [
    'Stt',
    'Doanh nghiệp/Cá nhân',
    'Mã số thuế',
    'Điện thoại',
];

export const FIELD_LIST_HEADER_SEARCH = 'Tìm kiếm danh sách';

export const FIELD_LIST_HEADER = [
    'Stt',
    // 'Trạng thái',
    'Ngành nghề',
    'Người tạo',
    'Ngày tạo'
];

export const INFORMATION_LIST_HEADER_SEARCH = 'Tìm kiếm tên truy xuất/ kê khai';

export const INFORMATION_LIST_HEADER = [
    'Stt',
    'Tên truy xuất/ kê khai',
    'Kiểu dữ liệu',
    'Bắt buộc'
];


export const ACCESS_LIST_HEADER_SEARCH = 'Tìm kiếm danh sách';

export const ACCESS_LIST_HEADER = [
    'Stt',
    'Hình ảnh',
    'Tên truy xuất',
    'Bắt buộc',
    'Kiểm tra cách ly',
    'Thu hoạch'
];

export const REGISTERED_FEE_LIST_HEADER_SEARCH = 'Tìm kiếm danh sách';

export const REGISTERED_FEE_LIST_HEADER = [
    'Stt',
    'Trạng thái',
    'Số tiền',
    'Ngày đăng ký',
    'Ngày hết hạn',
    'Tên doanh nghiệp',
    // 'Ngành nghề',
    'Ngày thu',
    'Người thu'
];

export const STAMP_FEE_LIST_HEADER_SEARCH = 'Tìm kiếm danh sách ';

export const STAMP_FEE_LIST_HEADER = [
    'Stt',
    'Trạng thái',
    'Số tiền',
    'SL',
    'Tên doanh nghiệp',
    'Ngành nghề',
    'Ngày thu',
    'Người thu'
];
export const COMPANY_LIST_REQUEST_EXTEND_HEADER_SEARCH = 'Tìm kiếm danh sách ';

export const COMPANY_LIST_REQUEST_EXTEND_HEADER = [
    'Stt',
    'Ngày hết hạn',
    'Ngày yêu cầu',
    'Ngành nghề',
    'Tên doanh nghiệp/Địa chỉ',
    'MST\nCMND/CCCD',
    'Điện thoại'
];

export const STAMPTEMPLATE = [
    'Stt',
    'Mẫu',
    'Tên',

];

export const MATERIAL_GROUP = [
    'Stt',
    // 'Ngành nghề',
    'Trạng thái',
    'Tên nhóm',
    'Người tạo',
    'Ngày tạo'
];
export const PRODUCT_GROUP = [
    'Stt',
    'Trạng thái',
    'Loại sản phẩm',
    'Người tạo',
    'Ngày tạo'
];

export const PRODUCTS = [
    'Stt',
    // 'Trạng thái',
    'Hình ảnh',
    'Sản phẩm',
    // 'Nhà sản xuất',
    // 'Nhà cung cấp'
    // 'Người tạo',
    'Ngày tạo',

];

export const UNIT = [
    'Stt',
    'Đơn vị tính',
];

export const USERPROVINCE = [
    'Stt',
    'Người dùng',
    'Vùng quản lý'
];

export const MASTERSERVER = [
    'Stt',
    'Diễn giải',
    'Tỉnh thành',
    'url',
    'url login',
    'url admin',
    'web client id',
    'web client secret'
];

export const ABOUTUS = 'Tìm kiếm liên hệ';

export const ALERT = [
    'Stt',
    'Nhóm quyền',
    'Thông báo',
];

export const TRACE = [
    'Stt',
    '',
    'Sản phẩm',
    'Nhà sản xuất',
    'Mã nhật ký'
];

export const COMPANY_LIST_REQUEST_UNLOCK_HEADER_SEARCH = 'Tìm kiếm danh sách ';

export const COMPANY_LIST_REQUEST_UNLOCK_HEADER = [
    'Stt',
    'Ngày khóa',
    'Ngày yêu cầu',
    'Ngành nghề',
    'Tên doanh nghiệp',
    'Mã số thuế',
    'Điện thoại'
];
export const BLOG_LIST_HEADER_SEARCH = 'Tìm kiếm danh sách ';

export const BLOG_LIST_HEADER = [
    'Stt',
    'Tiêu đề',
    // 'Loại',
    'Hình ảnh',
    'Nổi bật',
    'Hiển thị',
    'Lần xem',
    'Thời gian'
];
export const NEWS_LIST_HEADER_SEARCH = 'Tìm kiếm danh sách ';

export const NEWS_LIST_HEADER = [
    'Stt',
    'Tiêu đề',
    'Menu',
    'Hình ảnh',
    'Lần xem',
    'Thời gian'
];
export const MENU_LIST_HEADER_SEARCH = 'Tìm kiếm danh sách ';

export const MENU_LIST_HEADER = [
    'Stt',
    'Tên menu'
];
export const STAMP_LIST_HEADER_SEARCH = 'Tìm kiếm dải tem ';

export const STAMP_LIST_HEADER = [
    'Stt',
    'Dải tem',
    'Số lượng',
    'Chưa cấp',
    'Đã cấp',
    'Ngày tạo',
    'Người tạo'
];
export const STAMPPROVIDE_LIST_HEADER_SEARCH = 'Tìm kiếm dải tem ';

export const STAMPPROVIDE_LIST_HEADER = [
    'Stt',
    'Trạng thái',
    'Tên doanh nghiệp',
    'Số lượng yêu cầu',
    'Dải tem | Số lượng',
    'Ngày yêu cầu',
    'Ngày xử lý',
    'Người xử lý',
    'Lý do không duyệt'
];
export const PLANTINGTYPE_LIST_HEADER_SEARCH = 'Tìm kiếm vùng sản xuất ';

export const PLANTINGTYPE_LIST_HEADER = [
    'Stt',
    'Thể loại',
];
export const NAVBAR_PARENT_ITEM = {
    SYSTEM: 'Hệ thống',
    COMPANY: 'Quản lý doanh nghiệp',
    CATEGORY: 'Quản lý danh mục',
    STAMP: 'Quản lý dải tem',
    FEE: 'Quản lý thu',
    WEBSITE: 'Quản lý website',
    REPORT: 'Báo cáo',
    SETTING: 'Cài đặt hệ thống'
};

export const NAVBAR_PARENT_ITEM_LIST = [
    'Hệ thống',
    'Quản lý doanh nghiệp',
    'Quản lý danh mục',
    'Quản lý thu',
    'Quản lý dải tem',
    'Quản lý website',
    'Báo cáo',
    'Cài đặt hệ thống'
];

export const DEBT_COLLECT_OF_REGISTRASTION_OF_USE_HEADER = [
    'Stt',
    'Số tiền',
    'Tên doanh nghiệp'
];

export const LIABILITIES_STAPM_HEADER = [
    'Stt',
    'Số tiền',
    'Tên doanh nghiệp'
];

export const DATA_TYPES = {
    banDo: 5,
    trueFalse: 6
}

export const PARAM_OF_ASSCESS_LIST = [
    { name: 'Khách hàng', reference: 10 },
    { name: 'Nhà cung cấp', reference: 20 },
    { name: 'Nhân viên', reference: 30 },
    { name: 'Nguyên vật liệu', reference: 40 },
    { name: 'Kho hàng', reference: 50 },
    { name: 'Đơn vị tính', reference: 60 },
    { name: 'Đơn vị vận chuyển', reference: 70 },
    { name: 'Phương tiện vận chuyển', reference: 80 },
    { name: 'Phiếu xuất', reference: 90 },
    { name: 'Nhà máy/Thiết bị', reference: 91 },
];

export const DATA_TYPE_FIELD_LIST = [
    { fieldType: '1', name: "SẢN XUẤT & CHẾ BIẾN" },
    { fieldType: '2', name: "THƯƠNG MẠI & DỊCH VỤ" },
    { fieldType: '3', name: "CHĂN NUÔI" },
    { fieldType: '4', name: "TRỒNG TRỌT" },
    { fieldType: '5', name: "ĐÁNH BẮT" },
    { fieldType: '6', name: "VẬN TẢI" },
];

export const DATA_TYPE_LIST = [
    { dataType: '1', name: "TEXT" },
    { dataType: '2', name: "NUMBER" },
    { dataType: '3', name: "DATE" },
    { dataType: '4', name: "IMAGE" },
    { dataType: '5', name: "GPS" },
    { dataType: '6', name: "YES/NO" },
];

export const DATA_SORTODER_LIST = [
    { number: '1' },
    { number: '2' },
    { number: '3' },
    { number: '4' },
    { number: '5' },
    { number: '6' },
    { number: '7' },
    { number: '8' },
    { number: '9' },
    { number: '10' },

    { number: '11' },
    { number: '12' },
    { number: '13' },
    { number: '14' },
    { number: '15' },
    { number: '16' },
    { number: '17' },
    { number: '18' },
    { number: '19' },
    { number: '20' },

    { number: '21' },
    { number: '22' },
    { number: '23' },
    { number: '24' },
    { number: '25' },
    { number: '26' },
    { number: '27' },
    { number: '28' },
    { number: '29' },
    { number: '30' },
    { number: '31' },

    { number: '32' },
    { number: '33' },
    { number: '34' },
    { number: '35' },
    { number: '36' },
    { number: '37' },
    { number: '38' },
    { number: '39' },
    { number: '40' },

    { number: '41' },
    { number: '42' },
    { number: '43' },
    { number: '44' },
    { number: '45' },
    { number: '46' },
    { number: '47' },
    { number: '48' },
    { number: '49' },
    { number: '50' },

    { number: '51' },
    { number: '52' },
    { number: '53' },
    { number: '54' },
    { number: '55' },
    { number: '56' },
    { number: '57' },
    { number: '58' },
    { number: '59' },
    { number: '60' },

    { number: '61' },
    { number: '62' },
    { number: '63' },
    { number: '64' },
    { number: '65' },
    { number: '66' },
    { number: '67' },
    { number: '68' },
    { number: '69' },
    { number: '70' },

    { number: '71' },
    { number: '72' },
    { number: '73' },
    { number: '74' },
    { number: '75' },
    { number: '76' },
    { number: '77' },
    { number: '78' },
    { number: '79' },
    { number: '80' },

    { number: '81' },
    { number: '82' },
    { number: '83' },
    { number: '84' },
    { number: '85' },
    { number: '86' },
    { number: '87' },
    { number: '88' },
    { number: '89' },
    { number: '90' },

    { number: '91' },
    { number: '92' },
    { number: '93' },
    { number: '94' },
    { number: '95' },
    { number: '96' },
    { number: '97' },
    { number: '98' },
    { number: '99' },
    { number: '100' },
];

export const NAVBAR_ITEM = {
    USER_ACCOUNT: 'Tài khoản người dùng',
    ROLE_GROUP: 'Nhóm quyền',
    DATA_ZONE: 'Vùng dữ liệu',
    FUNCTION_ROLE: 'Phân quyền chức năng',
    DATA_ROLE: 'Phân vùng dữ liệu',
    PRICES: 'Bảng giá',
    HISTORY: 'Nhật ký hệ thống',
    NEWREGBUS: 'Danh sách mới đăng ký',
    COMPANY_AWAIT: 'Danh sách đang chờ duyệt',
    COMPANY_ALL: 'Danh sách doanh nghiệp',
    COMPANY_NOT_COMFIRM: 'Danh sách không được duyệt',
    COMPANY_LIST_REGISTER: 'Danh sách chờ xác thực',
    COMPANY_LIST_CERTIFIED: 'Danh sách doanh nghiệp tiêu biểu',
    COMPANY_LIST_ACTIVITIED: 'Danh sách đã xác thực',
    COMPANY_LIST_AWAIT_EXPIRED: 'Danh sách sắp hết hạn',
    COMPANY_LIST_EXPIRING: 'Danh sách chờ gia hạn',
    COPANY_LIST_LOCK: 'Danh sách bị khóa',
    FIELD_LIST: 'Danh mục ngành nghề',
    INFORMATION_LIST: 'Danh mục dữ liệu kê khai',
    ACCESS_LIST: 'Danh mục dữ liệu truy xuất',
    REGISTERED_FEE_LIST: 'Danh sách thu tiền đăng ký sử dụng',
    STAMP_FEE_LIST: 'Danh sách thu tiền đăng ký',
    COMPANY_LIST_REQUEST_EXTEND: 'Danh sách yêu cầu gia hạn',
    COMPANY_LIST_REQUEST_UNLOCK: 'Danh sách yêu cầu mở khóa',
    BLOG_LIST: 'Bài viết',
    NEWS_LIST: 'Tin tức',
    MENU_LIST: 'Menu',
    // CONTENT_WEBSITE: 'Cấu hình',
    CONTENT_WEBSITE: 'Tin tức',
    STAMP_LIST: 'Quản lý dải tem',
    STAMP_PROVIDE: 'Quản lý cấp phát tem',
    PLANTINGTYPE_LIST: 'Loại vùng sản xuất & thuộc tính',
    PLANGTINGZONE_LIST: 'vùng sản xuất',
    MATERIALGROUP: 'Nhóm sản phẩm/NVL',
    PRODUCTGROUP: 'Loại sản phẩm',
    REPORT_COMPANY_CHART: 'Tăng trưởng doanh nghiệp',
    REPORT_STAPM_CHART: 'Tăng trưởng tem',
    REPORT_REGISTER_CHART: 'Tăng trưởng tiền đăng ký',
    REPORT_PRODUCT_CHART: 'Báo cáo hàng hoá',
    SETTING: 'Cài đặt hệ thống',
    PLANNiNG_INTER: 'Danh sách doanh nghiệp/Cá nhân thuộc quy hoạch',
    UNIT: 'Danh sách đơn vị tính',
    PLANNiNG_OUTER: 'Danh sách doanh nghiệp/Cá nhân ngoài quy hoạch',
    PRODUCT_REPORTS: 'Thống kê sản phẩm theo nhóm',
    TRACE: 'Nhật ký sản phẩm',
    PRODUCTS: 'Sản phẩm',
    USERPROVINCE: 'Danh sách vùng quản lý',
    ABOUTUS: 'Cài đặt liên hệ',
    MASTERSERVER: 'Danh sách máy chủ',
    HISTORY: 'Nhật ký hệ thống',
    COMPANY_DELETED: 'Danh sách bị xoá',
    COMAPNY_COMFIRMED: 'Danh sách đã duyệt'
}
export const USER_INFO_DROPDOWN = [
    { label: 'Thông tin', url: '/', name: 'infoaccount' },
    { label: 'Đổi mật khẩu', url: '/', name: 'changepass' },
    { label: 'Thoát', url: '/', name: 'logout' }
];

export const PLANTING_ZONE = [
    'Stt',
    'Tên vùng sản xuất',
    'Loại vùng sản xuất',
    'Tọa độ',
    'Phạm vi',
    'Dung sai',
]

export const LIMIT_ITEM_IN_PAGE = 10;
export const LOADING_TIME = 1000;

export const UNITS = [
    {
        value: 1,
        label: 'km'
    },
    {
        value: 2,
        label: 'm'
    },
    {
        value: 3,
        label: 'hải lý'
    }
]

export const VARIABLES = {
    isFechingAlert: true
}