import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { actionCompanyListRegistered } from "../../../../../actions/CompanyListRegisteredActions";
import { actionField } from "../../../../../actions/FieldActions.js";
import { actionPartner } from "../../../../../actions/PartnerActions";
import { actionProducts } from "../../../../../actions/ProductsActions";
import NoImg from '../../../../../assets/img/NoImg/NoImg.jpg';
import { LOADING_TIME } from "../../../../../helpers/constant";
import { PLEASE_CHECK_CONNECT } from '../../../../../services/Common'
import { handleGenTree } from "../../../../../helpers/trees";
import {
    Spinner
} from 'reactstrap'

class ItemManuFace extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: null,
            limit: 10,
            status: null,
            statusList: [
                { name: 'Chưa xác thực', verifiedStatus: 0 },
                { name: 'Chờ xác thực', verifiedStatus: 1 },
                { name: 'Đã xác thực', verifiedStatus: 2 },
            ],
        }
    }
    componentWillReceiveProps(nextProp) {

        let { data } = nextProp.partner;
        const { limit } = this.state;
        let fieldData = nextProp.field.data;
        let haveRoot = false;
        let fieldDataParent = [];
        if (data !== this.state.data) {
            if (typeof (data) !== 'undefined') {
                if (typeof (data.listManuVerify) !== 'undefined') {
                    if (data.listManuVerify !== null) {
                        if (typeof (data.listManuVerify.partners) !== 'undefined') {
                            data.listManuVerify.partners.map((item, key) => {
                                item['thumbnail'] = <img src={item.logo ? item.logo : NoImg} style={{ width: 60, height: 60 }} />
                                if (item.verifiedStatus == 0) {
                                    item['statusName'] = 'Chưa xác thực'
                                }
                                else if (item.verifiedStatus == 1) {
                                    item['statusName'] = 'Chờ xác thực'
                                } else if (item.verifiedStatus == 2) {
                                    item['statusName'] = 'Đã xác thực'
                                };
                                item['index'] = key + 1;
                                item['collapse'] = false;

                            });
                            this.setState({
                                data: data.listManuVerify.partners,
                                history: data.listManuVerify.partners,
                                listLength: data.listManuVerify.total,
                                totalPage: Math.ceil(data.listManuVerify.partners.length / limit),
                                isLoaded: data.isLoading,
                                status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message),
                                totalElement: data.listManuVerify.total > limit ? limit : data.listManuVerify.total
                            });
                        } else {
                            this.setState({
                                history: data.listManuVerify.partners,
                                isLoaded: data.isLoading,
                                status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });
                        }
                    }
                }
            }
        }

        if (fieldData !== this.state.field) {
            if (typeof (fieldData) !== 'undefined') {
                if (fieldData.field !== null) {
                    if (typeof (fieldData.field) !== 'undefined') {
                        if (typeof (fieldData.field.fields) !== 'undefined') {
                            fieldData.field.fields
                                .filter(item => item.parentID === null)
                                .map(item => haveRoot = true);

                            if (haveRoot) {
                                fieldDataParent = handleGenTree(fieldData.field.fields, 'fieldName');

                                fieldDataParent.map((item, key) => {
                                    item['index'] = key + 1;

                                });
                            } else {
                                // Search Element in tree
                                fieldData.field.fields.map(
                                    (item, key, array) => (
                                        key === 0 && (item.parentID = null)
                                    ));

                                fieldDataParent = handleGenTree(fieldData.field.fields, 'fieldName');

                                fieldDataParent.map((item, key) => {
                                    item['index'] = key + 1
                                });
                            }
                        }

                        this.setState({
                            field: fieldDataParent,
                            fieldAll: fieldData.field.fields,
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    } else {
                        this.setState({
                            field: [],
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    }
                }
            }
        }
    }
    componentDidMount() {
        /* Fetch Summary */
        const { requestCompanyListRegistered, requestFieldStore, } = this.props;

        requestFieldStore(JSON.stringify({
            "search": "",
            "filter": "",
            "orderBy": "",
            "page": null,
            "limit": null
        })).then(res => {
            this.fetchSummary(JSON.stringify({
                "fieldID": "",

                "companyID": "",
                "verifiedStatus": null,
                "orderBy": "",
                "page": null,
                "limit": null
            }));
        })

        this.fetchSummary(JSON.stringify({
            "fieldID": "",

            "companyID": "",
            "verifiedStatus": null,
            "orderBy": "",
            "page": null,
            "limit": null
        }));
    }
    componentDidUpdate() {
        // This method is called when the route parameters change
        this.closeStatusModal();
    }

    fetchSummary = (data) => {
        const { requestListManufestPartnerVerify } = this.props;

        requestListManufestPartnerVerify(data);
    }
    closeStatusModal = () => {
        const { status, fetching } = this.state;

        if (status || !status && fetching) {
            setTimeout(() => {
                this.setState({ status: null, isLoaded: false });
            }, LOADING_TIME);
        }
    }

    render() {
        const { data, isLoaded } = this.state

        return (
            <>
                {
                    isLoaded ? (<>
                        <div style={{ display: 'table', margin: 'auto' }}>
                            <Spinner style={{ width: '2rem', height: '2rem' }} />
                        </div>
                    </>) : (<>
                        <div className='body-box-list'>
                            <table className='table-box-list'>
                                <thead>
                                    <tr className='table-tr-box-list'>
                                        <th>Hình ảnh</th>
                                        <th>Nhà cung cấp</th>
                                        <th>Đơn vị xác thực</th>
                                        <th>Trạng thái</th>
                                    </tr>
                                    {/* <hr className='hr-table' /> */}
                                </thead>
                                <tbody>
                                    {
                                        Array.isArray(data) && (
                                            data.map((item, key) => (
                                                <tr key={key} className='table-hover-css'>
                                                    <td className='text-algin-img'>
                                                        <img src={item.logo ? item.logo : NoImg} style={{ width: 60, height: 60 }} />
                                                    </td>
                                                    <td className='text-algin-text'><span>{item.partnerName}</span> </td>
                                                    <td className='text-algin-text'><span>{item.verifiedName ? item.verifiedName : <i>Chưa có dữ liệu</i>}</span></td>
                                                    <td className='center-align-td'>
                                                        {
                                                            item.verifiedStatus === 2 ? (<>
                                                                <span className='activeStt font-size-stt'>Đã xác thực</span>
                                                            </>) : null
                                                        }
                                                        {
                                                            item.verifiedStatus === 1 ? (<>
                                                                <span className='notyetStt font-size-stt'>Chờ xác thực</span>
                                                            </>) : null
                                                        }
                                                        {
                                                            item.verifiedStatus === 0 ? (<>
                                                                <span className='noActiveStt font-size-stt'>Chưa xác thực</span>
                                                            </>) : null
                                                        }
                                                    </td>
                                                </tr>
                                            )))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </>)
                }

            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        products: state.ProductsStore,
        dataCompany: state.CompanyListRegisteredStore,
        field: state.FieldStore,
        partner: state.PartnerStore
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionProducts, dispatch),
        ...bindActionCreators(actionCompanyListRegistered, dispatch),
        ...bindActionCreators(actionField, dispatch),
        ...bindActionCreators(actionPartner, dispatch),
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ItemManuFace)