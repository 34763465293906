import React, { Component } from "react";
import SelectTree from "components/SelectTree";
import classes from './index.module.css';
import Validate from "react-validate-form";
import { rules, validations, checkFieldName, checkFieldNameBool } from "../../../helpers/validation";
import { DATA_TYPE_FIELD_LIST } from "../../../helpers/constant";
import Select from "components/Select";

// reactstrap components
import {
	Input,
	InputGroup
} from "reactstrap";

class AddNewModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: {
				"id": "",
				"fieldCode": "",
				"fieldName": "",
				"parentID": "",
				"fieldType": 0
			},
			activeSubmit: false,
			checkFieldName: '',
			fieldSelect: null
		}
	}

	componentWillMount() {
		const { fieldSelect } = this.props;
		this.setState({ fieldSelect })
	}

	handleChange = (event) => {
		let { data } = this.state;
		let { field } = this.props;
		const ev = event.target;

		data[ev['name']] = ev['value'];
		this.setState({ data });

		// Check Validation 
		this.handleCheckValidation();

		// Check Confirm Password
		this.setState({
			checkFieldName: checkFieldName(data.fieldName, field)
		});
	}

	handleSelect = (value, name) => {
		const { fieldSelect } = this.state;

		let { data } = this.state;

		if (value === null) value = "";

		data[name] = value;

		this.setState({ data });
		// Check Validation 
		this.handleCheckValidation();
	}

	handleCheckValidation = () => {
		const { handleCheckValidation, handleNewData } = this.props;
		let { data } = this.state;
		let { field } = this.props;

		// console.log(checkFieldNameBool(data.fieldName, field));
		if (data.fieldName.length > 0 && data.fieldName.length < 255 && !checkFieldNameBool(data.fieldName, field) === true) {
			this.setState({ activeSubmit: true });

			// Check Validation 
			handleCheckValidation(true);

			// Handle New Data
			handleNewData(data);
		} else {
			this.setState({ activeSubmit: false });
			handleCheckValidation(false);

			// Handle New Data
			handleNewData(data);
		}
	}

	render() {
		const { field, handleOpenSelectTree, errorInsert, errorMessage, fieldSelect } = this.props;
		const { data, checkFieldName } = this.state;

		// console.log(fieldSelect);


		return (
			<div className={classes.formControl}>

				{/* <div className={classes.rowItem}>
					<label
						className="form-control-label"
					>
						Mã ngành nghề&nbsp;<b style={{ color: 'red' }}>*</b>
					</label>
					<div className={classes.inputArea}>
						<InputGroup className="input-group-alternative css-border-input">
							<Input
								type="text"
								name='fieldCode'
								placeholder='Mã ngành nghề'
								autoFocus={true}
								onKeyUp={(event) => this.handleChange(event)}
							/>
						</InputGroup>
						<p className='form-error-message margin-bottom-0'>{errorInsert['fieldCode'] || ''}</p>
					</div>
				</div> */}

				<div className={classes.rowItem}>
					<label
						className="form-control-label"
					>
						Tên ngành nghề&nbsp;<b style={{ color: 'red' }}>*</b>
					</label>

					<Validate
						validations={validations}
						rules={rules}
					>
						{({ validate, errorMessages }) => (
							<div className={classes.inputArea}>
								<InputGroup className="input-group-alternative css-border-input">
									<Input
										type="text"
										name='fieldName'
										placeholder='Tên ngành nghề'
										defaultValue={data.fieldName}
										required
										onChange={validate}
										onKeyUp={(event) => this.handleChange(event)}
									/>
								</InputGroup>
								<p className='form-error-message margin-bottom-0'>{errorInsert['fieldName'] || ''}</p>

							</div>
						)}
					</Validate>
				</div>

				{/* <div className={classes.rowItem}>
					<label
						className="form-control-label"
					>
						Loại ngành nghề<b style={{ color: 'red' }}>*</b>
					</label>
					<div className={classes.inputArea}>
						<Select
							className=""
							name="fieldType"
							title='Chọn loại ngành nghề'
							data={DATA_TYPE_FIELD_LIST}
							labelName='name'
							val='fieldType'
							handleChange={this.handleSelect}
						/>
						<p className='form-error-message margin-bottom-0'>{errorInsert['fieldType'] || ''}</p>
					</div>

				</div> */}


				<div className={classes.rowItem}>

					<label
						className="form-control-label"
					>
						Thuộc ngành nghề<b style={{ color: 'red' }}>*</b>
					</label>
					<div className={classes.inputArea}>
						<SelectTree
							name="parentID"
							title='Chọn ngành nghề'
							data={fieldSelect}
							labelName='fieldName'
							fieldName='fieldName'
							fieldTypeNotNull = {true}
							val='id'
							handleChange={this.handleSelect}
							handleOpenSelectTree={handleOpenSelectTree}
						/>
						<p className='form-error-message margin-bottom-0'>{errorInsert['parentID'] || ''}</p>
					</div>

				</div>



			</div>
		);
	}
};

export default AddNewModal;
