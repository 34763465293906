import React, { Component } from "react";
import { bindActionCreators } from "redux";
import classes from './index.module.css';
import InputCurrency from '../../../components/InputCurrency';
import Validate from "react-validate-form";
import { rules, validations, checkPasswordConfirm } from "../../../helpers/validation";
import Select from "components/Select";
import { Guid } from 'guid-typescript';
import PopupMessage from "../../../components/PopupMessage";
import { removeDuplicates } from '../../../helpers/common';
import { actionUserProvince } from '../../../actions/UserProvinceActions';
import { actionMasterServer } from '../../../actions/MasterServerActions'

import IconDelete from '../../../assets/img/buttons/delete.png';
import IconAdd from '../../../assets/img/buttons/add.svg';

// reactstrap components
import {
    Input,
    InputGroup
} from "reactstrap";
import { compose } from "recompose";
import { connect } from "react-redux";

class InsertOrUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {

            provinces: this.props,
            userList: this.props,
            activeSubmit: false,
            isShowArea: false,
            zones: [],
            isShowForEdit: false,
            description: '',
            url: '',
            urlLogin: '',
            urlAdmin: '',
            webClientID: '',
            webClientSecret: '',
            provinceID: ''

        }
        this.redSelect = null
    }

    componentWillUnmount() {
        this.setState(previousState => {
            return {
                ...previousState,
                userProvince: '',
                userName: '',
                userId: null,
                id: null,
                typeManage: 1,
                isShowArea: false,


            }
        })

    }


    handleChange = event => {
        // let { data } = this.state;
        const ev = event.target.value;
        this.setState(previousState => {
            return {
                ...previousState,
                [event.target.name]: ev
            }
        }, () => {
            if (this.props.onHandleChangeValue) {
                this.props.onHandleChangeValue(this.state);
            }
        })


    }

    async componentDidMount() {
        const { id, getIdMasterServerUpdate, getProvinceList, getIdUserProvinceUpdate } = this.props;
        const { userList } = this.state
        // const filterUserList = data?.list.filter((res) =>
        //     res.userProvinceDtos.length <= 0
        // )

        // let filterUserProvince =
        //     userList.userList.filter(us =>
        //         filterUserList.filter(ft =>
        //             us.id.trim().toUpperCase() === ft.userID.trim().toUpperCase())).map(item => console.log(item))



        if (id) {
            getIdMasterServerUpdate(id).then(res => {

                let masterServerId = res.data.data || {};
                this.setState(previousState => {
                    return {
                        ...previousState,
                        masterServerId,
                        // userListId,
                        // userId: userListId[0].userID,

                    }
                }, () => {
                    if (this.props.onHandleChangeValue) {
                        this.props.onHandleChangeValue(this.state);
                    }
                })
            })

            // const result = await getIdUserProvinceUpdate(id)
            // const data = ((result || {}).data || {}).data || null;
            // // console.log(data);
            // const zones = (data[0].userProvinceDtos).map(item => {
            //     return {
            //         id: Guid.create().toString(),
            //         provinceName: item.provinceName,
            //         provinceID: item.provinceID,
            //     }
            // });
            // // console.log(zones);
            // this.setState(previousState => {
            //     return {
            //         ...previousState,
            //         zones,

            //         //districtId: null
            //     }
            // }, () => {
            //     // console.log(this.state, 66655);
            //     if (this.props.onHandleChangeValue) {
            //         this.props.onHandleChangeValue(this.state);
            //     }
            // })

        }
    }


    onChangeSelect = name => value => {
        let nameAfterGetFromSever

        const { masterServer, dataProvince } = this.props
        const filterData = dataProvince?.filter((masterServer) => (masterServer.id || '') === value)
        if (filterData[0]?.provinceName) {
            nameAfterGetFromSever = filterData[0]?.provinceName?.toUpperCase()
        }

        // console.log(nameAfterGetFromSever, 'nameAfterGetFromSever');
        // this.setState({ errMessageND: '', errMessageTH: '' })

        this.setState(previousState => {
            return {
                ...previousState,
                [name]: value,
                description: nameAfterGetFromSever
            }
        },
            () => {

                if (this.props.onHandleChangeValue) {
                    this.props.onHandleChangeValue(this.state);
                }
            }
        )
    }

    onAddArea = () => {
        const { provinceId, data, districtId, wardId, typeManage, popupMessage, errMessage } = this.state;
        const { dataProvince, districts, wards, userListId } = this.props;
        let provinces = dataProvince;
        const zones = [...this.state.zones];

        const province = provinces.find(p => p.id == provinceId);
        let statusCheck = false;
        if (provinceId) {
            zones.filter(p => p.provinceId == provinceId).map(item => statusCheck = true)
            if (statusCheck == true) {
                this.setState({
                    errMessage: "Tĩnh thành này đã có"
                })
                this.toggleModal('popupMessage')
                return;
                statusCheck = false;
            }
        }
        // console.log(provinceId, 87);
        if (!provinceId) {
            this.setState({
                errMessage: 'Chưa chọn Tỉnh/Thành'
            })
            this.toggleModal('popupMessage')
            return;
        }
        // zones.provinceID = province.id

        zones.push({
            id: Guid.create().toString(),
            provinceId: province.id,
            provinceName: province.provinceName,

        });

        this.setState(previousState => {
            return {
                ...previousState,
                zones
                //districtId: null
            }
        }, () => {
            if (this.props.onHandleChangeValue) {
                this.props.onHandleChangeValue(this.state);
            }
        });

    }
    // onAddAll = () => {
    //     const { provinceId } = this.state;
    //     const { dataProvince } = this.props;
    //     let provinces = dataProvince;
    //     let zones = [...this.state.zones];

    //     const province = provinces.find(p => p.id == provinceId);

    //     dataProvince.map(item => {
    //         zones.push({
    //             id: Guid.create().toString(),
    //             provinceId: item.id,
    //             provinceName: item.provinceName,
    //         })
    //     })

    //     zones = removeDuplicates(zones, item => item.provinceId)

    //     this.setState(previousState => {
    //         return {
    //             ...previousState,
    //             zones,
    //             //districtId: null
    //         }
    //     }, () => {
    //         if (this.props.onHandleChangeValue) {
    //             this.props.onHandleChangeValue(this.state);
    //         }
    //     });

    // }
    onDeleteArea = id => () => {
        const zones = [...this.state.zones];

        const zone = zones.find(p => p.id == id);

        if (zone) {
            const zoneNews = zones.filter(p => p.id != id);

            this.setState(previousState => {
                return {
                    ...previousState,
                    zones: zoneNews
                }
            }, () => {
                if (this.props.onHandleChangeValue) {
                    this.props.onHandleChangeValue(this.state);
                }
            });
        } else {
            this.setState({
                errMessage: 'Không tìm thấy khu vực này'
            })
            this.toggleModal('popupMessage')
            return;
        }
    }

    toggleModal = (state, type) => {
        if (this.state[state] && type == 1) {
            return;
        } else {
            this.setState({
                [state]: !this.state[state],
                detail: null,
                //errMessage:null
            });
        }
    };
    render() {
        const { data, masterServerId, userName, zones, popupMessage, errMessage, description, url, urlAdmin, urlLogin, webClientID, webClientSecret, provinceID } = this.state;

        const { errorInsert, dataProvince, userList, id, userListIds } = this.props;
        return (
            <div className='wrap-insert-or-update-zone'>
                <div className='wrap-insert-or-update-zone-item'>
                    <label className='wrap-insert-or-update-zone-item-label'>Tỉnh/Thành &nbsp;
                        <b style={{ color: 'red' }}>*</b>
                    </label>
                    <div className='wrap-insert-or-update-zone-item-box'>
                        {id ? (
                            <>{masterServerId ? (<Select
                                className='wrap-insert-or-update-zone-item-select'
                                title='Chọn tỉnh/thành'
                                labelName='provinceName'
                                val='id'
                                name="provinceId"
                                defaultValue={masterServerId?.provinceID}
                                data={dataProvince}
                                handleChange={this.onChangeSelect("provinceID")}
                            />) : null}</>
                        ) : (<Select
                            className='wrap-insert-or-update-zone-item-select'
                            title='Chọn tỉnh/thành'
                            labelName='provinceName'
                            val='id'
                            name="provinceId"
                            defaultValue={provinceID}
                            data={dataProvince}
                            handleChange={this.onChangeSelect("provinceId")}
                        />)}

                        <p className='form-error-message  margin-bottom-0'>{errorInsert.zones || ''}</p>
                        {/* <p className='form-error-message'>{this.state.errMessageTH || ''}</p> */}
                    </div>
                </div>
                <div className='wrap-insert-or-update-zone-item'>
                    <label className='wrap-insert-or-update-zone-item-label'>
                        Diễn giải&nbsp;
                        <b style={{ color: 'red' }}>*</b>
                    </label>
                    <div className='wrap-insert-or-update-zone-item-box'>
                        {id ? (
                            <>
                                {masterServerId ? (<InputGroup className="input-group-alternative css-border-input">
                                    <Input
                                        name='description'
                                        placeholder='Diễn giải'
                                        defaultValue={masterServerId?.description}
                                        onKeyUp={(event) => this.handleChange(event)}
                                    />
                                </InputGroup>) : null}
                            </>
                        ) : (<InputGroup className="input-group-alternative css-border-input">
                            <Input
                                name='description'
                                placeholder='Diễn giải'
                                value={description}
                                onChange={(event) => this.handleChange(event)}
                            />
                        </InputGroup>)}
                        <p className='form-error-message margin-bottom-0'>{errorInsert.description || ''}</p>
                    </div>
                </div>
                <div className='wrap-insert-or-update-zone-item'>
                    <label className='wrap-insert-or-update-zone-item-label'>
                        URL&nbsp;
                        <b style={{ color: 'red' }}>*</b>
                    </label>

                    <div className='wrap-insert-or-update-zone-item-box'>
                        {id ? (<>
                            {masterServerId ? (<InputGroup className="input-group-alternative css-border-input">
                                <Input
                                    name='url'
                                    placeholder='URL'
                                    defaultValue={masterServerId.url}
                                    onKeyUp={(event) => this.handleChange(event)}
                                />
                            </InputGroup>) : null}
                        </>) : (<InputGroup className="input-group-alternative css-border-input">
                            <Input
                                name='url'
                                placeholder='URL'
                                defaultValue={url}
                                onKeyUp={(event) => this.handleChange(event)}
                            />
                        </InputGroup>)}
                        <p className='form-error-message margin-bottom-0'>{errorInsert.url || ''}</p>
                    </div>
                </div>
                <div className='wrap-insert-or-update-zone-item'>
                    <label className='wrap-insert-or-update-zone-item-label'>
                        URL Login&nbsp;
                        <b style={{ color: 'red' }}>*</b>
                    </label>

                    <div className='wrap-insert-or-update-zone-item-box'>
                        {id ? (
                            <>{masterServerId ? (<InputGroup className="input-group-alternative css-border-input">
                                <Input
                                    name='urlLogin'
                                    placeholder='URL Login'
                                    defaultValue={masterServerId.urlLogin}
                                    onKeyUp={(event) => this.handleChange(event)}

                                />
                            </InputGroup>) : null}</>
                        ) : (<InputGroup className="input-group-alternative css-border-input">
                            <Input
                                name='urlLogin'
                                placeholder='URL Login'
                                defaultValue={urlLogin}
                                onKeyUp={(event) => this.handleChange(event)}

                            />
                        </InputGroup>)}
                        <p className='form-error-message margin-bottom-0'>{errorInsert.urlLogin || ''}</p>
                    </div>
                </div>
                <div className='wrap-insert-or-update-zone-item'>
                    <label className='wrap-insert-or-update-zone-item-label'>
                        URL Admin&nbsp;
                        <b style={{ color: 'red' }}>*</b>
                    </label>

                    <div className='wrap-insert-or-update-zone-item-box'>
                        {id ? (
                            <>{masterServerId ? (<InputGroup className="input-group-alternative css-border-input">
                                <Input
                                    name='urlAdmin'
                                    placeholder='URL Admin'
                                    defaultValue={masterServerId.urlAdmin}
                                    onKeyUp={(event) => this.handleChange(event)}

                                />
                            </InputGroup>) : null}</>
                        ) : (<InputGroup className="input-group-alternative css-border-input">
                            <Input
                                name='urlAdmin'
                                placeholder='URL Admin'
                                defaultValue={urlAdmin}
                                onKeyUp={(event) => this.handleChange(event)}

                            />
                        </InputGroup>)}

                        <p className='form-error-message margin-bottom-0'>{errorInsert.urlAdmin || ''}</p>
                    </div>
                </div>
                <div className='wrap-insert-or-update-zone-item'>
                    <label className='wrap-insert-or-update-zone-item-label'>
                        Web Client ID&nbsp;
                        <b style={{ color: 'red' }}>*</b>
                    </label>

                    <div className='wrap-insert-or-update-zone-item-box'>
                        {id ? (
                            <>{masterServerId ? (<InputGroup className="input-group-alternative css-border-input">
                                <Input
                                    name='webClientID'
                                    placeholder=' Web Client ID'
                                    defaultValue={masterServerId.webClientID}
                                    onKeyUp={(event) => this.handleChange(event)}

                                />
                            </InputGroup>) : null}</>
                        ) : (<InputGroup className="input-group-alternative css-border-input">
                            <Input
                                name='webClientID'
                                placeholder=' Web Client ID'
                                defaultValue={webClientID}
                                onKeyUp={(event) => this.handleChange(event)}

                            />
                        </InputGroup>)}

                        <p className='form-error-message margin-bottom-0'>{errorInsert.webClientID || ''}</p>
                    </div>
                </div>
                <div className='wrap-insert-or-update-zone-item'>
                    <label className='wrap-insert-or-update-zone-item-label'>
                        Web Client Secret&nbsp;
                        <b style={{ color: 'red' }}>*</b>
                    </label>

                    <div className='wrap-insert-or-update-zone-item-box'>
                        {id ? (
                            <>{masterServerId ? (<InputGroup className="input-group-alternative css-border-input">
                                <Input
                                    name='webClientSecret'
                                    placeholder='Web Client Secret'
                                    defaultValue={masterServerId.webClientSecret}
                                    onKeyUp={(event) => this.handleChange(event)}

                                />
                            </InputGroup>) : null}</>
                        ) : (<InputGroup className="input-group-alternative css-border-input">
                            <Input
                                name='webClientSecret'
                                placeholder='Web Client Secret'
                                defaultValue={webClientSecret}
                                onKeyUp={(event) => this.handleChange(event)}

                            />
                        </InputGroup>)}
                        <p className='form-error-message margin-bottom-0'>{errorInsert.webClientSecret || ''}</p>
                    </div>
                </div>
                <div className='wrap-insert-or-update-zone-add'>

                    {/* <button onClick={this.onAddAll} className='wrap-insert-or-update-zone-add-button mr-2'>
                        <img className='wrap-insert-or-update-zone-add-button-icon' src={IconAdd} />
                        Thêm tất cả
                    </button> */}


                    {/* <button onClick={this.onAddArea} className='wrap-insert-or-update-zone-add-button'>
                        <img className='wrap-insert-or-update-zone-add-button-icon' src={IconAdd} />
                        Thêm
                    </button> */}
                    {/* ) : null
                    } */}
                </div>
                {/* <div className='wrap-insert-or-update-zone-area'>
                    <label className='wrap-insert-or-update-zone-item-label'>Khu vực được chọn</label>
                    <p className='form-error-message  margin-bottom-0'>{errorInsert.zones || ''}</p>
                    {/* <p className='form-error-message'>{errors.zones || ''}</p> */}
                {/* <div className='wrap-insert-or-update-zone-area-list'>
                        {zones?.map(item => {
                            return (
                                <div key={item.id} className='wrap-insert-or-update-zone-area-list-item'>
                                    <p className='wrap-insert-or-update-zone-area-list-item-label'>
                                        {item.provinceName ? item.provinceName : null}</p>
                                    <button onClick={this.onDeleteArea(item.id)} className='wrap-insert-or-update-zone-area-list-item-delete'>
                                        <img className='wrap-insert-or-update-zone-area-list-item-delete-icon' src={IconDelete} />
                                    </button>
                                </div>
                            )
                        })}
                    </div> */}
                {/* </div> */}
                <PopupMessage
                    popupMessage={popupMessage}
                    moduleTitle={'Thông báo'}
                    moduleBody={errMessage}
                    toggleModal={this.toggleModal}
                />

            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        ...state.UserProvinceStore,
        masterServer: state.MasterServerStore,
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionUserProvince, dispatch),
        ...bindActionCreators(actionMasterServer, dispatch)
    }
}
export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(InsertOrUpdate);
