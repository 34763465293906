import React, { Component, useState } from "react";
import { bindActionCreators } from "redux";
import compose from 'recompose/compose';
import { connect } from "react-redux";
import { actionUnit } from "../../../actions/UnitActions";
import { actionUserProvince } from "../../../actions/UserProvinceActions";
import { actionLocationCreators } from '../../../actions/LocationListAction';

import { USERPROVINCE } from "../../../helpers/constant";
import { setAlertContext, openAlertContext } from "../../../helpers/common.js";
import { PLEASE_CHECK_CONNECT, ACCOUNT_CLAIM_FF, ACCOUNT_ID, IS_ADMIN } from "../../../services/Common";
import moment from 'moment';
import HeaderTable from "components/HeaderTable";
import HeadTitleTable from "components/HeadTitleTable";
import Pagination from "components/Pagination";
import classes from './index.module.css';
import './style.css'
import SearchModal from "./SearchModal";
import { LIMIT_ITEM_IN_PAGE, LOADING_TIME } from "../../../helpers/constant";
import WarningPopup from "../../../components/WarningPopup";
import WarningPopupDel from "../../../components/WarningPopupDel";
import UpdatePopup from "../../../components/UpdatePopup";
import { generateStyleTableCol } from '../../../bases/controls/helper';
import '../../../assets/css/global/index.css';
import '../../../assets/css/page/user.css';
import MenuButton from "../../../assets/img/buttons/menu.png";
import InsertOrUpdate from "./InsertOrUpdate";
import PopupMessage from "../../../components/PopupMessage";
import CreateNewPopup from "../../../components/CreateNewPopup";
import { getErrorMessageServer } from "utils/errorMessageServer.js";
import {
  Card,
  Table,
  Container,
  Row,
  Spinner,
  Input,
  ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";

class UserProvince extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      location: [],
      userList: [],
      detail: null,
      update: null,
      create: null,
      delete: null,
      isLoaded: null,
      status: null,
      open: false,
      comfirm: null,
      xem: null,
      company: null,
      message: '',
      headerTitle: USERPROVINCE,
      limit: LIMIT_ITEM_IN_PAGE,
      beginItem: 0,
      endItem: LIMIT_ITEM_IN_PAGE,
      totalElement: 0,
      listLength: 0,
      isShowForEdit: false,
      currentPage: 0,
      filter: {
        "search": "",
        "filter": "",
        "orderBy": "",
        "page": null,
        "limit": null
      },
      deleteItem: null,
      dropdownOpen: false,
      warningPopupModal: false,
      activeCreateSubmit: false,
      errorInsert: {},
      errorUpdate: {},
      currentRow: null,
      dataInsert: {},
    }
  }



  componentWillReceiveProps(nextProp) {
    let { data } = nextProp.userProvince;
    // const { location } = nextProp.location
    const { limit } = this.state;
    if (data !== this.state.data) {
      if (typeof (data) !== 'undefined') {
        if (typeof (data.list) !== 'undefined') {
          if (data.list !== null) {
            let dataFilter = data.list.filter(item => item.userProvinceDtos.length > 0)

            if (typeof (dataFilter) !== 'undefined') {
              dataFilter.map((item, key) => {
                item['index'] = key + 1;
                item['collapse'] = false;
              });

              this.setState({
                data: dataFilter,
                listLength: dataFilter.length,
                totalPage: Math.ceil(dataFilter.length / limit),
                isLoaded: data.isLoading, status: data.status,
                message: PLEASE_CHECK_CONNECT(data.message)
              });
            } else {
              this.setState({
                data: dataFilter,
                isLoaded: data.isLoading,
                status: data.status,
                message: PLEASE_CHECK_CONNECT(data.message)
              });
            }
          }
        }
      }
    }

  }

  toggle = (el, val) => {
    let { data } = this.state;

    data.filter(item => item.userID === val)
      .map(item => item.collapse = !item.collapse);

    this.setState({ data });
  }

  componentWillMount() {
    const { getProvinceList, dataProvince, requestUserListStore } = this.props

    getProvinceList().then(res => {
      if (res.data.status == 200) {
        this.setState({ dataProvince: res.data.data })
      }
    })

    this.fetchSummary(JSON.stringify({
      "search": "",
      "filter": "",
      "orderBy": "",
      "page": null,
      "limit": null
    }));

    // requestUserListStore(JSON.stringify({
    //   "search": "",
    //   "filter": "",
    //   "orderBy": "",
    //   "page": null,
    //   "limit": null
    // })).then(res => {
    //   if (res.data.status == 200) {
    //     this.setState({ userList: res.data.data.users })
    //     console.log(res.data.data.users);
    //   }
    // })

  }

  componentDidUpdate() {
    // This method is called when the route parameters change
    this.closeStatusModal();
  }

  fetchSummary = (data) => {
    const { requestUserProvinceList } = this.props;
    return requestUserProvinceList(data);
  }



  handleDeleteRow = () => {
    const { deleteUserProvince } = this.props;
    let { data, deleteItem } = this.state;


    deleteUserProvince(deleteItem)
      .then(res => {
        if (res.data.status == 200) {
          this.fetchSummary(JSON.stringify({
            "search": "",
            "filter": "",
            "orderBy": "",
            "page": null,
            "limit": null
          }))
        } else {
          this.setState({
            errNoti: res.data.message,
          })
          this.toggleModal('popupMessage')
        }
      })
  }



  closeStatusModal = () => {
    const { status } = this.state;

    if (status || !status) {
      setTimeout(() => {
        this.setState({ status: null, isLoaded: false });
      }, LOADING_TIME);
    }
  }




  toggleModal = (state, type) => {
    if (this.state[state] && type == 1) {
      return;
    } else {
      this.setState({
        [state]: !this.state[state],
        detail: null,
        errorUpdate: {},
        errorInsert: {},
      });
    }
  };

  clearFilter = () => {
    const { filter } = this.state;
    let clearFilter = {
      "search": "",
      "filter": "",
      "orderBy": "",
      "page": null,
      "limit": null
    }
    this.setState({ filter: clearFilter })
  }


  handlePageClick = (data) => {
    let { limit, beginItem, endItem } = this.state;
    let selected = data.selected;
    let offset = Math.ceil(selected * limit);
    let total = 0;

    beginItem = offset;
    endItem = offset + limit;
    this.state.data.map((item, key) => (
      key >= beginItem && key < endItem && total++
    ));

    if (selected > 0) {
      total = (selected * limit) + total;
    } else total = total;

    this.setState({ beginItem: beginItem, endItem: endItem, currentPage: selected + 1, totalElement: total });
  };

  handleChangeFilter = (event) => {
    let { filter } = this.state;
    const ev = event.target;

    filter[ev['name']] = ev['value'];

    this.setState({ filter });
  }

  handleSubmitSearchForm = () => {
    const { filter } = this.state;
    this.clearFilter();
    this.fetchSummary(JSON.stringify(filter));
  }
  onHandleChangeValue = data => {
    this.setState(previousState => {
      return {
        ...previousState,
        dataInsert: data
      }
    }, () => {

      this.setState(previousState => {
        const errorInsert = this.checkDataInsert();
        return {
          ...previousState,
          errorInsert
        }
      });
    });
  }
  // s
  checkDataInsert = isCheck => {
    if (!isCheck) {
      return {};
    }

    const { dataInsert, data, editId, currentRow } = this.state;
    const userId = dataInsert.userId;
    const zones = (dataInsert || {}).zones || [];

    const errorInsert = {};

    if (!userId) {
      errorInsert.userId = 'Người dùng không được bỏ trống';
    }

    if (zones.length <= 0) {
      errorInsert.zones = 'Chưa chọn khu vực';
    }

    return errorInsert;
  }

  handleModal = (stutus, openModal, closeModal) => {
    if (stutus || this.state.isShowForEdit) {
      closeModal();
    } else {
      openModal();
    }

    this.setState(previousState => {
      return {
        ...previousState,
        isShowForEdit: false,
        editId: null
      }
    });
  }

  onConfirm = (toggleModal, closePopup) => {
    const { dataInsert, editId } = this.state;
    const { requestUserProvinceList, requestUserListStore, getProvinceList } = this.props
    const userId = dataInsert.userId;
    const zones = (dataInsert || {}).zones;
    const id = editId;
    let zonesId = [];

    if (zones) {
      zones.map(item => {
        zonesId.push(item.provinceId)
      }
      )
    }

    const errorInsert = this.checkDataInsert(true);

    this.setState(previousState => {
      return {
        ...previousState,
        errorInsert
      }
    });

    if (Object.keys(errorInsert).length > 0) {
      return;
    }

    if (id) {

      this.props.updateUserProvinceList(userId, zonesId).then(res => {
        const data = (res || {}).data || {};
        if (data.status == 200) {
          this.fetchSummary(JSON.stringify({
            "search": "",
            "filter": "",
            "orderBy": "",
            "page": null,
            "limit": null
          }))
          getProvinceList().then(res => {
            if (res.data.status == 200) {
              this.setState({ dataProvince: res.data.data })
            }
          })

          if (toggleModal) {
            toggleModal();
          }

          this.setState(previousState => {
            return {
              ...previousState,
              isShowForEdit: false,
              editId: null,
              message: 'Sửa vùng quản lý thành công'
            }
          });
        } else {
          const message = getErrorMessageServer(res);

          this.setState({ errNoti: data.message || 'Sửa vùng quản lý thất bại' });

          this.toggleModal('popupMessage');
        }
      });
    } else {
      this.props.createUserProvinceList(userId, zonesId).then(res => {
        const data = (res || {}).data || {};
        if (data.status == 200) {
          getProvinceList().then(res => {
            if (res.data.status == 200) {
              this.setState({ dataProvince: res.data.data })
            }
          })

          this.fetchSummary(JSON.stringify({
            "search": "",
            "filter": "",
            "orderBy": "",
            "page": null,
            "limit": null
          })).then(() => {
            if (toggleModal) {
              toggleModal();
            }

            if (closePopup != 'closePopup') {
              this.setState({ message: 'Thêm vùng quản lý thành công' });

              const timeOut = setTimeout(() => {
                const buttonSaveAndInsert = document.getElementById('header-table-insert');

                if (buttonSaveAndInsert) {
                  buttonSaveAndInsert.click();
                }

                clearTimeout(timeOut);
              }, 1000);
            }
          });
        } else {
          this.setState({ errNoti: data.message || 'Thêm vùng quản lý thất bại' });
          this.toggleModal('popupMessage');
        }

      });

    }
  }

  onEdit = (id, item) => () => {

    this.setState(previousState => {
      return {
        ...previousState,
        isShowForEdit: true,
        editId: id,
        currentRow: item,
      }
    });

  }

  render() {

    const { hookClass, hookSpacing, hookPagination, hideTitle } = this.props;
    const {
      isLoaded,
      status,
      message,
      data,
      detail,
      userList,
      headerTitle,
      beginItem,
      endItem,
      listLength,
      totalPage,
      totalElement,
      filter,
      warningPopupDelModal,
      activeCreateSubmit,
      popupMessage,
      errNoti,
      createNewModal,
      isShowForEdit,
      errorInsert,
      editId
    } = this.state;

    const statusPopup = { status: status, message: message };
    let isDisableAdd = true;
    let isDisableEdit = true;
    let isDisableDelete = true;
    if (IS_ADMIN) {
      isDisableAdd = false;
      isDisableEdit = false;
      isDisableDelete = false;
    } else {
      ACCOUNT_CLAIM_FF.filter(x => x == "RoleZones.Add").map(y => isDisableAdd = false)
      ACCOUNT_CLAIM_FF.filter(x => x == "RoleZones.Edit").map(y => isDisableEdit = false)
      ACCOUNT_CLAIM_FF.filter(x => x == "RoleZones.Delete").map(y => isDisableDelete = false)
    }

    return (
      <>
        {
          <div className={`${classes.wrapper} ${typeof (hookClass) !== 'undefined' && hookClass}`}>
            <Container fluid className={typeof (hookSpacing) !== 'undefined' && hookSpacing}>
              {
                isLoaded ? (
                  <div style={{ display: 'table', margin: 'auto' }}>
                    <Spinner style={{ width: '3rem', height: '3rem' }} />
                  </div>
                ) : (
                  <Row>
                    <div className="col">
                      {/* Header */}
                      <HeaderTable
                        hideSearch={true}
                        dataReload={() => this.fetchSummary(
                          JSON.stringify({
                            "search": "",
                            "filter": "",
                            "orderBy": "",
                            "page": null,
                            "limit": null
                          }))}
                        hideCreate={isDisableAdd == false ? false : true}
                        hideTitle={typeof (hideTitle) !== 'undefined' && hideTitle}
                        searchForm={
                          <SearchModal
                            filter={filter}
                            handleChangeFilter={this.handleChangeFilter}
                          />
                        }
                        handleSubmitSearchForm={() => this.handleSubmitSearchForm()}

                        moduleTitle={isShowForEdit ? 'Sửa vùng quản lý' : 'Thêm vùng quản lý'}
                        moduleBody={
                          <InsertOrUpdate
                            data={this.state.data}
                            userList={userList}
                            id={editId}
                            dataProvince={this.state.dataProvince}
                            errorInsert={errorInsert}
                            onHandleChangeValue={this.onHandleChangeValue}
                          />}
                        isShowForEdit={isShowForEdit}
                        activeSubmit={activeCreateSubmit}
                        onConfirm={this.onConfirm}
                        handleModal={this.handleModal}
                        handleCreateInfoData={this.handleCreateInfoData}
                      />

                      {/* Table */}
                      <Card className="shadow">
                        <Table className="align-items-center tablecs table-css-user-province" responsive>
                          <HeadTitleTable headerTitle={headerTitle}
                            classHeaderColumns={{
                              0: 'table-scale-col table-user-col-1'
                            }}
                          />
                          <tbody ref={ref => this.tableBody = ref}>
                            {
                              Array.isArray(data) && (
                                data
                                  .filter((item, key) => key >= beginItem && key < endItem)
                                  .map((item, key) => {
                                    // if (item?.userProvinceDtos?.length > 0) {
                                    return <tr key={key} style={{ ...generateStyleTableCol(this.tableBody, (data || []).length) }} className="table-hover-css">
                                      <td className='table-scale-col table-user-col-1'>{item.index}</td>
                                      <td style={{ textAlign: 'left' }} className='table-scale-col'>{item.userName}</td>
                                      <td style={{ textAlign: 'left' }} className='table-scale-col'>
                                        {Array.isArray(item.userProvinceDtos) && (
                                          item.userProvinceDtos.map((item2, key) => (
                                            <><span key={key}>{item2.provinceName}</span><br /></>
                                          )))}
                                      </td>
                                      <td>
                                        {isDisableEdit == true && isDisableDelete == true ? null : (
                                          <ButtonDropdown isOpen={item.collapse} toggle={() => this.toggle(key, item.userID)}>
                                            <DropdownToggle>
                                              <img src={MenuButton} />
                                            </DropdownToggle>
                                            <DropdownMenu>
                                              {isDisableEdit == false ? (
                                                <DropdownItem
                                                  onClick={
                                                    // () => {
                                                    this.onEdit(item.userID, item)

                                                    // this.setState({ currentRow: item })
                                                    // }
                                                  }
                                                >
                                                  Sửa
                                                </DropdownItem>
                                              ) : null}
                                              {isDisableEdit == true || isDisableDelete == true ? null : (
                                                <DropdownItem divider />
                                              )
                                              }
                                              {isDisableDelete == false ? (
                                                <DropdownItem

                                                  onClick={() => {
                                                    this.toggleModal('warningPopupDelModal');
                                                    this.setState({ deleteItem: item.userID });
                                                  }}
                                                >
                                                  Xóa
                                                </DropdownItem>
                                              ) : null}
                                            </DropdownMenu>
                                          </ButtonDropdown>
                                        )}
                                      </td>
                                    </tr>
                                    // }

                                  })
                              )
                            }
                          </tbody>
                        </Table>
                      </Card>
                      {
                        // Page of Table
                        Array.isArray(data) && (
                          data.length > 0 && (
                            <Pagination
                              data={data}
                              listLength={listLength}
                              totalPage={totalPage}
                              totalElement={totalElement}
                              handlePageClick={this.handlePageClick}
                            />
                          )
                        )
                      }
                    </div>
                  </Row>
                )
              }
              <PopupMessage
                popupMessage={popupMessage}
                moduleTitle={'Thông báo'}
                moduleBody={errNoti}
                toggleModal={this.toggleModal}
              />

              <WarningPopupDel
                moduleTitle='Thông báo'
                moduleBody={
                  <p style={{ textAlign: 'center', fontSize: '1.2rem' }}>
                    Bạn đồng ý xóa thông tin này?
                  </p>}
                warningPopupDelModal={warningPopupDelModal}
                toggleModal={this.toggleModal}
                handleWarning={this.handleDeleteRow}
              />

              {/* <CreateNewPopup
                createNewModal={createNewModal}
                moduleTitle='Thêm vùng quản lý'
                type100={true}
                moduleBody={
                  <InsertOrUpdate
                    id={editId}
                    errorInsert={errorInsert}
                    onHandleChangeValue={this.onHandleChangeValue}
                  />}
                data={this.state.data}
                toggleModal={this.toggleModal}
                activeSubmit={activeCreateSubmit}
                onConfirm={(data, close) => {
                  this.onConfirm(data, close);
                }}
              /> */}
              <CreateNewPopup
                createNewModal={createNewModal}
                moduleTitle='Thêm vùng quản lý'
                type100={true}
                moduleBody={
                  <InsertOrUpdate
                    id={editId}
                    errorInsert={errorInsert}
                    onHandleChangeValue={this.onHandleChangeValue}
                  />}
                data={this.state.data}
                toggleModal={this.toggleModal}
                activeSubmit={activeCreateSubmit}
                onConfirm={(data, close) => {
                  this.onConfirm(data, close);
                }}
              />

              {
                //Set Alert Context
                setAlertContext(statusPopup)
              }

              {
                //Open Alert Context
                openAlertContext(statusPopup)
              }
            </Container>
          </div>
        }
      </>

    )
  }

}
const mapStateToProps = (state) => {
  return {
    userProvince: state.UserProvinceStore,
    location: state.LocationStore,

  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(actionUserProvince, dispatch),
    ...bindActionCreators(actionLocationCreators, dispatch),

  }
}
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UserProvince);