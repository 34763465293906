import React, { Component } from "react";
import Select from "components/Select";
import classes from './index.module.css';
import Validate from "react-validate-form";
import { rules, validations } from "../../helpers/validation";
import moment from 'moment';
import { handleCurrencyFormat } from "../../helpers/common";
import { DOMAIN_IMG } from "../../services/Common";
import 'react-slideshow-image/dist/styles.css'
import { EXTENSION_FILE_IMAGE } from "../../bases/helper";
import { Zoom } from 'react-slideshow-image';
import HeadTitleTable from "components/HeadTitleTable";
import { FILE_UPLOAD } from "../../helpers/constant";
import { generateStyleTableCol } from '../../bases/controls/helper';
import NoImg from "../../assets/img/NoImg/NoImg.jpg"
// import ReactFancyBox from 'react-fancybox'
import wIcon from "../../assets/img/icons/common/word.png"
import xIcon from "../../assets/img/icons/common/excel.png"
import pIcon from "../../assets/img/icons/common/pdf-file.png"


// reactstrap components
import {
  Input,
  InputGroup,
  Table
} from "reactstrap";

class ViewModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      newData: {
        "id": '',
        "year": '',
      },
      activeSubmit: false,
      selectRow: 0,
      year: null,
      price: null,
      cutLocation: null,
      pathImageDefaul: null,
      fileUpdate: [],
      headerTitle: FILE_UPLOAD,
    }
  }

  componentDidMount() {
    const { data, price, fileUpdate } = this.props;
    if (data.images) {
      const pIm = data.images;
      const spl = pIm.split(';')
      this.setState(previousState => {
        return {
          ...previousState,
          pathImageDefaul: spl
        }
      })
    }
    if (fileUpdate) {
      this.setState({ fileUpdate })
    }
    this.setState({ data, price, cutLocation: data.location, });
    this.handleCheckValidation();
  }

  handleChange = (event) => {
    let { data, } = this.state;
    const ev = event.target;

    if (ev['name'].indexOf('passwordConfirm') > -1) {
      this.setState({ [ev['name']]: ev['value'] });
    }
    else data[ev['name']] = ev['value'];

    this.setState({ data });

    // Check Validation 
    this.handleCheckValidation();
  }

  handleSelect = (value, name) => {
    let { data, price } = this.state;

    if (value === null) value = "";

    data[name] = value;

    this.setState({ data });

    // Check Validation 
    this.handleCheckValidation();
  }
  handleSelectPrice = (value, name) => {
    const { price, data } = this.state;
    let current = null;

    if (value === null) value = "";


    this.handleCheckValidation(value);
  }

  handleCheckValidation = (newData = null) => {
    const { handleCheckValidation, handleNewData } = this.props;
    let { data } = this.state;
    let current = null;
    if (data === null) {
      this.setState({ activeSubmit: false });
      //handleCheckValidation(false);
    }
    else {

      if (newData !== null) {
        this.setState({ activeSubmit: true });

        // Check Validation 
        handleCheckValidation(true);

        // Handle New Data
        this.setState({ selectRow: current.amount })

        handleNewData(current);
      } else {

        handleCheckValidation(false);
      }
    }
  }

  handleClear = () => {
    this.setState({
      data: null,
      activeSubmit: false,

    })
  }

  render() {
    const { data, selectRow, cutLocation, pathImageDefaul, fileUpdate, headerTitle } = this.state;

    // let listAttachments = [];
    // let listViewAttachmentsImg = [];
    // let listViewAttachmentsFile = [];

    // if (fileUpdate) {
    //     fileUpdate.map(item => (
    //         listAttachments = listAttachments.concat(item.attachments.split(';'))
    //     ))
    // }
    // console.log(listAttachments)

    // if (listAttachments) {
    //     listAttachments.map(item => (

    //     ))
    // }

    // if (listAttachments) {
    //     EXTENSION_FILE_IMAGE.filter(x => {
    //         listAttachments.filter(y =>
    //             y.split('.').pop().trim() == x
    //         ).map(it => {
    //             if (it) {
    //                 listAttachments.push(it + '.img');
    //             }
    //             var index = listAttachments.indexOf(it);
    //             if (index > -1) {
    //                 listAttachments.splice(index, 1);
    //             }
    //         })
    //     })
    // }


    // fileUpdate.map(item => (
    //     item.lAtt = listAttachments
    // ))

    // fileUpdate.map(item => {
    //     if (item.lAtt) {
    //         item.lAtt.filter(x =>
    //             x.split('.').pop().trim() == "img"
    //         ).map(thImg => {
    //             listViewAttachmentsImg.push(thImg.replace('.img', ''))
    //         })
    //     }
    // })

    // fileUpdate.map(item => {
    //     if (item.lAtt) {
    //         item.lAtt.filter(x =>
    //             x.split('.').pop().trim() != "img"
    //         ).map(thImg => {
    //             listViewAttachmentsFile.push(thImg.replace('.img', ''))
    //         })
    //     }
    // })

    // if (fileUpdate) {
    //     console.log(fileUpdate[0].lAtt[0].split('.').pop().trim())
    //     fileUpdate
    //     listViewAttachmentsImg = fileUpdate.filter(x=>{
    //         EXTENSION_FILE_IMAGE.filter()
    //     })
    // }


    return (
      data !== null && (
        <>
          <div className={classes.formControl} style={{ border: '1px solid #000' }}>
            <div style={{
              alignSelf: 'start',
              lineHeight: '0.1em',
              marginLeft: 30,

            }}>
              <span style={{ background: '#fff', padding: '0 5px', fontWeight: 'bold', fontSize: 17, color: '#09b2fd' }}>THÔNG TIN CHUNG</span>
            </div>
            <div className={`${classes.rowItem} css-margin-company-await`}>
              <div>
                <label
                  className="form-control-label col"
                >
                  Tên doanh nghiệp/Hợp tác xã:
                </label>
              </div>
              <div style={{ width: '100%' }}>
                <div className={classes.inputArea} >
                  <Input
                    style={{

                      background: 'transparent',
                      borderWidth: 0,
                      color: '#000'
                    }}
                    type="text"
                    defaultValue={data.companyName}
                    readOnly
                    onKeyUp={(event) => this.handleChange(event)}
                  />

                </div>
              </div>
            </div>
            <div className={`${classes.rowItem} css-margin-company-await`}>
              <div>
                <label
                  className="form-control-label col"
                >
                  Ngành nghề đăng ký:
                </label>
              </div>
              <div style={{ width: '100%' }}>
                <div className={classes.inputArea} >

                  <Input
                    style={{

                      background: 'transparent',
                      borderWidth: 0,
                      color: '#000'
                    }}
                    type="text"
                    defaultValue={data.fieldName}
                    readOnly
                    onKeyUp={(event) => this.handleChange(event)}
                  />

                </div>
              </div>
            </div>
            <div className={`${classes.rowItem} css-margin-company-await`}>
              <div>
                <label
                  className="form-control-label col"
                >
                  Mã số thuế/CCCD/CMND:
                </label>
              </div>
              <div style={{ width: '100%' }}>
                <div className={classes.inputArea} >
                  {/* <InputGroup className="input-group-alternative"> */}
                  <Input
                    style={{

                      background: 'transparent',
                      borderWidth: 0,
                      color: '#000'
                    }}
                    type="text"
                    defaultValue={data.taxCode}
                    readOnly
                    onKeyUp={(event) => this.handleChange(event)}
                  />
                  {/* </InputGroup> */}
                </div>
              </div>
            </div>
            <div className={`${classes.rowItem} css-margin-company-await`}>
              <div>
                <label
                  className="form-control-label col"
                >
                  Địa chỉ:
                </label>
              </div>
              <div style={{ width: '100%' }}>
                <div className={classes.inputArea} >
                  {/* <InputGroup className="input-group-alternative"> */}
                  <Input
                    style={{

                      background: 'transparent',
                      borderWidth: 0,
                      color: '#000'
                    }}
                    type="text"
                    defaultValue={
                      data.address + ', ' + data.wardName + ', ' + data.districtName + ', ' + data.pRovinceName
                    }
                    readOnly
                    onKeyUp={(event) => this.handleChange(event)}
                  />
                  {/* </InputGroup> */}
                </div>
              </div>
            </div>
            <div className={`${classes.rowItem} css-margin-company-await`}>
              <div>
                <label
                  className="form-control-label col"
                >
                  Điện thoại:
                </label>
              </div>
              <div style={{ width: '100%' }}>
                <div className={classes.inputArea} >
                  {/* <InputGroup className="input-group-alternative"> */}
                  <Input
                    style={{

                      background: 'transparent',
                      borderWidth: 0,
                      color: '#000'
                    }}
                    type="text"
                    defaultValue={data.phoneNumber}
                    readOnly
                    onKeyUp={(event) => this.handleChange(event)}
                  />
                  {/* </InputGroup> */}
                </div>
              </div>
            </div>
            <div className={`${classes.rowItem} css-margin-company-await`} style={{ marginBottom: 5 }}>
              <div>
                <label
                  className="form-control-label col"
                >
                  Email:
                </label>
              </div>
              <div style={{ width: '100%' }}>
                <div className={classes.inputArea} >
                  {/* <InputGroup className="input-group-alternative"> */}
                  <Input
                    style={{

                      background: 'transparent',
                      borderWidth: 0,
                      color: '#000'
                    }}
                    type="text"
                    defaultValue={data.email}
                    readOnly
                    onKeyUp={(event) => this.handleChange(event)}
                  />
                  {/* </InputGroup> */}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.formControl} style={{ marginTop: 20, border: '1px solid #000' }}>
            <div style={{
              alignSelf: 'start',
              lineHeight: '0.1em',
              marginLeft: 30,

            }}>
              <span style={{ background: '#fff', padding: '0 5px', fontWeight: 'bold', fontSize: 17, color: '#09b2fd' }}>HỒ SƠ ĐÍNH KÈM</span>
            </div>
            <div className={`${classes.rowItem} css-margin-company-await `}>
              <div>
                <label
                  className="form-control-label col"
                >
                  Số năm đăng ký:
                </label>
              </div>
              <div style={{ width: '100%' }}>
                <div className={classes.inputArea} >
                  <Input
                    style={{

                      background: 'transparent',
                      borderWidth: 0,
                      color: '#000'
                    }}
                    type="text"
                    defaultValue={data.yearNumber}
                    readOnly
                    onKeyUp={(event) => this.handleChange(event)}
                  />

                </div>
              </div>
            </div>
            <div className={`${classes.rowItem} css-margin-company-await`}>
              <div>
                <label
                  className="form-control-label col"
                >
                  Số tiền phải thanh toán:
                </label>
              </div>
              <div style={{ width: '100%' }}>
                <div className={classes.inputArea} >
                  <Input
                    style={{

                      background: 'transparent',
                      borderWidth: 0,
                      color: '#000'
                    }}
                    type="text"
                    defaultValue={handleCurrencyFormat(data.amount) + ' đồng'}
                    readOnly
                    onKeyUp={(event) => this.handleChange(event)}
                  />

                </div>
              </div>
            </div>
            <div className={`${classes.rowItem} css-margin-company-await`}>
              <div style={{ width: '100%' }}>
                <label
                  className="form-control-label col"
                >
                  Hồ sơ đính kèm bao gồm:
                </label>
                <div style={{ width: '100%', margin: 10 }}>
                  <div className={classes.inputArea} >
                    <Input
                      style={{
                        fontWeight: 'bold',
                        background: 'transparent',
                        borderWidth: 0,
                        color: '#000'
                      }}
                      type="textarea"
                      defaultValue={data.attachments}
                      readOnly
                      rows={3}
                    />
                  </div>
                </div>
              </div>

            </div>
            <div className={`${classes.rowItem} css-margin-company-await`} style={{ marginBottom: 5, marginTop: 5 }}>
              <div style={{ width: '100%' }}>
                <label
                  className="form-control-label col"
                >
                  Tập tin đính kèm:
                </label>
                <div style={{ width: '100%' }}>
                  {fileUpdate &&
                    <div className={classes.inputArea} style={{ padding: '5px 15px' }}>
                      {
                        Array.isArray(fileUpdate) && (
                          fileUpdate.map((item, key) => {
                            let listAttachments = (item.attachments || '').split(';').filter(p => p);
                            let listViewAttachmentsImg = [];
                            let listViewAttachmentsFile = [];

                            listAttachments.map(item => {
                              if (EXTENSION_FILE_IMAGE.find(p => p == item.split('.').pop())) {
                                listViewAttachmentsImg.push(item);
                              } else {
                                listViewAttachmentsFile.push(item);
                              }
                            });
                            return <div key={key}>
                              <div style={{ textAlign: 'left' }} className='table-scale-col'>
                                {listViewAttachmentsImg ?
                                  (
                                    Array.isArray(listViewAttachmentsImg) && (
                                      listViewAttachmentsImg.map((lst, key) => {
                                        // <a href={lst} target='_blank'><img key={key} width={70} height={70} src={lst} style={{ marginRight: '3px' }} /></a>
                                        return (
                                          <>
                                            {lst ? (
                                              <div id='CommAwaitView' style={{ marginBottom: 5, cursor: 'pointer' }}>
                                                {/* <ReactFancyBox
                                                  image={lst}
                                                  thumbnail={lst}
                                                /> */}
                                                <a href={lst} target='_blank'><img width={150} height={150} src={lst} /></a>
                                              </div>
                                            ) : <img width={150} height={150} src={NoImg} />}
                                          </>
                                        )
                                      }
                                      )
                                    )
                                  )
                                  : null
                                }
                                {listViewAttachmentsFile ?
                                  (
                                    Array.isArray(listViewAttachmentsFile) && (
                                      listViewAttachmentsFile.map((lst, key) => (
                                        <>
                                          <a key={key} target='_blank' href={lst}
                                            style={{
                                              marginRight: '3px',
                                              width: '500px',
                                              display: 'block',
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                              marginBottom: 5
                                            }}>
                                            {lst.split('.').pop() == 'docx' || lst.split('.').pop() == 'doc' ? (
                                              <img src={wIcon} width={25} height={25} />) : null}
                                            {lst.split('.').pop() == 'xlsx' || lst.split('.').pop() == 'xls' ? (
                                              <img src={xIcon} width={25} height={25} />) : null}
                                            {lst.split('.').pop() == 'pdf' ? (
                                              <img src={pIcon} width={25} height={25} />) : null}
                                            &ensp;{lst.split("/").pop()}
                                          </a>
                                        </>
                                      ))
                                    )
                                  )
                                  : null
                                }
                              </div>
                            </div>
                          })
                        )
                      }
                    </div>
                  }
                </div>
              </div>

            </div>
          </div>

        </>
      )
    );
  }
};

export default ViewModal;
