import React, { Component } from "react";
import compose from 'recompose/compose';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../../../../actions/UserListActions.js";
import { actionRoleCreators } from "../../../../actions/RoleListActions.js";
import { PLEASE_CHECK_CONNECT } from "../../../../services/Common";
import { LIMIT_ITEM_IN_PAGE, LOADING_TIME } from "../../../../helpers/constant";
import NoImg from '../../../../assets/img/NoImg/NoImg.jpg'
import { Spinner, Row } from 'reactstrap';
import { NavLink } from 'react-router-dom';

class ListUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: null,
            status: null,
            limit: 10,
        }
    }
    componentWillReceiveProps(nextProp) {
        const { requestUserListStore } = this.props;
        let { data } = nextProp.account;
        const { role } = nextProp;
        const { limit } = this.state;
        let roleData = null;

        if (role !== this.state.role) {
            if (typeof (role) !== 'undefined') {
                if (typeof (role.data) !== 'undefined') {
                    roleData = role.data;

                    if (typeof (roleData.roles) !== 'undefined') {
                        this.setState({ roles: roleData.roles.roles, isLoaded: roleData.isLoading, status: roleData.status, message: PLEASE_CHECK_CONNECT(roleData.message) });
                    }
                }
            }
        }

        if (data !== this.state.data) {
            if (typeof (data) !== 'undefined') {
                if (typeof (data.data) !== 'undefined') {
                    if (data.data !== null) {
                        if (typeof (data.data.users) !== 'undefined') {
                            data.data.users.map((item, key) => {
                                item['index'] = key + 1
                                item['collapse'] = false
                            });

                            this.setState({ data: data.data.users, listLength: data.data.total, totalPage: Math.ceil(data.data.users.length / limit), isLoaded: data.isLoading, status: data.status, message: PLEASE_CHECK_CONNECT(data.message) });
                        }
                    }
                }

                // Set State - After Open Detail
                if (typeof (data.detail) !== 'undefined') {
                    this.setState({
                        detail: data.detail,
                        dfPasswordHash: data.detail.PasswordHash,
                        isLoaded: false,
                        status: data.status,
                        message: PLEASE_CHECK_CONNECT(data.message)
                    });
                }
            }
        }
    }
    componentWillMount() {
        /* Fetch Summary */
        this.fetchSummary(JSON.stringify({ "status": 1, "roleIDs": "", "userName": "", "fullName": "", "phone": "", "email": "", "position": "", "orderBy": "", "page": null, "limit": null }));
    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        this.closeStatusModal();
    }

    fetchSummary = (data) => {
        const { requestUserListStore, getAllRoleList } = this.props;

        requestUserListStore(data);
        getAllRoleList(data);
    }

    closeStatusModal = () => {
        const { status } = this.state;

        if (status || !status) {
            setTimeout(() => {
                this.setState({ status: null, isLoaded: false });
            }, LOADING_TIME);
        }
    }
    render() {
        const { data, isLoaded } = this.state
        // console.log(data);
        return (
            <>
                <div className='header-box-list box-list-border'>
                    <h4>Danh sách quản trị </h4>
                </div>
                {
                    isLoaded ? (<div style={{ display: 'table', margin: 'auto' }}>
                        <Spinner style={{ width: '3rem', height: '3rem' }} />
                    </div>) : (
                        <div className='body-box-list'>
                            {
                                Array.isArray(data) && (
                                    data.map((item, key) => (
                                        <div className=' mrg-10 mr-bottom'>
                                            <Row key={key} className=" mrg-10">
                                                <div className='col-3 '>
                                                    <img src={item.avatar ? item.avatar : NoImg} alt="..." style={{ width: 60, height: 60, borderRadius: '50%' }} />
                                                </div>
                                                <div className='col-9'>
                                                    <div className='box-text'>
                                                        <span className='info-box-text' style={{ fontWeight: 600 }}>{item.fullName}</span>
                                                        <span className='info-box-text'><i>{item.roleName}</i></span>
                                                    </div>

                                                </div>
                                            </Row>
                                        </div>
                                    ))
                                )
                            }
                        </div>
                    )
                }
                <div className='footer-box-list'>
                    <NavLink to='/trang_chu/tai_khoan_nguoi_dung'>Xem thêm</NavLink>
                </div>

            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        account: state.UserListStore,
        role: state.RoleStore
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionRoleCreators, dispatch),
        ...bindActionCreators(actionCreators, dispatch)
    }
}


export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ListUser)