import {
  FIELD_LIST,
  FIELD_LIST_COMPANYAWAIT,
  FIELD_LIST_COMPANYNOTCOMFIRM,
  FIELD_LIST_COMPANYAWAITEXTEND,
  FIELD_LIST_COMPANYEXPIRING,
  FIELD_LIST_COMPANYLOCK,
  FIELD_LIST_COMPANYREQUESTEXTEND,
  FIELD_LIST_COMPANYCOMFIRMED,
  FIELD_LIST_COMPANYDELETED,
  FIELD_CREATE,
  FIELD_UPDATE,
  FIELD_DELETE,
  FIELD_LOCK,
  FIELD_BY_COMPANY_FIELD_LIST,
} from "../apis";
import {
  post, del, get
} from "../services/Dataservice";
import {
  SUCCESS_CODE,
  GET_FIELD_TYPE,
  GET_FIELD_SUCCESS_TYPE,
  GET_FIELD_FAIL_TYPE,
  GET_FIELD_COMPANYAWAIT_TYPE,
  GET_FIELD_COMPANYAWAIT_FAIL_TYPE,
  GET_FIELD_COMPANYAWAIT_SUCCESS_TYPE,
  GET_FIELD_COMPANYNOTCOMFIRM_TYPE,
  GET_FIELD_COMPANYNOTCOMFIRM_SUCCESS_TYPE,
  GET_FIELD_COMPANYNOTCOMFIRM_FAIL_TYPE,
  GET_FIELD_COMPANYAWAITEXTEND_TYPE,
  GET_FIELD_COMPANYAWAITEXTEND_SUCCESS_TYPE,
  GET_FIELD_COMPANYAWAITEXTEND_FAIL_TYPE,
  GET_FIELD_COMPANYEXPIRING_TYPE,
  GET_FIELD_COMPANYEXPIRING_SUCCESS_TYPE,
  GET_FIELD_COMPANYEXPIRING_FAIL_TYPE,
  GET_FIELD_COMPANYLOCK_TYPE,
  GET_FIELD_COMPANYLOCK_SUCCESS_TYPE,
  GET_FIELD_COMPANYLOCK_FAIL_TYPE,
  GET_FIELD_COMPANYREQUESTEXTEND_TYPE,
  GET_FIELD_COMPANYREQUESTEXTEND_SUCCESS_TYPE,
  GET_FIELD_COMPANYREQUESTEXTEND_FAIL_TYPE,
  GET_FIELD_COMPANYCOMFIRMED_TYPE,
  GET_FIELD_COMPANYCOMFIRMED_SUCCESS_TYPE,
  GET_FIELD_COMPANYCOMFIRMED_FAIL_TYPE,
  GET_FIELD_COMPANYDELETED_TYPE,
  GET_FIELD_COMPANYDELETED_SUCCESS_TYPE,
  GET_FIELD_COMPANYDELETED_FAIL_TYPE,
  GET_CREATE_FIELD_TYPE,
  GET_CREATE_FIELD_SUCCESS_TYPE,
  GET_CREATE_FIELD_FAIL_TYPE,
  GET_UPDATE_FIELD_TYPE,
  GET_UPDATE_FIELD_SUCCESS_TYPE,
  GET_UPDATE_FIELD_FAIL_TYPE,
  GET_DELETE_FIELD_TYPE,
  GET_DELETE_FIELD_SUCCESS_TYPE,
  GET_DELETE_FIELD_FAIL_TYPE,
  GET_FIELD_SELECT_TYPE,
  GET_FIELD_SELECT_SUCCESS_TYPE,
  GET_FIELD_SELECT_FAIL_TYPE,
  FIELD_LOCK_TYPE,
  FIELD_LOCK_SUCCESS_TYPE,
  FIELD_LOCK_FAIL_TYPE,
} from "../services/Common";

const initialState = { data: [], isLoading: false, status: false, message: '' };

export const actionField = {
  requestFieldStore: (data) => async (dispatch, getState) => {
    const dataJSON = JSON.parse(data);

    const isScrollInfiniting = dataJSON.isScrollInfiniting;

    if (!isScrollInfiniting) {
      dispatch({
        type: GET_FIELD_TYPE, data: initialState
      });
    }

    return new Promise(async resolve => {
      if (!isScrollInfiniting) {
        dispatch({
          type: GET_FIELD_TYPE, data: initialState
        });
      }

      await post(FIELD_LIST, data)
        .then(res => {
          if (res.status === SUCCESS_CODE) {
            if (isScrollInfiniting) {
              const _data = {
                fields: ((((getState().FieldStore || {}).data || {}).field || {}).fields || []).concat((res.data || {}).fields || []),
                total: (res.data || {}).total
              }

              dispatch({
                type: GET_FIELD_SUCCESS_TYPE, data: { field: _data, isLoading: false, status: true, message: res.message }
              });
            } else {
              dispatch({
                type: GET_FIELD_SUCCESS_TYPE, data: { field: res.data, isLoading: true, status: true, message: res.message }
              });
            }
          } else {
            dispatch({
              type: GET_FIELD_FAIL_TYPE, data: { field: [], isLoading: true, status: false, message: res.message }
            });
          }
          resolve({
            status: true,
            data: res
          });
        })
        .catch(err => {
          dispatch({ type: GET_FIELD_FAIL_TYPE, data: { field: [], isLoading: true, status: false, message: err.message } });
          resolve({
            status: false,
            error: err
          });
        })
    })
  },

  requestFieldCompanyAwaitStore: (data) => async (dispatch, getState) => {
    return new Promise(async resolve => {
      dispatch({
        type: GET_FIELD_COMPANYAWAIT_TYPE, data: initialState
      });

      await post(FIELD_LIST_COMPANYAWAIT, data)
        .then(res => {
          if (res.status === SUCCESS_CODE) {
            dispatch({
              type: GET_FIELD_COMPANYAWAIT_SUCCESS_TYPE, data: { fieldCompanyAwait: res.data, isLoading: true, status: true, message: res.message }
            });
          } else {
            dispatch({
              type: GET_FIELD_COMPANYAWAIT_FAIL_TYPE, data: { fieldCompanyAwait: [], isLoading: true, status: false, message: res.message }
            });
          }
          resolve({
            status: true,
            data: res
          });
        })
        .catch(err => {
          dispatch({ type: GET_FIELD_COMPANYAWAIT_FAIL_TYPE, data: { fieldCompanyAwait: [], isLoading: true, status: false, message: err.message } });
          resolve({
            status: false,
            error: err
          });
        })
    })
  },

  requestFieldCompanNotComfirmStore: (data) => async (dispatch, getState) => {
    return new Promise(async resolve => {
      dispatch({
        type: GET_FIELD_COMPANYNOTCOMFIRM_TYPE, data: initialState
      });

      await post(FIELD_LIST_COMPANYNOTCOMFIRM, data)
        .then(res => {
          if (res.status === SUCCESS_CODE) {
            dispatch({
              type: GET_FIELD_COMPANYNOTCOMFIRM_SUCCESS_TYPE, data: { fieldCompanyNotComfirm: res.data, isLoading: true, status: true, message: res.message }
            });
          } else {
            dispatch({
              type: GET_FIELD_COMPANYNOTCOMFIRM_FAIL_TYPE, data: { fieldCompanyNotComfirm: [], isLoading: true, status: false, message: res.message }
            });
          }
          resolve({
            status: true,
            data: res
          });
        })
        .catch(err => {
          dispatch({ type: GET_FIELD_COMPANYNOTCOMFIRM_FAIL_TYPE, data: { fieldCompanyNotComfirm: [], isLoading: true, status: false, message: err.message } });
          resolve({
            status: false,
            error: err
          });
        })
    })
  },

  requestFieldCompanyExpiringStore: (data) => async (dispatch, getState) => {
    return new Promise(async resolve => {
      dispatch({
        type: GET_FIELD_COMPANYEXPIRING_TYPE, data: initialState
      });

      await post(FIELD_LIST_COMPANYEXPIRING, data)
        .then(res => {
          if (res.status === SUCCESS_CODE) {
            dispatch({
              type: GET_FIELD_COMPANYEXPIRING_SUCCESS_TYPE, data: { fieldCompanyExpiring: res.data, isLoading: true, status: true, message: res.message }
            });
          } else {
            dispatch({
              type: GET_FIELD_COMPANYEXPIRING_FAIL_TYPE, data: { fieldCompanyExpiring: [], isLoading: true, status: false, message: res.message }
            });
          }
          resolve({
            status: true,
            data: res
          });
        })
        .catch(err => {
          dispatch({ type: GET_FIELD_COMPANYEXPIRING_FAIL_TYPE, data: { fieldCompanyExpiring: [], isLoading: true, status: false, message: err.message } });
          resolve({
            status: false,
            error: err
          });
        })
    })
  },

  requestFieldCompanyAwaitExtendStore: (data) => async (dispatch, getState) => {
    return new Promise(async resolve => {
      dispatch({
        type: GET_FIELD_COMPANYAWAITEXTEND_TYPE, data: initialState
      });

      await post(FIELD_LIST_COMPANYAWAITEXTEND, data)
        .then(res => {
          if (res.status === SUCCESS_CODE) {
            dispatch({
              type: GET_FIELD_COMPANYAWAITEXTEND_SUCCESS_TYPE, data: { fieldCompanyAwaitExtend: res.data, isLoading: true, status: true, message: res.message }
            });
          } else {
            dispatch({
              type: GET_FIELD_COMPANYAWAITEXTEND_FAIL_TYPE, data: { fieldCompanyAwaitExtend: [], isLoading: true, status: false, message: res.message }
            });
          }
          resolve({
            status: true,
            data: res
          });
        })
        .catch(err => {
          dispatch({ type: GET_FIELD_COMPANYAWAITEXTEND_FAIL_TYPE, data: { fieldCompanyAwaitExtend: [], isLoading: true, status: false, message: err.message } });
          resolve({
            status: false,
            error: err
          });
        })
    })
  },

  requestFieldCompanyLockStore: (data) => async (dispatch, getState) => {
    return new Promise(async resolve => {
      dispatch({
        type: GET_FIELD_COMPANYLOCK_TYPE, data: initialState
      });

      await post(FIELD_LIST_COMPANYLOCK, data)
        .then(res => {
          if (res.status === SUCCESS_CODE) {
            dispatch({
              type: GET_FIELD_COMPANYLOCK_SUCCESS_TYPE, data: { fieldCompanyLock: res.data, isLoading: true, status: true, message: res.message }
            });
          } else {
            dispatch({
              type: GET_FIELD_COMPANYLOCK_FAIL_TYPE, data: { fieldCompanyLock: [], isLoading: true, status: false, message: res.message }
            });
          }
          resolve({
            status: true,
            data: res
          });
        })
        .catch(err => {
          dispatch({ type: GET_FIELD_COMPANYLOCK_FAIL_TYPE, data: { fieldCompanyLock: [], isLoading: true, status: false, message: err.message } });
          resolve({
            status: false,
            error: err
          });
        })
    })
  },

  requestFieldCompanyRequestExtendStore: (data) => async (dispatch, getState) => {
    return new Promise(async resolve => {
      dispatch({
        type: GET_FIELD_COMPANYREQUESTEXTEND_TYPE, data: initialState
      });

      await post(FIELD_LIST_COMPANYREQUESTEXTEND, data)
        .then(res => {
          if (res.status === SUCCESS_CODE) {
            dispatch({
              type: GET_FIELD_COMPANYREQUESTEXTEND_SUCCESS_TYPE, data: { fieldCompanyRequestExtend: res.data, isLoading: true, status: true, message: res.message }
            });
          } else {
            dispatch({
              type: GET_FIELD_COMPANYREQUESTEXTEND_FAIL_TYPE, data: { fieldCompanyRequestExtend: [], isLoading: true, status: false, message: res.message }
            });
          }
          resolve({
            status: true,
            data: res
          });
        })
        .catch(err => {
          dispatch({ type: GET_FIELD_COMPANYREQUESTEXTEND_FAIL_TYPE, data: { fieldCompanyRequestExtend: [], isLoading: true, status: false, message: err.message } });
          resolve({
            status: false,
            error: err
          });
        })
    })
  },

  requestFieldCompanyComfirmedStore: (data) => async (dispatch, getState) => {
    return new Promise(async resolve => {
      dispatch({
        type: GET_FIELD_COMPANYCOMFIRMED_TYPE, data: initialState
      });

      await post(FIELD_LIST_COMPANYCOMFIRMED, data)
        .then(res => {
          if (res.status === SUCCESS_CODE) {
            dispatch({
              type: GET_FIELD_COMPANYCOMFIRMED_SUCCESS_TYPE, data: { fieldCompanyComfirmed: res.data, isLoading: true, status: true, message: res.message }
            });
          } else {
            dispatch({
              type: GET_FIELD_COMPANYCOMFIRMED_FAIL_TYPE, data: { fieldCompanyComfirmed: [], isLoading: true, status: false, message: res.message }
            });
          }
          resolve({
            status: true,
            data: res
          });
        })
        .catch(err => {
          dispatch({ type: GET_FIELD_COMPANYCOMFIRMED_FAIL_TYPE, data: { fieldCompanyComfirmed: [], isLoading: true, status: false, message: err.message } });
          resolve({
            status: false,
            error: err
          });
        })
    })
  },

  requestFieldCompanyDeletedStore: (data) => async (dispatch, getState) => {
    return new Promise(async resolve => {
      dispatch({
        type: GET_FIELD_COMPANYDELETED_TYPE, data: initialState
      });

      await post(FIELD_LIST_COMPANYDELETED, data)
        .then(res => {
          if (res.status === SUCCESS_CODE) {
            dispatch({
              type: GET_FIELD_COMPANYDELETED_SUCCESS_TYPE, data: { fieldCompanyDeleted: res.data, isLoading: true, status: true, message: res.message }
            });
          } else {
            dispatch({
              type: GET_FIELD_COMPANYDELETED_FAIL_TYPE, data: { fieldCompanyDeleted: [], isLoading: true, status: false, message: res.message }
            });
          }
          resolve({
            status: true,
            data: res
          });
        })
        .catch(err => {
          dispatch({ type: GET_FIELD_COMPANYDELETED_FAIL_TYPE, data: { fieldCompanyDeleted: [], isLoading: true, status: false, message: err.message } });
          resolve({
            status: false,
            error: err
          });
        })
    })
  },

  requestFieldSSelecttore: (data) => async (dispatch, getState) => {
    return new Promise(async resolve => {
      dispatch({
        type: GET_FIELD_SELECT_TYPE, data: initialState
      });

      await post(FIELD_LIST, data)
        .then(res => {
          if (res.status === SUCCESS_CODE) {
            dispatch({
              type: GET_FIELD_SELECT_SUCCESS_TYPE, data: { fieldSelect: res.data, isLoading: true, status: true, message: res.message }
            });
          } else {
            dispatch({
              type: GET_FIELD_SELECT_FAIL_TYPE, data: { fieldSelect: [], isLoading: true, status: false, message: res.message }
            });
          }
          resolve({
            status: true,
            data: res
          });
        })
        .catch(err => {
          dispatch({ type: GET_FIELD_SELECT_FAIL_TYPE, data: { fieldSelect: [], isLoading: true, status: false, message: err.message } });
          resolve({
            status: false,
            error: err
          });
        })
    })
  },
  createField: (data) => async (dispatch, getState) => {
    return new Promise(async resolve => {
      dispatch({
        type: GET_CREATE_FIELD_TYPE, data: initialState
      });

      await post(FIELD_CREATE, data)
        .then(res => {
          if (res.status === SUCCESS_CODE) {
            dispatch({
              type: GET_CREATE_FIELD_SUCCESS_TYPE, data: { create: res.data, isLoading: true, status: true, message: res.message }
            });
          } else {
            dispatch({
              type: GET_CREATE_FIELD_FAIL_TYPE, data: { create: [], isLoading: true, status: false, message: res.message }
            });
          }
          resolve({
            status: true,
            data: res
          });
        })
        .catch(err => {
          dispatch({ type: GET_CREATE_FIELD_FAIL_TYPE, data: { create: [], isLoading: true, status: false, message: err.message } });
          resolve({
            status: false,
            error: err
          });
        })
    })
  },

  updateField: (data) => async (dispatch, getState) => {
    dispatch({
      type: GET_UPDATE_FIELD_TYPE, data: initialState
    });

    await post(FIELD_UPDATE, data)
      .then(res => {
        if (res.status === SUCCESS_CODE) {
          dispatch({
            type: GET_UPDATE_FIELD_SUCCESS_TYPE, data: { update: res.data, isLoading: true, status: true, message: res.message }
          });
        } else {
          dispatch({
            type: GET_UPDATE_FIELD_FAIL_TYPE, data: { update: [], isLoading: true, status: false, message: res.message }
          });
        }
      })
      .catch(err => {
        dispatch({ type: GET_UPDATE_FIELD_FAIL_TYPE, data: { update: [], isLoading: true, status: false, message: err.message } });
      })
  },

  deleteField: (id) => async (dispatch, getState) => {
    dispatch({
      type: GET_DELETE_FIELD_TYPE, data: initialState
    });

    await del(FIELD_DELETE + id)
      .then(res => {
        if (res.status === SUCCESS_CODE) {
          dispatch({
            type: GET_DELETE_FIELD_SUCCESS_TYPE, data: { delete: res.data, isLoading: true, status: true, message: res.message }
          });
        } else {
          dispatch({
            type: GET_DELETE_FIELD_FAIL_TYPE, data: { delete: [], isLoading: true, status: false, message: res.message }
          });
        }
      })
      .catch(err => {
        dispatch({ type: GET_DELETE_FIELD_FAIL_TYPE, data: { update: [], isLoading: true, status: false, message: err.message } });
      })
  },
  requestLockField: (id) => async (dispatch, getState) => {
    return new Promise(async resolve => {
      dispatch({
        type: FIELD_LOCK_TYPE, data: initialState
      });

      return await get(FIELD_LOCK + id)
        .then(res => {
          if (res.status === SUCCESS_CODE) {
            dispatch({ type: FIELD_LOCK_SUCCESS_TYPE, data: { lock: res.data, isLoading: true, status: true, message: res.message } });
          } else {
            dispatch({ type: FIELD_LOCK_FAIL_TYPE, data: { lock: [], isLoading: true, status: false, message: res.message } });
          }

          resolve({
            status: true,
            data: res
          });
        })
        .catch(err => {
          dispatch({ type: FIELD_LOCK_FAIL_TYPE, data: { lock: [], isLoading: true, status: false, message: err.message } });

          resolve({
            status: false,
            error: err
          });
        })
    })
  },
  requestFieldByCompanyFieldStore: (data) => async (dispatch, getState) => {
    dispatch({
      type: GET_FIELD_TYPE, data: initialState
    });

    return new Promise(async resolve => {
      dispatch({
        type: GET_FIELD_TYPE, data: initialState
      });
      await post(FIELD_BY_COMPANY_FIELD_LIST, data)
        .then(res => {
          if (res.status === SUCCESS_CODE) {
            dispatch({
              type: GET_FIELD_SUCCESS_TYPE, data: { field: res.data, isLoading: true, status: true, message: res.message }
            });
          } else {
            dispatch({
              type: GET_FIELD_FAIL_TYPE, data: { field: [], isLoading: true, status: false, message: res.message }
            });
          }
          resolve({
            status: true,
            data: res
          });
        })
        .catch(err => {
          dispatch({ type: GET_FIELD_FAIL_TYPE, data: { field: [], isLoading: true, status: false, message: err.message } });
          resolve({
            status: false,
            error: err
          });
        })
    })
  },
}