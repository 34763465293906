import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { bindActionCreators } from "redux";
import compose from 'recompose/compose';
import { connect } from "react-redux";
import { PLEASE_CHECK_CONNECT, ACCOUNT_CLAIM_FF, ACCOUNT_ID, IS_ADMIN } from "../../../services/Common";
import { configSystemAction } from "../../../actions/ConfigSystemAction";
import { actionAboutUs } from '../../../actions/AboutUsActions';
import { actionCompanyListRegistered } from "../../../actions/CompanyListRegisteredActions";
import { actionStampPlate } from "../../../actions/StampTemplateActions";
import Select1 from "components/Select";
import '../../../assets/css/page/config_system.css';
import ButtonSave from "../../../assets/img/buttons/btnLuuLocBui.png";
import ButtomAdd from "../../../assets/img/buttons/btnThemMoiLocBui.png";
import ButtonEdit from "../../../assets/img/buttons/edit.svg";
import ButtonDelete from "../../../assets/img/buttons/delete.png";
import InsertOrUpdate from './InsertOrUpdate';
import Loading from '../../../components/loading';
import { getErrorMessageServer } from 'utils/errorMessageServer';
import Message, { TYPES } from '../../../components/message';
import { validEmail } from 'bases/helper';
import { validPhone } from 'bases/helper';
import { numberWithCommas, replaceCommaDot } from 'bases/helper';
import { validExtensionFileImage } from 'bases/helper';
import { validSize, getExtensionFile } from 'bases/helper';
import { MAX_FILE_IMAGE_SIZE } from 'bases/helper';
import { EXTENSION_FILE_IMAGE, EXTENSION_FILE_PDF, EXTENSION_FILE_WORD, EXTENSION_FILE_EXCEL } from 'bases/helper';
import classes from './index.module.css';
import MenuButton from "../../../assets/img/buttons/menu.png";
import moment from 'moment';
import PlusImg from "../../../assets/img/buttons/plus.svg";
import SaveIcon1 from "../../../assets/img/buttons/save.svg";
import Folder from '../../../assets/img/buttons/folder.svg'
import Select from 'react-select';
import { defaultTheme } from 'react-select';
import SelectSearch, { fuzzySearch } from "react-select-search";
import { generateStyleTableCol } from '../../../bases/controls/helper';
import './select-search.css';
import { ABOUTUS, ALERT } from "../../../helpers/constant";
import HeadTitleTable from "components/HeadTitleTable";
import NoImg from "../../../assets/img/NoImg/NoImg.jpg";
import Pagination from "components/Pagination";
import UpdatePopup from "../../../components/UpdatePopup";
import UpdateModal from "./UpdateModal";
import HeaderTable from "components/HeaderTable";
import CreateNewPopup from "../../../components/CreateNewPopup";
import AddNewModal from "./AddNewModal";
import WarningPopup from "../../../components/WarningPopup";
import { TOKEN } from "../../../services/Common";
import axios from "axios";
import { CONFIG_UPDATE_IMG } from "../../../apis";
import PopupMessage from "../../../components/PopupMessage";
import InsertOrUpdateAlert from "./InsertOrUpdateAlert";
import WarningPopupDel from "../../../components/WarningPopupDel";

import {
  Card,
  Table,
  Container,
  Row,
  Spinner,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup
} from "reactstrap";
import { data } from 'jquery';
import { size } from 'lodash';
import { getFileName } from 'bases/helper';

const { colors } = defaultTheme;

const selectStyles = {
  control: provided => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

class AboutUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
      isInsertOrUpdate: false,
      updateId: null,
      dataServer: null,
      dataCompany: null,
      companyId: null,
      headerTitle: ABOUTUS,
      headerTitleAlert: ALERT,
      limit: 3,
      beginItem: 0,
      endItem: 3,
      totalElement: 0,
      listLength: 0,
      currentPage: 0,
      infoCompany: {
        companyName: '',
        phoneNumber: '',
        mobile: '',
        email: '',
        address: '',
        taxCode: '',
        logo: '',
        introduce: '',
        // bankName: '',
        // bankAcc: '',
        // bankNumber: '',
        attachment: '',
        website: '',
        logoFile: '',
        attachmentFile: ''
      },
      dataProvider: {},
      dataDistrict: {},
      dataWard: {},
      errorUpdate: {},
      errorInsert: {},
      dataStamp: [],
      configSetting: {
        price: 0,
        email: '',
        phoneNumber: '',
        contentEmailRegister: '',
        contentEmailChangePassword: '',
        contentSMSRegister: '',
        contentSMSChangePassword: '',
        attachments: '',
        attachmentStamps: '',
      },
      errorsInfoCompany: {},
      errorsConfigSystem: {},
      isOpen: false,
      valueDr: null,
      options: [],

      limitAlert: 10,
      beginItemAlert: 0,
      endItemAlert: 10,
      totalElementAlert: 0,
      listLengthAlert: 0,
      currentPageAlert: 0,
      isShowForEdit: false,
    }
    this.refEditorContentSendEmailRegisterUsage = null;
    this.refEditorContentSendEmailChangePassword = null;
    this.refInputFileCompanyLogo = null;
    this.redSelect = null;
    this.refInputFileAttachment = null;
  }

  // componentWillReceiveProps(nextProp) {
  // }
  componentWillUnmount() {
    this.onSaveInfoCompany()
    const { requestAboutUsList } = this.props;

    requestAboutUsList(
      JSON.stringify({
        "search": "",
        "filter": "",
        "orderBy": "",
        "page": null,
        "limit": null
      })
    ).then(res => {
      this.setState({ dataAboutUs: res.data })
    })

  }
  componentDidMount() {
    // this.getInfoCompany();
    // this.onSaveInfoCompany()
    const { requestAboutUsList } = this.props;
    requestAboutUsList().then(res => {

      this.setState(previousState => {
        return {
          ...previousState,
          dataAboutUs: res.data
        }

      })
    })
  }



  onChooseTab = tab => () => {
    this.setState(previousState => {
      return {
        ...previousState,
        currentTab: tab
      }
    }, () => {
      if (tab == 0) {
        // this.onChangeLogoInfoCompany();
        // this.getInfoCompany();
        this.onSaveInfoCompany()
      } else if (tab == 1) {
        this.getInfoCompany();
      }
      // } else if (tab == 2) {
      //     this.getListConfigServer();
      // } else if (tab == 3) {
      //     this.getListStampTemplate();
      // }
      else if (tab == 4) {
        this.getListAlert();
      }
    });
  }

  getListAlert = () => {
    const { getListAlertRoles } = this.props;
    const { limitAlert } = this.state;
    getListAlertRoles(JSON.stringify({
      "search": "",
      "filter": "",
      "orderBy": "",
      "page": null,
      "limit": null
    })).then(res => {
      if (res.data.status == 200) {
        let totalElementAlert = 0;
        if (res.data.data.listAlertRoles.length > limitAlert) {
          totalElementAlert = limitAlert;
        } else {
          totalElementAlert = res.data.data.listAlertRoles.length;
        }
        this.setState({
          totalElementAlert,
          dataAlert: res.data.data.listAlertRoles,
          listLengthAlert: res.data.data.listAlertRoles.length,
          totalPageAlert: Math.ceil(res.data.data.listAlertRoles.length / this.state.limitAlert),
        })
      }
    })
  }

  handleOpenEditAlert = (id) => {
    this.setState(previousState => {
      return {
        ...previousState,
        isShowForEdit: true,
        editId: id
      }
    });
  }

  handleDeleteRowAlert = () => {
    const { deleteAlertRoles, getListAlertRoles } = this.props;
    let { deleteItemAlert, limitAlert } = this.state;

    deleteAlertRoles(deleteItemAlert)
      .then(res => {
        if (res.data.status == 200) {
          getListAlertRoles(JSON.stringify({
            "search": "",
            "filter": "",
            "orderBy": "",
            "page": null,
            "limit": null
          })).then(res => {
            if (res.data.status == 200) {
              let totalElementAlert = 0;
              if (res.data.data.listAlertRoles.length > limitAlert) {
                totalElementAlert = limitAlert;
              } else {
                totalElementAlert = res.data.data.listAlertRoles.length;
              }
              this.setState({
                totalElementAlert,
                dataAlert: res.data.data.listAlertRoles,
                listLengthAlert: res.data.data.listAlertRoles.length,
                totalPageAlert: Math.ceil(res.data.data.listAlertRoles.length / this.state.limitAlert),
              })
            }
          })
        }
        else {
          this.setState({ messageErr: res.data.message || 'Không thàng công' })
          this.toggleModal('popupMessage')
        }
      }
      )

  }

  handlePageClickAlert = (data) => {
    let { limitAlert, beginItemAlert, endItemAlert } = this.state;
    let selected = data.selected;
    let offset = Math.ceil(selected * limitAlert);
    let total = 0;

    beginItemAlert = offset;
    endItemAlert = offset + limitAlert;

    this.state.dataAlert.map((item, key) => (
      key >= beginItemAlert && key < endItemAlert && total++
    ));

    if (selected > 0) {
      total = (selected * limitAlert) + total;
    } else total = total;

    this.setState({ beginItemAlert: beginItemAlert, endItemAlert: endItemAlert, currentPageAlert: selected + 1, totalElementAlert: total });
  };

  onConfirmAlert = (toggleModal, closePopup) => {
    let { newDataInAlert, limitAlert, totalElementAlert } = this.state;
    const { createAlertRoles, getListAlertRoles } = this.props;
    const errorInsertAlert = {};
    let id = "";
    let roleID = "";
    let alertTypeIDs = [];
    if (newDataInAlert || newDataInAlert != {}) {
      id = (newDataInAlert || {}).id;
      roleID = (newDataInAlert || {}).roleID;
      alertTypeIDs = newDataInAlert ? (newDataInAlert.alertTypeIDs.length > 0 ? (newDataInAlert.alertTypeIDs).split(',').filter(p => (p != "")) : []) : [];
    }

    this.setState(previousState => {
      return {
        ...previousState,
        errorInsertAlert
      }
    });

    if (!roleID) {
      errorInsertAlert['roleID'] = 'Chưa chọn nhóm người dùng';
    }

    if (alertTypeIDs.length <= 0) {
      errorInsertAlert['alertTypeIDs'] = 'Chưa chọn loại thông báo';
    }

    if (Object.keys(errorInsertAlert).length > 0) {
      this.setState(previousState => {
        return {
          ...previousState,
          errorInsertAlert,
        }
      });

      return;
    }

    this.setState(previousState => {
      return {
        ...previousState,
        errorInsertAlert: {},
      }
    });
    if (id) {
      createAlertRoles(JSON.stringify({
        id: id,
        roleID: roleID,
        alertTypeIDs: alertTypeIDs
      })).then(res => {
        if (res.data.status == 200) {
          this.setState({
            newDataInAlert: {
              id: '',
              roleID: '',
              alertTypeIDs: []
            }
          })
          getListAlertRoles(JSON.stringify({
            "search": "",
            "filter": "",
            "orderBy": "",
            "page": null,
            "limit": null
          })).then(res => {
            if (res.data.status == 200) {
              let totalElementAlert = 0;
              if (res.data.data.listAlertRoles.length > limitAlert) {
                totalElementAlert = limitAlert;
              } else {
                totalElementAlert = res.data.data.listAlertRoles.length;
              }
              this.setState({
                totalElementAlert,
                dataAlert: res.data.data.listAlertRoles,
                listLengthAlert: res.data.data.listAlertRoles.length,
                totalPageAlert: Math.ceil(res.data.data.listAlertRoles.length / this.state.limitAlert),
              })
            }
          })
          if (closePopup != 'closePopup') { this.toggleModal('createNewModal'); }
        } else {
          this.setState({ messageErr: res.data.message })
          this.toggleModal('popupMessage')
        }
      })
    } else {
      createAlertRoles(JSON.stringify({
        roleID: roleID,
        alertTypeIDs: alertTypeIDs
      })).then(res => {
        if (res.data.status == 200) {
          this.setState({
            newDataInAlert: {}
          })
          getListAlertRoles(JSON.stringify({
            "search": "",
            "filter": "",
            "orderBy": "",
            "page": null,
            "limit": null
          })).then(res => {
            if (res.data.status == 200) {
              let totalElementAlert = 0;
              if (res.data.data.listAlertRoles.length > limitAlert) {
                totalElementAlert = limitAlert;
              } else {
                totalElementAlert = res.data.data.listAlertRoles.length;
              }
              this.setState({
                totalElementAlert,
                dataAlert: res.data.data.listAlertRoles,
                listLengthAlert: res.data.data.listAlertRoles.length,
                totalPageAlert: Math.ceil(res.data.data.listAlertRoles.length / this.state.limitAlert),
              })
            }
          })
          if (toggleModal) {
            toggleModal();
          }
          if (closePopup != 'closePopup') { this.toggleModal('createNewModal'); }
        } else {
          this.setState({ messageErr: res.data.message })
          this.toggleModal('popupMessage')
        }
      })
    }
  }

  toggleAlert = (el, val) => {
    let { dataAlert } = this.state;

    dataAlert.filter(item => item.roleID === val)
      .map(item => item.collapse = !item.collapse);

    this.setState({ dataAlert });
  }

  onHandleChangeValue = data => {

    this.setState(previousState => {
      return {
        ...previousState,
        newDataInAlert: data.newData
      }
    })
  }

  getListStampTemplate = () => {
    const { requestListStampPlate } = this.props;
    requestListStampPlate(JSON.stringify({
      "search": "",
      "filter": "",
      "orderBy": "",
      "page": null,
      "limit": null
    })).then(res => {
      if (res.data.status == 200) {
        res.data.data.stamps.map((item, key) => {
          item['collapse'] = false;
        })
        this.setState({
          dataStampAll: res.data.data.stamps,
          dataStamp: res.data.data.stamps,
          listLength: res.data.data.stamps.length,
          totalPage: Math.ceil(res.data.data.stamps.length / this.state.limit),
        })
      }
    })
  }

  renderCreateModal = () => {
    const { errorInsert, currentFilter, dataInPopup } = this.state;

    return (
      <AddNewModal
        handleCheckValidation={this.handleCheckValidation}
        handleNewData={this.handleNewDataAddNew}
        errorInsert={errorInsert}
      />
    );
  }

  handleOpenEdit = (id) => {

    this.setState({ idRow: id })
  }

  handleCheckValidation = (status) => {
    this.setState({ activeCreateSubmit: status });
  }

  handleNewData = (data, file) => {
    this.setState({ newData: data, file: file });
  }

  handleNewDataAddNew = (data) => {
    this.setState({ newDataIn: data })
  }

  componentInsert = (value, closeForm, closePopup) => {
    let { newDataIn, dataStampAll } = this.state;
    const { requestListCreatePlate, requestListStampPlate } = this.props;
    const errorInsert = {};
    const formData = new FormData();

    this.setState(previousState => {
      return {
        ...previousState,
        errorInsert
      }
    });

    let insertData = {}

    if (newDataIn) {
      insertData = {
        ID: newDataIn.ID,
        Name: newDataIn.Name,
        file: newDataIn.file
      }
    }

    if (!insertData.Name) {
      errorInsert['Name'] = 'Tên mẫu tem không được bỏ trống';
    }

    if (!insertData.file) {
      errorInsert['Template'] = 'Hình ảnh không được bỏ trống';
    }


    if (insertData.Name) {
      let flag = false;
      dataStampAll.filter(item => item.name === insertData.Name.trim())
        .map(item => flag = true);
      if (flag == true) {
        errorInsert['Name'] = 'Tên mẫu tem này đã có';
      }
    }

    if (Object.keys(errorInsert).length > 0) {
      this.setState(previousState => {
        return {
          ...previousState,
          errorInsert,
        }
      });

      return;
    }
    formData.append('ID', insertData.ID);
    formData.append('Name', insertData.Name);
    formData.append('file', insertData.file ? insertData.file : '');

    this.setState(previousState => {
      return {
        ...previousState,
        errorInsert: {},
      }
    });
    if (closeForm) {
      closeForm();
    }

    //this.handleCloseUN(true);
    requestListCreatePlate(formData).then(res => {
      if (res.data.status == 200) {
        requestListStampPlate(JSON.stringify({
          "search": "",
          "filter": "",
          "orderBy": "",
          "page": null,
          "limit": null
        })).then(res => {
          if (res.data.status == 200) {
            res.data.data.stamps.map((item, key) => {
              item['collapse'] = false;
            })
            this.setState({
              dataStampAll: res.data.data.stamps,
              dataStamp: res.data.data.stamps,
              listLength: res.data.data.stamps.length,
              totalPage: Math.ceil(res.data.data.stamps.length / this.state.limit),
            })

          }
        })
        if (closePopup != 'closePopup') { this.toggleModal('createNewModal'); }
      } else {
        this.setState({ messageErr: res.data.message })
        this.toggleModal('popupMessage')
      }
    })
  }

  componentUpdate = (value) => {

    let { newData, currentRow, dataStampAll } = this.state;
    const { requestListUpdatePlate, requestListStampPlate } = this.props;
    const errorUpdate = {};
    const formData = new FormData();

    this.setState(previousState => {
      return {
        ...previousState,
        errorUpdate
      }
    });
    const updateData = {
      ID: newData.ID,
      Name: newData.Name,
      Template: newData.Template,
      file: newData.file
    }

    if (!updateData.Name) {
      errorUpdate['Name'] = 'Tên mẫu tem không được bỏ trống';
    }

    if (!updateData.Template && !updateData.file) {
      errorUpdate['Template'] = 'Hình ảnh không được bỏ trống';
    }

    let flag = false;
    if (updateData.Name) {

      if (updateData.Name.trim().toUpperCase().indexOf(currentRow.name.trim().toUpperCase()) === -1) {
        dataStampAll.filter(item => item.name.trim().toUpperCase() === updateData.Name.trim().toUpperCase())
          .map(item => flag = true);
      } else {
        flag = false;
      }
      if (flag == true) {
        errorUpdate['Name'] = 'Tên mẫu tem này đã có';
      }
    }

    if (Object.keys(errorUpdate).length > 0) {

      this.setState(previousState => {
        return {
          ...previousState,
          errorUpdate,
        }
      });

      return;
    }
    formData.append('ID', updateData.ID);
    formData.append('Name', updateData.Name);
    formData.append('Template', updateData.file ? '' : updateData.Template);
    formData.append('file', updateData.file ? updateData.file : '');

    this.setState(previousState => {
      return {
        ...previousState,
        errorUpdate: {},
      }
    });


    //this.handleCloseUN(true);
    requestListUpdatePlate(formData).then(res => {
      if (res.data.status == 200) {
        this.toggleModal('updateModal');
        requestListStampPlate(JSON.stringify({
          "search": "",
          "filter": "",
          "orderBy": "",
          "page": null,
          "limit": null
        })).then(res => {
          if (res.data.status == 200) {
            res.data.data.stamps.map((item, key) => {
              item['collapse'] = false;
            })
            this.setState({
              dataStampAll: res.data.data.stamps,
              dataStamp: res.data.data.stamps,
              listLength: res.data.data.stamps.length,
              totalPage: Math.ceil(res.data.data.stamps.length / this.state.limit),
            })
          }
        })
      }
    })
  }

  handleDeleteRow = () => {
    const { requestDeletetStampPlate, requestListStampPlate } = this.props;
    let { deleteItem } = this.state;


    requestDeletetStampPlate(deleteItem)
      .then(res => {
        if (res.data.status == 200) {
          requestListStampPlate(JSON.stringify({
            "search": "",
            "filter": "",
            "orderBy": "",
            "page": null,
            "limit": null
          })).then(res => {
            if (res.data.status == 200) {
              res.data.data.stamps.map((item, key) => {
                item['collapse'] = false;
              })
              this.setState({
                dataStampAll: res.data.data.stamps,
                dataStamp: res.data.data.stamps,
                listLength: res.data.data.stamps.length,
                totalPage: Math.ceil(res.data.data.stamps.length / this.state.limit),
              })
            }
          })
        }
      }
      )

  }

  handlePageClick = (data) => {
    let { limit, beginItem, endItem } = this.state;
    let selected = data.selected;
    let offset = Math.ceil(selected * limit);
    let total = 0;

    beginItem = offset;
    endItem = offset + limit;

    this.state.dataStamp.map((item, key) => (
      key >= beginItem && key < endItem && total++
    ));

    if (selected > 0) {
      total = (selected * limit) + total;
    } else total = total;

    this.setState({ beginItem: beginItem, endItem: endItem, currentPage: selected + 1, totalElement: total });
  };

  toggleModal = (state, type) => {
    if (this.state[state] && type == 1) {
      return;
    } else {
      this.setState({
        [state]: !this.state[state],
        newDataIn: null,
        newData: null,
        errorInsert: {},
        errorUpdate: {},
      });
    }
  };

  // 3
  getListConfigServer = () => {

  }
  // 3
  checkValidateFormConfigSystem = () => {
    const { configSetting } = this.state;

    const errorsConfigSystem = {};

    // if (!configSetting.price) {
    //     errorsConfigSystem.price = 'Giá tiền 1 con tem không được bỏ trống';
    // }

    // if (configSetting.price && configSetting.price <= 0) {
    //     errorsConfigSystem.price = 'Giá tiền 1 con tem không được nhỏ hơn hoặc bằng 0';
    // }

    if (!configSetting.email) {
      errorsConfigSystem.email = 'Email gửi thư không được bỏ trống';
    }

    if (configSetting.email && (configSetting.email || '').length > 255) {
      errorsConfigSystem.email = 'Email gửi thư nhập tối đa 255 kí tự';
    }

    if (configSetting.email && !validEmail(configSetting.email)) {
      errorsConfigSystem.email = 'Email gửi thư không đúng định dạng';
    }

    // if (!configSetting.phoneNumber) {
    //     errorsConfigSystem.phoneNumber = 'Điện thoại không được bỏ trống';
    // }

    if (!configSetting.attachments) {
      errorsConfigSystem.attachments = 'Hồ sơ đính kèm không được bỏ trống';
    }

    if (!configSetting.attachmentStamps) {
      errorsConfigSystem.attachmentStamps = 'Hồ sơ đính kèm không được bỏ trống';
    }

    // if (configSetting.phoneNumber && (configSetting.phoneNumber || '').length > 255) {
    //     errorsConfigSystem.phoneNumber = 'Điện thoại nhập tối đa 255 kí tự';
    // }

    // if (configSetting.phoneNumber && !validPhone(configSetting.phoneNumber)) {
    //     errorsConfigSystem.phoneNumber = 'Điện thoại không đúng định dạng';
    // }

    //const contentEmailRegister = this.refEditorContentSendEmailRegisterUsage.getContent();
    const contentEmailChangePassword = this.refEditorContentSendEmailChangePassword.getContent();

    // if (!contentEmailRegister) {
    //     errorsConfigSystem.contentEmailRegister = 'Nội dung gửi email xác nhận đăng ký sử dụng không được bỏ trống';
    // }

    if (!contentEmailChangePassword) {
      errorsConfigSystem.contentEmailChangePassword = 'Nội dung gửi email thay đổi mật khẩu không được bỏ trống';
    }

    if (!configSetting.contentSMSRegister) {
      errorsConfigSystem.contentSMSRegister = 'Nội dung gửi SMS xác nhận đăng ký sử dụng không được bỏ trống';
    }

    if (!configSetting.contentSMSChangePassword) {
      errorsConfigSystem.contentSMSChangePassword = 'Nội dung gửi SMS thay đổi mật khẩu không được bỏ trống';
    }

    return errorsConfigSystem;
  }

  getConfigSystem = async () => {
    const { getConfigSetting } = this.props;

    const configSetting = await getConfigSetting();

    const data = (configSetting.data || {}).data || {};

    const templates = data.templates || [];

    const contentEmailRegister = templates.find(p => p.contentType == 'registerCompany' && p.note == 'Email');
    const contentSMSRegister = templates.find(p => p.contentType == 'registerCompany' && p.note == 'SMS');
    const contentEmailChangePassword = templates.find(p => p.contentType == 'changPass' && p.note == 'Email');
    const contentSMSChangePassword = templates.find(p => p.contentType == 'changPass' && p.note == 'SMS');

    this.setState(previousState => {
      return {
        ...previousState,
        configSetting: {
          ...previousState.configSetting,
          email: data.email,
          phoneNumber: data.phoneNumber,
          price: data.stamp,
          attachments: data.attachments,
          attachmentStamps: data.attachmentStamps,
          contentEmailRegister: contentEmailRegister ? contentEmailRegister.description : '',
          contentEmailChangePassword: contentEmailChangePassword ? contentEmailChangePassword.description : '',
          contentSMSRegister: contentSMSRegister ? contentSMSRegister.description : '',
          contentSMSChangePassword: contentSMSChangePassword ? contentSMSChangePassword.description : ''
        }
      }
    });
  }

  onInsert = () => {
    this.setState(previousState => {
      return {
        ...previousState,
        isInsertOrUpdate: true
      }
    });
  }

  onUpdate = id => () => {
    this.setState(previousState => {
      return {
        ...previousState,
        isInsertOrUpdate: true,
        updateId: id
      }
    });
  }

  onClose = () => {
    this.setState(previousState => {
      return {
        ...previousState,
        isInsertOrUpdate: false,
        updateId: null
      }
    });
  }

  onConfirm = () => {
    this.setState(previousState => {
      return {
        ...previousState,
        isInsertOrUpdate: false,
        updateId: null
      }
    });
  }

  onChangeValueConfigSystem = name => e => {
    let value = e.target.value;

    if (name === 'price') {
      value = replaceCommaDot(value, '');
    }

    this.setState(previousState => {
      return {
        ...previousState,
        configSetting: {
          ...previousState.configSetting,
          [name]: value
        }
      }
    });
  }
  // 2

  // 1
  getInfoCompany = async () => {
    const { getInfoCompany, getListDistrictForInfoCompany, getListProvinceForInfoCompany, requestAboutUsList } = this.props;
    const infoCompany = await requestAboutUsList();
    const data = (infoCompany.data || {}).data || {};
    let districtName = '';
    let wardName = '';
    let districtId = '';
    let wardId = '';
    let companyId = this.state.companyId;


    this.setState(previousState => {
      districtId = data.districtID;
      wardId = data.wardID;

      return {
        ...previousState,
        infoCompany: {
          nameCompany: data.companyName,
        }

      }
    });
    getListProvinceForInfoCompany().then(res => {
      const data = (res.data || {}).data || null;
      if (data) {
        this.setState(previousState => {
          return {
            ...previousState,
            dataProvider: data
          }
        });
      }

    });

    const dataCompany = {
      "companyName": "",
      "address": "",
      "phoneNumber": "",
      "mobile": "",
      "email": "",
      "website": "",
      "taxCode": "",
      "logo": "",
      "introduce": null,
      "bankName": null,
      "bankAcc": null,
      "bankNumber": null,
      "orderBy": "",
      "page": null,
      "limit": null
    }

  }

  checkValidateFormInfoCompany = () => {
    const { infoCompany, requestAboutUsList, aboutUs } = this.state;
    const errorsInfoCompany = {};

    // if (!infoCompany.companyName) {
    //     errorsInfoCompany.companyName = 'Tên tổ chức không được bỏ trống';
    // }

    if (infoCompany.companyName && (infoCompany.companyName || '').length > 255) {
      errorsInfoCompany.companyName = 'Tên tổ chức nhập tối đa 255';
    }
    if (infoCompany.phoneNumber && !validPhone(infoCompany.phoneNumber)) {
      errorsInfoCompany.phoneNumber = 'Số điện thoại không đúng định dạng';
    }
    if (infoCompany.email && !validEmail(infoCompany.email)) {
      errorsInfoCompany.email = 'Email không đúng định dạng';
    }
    if (!infoCompany) {
      errorsInfoCompany.address = 'Địa chỉ không được bỏ trống';
    }
    if (infoCompany.taxCode && (infoCompany.taxCode || '').length > 255) {
      errorsInfoCompany.taxCode = 'Mã số thuế nhập tối đa 255';
    }
    if (infoCompany.logoFile && !validSize(infoCompany.logoFile.size, MAX_FILE_IMAGE_SIZE)) {
      errorsInfoCompany.logo = 'Logo không được quá ' + MAX_FILE_IMAGE_SIZE + 'MB';
    }
    if (infoCompany.logoFile && !validExtensionFileImage(infoCompany.logoFile.fileName)) {
      errorsInfoCompany.logo = 'Logo không đúng định dạng ' + EXTENSION_FILE_IMAGE.join(', ');
    }
    // if(infoCompany.attachment)
    return errorsInfoCompany;
  }

  onChooseLogoInfoCompany = () => {
    if (this.refInputFileCompanyLogo) {
      this.refInputFileCompanyLogo.click();
    }
  }

  onChangeValueInfoCompany = name => e => {

    let valueChange
    if (name === "introduce") {
      valueChange = e.target.getContent()
    } else {
      valueChange = e?.target?.value;
    }
    // let valueAtt
    // if (name === 'attachment') {
    //     valueAtt = e.target.files[0]
    // } else {
    //     valueAtt = e?.target?.value
    // }
    this.setState(previousState => {
      return {
        ...previousState,
        infoCompany: {
          ...previousState.infoCompany,
          [name]: valueChange
        }
      }
    });
  }


  // checkAttachmentFile = e => {
  //     this.setState(previousState => {
  //         return {
  //             ...previousState,
  //             errorsInfoCompany: {
  //                 ...previousState.errorsInfoCompany,
  //                 attachment: null
  //             }
  //         }
  //     });
  //     const files = e.target.files || [];
  //     if (files.length > 0) {
  //         const file = files[0];
  //         if (file) {
  //             if (!validExtensionFileImage(file.name) && !getExtensionFile(file.name, EXTENSION_FILE_WORD) && !getExtensionFile(file.name, EXTENSION_FILE_EXCEL) && !getExtensionFile(file.name, EXTENSION_FILE_PDF)) {
  //                 this.refInputFileAttachment.files = null;
  //                 this.setState(previousState => {
  //                     return {
  //                         ...previousState,
  //                         errorsInfoCompany: {
  //                             ...previousState.errorsInfoCompany,
  //                             attachment: 'File thanh toán không đúng định dạng ' + EXTENSION_FILE_IMAGE.join(', ') + ' ' + EXTENSION_FILE_WORD + ' ' + EXTENSION_FILE_EXCEL + ' ' + EXTENSION_FILE_PDF
  //                         }
  //                     }
  //                 });

  //                 return;
  //             }
  //             if (!validSize(file.size, MAX_FILE_IMAGE_SIZE)) {
  //                 this.refInputFileAttachment.files = null;
  //                 this.setState(previousState => {
  //                     return {
  //                         ...previousState,
  //                         errorsInfoCompany: {
  //                             ...previousState.errorsInfoCompany,
  //                             attachment: 'File thanh toán không được quá ' + MAX_FILE_IMAGE_SIZE + 'MB'
  //                         }
  //                     }
  //                 });

  //                 return;
  //             }
  //             // const attachment = URL.createObjectURL(file);
  //             const data = this.state.dataAboutUs.data.aboutUs;
  //             if (data) {
  //                 this.setState(previousState => {

  //                     return {
  //                         ...previousState,
  //                         dataAboutUs: {
  //                             ...previousState,
  //                             data: {
  //                                 ...previousState,
  //                                 aboutUs: {
  //                                     companyName: data.companyName,
  //                                     taxCode: data.taxCode,
  //                                     address: data.address,
  //                                     phoneNumber: data.phoneNumber,
  //                                     email: data.email,
  //                                     website: data.website,
  //                                     attachment: data.attachment,
  //                                     // attachmentFile: file,
  //                                     introduce: data.introduce,
  //                                     logo: data.logo,
  //                                     // logoFile: file
  //                                 }
  //                             }

  //                         }
  //                     }
  //                 });
  //             }
  //         }
  //     }
  // }
  onChangeLogoInfoCompany = e => {
    this.setState(previousState => {
      return {
        ...previousState,
        errorsInfoCompany: {
          ...previousState.errorsInfoCompany,
          logo: null
        }
      }
    });
    const files = e.target.files || [];

    if (files.length > 0) {
      const file = files[0];

      if (file) {
        if (!validExtensionFileImage(file.name)) {
          this.refInputFileCompanyLogo.files = null;

          this.setState(previousState => {
            return {
              ...previousState,
              errorsInfoCompany: {
                ...previousState.errorsInfoCompany,
                logo: 'Logo không đúng định dạng ' + EXTENSION_FILE_IMAGE.join(', ')
              }
            }
          });
          return;
        }
        if (!validSize(file.size, MAX_FILE_IMAGE_SIZE)) {
          this.refInputFileCompanyLogo.files = null;

          this.setState(previousState => {
            return {
              ...previousState,
              errorsInfoCompany: {
                ...previousState.errorsInfoCompany,
                logo: 'Logo không được quá ' + MAX_FILE_IMAGE_SIZE + 'MB'
              }
            }
          });

          return;
        }

        const logo = URL.createObjectURL(file);
        const data = this.state.dataAboutUs.data.aboutUs;
        if (data) {
          this.setState(previousState => {

            return {
              ...previousState,
              dataAboutUs: {
                ...previousState,
                data: {
                  ...previousState,
                  aboutUs: {
                    companyName: data.companyName,
                    taxCode: data.taxCode,
                    address: data.address,
                    phoneNumber: data.phoneNumber,
                    email: data.email,
                    website: data.website,
                    attachment: data.attachment,
                    introduce: data.introduce,
                    logo,
                    logoFile: file
                  }
                }

              }
            }
          });
        }
      }
    }
  }

  onSaveInfoCompany = () => {

    const errorsInfoCompany = this.checkValidateFormInfoCompany();

    this.setState(previousState => {
      return {
        ...previousState,
        errorsInfoCompany,
        // errorsConfigSystem,
      }
    });

    if (Object.keys(errorsInfoCompany).length > 0) {
      return;
    }

    const { infoCompany, dataAboutUs } = this.state;
    const previousState = dataAboutUs?.data?.aboutUs


    const formData = new FormData();

    formData.append('CompanyName', infoCompany.companyName ? infoCompany.companyName : previousState.companyName);
    formData.append('PhoneNumber', infoCompany.phoneNumber ? infoCompany.phoneNumber : previousState.phoneNumber);
    formData.append('Email', infoCompany.email ? infoCompany.email : previousState.email);
    formData.append('Address', infoCompany.address ? infoCompany.address : previousState.address);
    formData.append('TaxCode', infoCompany.taxCode ? infoCompany.taxCode : previousState.taxCode);
    formData.append('Website', infoCompany.website ? infoCompany.website : previousState.website)
    formData.append('Introduce', infoCompany.introduce ? infoCompany.introduce : previousState.introduce);
    formData.append('Attachment', infoCompany.attachment ? infoCompany.attachment : previousState.attachment)

    // if ((this.refInputFileAttachment && this.refInputFileAttachment.files.length > 0)) {
    //     formData.append('AttachmentFile', previousState.attachmentFile)
    // } else {
    //     // console.log("wewe")
    //     formData.append('Attachment', infoCompany.attachment ? infoCompany.attachment : previousState.attachment);
    // }
    if ((this.refInputFileCompanyLogo && this.refInputFileCompanyLogo.files.length > 0)) {
      formData.append('LogoFile', previousState.logoFile);
    } else {
      formData.append('Logo', infoCompany.logo ? infoCompany.logo : previousState.logo);
    }

    Loading.show();
    this.props.updateAboutUs(formData).then(res => {
      Loading.close();

      const data = (res.data || {});

      if (data.status == 200) {

      } else {
        const message = getErrorMessageServer(res);

        this.setState({ messageErr: message || 'Hệ thống trục trặc' });
        this.toggleModal('popupMessage')
        // Message.show(TYPES.ERROR, 'Thông báo', message || 'Hệ thống trục trặc');
      }
    });
  }

  onDeleteLogoInfoCompany = () => {
    if (this.refInputFileCompanyLogo) {
      this.refInputFileCompanyLogo.files = null;
      const data = this.state.dataAboutUs.data.aboutUs;
      if (data) {
        this.setState(previousState => {
          return {
            ...previousState,
            dataAboutUs: {
              ...previousState,
              data: {
                ...previousState,
                aboutUs: {
                  companyName: data.companyName,
                  taxCode: data.taxCode,
                  address: data.address,
                  phoneNumber: data.phoneNumber,
                  email: data.email,
                  website: data.website,
                  attachment: data.attachment,
                  introduce: data.introduce,
                  logo: '',
                  logoFile: ''
                }
              }
            }
          }
        })
      }
    }
  }
  // 1

  toggle = (el, val) => {
    let { dataServer } = this.state;

    dataServer.filter(item => item.id === val)
      .map(item => item.collapse = !item.collapse);

    this.setState({ dataServer });
  }

  toggleStamp = (el, val) => {
    let { dataStamp } = this.state;

    dataStamp.filter(item => item.id === val)
      .map(item => item.collapse = !item.collapse);

    this.setState({ dataStamp });
  }

  dataHandele = data => {
    const { companyId, valueDr } = this.state;
    if (data == 200) {

      this.props.getConfigServer({ companyId: valueDr }).then(res => {
        const data = (res.data || {}).data || [];
        if (data) {
          data.map((item, key) => {
            item['index'] = key + 1
            item['collapse'] = false
            if (item.createdDate != null) {
              item['createdDate'] = moment(item.createdDate).format('DD-MM-YYYY')
            }
          });
          this.setState(previousState => {
            return {
              ...previousState,
              dataServer: data,
            }
          });
        }
      })

    }
  }

  toggleOpen = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };
  onSelectChange = valueDr => {
    this.toggleOpen();
    const idValueDr = valueDr;
    this.setState({ valueDr: valueDr })
    this.props.getConfigServer({ companyId: idValueDr }).then(res => {
      const data = (res.data || {}).data || [];
      if (data) {
        data.map((item, key) => {
          item['index'] = key + 1
          item['collapse'] = false
          if (item.createdDate != null) {
            item['createdDate'] = moment(item.createdDate).format('DD-MM-YYYY')
          }
        });
        this.setState(previousState => {
          return {
            ...previousState,
            dataServer: data,
          }
        });
      }
    })
    // }

  };


  render() {
    const { ConfigSystemStore } = this.props;
    const {
      configSetting,
      errorsConfigSystem,
      errorsInfoCompany,
      currentTab,
      isInsertOrUpdate,
      infoCompany,
      dataCompany,
      dataServer,
      updateId,
      dataProvider,
      dataWard,
      dataAboutUs,
      isOpen,
      options,
      dataStamp,
      headerTitle,
      beginItem,
      endItem,
      totalElement,
      listLength,
      totalPage,
      updateModal,
      activeCreateSubmit,
      idRow,
      errorUpdate,
      createNewModal,
      warningPopupModal,
      popupMessage,
      messageErr,
      dataAlert,
      editId,
      errorInsertAlert,
      isShowForEdit,
      warningPopupDelModal,
      listLengthAlert,
      totalPageAlert,
      totalElementAlert,
      endItemAlert,
      beginItemAlert,
      currentRowAlert,
      headerTitleAlert
    } = this.state;
    let isDisableAdd = true;
    let isDisableEdit = true;
    let isDisableDelete = true;
    if (IS_ADMIN) {
      isDisableAdd = false;
      isDisableEdit = false;
      isDisableDelete = false;
    } else {
      ACCOUNT_CLAIM_FF.filter(x => x == "Configs.Add").map(y => isDisableAdd = false)
      ACCOUNT_CLAIM_FF.filter(x => x == "Configs.Edit").map(y => isDisableEdit = false)
      ACCOUNT_CLAIM_FF.filter(x => x == "Configs.Delete").map(y => isDisableDelete = false)
    }

    options.map(option => {
      option.name = option.companyName;
      option.value = option.id;

    })
    return (
      <div className='config-system'>
        <div className='config-system-tab'>
          <div onClick={this.onChooseTab(0)} className={`config-system-tab-item ${currentTab == 0 ? 'active' : ""}`}>LIÊN HỆ</div>
          <div onClick={this.onChooseTab(1)} className={`config-system-tab-item ${currentTab == 1 ? 'active' : ""}`}>GIỚI THIỆU ỨNG DỤNG</div>
          {/* <div onClick={this.onChooseTab(2)} className={`config-system-tab-item ${currentTab == 2 ? 'active' : ''}`}>CÀI ĐẶT MÁY CHỦ</div> */}
          {/* <div onClick={this.onChooseTab(3)} className={`config-system-tab-item ${currentTab == 3 ? 'active' : ''}`}>MẪU IN TEM QR</div> */}
          <div onClick={this.onChooseTab(4)} className={`config-system-tab-item ${currentTab == 4 ? 'active' : ''}`}>CÀI ĐẶT THÔNG BÁO</div>
        </div>
        <div className='config-system-content'>
          {currentTab == 0 ? (
            <div className='config-system-content-info-company'>
              <div className='config-system-content-info-company-item'>
                <label className='config-system-content-info-company-item-label css-label-width-system'>Tên công ty&nbsp;</label>
                <div className='config-system-content-info-company-item-box'>
                  <InputGroup className="input-group-alternative css-border-input">
                    <input autoFocus={true} onChange={this.onChangeValueInfoCompany('companyName')} defaultValue={dataAboutUs?.data?.aboutUs?.companyName} className='config-system-content-info-company-item-input css-border-webConfig' type="text" />
                  </InputGroup>

                  <p className='form-error-message'>{errorsInfoCompany.companyName}</p>
                </div>
              </div>
              <div className='config-system-content-info-company-item'>
                <label className='config-system-content-info-company-item-label css-label-width-system'>Mã số thuế &nbsp;</label>
                <div className='config-system-content-info-company-item-box'>
                  <InputGroup className="input-group-alternative css-border-input">
                    <input onChange={this.onChangeValueInfoCompany('taxCode')} defaultValue={dataAboutUs?.data?.aboutUs?.taxCode} className='config-system-content-info-company-item-input css-border-webConfig' type="text" />
                  </InputGroup>

                  <p className='form-error-message'>{errorsInfoCompany.taxCode}</p>
                </div>
              </div>
              <div className='config-system-content-info-company-item'>
                <label className='config-system-content-info-company-item-label css-label-width-system'>Địa chỉ &nbsp;</label>
                <div className='config-system-content-info-company-item-box'>
                  <InputGroup className="input-group-alternative css-border-input">
                    <input onChange={this.onChangeValueInfoCompany('address')} defaultValue={dataAboutUs?.data?.aboutUs?.address} className='config-system-content-info-company-item-input css-border-webConfig' type="text" />
                  </InputGroup>

                  <p className='form-error-message'>{errorsInfoCompany.address}</p>
                </div>
              </div>
              <div className='config-system-content-info-company-item'>
                <label className='config-system-content-info-company-item-label css-label-width-system'>Điện thoại</label>
                <div className='config-system-content-info-company-item-box'>
                  <InputGroup className="input-group-alternative css-border-input">
                    <input onChange={this.onChangeValueInfoCompany('phoneNumber')} defaultValue={dataAboutUs?.data?.aboutUs?.phoneNumber} className='config-system-content-info-company-item-input css-border-webConfig' type="text" />
                  </InputGroup>

                  <p className='form-error-message'>{errorsInfoCompany.phoneNumber}</p>
                </div>
              </div>
              <div className='config-system-content-info-company-item'>
                <label className='config-system-content-info-company-item-label css-label-width-system'>Email</label>
                <div className='config-system-content-info-company-item-box'>
                  <InputGroup className="input-group-alternative css-border-input">
                    <input onChange={this.onChangeValueInfoCompany('email')} defaultValue={dataAboutUs?.data?.aboutUs?.email} className='config-system-content-info-company-item-input css-border-webConfig' type="text" />
                  </InputGroup>

                  <p className='form-error-message'>{errorsInfoCompany.email}</p>
                </div>
              </div>
              <div className='config-system-content-info-company-item'>
                <label className='config-system-content-info-company-item-label css-label-width-system'>Website &nbsp;</label>
                <div className='config-system-content-info-company-item-box'>
                  <InputGroup className="input-group-alternative css-border-input">
                    <input onChange={this.onChangeValueInfoCompany('website')} defaultValue={dataAboutUs?.data?.aboutUs?.website} className='config-system-content-info-company-item-input css-border-webConfig' type="text" />
                  </InputGroup>

                  <p className='form-error-message'>{errorsInfoCompany.website}</p>
                </div>
              </div>

              <div className='config-system-content-info-company-item'>
                <label className='config-system-content-info-company-item-label css-label-width-system'>Thông tin thanh toán &nbsp;</label>
                <div className='config-system-content-info-company-item-box'>
                  {/* <label style={{ marginRight: '10px', textAlign: "left" }} size="lg" htmlFor='file-choose' className='btn-primary-cs'><img style={{ color: '#000' }} src={Folder} alt="folder" /> Chọn file</label> */}
                  <InputGroup className="input-group-alternative css-border-input">
                    <textarea
                      rows={5}
                      // id="file-choose"
                      onChange={this.onChangeValueInfoCompany('attachment')}
                      // ref={ref => this.refInputFileAttachment = ref}
                      defaultValue={dataAboutUs?.data?.aboutUs?.attachment}
                      className='config-system-content-info-company-item-input css-border-webConfig'
                      type="textarea"
                    // accept='image/*, .pdf, doc, .xls, .xlsx, .csv, .docx ' 
                    />
                  </InputGroup>

                  {/* <Button className="btn-primary-cs">
                                        Chọn File                                       
                                    </Button> */}
                  <p className='form-error-message'>{errorsInfoCompany.attachment}</p>
                </div>
              </div>

              <div className='config-system-content-info-company-item'>
                <label className='config-system-content-info-company-item-label css-label-width-system'>Logo</label>
                <div className='config-system-content-info-company-item-box'>
                  <input onChange={this.onChangeLogoInfoCompany} ref={ref => this.refInputFileCompanyLogo = ref} type='file' style={{ display: 'none', }} className='hidden' accept='image/*' />
                  <img onClick={this.onChooseLogoInfoCompany} className='config-system-content-info-company-item-logo' src={dataAboutUs?.data?.aboutUs?.logo} />
                  <div className='config-system-content-info-company-item-delete-logo'>
                    <button onClick={this.onDeleteLogoInfoCompany} className='config-system-content-info-company-item-delete-logo-button'>Xóa</button>
                  </div>
                  <p className='form-error-message'>{errorsInfoCompany.logo}</p>
                </div>
              </div>
              <div className='config-system-content-info-company-function'>
                {/* <button onClick={this.onSaveInfoCompany} className='config-system-content-info-company-function-button'>
                                    <img className='config-system-content-info-company-function-button-icon' src={ButtonSave} />
                                </button> */}
                {isDisableAdd == true ? null : (
                  <Button
                    color="default"
                    type="button"
                    className={`btn-success-cs`}
                    style={{ margin: "inherit" }}
                    onClick={this.onSaveInfoCompany}
                  >
                    <img src={SaveIcon1} alt='Lưu lại' />
                    <span>Lưu lại</span>
                  </Button>
                )}
              </div>
            </div>
          ) : null}
          {currentTab == 1 ? (
            <div className='config-system-content-config-system'>
              <div className='config-system-content-config-system-item'>
                <div className='config-system-content-config-system-item-box'>
                  <InputGroup className="input-group-alternative css-border-input css-border-webConfig">
                    <Editor onInit={(_, editor) => {
                      this.refEditorContentSendEmailChangePassword = editor;

                    }}

                      onChange={this.onChangeValueInfoCompany('introduce')}

                      defaultValue={dataAboutUs?.data?.aboutUs?.introduce}
                      initialValue={dataAboutUs?.data?.aboutUs?.introduce}
                      init={{
                        width: '100%',
                        height: 300,
                        menubar: false,
                        toolbar: 'undo redo | formatselect | image | link | code | ' +
                          'bold italic forecolor backcolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                        selector: 'textarea#file-picker',
                        plugins: 'image code link',
                        image_title: true,

                        automatic_uploads: true,
                        file_picker_types: 'image',
                        file_picker_callback: (cb, value, meta) => {
                          let _this = this;

                          var input = document.createElement('input');
                          input.setAttribute('type', 'file');
                          input.setAttribute('accept', 'image/*');
                          input.onchange = async function () {
                            var file = this.files[0];
                            var reader = new FileReader();
                            reader.onload = function () {
                              var id = 'blobid' + (new Date()).getTime();
                              var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                              var base64 = reader.result.split(',')[1];
                              var blobInfo = blobCache.create(id, file, base64);
                              blobCache.add(blobInfo);
                              cb(blobInfo.blobUri(), { title: file.name });
                            };
                            let data = null;
                            let imageFile = new FormData();
                            let fileLink = null;
                            imageFile.append('files', file);

                            try {
                              data = await axios({
                                method: 'post',
                                url: CONFIG_UPDATE_IMG,
                                headers: {
                                  authorization: TOKEN + 'a'
                                },
                                data: imageFile
                              })
                              if (data.data.status == 200) {
                                fileLink = data.data.data;
                                cb(fileLink);
                              } else {
                                _this.setState({ messageErr: 'Lỗi hệ thống' })
                                _this.toggleModal('popupMessage')
                                return;
                              }
                            } catch (error) {
                              _this.setState({ messageErr: 'Lỗi hệ thống' })
                              _this.toggleModal('popupMessage')
                              return;
                            }
                            //reader.readAsDataURL(file);
                          };

                          input.click();

                        },
                      }} />
                  </InputGroup>
                  <p className='form-error-message'>{errorsInfoCompany.introduce}</p>
                </div>
              </div>

              <div className='config-system-content-config-system-item-function'>
                {/* <button onClick={this.onSaveConfigSystem} className='config-system-content-config-system-item-function-button'>
                                    <img className='config-system-content-config-system-item-function-button-icon' src={ButtonSave} />
                                </button> */}
                {isDisableAdd == true ? null : (
                  <Button
                    color="default"
                    type="button"
                    className={`btn-success-cs`}
                    style={{ margin: "inherit" }}
                    onClick={this.onSaveInfoCompany}
                  >
                    <img src={SaveIcon1} alt='Lưu lại' />
                    <span>Lưu lại</span>
                  </Button>
                )}
              </div>
            </div>
          ) : null}
          {currentTab == 4 ? (
            <div className='config-system-content-config-server'>
              <HeaderTable
                hideSearch={true}
                hideReload={true}
                styleCustom={'justifyContentStart'}
                moduleTitle={isShowForEdit ? 'Chỉnh sửa' : 'Thêm mới'}
                isShowForEdit={isShowForEdit}
                moduleBody={
                  <InsertOrUpdateAlert
                    id={editId}
                    currentRowAlert={currentRowAlert}
                    errors={errorInsertAlert}
                    onHandleChangeValue={this.onHandleChangeValue} />}
                handleModal={this.handleModal}
                onConfirm={this.onConfirmAlert}
              />
              <Card className="shadow">
                <Table className="align-items-center tablecs table-css-config-system" responsive>
                  <HeadTitleTable
                    headerTitle={headerTitleAlert}
                    classHeaderColumns={{
                      0: 'table-scale-col table-user-col-1'
                    }}
                  />
                  <tbody ref={ref => this.tableBody = ref}>
                    {
                      Array.isArray(dataAlert) && (
                        dataAlert
                          .filter((item, key) => key >= beginItemAlert && key < endItemAlert)
                          .map((item, key) => (
                            <tr key={key} style={{ ...generateStyleTableCol(this.tableBody, (dataAlert || []).length) }}>
                              <td className='table-scale-col table-user-col-1'>{key + 1}</td>
                              <td style={{ textAlign: 'left' }} className='table-scale-col'>{item.roleName}</td>
                              <td style={{ textAlign: 'left' }} className='table-scale-col'>
                                {item.alertTypeName.split(',').map((item1, key1) => (
                                  <><span>{item1}</span><br /></>
                                ))}
                              </td>
                              <td>

                                <ButtonDropdown isOpen={item.collapse} toggle={() => this.toggleAlert(key, item.roleID)}>
                                  <DropdownToggle>
                                    <img src={MenuButton} />
                                  </DropdownToggle>
                                  <DropdownMenu>

                                    {/* <DropdownItem
                                      onClick={() => {
                                        // this.toggleModal('updateModal');
                                        this.handleOpenEditAlert(item.roleID);
                                        this.setState({ currentRowAlert: item })
                                      }}>
                                      Sửa
                                    </DropdownItem>
                                    <DropdownItem divider /> */}
                                    <DropdownItem
                                      onClick={() => {
                                        this.toggleModal('warningPopupDelModal');
                                        this.setState({ deleteItemAlert: item.roleID });
                                      }}
                                    >
                                      Xoá
                                    </DropdownItem>

                                  </DropdownMenu>
                                </ButtonDropdown>

                              </td>
                            </tr>
                          )
                          )
                      )
                    }
                  </tbody>
                </Table>
              </Card>
              {
                // Page of Table
                Array.isArray(dataAlert) && (
                  dataAlert.length > 0 && (
                    <Pagination
                      data={dataAlert}
                      listLength={listLengthAlert}
                      totalPage={totalPageAlert}
                      totalElement={totalElementAlert}
                      handlePageClick={this.handlePageClickAlert}
                    />
                  )
                )
              }

              <UpdatePopup
                moduleTitle='Sửa mẫu tem'
                moduleBody={
                  <UpdateModal
                    errorUpdate={errorUpdate}
                    id={idRow}
                    handleCheckValidation={this.handleCheckValidation}
                    handleNewData={this.handleNewData}
                  />}
                updateModal={updateModal}
                toggleModal={this.toggleModal}
                activeSubmit={activeCreateSubmit}
                handleUpdateInfoData={this.componentUpdate}
              />

              <PopupMessage
                popupMessage={popupMessage}
                moduleTitle={'Thông báo'}
                moduleBody={messageErr}
                toggleModal={this.toggleModal}
              />

              {/* <CreateNewPopup
                createNewModal={createNewModal}
                moduleTitle='Thêm mẫu tem'
                moduleBody={this.renderCreateModal()}
                toggleModal={this.toggleModal}
                activeSubmit={activeCreateSubmit}
                type100={true}
                handleCreateInfoData={(data, beta, close) => {
                  this.componentInsert(data, () => {
                    this.setState({
                      createNewModal: false
                    });
                  }, close);
                }}
              /> */}

              <CreateNewPopup
                createNewModal={createNewModal}
                moduleTitle='Thêm mới'
                type100={true}
                moduleBody={
                  <InsertOrUpdateAlert
                    errors={errorInsertAlert}
                    onHandleChangeValue={this.onHandleChangeValue}
                  />}
                toggleModal={this.toggleModal}
                activeSubmit={activeCreateSubmit}
                onConfirm={(data, close) => {
                  this.onConfirmAlert(data, close);
                }}
              />

              <WarningPopupDel
                moduleTitle='Thông báo'
                moduleBody={<p style={{ textAlign: 'center', fontSize: '1.2rem' }}>Bạn đồng ý xoá thông tin này?</p>}
                warningPopupDelModal={warningPopupDelModal}
                toggleModal={this.toggleModal}
                handleWarning={this.handleDeleteRowAlert}
              />
            </div>
          ) : null}
        </div>
        <PopupMessage
          popupMessage={popupMessage}
          moduleTitle={'Thông báo'}
          moduleBody={messageErr}
          toggleModal={this.toggleModal}
        />
      </div>
    )
  }
}

const Menu = props => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      css={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
      }}
      {...props}
    />
  );
};
const Blanket = props => (
  <div
    css={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({ children, isOpen, target, onClose }) => (
  <div css={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);
const Svg = p => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);
const DropdownIndicator = () => (
  <div css={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
);
const ChevronDown = () => (
  <Svg style={{ marginRight: -6 }}>
    <path
      d="M8.292 10.293a1.009 1.009 0 0 0 0 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 0 0 0-1.419.987.987 0 0 0-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 0 0-1.406 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </Svg>)


const mapStateToProps = (state) => {
  return {
    ConfigSystemStore: state.ConfigSystemStore,
    // dataCompany: state.CompanyListRegisteredStore,
    stampTemplate: state.StampPlateStore,
    aboutUs: state.AboutUsStore
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(configSystemAction, dispatch),
    // ...bindActionCreators(actionCompanyListRegistered, dispatch),
    ...bindActionCreators(actionStampPlate, dispatch),
    ...bindActionCreators(actionAboutUs, dispatch),
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AboutUs);