import {
    STAMPPROVIDE_LIST,
    STAMPPROVIDE_LIST_COMFIRM,
    STAMPPROVIDE_LIST_UNCOMFIRM,
    QRCODE_STAMPID,
    STAMPPROVIDE_DETAIL
} from "../apis";
import {
    get, post, del
} from "../services/Dataservice";
import {
    SUCCESS_CODE,
    GET_STAMPPROVIDE_LIST_TYPE,
    GET_STAMPPROVIDE_LIST_SUCCESS_TYPE,
    GET_STAMPPROVIDE_LIST_FAIL_TYPE,
    GET_COMFIRM_STAMPPROVIDE_LIST_FAIL_TYPE,
    GET_COMFIRM_STAMPPROVIDE_LIST_SUCCESS_TYPE,
    GET_COMFIRM_STAMPPROVIDE_LIST_TYPE,
    GET_UNCOMFIRM_STAMPPROVIDE_LIST_TYPE,
    GET_UNCOMFIRM_STAMPPROVIDE_LIST_SUCCESS_TYPE,
    GET_UNCOMFIRM_STAMPPROVIDE_LIST_FAIL_TYPE,
    GET_QRCODE_STAMPID_TYPE,
    GET_QRCODE_STAMPID_SUCCESS_TYPE,
    GET_QRCODE_STAMPID_FAIL_TYPE
} from "../services/Common";

const initialState = { data: [], isLoading: false, status: false, message: '' };

export const actionStampProvideList = {
    requestStampProvideList: (data) => async (dispatch, getState) => {
        dispatch({
            type: GET_STAMPPROVIDE_LIST_TYPE, data: initialState
        });

        await post(STAMPPROVIDE_LIST, data)
            .then(res => {
                if (res.status === SUCCESS_CODE) {
                    dispatch({
                        type: GET_STAMPPROVIDE_LIST_SUCCESS_TYPE, data: { stampprovide: res.data, isLoading: true, status: true, message: res.message }
                    });
                } else {
                    dispatch({
                        type: GET_STAMPPROVIDE_LIST_FAIL_TYPE, data: { stampprovide: [], isLoading: true, status: false, message: res.message }
                    });
                }
            })
            .catch(err => {
                dispatch({ type: GET_STAMPPROVIDE_LIST_FAIL_TYPE, data: { stampprovide: [], isLoading: true, status: false, message: err.message } });
            })
    },
    requestComfirmStampProvide: (id) => async (dispatch, getState) => {
        return new Promise(async resolve => {
            dispatch({
                type: GET_COMFIRM_STAMPPROVIDE_LIST_TYPE, data: initialState
            });

            return await get(STAMPPROVIDE_LIST_COMFIRM + id)
                .then(res => {
                    if (res.status === SUCCESS_CODE) {
                        dispatch({ type: GET_COMFIRM_STAMPPROVIDE_LIST_SUCCESS_TYPE, data: { confirm: res.data, isLoading: true, status: true, message: res.message } });
                    } else {
                        dispatch({ type: GET_COMFIRM_STAMPPROVIDE_LIST_FAIL_TYPE, data: { confirm: [], isLoading: true, status: false, message: res.message } });
                    }
                    resolve({
                        status: true,
                        data: res
                    });
                })
                .catch(err => {
                    dispatch({ type: GET_COMFIRM_STAMPPROVIDE_LIST_FAIL_TYPE, data: { confirm: [], isLoading: true, status: false, message: err.message } });
                    resolve({
                        status: false,
                        error: err
                    });
                })
        })
    },
    requestUncomfirmStampProvide: (data) => async (dispatch, getState) => {
        dispatch({
            type: GET_QRCODE_STAMPID_TYPE, data: initialState
        });

        await post(STAMPPROVIDE_LIST_UNCOMFIRM, data)
            .then(res => {
                if (res.status === SUCCESS_CODE) {
                    dispatch({ type: GET_UNCOMFIRM_STAMPPROVIDE_LIST_SUCCESS_TYPE, data: { unconfirm: res.data, isLoading: true, status: true, message: res.message } });
                } else {
                    dispatch({ type: GET_UNCOMFIRM_STAMPPROVIDE_LIST_FAIL_TYPE, data: { unconfirm: [], isLoading: true, status: false, message: res.message } });
                }
            })
            .catch(err => {
                dispatch({ type: GET_UNCOMFIRM_STAMPPROVIDE_LIST_FAIL_TYPE, data: { unconfirm: [], isLoading: true, status: false, message: err.message } });
            })
    },
    printQR: (id) => async (dispatch, getState) => {
        return new Promise(async resolve => {
            dispatch({
                type: GET_UNCOMFIRM_STAMPPROVIDE_LIST_TYPE, data: initialState
            });

            await get(QRCODE_STAMPID + id)
                .then(res => {
                    if (res.status === SUCCESS_CODE) {
                        dispatch({ type: GET_QRCODE_STAMPID_SUCCESS_TYPE, data: { print: res.data, isLoading: true, status: true, message: res.message } });
                    } else {
                        dispatch({ type: GET_QRCODE_STAMPID_FAIL_TYPE, data: { print: [], isLoading: true, status: false, message: res.message } });
                    }
                    resolve({
                        status: true,
                        data: res
                    });
                })
                .catch(err => {
                    dispatch({ type: GET_QRCODE_STAMPID_FAIL_TYPE, data: { print: [], isLoading: true, status: false, message: err.message } });
                    resolve({
                        status: false,
                        error: err
                    });
                })
        })
    },
    detail: (id) => async (dispatch, getState) => {
        return new Promise(async resolve => {
            const url = STAMPPROVIDE_DETAIL.replace('{0}', id);

            await get(url)
                .then(res => {
                    resolve({
                        status: true,
                        data: res
                    });
                })
                .catch(err => {
                    resolve({
                        status: false,
                        error: err
                    });
                })
        })
    },
}