import React, { Component } from "react";
import Select from "components/Select";
import classes from './index.module.css';
import InputCurrency from '../../../components/InputCurrency';
import Validate from "react-validate-form";
import { rules, validations, checkPasswordConfirm } from "../../../helpers/validation";

// reactstrap components
import {
	Input,
	InputGroup
} from "reactstrap";

class AddNewModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: null,
			newData: {
				id: '',
				year: 0,
				amount: 0,
				amount2: 0,
				amount3: 0
			},
			activeSubmit: false
		}
	}

	componentDidMount() {
		const { data } = this.props;
		let { newData } = this.state;
		if (data) {
			newData.id = data.id;
			newData.year = parseInt(data.year);
			newData.amount = parseInt(data.amount);
			newData.amount2 = parseInt(data.amount2);
			newData.amount3 = parseInt(data.amount3);
		}


		this.setState({ data, newData });
		this.handleCheckValidation();
	}

	handleChange = (event) => {
		let { newData } = this.state;
		const ev = event.target;

		newData[ev['name']] = ev['value'];
		this.setState({ newData });

		// Check Validation 
		this.handleCheckValidation(ev['name'], ev['value']);
	}

	handleChangeNum = (event) => {
		let { newData } = this.state;
		const ev = event.target;

		ev['value'] = Number(ev['value'].replaceAll('.', ''));
		newData[ev['name']] = ev['value'];
		// Check Validation 
		this.handleCheckValidation(ev['name'], ev['value']);
	}

	handleCheckValidation = (name = null, value = null) => {
		const { handleCheckValidation, handleNewDetail } = this.props;
		let { newData } = this.state;

		this.setState({ activeSubmit: true });

		// Check Validation 
		handleCheckValidation(true);

		// Handle New Data
		handleNewDetail(newData);


	}

	render() {
		const { data } = this.state;
		const { errorUpdate } = this.props;
		return (
			data !== null && (
				<div className={classes.formControl}>
					<div className={classes.rowItem}>
						<label
							style={{ width: '195px' }}
							className="form-control-label"
						>
							Số năm&nbsp;<b style={{ color: 'red' }}>*</b>
						</label>

						<Validate
							validations={validations}
							rules={rules}
						>
							{({ validate, errorMessages }) => (
								<div className={classes.inputArea}>
									<InputGroup className="input-group-alternative css-border-input">
										<Input
											type="number"
											name='year'
											//placeholder='Số năm'
											defaultValue={data.year}
											required
											disabled={data.year == 1 ? true : false}
											onChange={validate}
											autoFocus={true}
											onKeyUp={(event) => this.handleChange(event)}
										/>
									</InputGroup>
									<p className='form-error-message margin-bottom-0'>{errorUpdate['year'] || ''}</p>
								</div>
							)}
						</Validate>
					</div>

					<div className={classes.rowItem}>
						<label
							style={{ width: '195px' }}
							className="form-control-label"
						>
							Giá doanh nghiệp&nbsp;<b style={{ color: 'red' }}>*</b>
						</label>

						<div className={classes.inputArea}>
							<InputGroup className="input-group-alternative css-border-input">
								<InputCurrency
									name='amount'
									//placeholder='Số tiền'
									defaultValue={data.amount}
									required
									onKeyUp={(event) => this.handleChangeNum(event)}
								/>
							</InputGroup>
							<p className='form-error-message margin-bottom-0'>{errorUpdate['amount'] || ''}</p>
						</div>
					</div>

					<div className={classes.rowItem}>
						<label
							style={{ width: '195px' }}
							className="form-control-label"
						>
							Giá cá nhân&nbsp;<b style={{ color: 'red' }}>*</b>
						</label>

						<div className={classes.inputArea}>
							<InputGroup className="input-group-alternative css-border-input">
								<InputCurrency
									name='amount2'
									//placeholder='Số tiền'
									defaultValue={data.amount2}
									required
									onKeyUp={(event) => this.handleChangeNum(event)}
								/>
							</InputGroup>
							<p className='form-error-message margin-bottom-0'>{errorUpdate['amount2'] || ''}</p>
						</div>
					</div>

					<div className={classes.rowItem}>
						<label
							style={{ width: '195px' }}
							className="form-control-label"
						>
							Giá hợp tác xã&nbsp;<b style={{ color: 'red' }}>*</b>
						</label>

						<div className={classes.inputArea}>
							<InputGroup className="input-group-alternative css-border-input">
								<InputCurrency
									name='amount3'
									//placeholder='Số tiền'
									defaultValue={data.amount3}
									required
									onKeyUp={(event) => this.handleChangeNum(event)}
								/>
							</InputGroup>
							<p className='form-error-message margin-bottom-0'>{errorUpdate['amount3'] || ''}</p>
						</div>
					</div>
				</div>
			)
		);
	}
};

export default AddNewModal;
