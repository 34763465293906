import {
  MASTERSERVER_LIST_FAIL_TYPE,
  MASTERSERVER_LIST_SUCCESS_TYPE,
  MASTERSERVER_DELETE_SUCCESS_TYPE,
  MASTERSERVER_DELETE_FAIL_TYPE,
  MASTERSERVER_CREATE_SUCCESS_TYPE,
  MASTERSERVER_CREATE_FAIL_TYPE,
  MASTERSERVER_UPDATE_FAIL_TYPE,
  MASTERSERVER_UPDATE_SUCCESS_TYPE,
  MASTERSERVER_GET_FAIL_TYPE,
  MASTERSERVER_GET_SUCCESS_TYPE
} from "../services/Common";

const initialState = { data: [], isLoading: false, status: null, message: '' };

export const masterServerReducer = (state, action) => {
  state = state || initialState;

  if (action.type === MASTERSERVER_LIST_SUCCESS_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === MASTERSERVER_LIST_FAIL_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  if (action.type === MASTERSERVER_DELETE_SUCCESS_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === MASTERSERVER_DELETE_FAIL_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  if (action.type === MASTERSERVER_CREATE_SUCCESS_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  if (action.type === MASTERSERVER_CREATE_FAIL_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  if (action.type === MASTERSERVER_UPDATE_FAIL_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  if (action.type === MASTERSERVER_UPDATE_SUCCESS_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  if (action.type === MASTERSERVER_GET_FAIL_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  if (action.type === MASTERSERVER_GET_SUCCESS_TYPE) {
    return {
      data: action.data,
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }


  return state;
};