import React, { Component } from "react";
import classes from './index.module.css';
import Select from "components/Select";
import ReactDatetime from "react-datetime";
import Validate from "react-validate-form";
import { rules, validations } from "../../../helpers/validation";

// reactstrap components
import {
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

class SearchModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  

  render() {
    const { filter, field, handleChangeFilter, handleStatus, handleSelectCS, handleSelectWard, province, district, ward, startDate, endDate, handleChangeStartDate, handleChangeEndDate } = this.props;

    return (
      <div className={classes.searchArea}>
        {/* <div className={classes.searchRowBet}> */}
        <div className="row">
        <div className={`${classes.checkBoxItem} row`} style={{alignItems:'center', marginRight:50}}>
            <input
              name="status"
              type="checkbox"
              value={2}
              checked={filter.status === "2" ? true : false}
              className="checkbox-status"
              onClick={(event) => handleStatus(event)}
            />
            <label className={classes.activeStt} style={{textAlign:'center'}}>Đã duyệt</label>
          </div>

          <div className={`${classes.checkBoxItem} row`} style={{alignItems:'center'}}>
            <input
              name="status"
              type="checkbox"
              value={1}
              checked={filter.status === "1" ? true : false}
              className="checkbox-status"
              onClick={(event) => handleStatus(event)}
            />
            <label className={classes.notyetStt} style={{textAlign:'center'}}>Chưa duyệt</label>
          </div>
        </div>
        
        <div className={classes.searchRow}>
          <div className={`${classes.searchRowItem} ${classes.searchRowBetItem}`}>
            <div className={classes.item}>
              <label>Từ ngày</label>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Từ ngày"
                    }}
                    name='startDate'
                    value={filter.startDate}
                    timeFormat={false}
                    onChange={(e) => handleChangeStartDate(e)}
                  />
                </InputGroup>
              </FormGroup>
            </div>
            
            <div className={classes.item}>
              <label>Đến ngày</label>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Đến ngày"
                    }}
                    name='endDate'
                    value={filter.endDate}
                    timeFormat={false}
                    onChange={(e) => handleChangeEndDate(e)}
                  />
                </InputGroup>
              </FormGroup>
            </div>
          </div>
        </div>
        
        <div className={classes.searchRow}>
          <div className={classes.searchRowItem}>
            <label>Tên doanh nghiệp</label>
            <Input
              name="companyName"
              placeholder="Tên doanh nghiệp"
              value={filter.companyName}
              onChange={(event) => handleChangeFilter(event)}
              type="text"
            />
          </div>
        </div>

        <div className={classes.searchRow}>
          <div className={classes.searchRowItem}>
            <label>Mã số thuế</label>
            <Input
              placeholder="Mã số thuế" 
              type="number"
              name="taxCode"
              value={filter.taxCode}
              onChange={(event) => handleChangeFilter(event)}
            />
          </div>
        </div>

        
      </div>
    );
  }
};

export default SearchModal;
