import React, { Component } from 'react'
import { bindActionCreators } from "redux";
import compose from 'recompose/compose';
import { connect } from "react-redux";
import { Bar, Line } from 'react-chartjs-2';
import moment from 'moment';
import { PLEASE_CHECK_CONNECT } from "../../../../services/Common";
import { LIMIT_ITEM_IN_PAGE, LOADING_TIME } from "../../../../helpers/constant";
import { actionSaleRegisterReports } from "../../../../actions/SaleRegisterReportsActions";
import { actionLocationCreators } from "../../../../actions/LocationListAction";
import { Input } from 'reactstrap'

class ChartSaleRegister extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            newData: [],
            month: [],
            quarter: [],
            yearY: [],
            isLoaded: null,
            status: null,
            message: '',
            checkAtive: [{}],
            company: [],
            limit: LIMIT_ITEM_IN_PAGE,
            beginItem: 0,
            endItem: LIMIT_ITEM_IN_PAGE,
            totalElement: 0,
            listLength: 0,
            currentPage: 0,
            yearM: new Date().getFullYear(),
            yearQ: new Date().getFullYear(),
            dataYear: {
                "yearfromY": new Date().getFullYear() - 4,
                "yeartoY": new Date().getFullYear(),
            },
            displayName: [],
            displayNameQ: [],
            displayNameY: [],
            number1: [],
            number2: [],
            number3: [],
            number1Q: [],
            number2Q: [],
            number3Q: [],
            number1Y: [],
            number2Y: [],
            number3Y: [],
            isChecked: 1

        }
    }
    componentWillReceiveProps(nextProp) {
        let { data } = nextProp.saleRegisterReport;
        const { limit } = this.state;
        let displayName = [];
        let number1 = [];
        let number2 = [];
        let number3 = [];
        let displayNameQ = [];
        let number1Q = [];
        let number2Q = [];
        let number3Q = [];
        let displayNameY = [];
        let number1Y = [];
        let number2Y = [];
        let number3Y = [];

        let locationData = nextProp.location.data;

        if (data !== this.state.data) {
            if (typeof (data) !== 'undefined') {
                if (typeof (data.month) !== 'undefined') {
                    //console.log(data.month)
                    if (data.month !== null) {
                        if (typeof (data.month) !== 'undefined') {
                            data.month = data.month.sort((a, b) => a['displayName'] - b['displayName']);
                            for (const dataMap of data.month) {
                                displayName.push('Tháng ' + parseInt(dataMap.displayName));
                                number1.push(parseInt(dataMap.number1));

                            }

                            this.setState({
                                displayName: displayName,
                                number1: number1,

                            });

                            this.setState({
                                data: data.month,
                                month: data.month,
                                listLength: data.month.total,
                                totalPage: Math.ceil(data.month.length / limit),
                                isLoaded: data.isLoading, status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });

                        } else {
                            this.setState({
                                data: data.month,
                                month: data.month,
                                isLoaded: data.isLoading,
                                status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });
                        }
                    }
                }
                if (typeof (data.quarter) !== 'undefined') {
                    //console.log(data.month)
                    if (data.quarter !== null) {
                        if (typeof (data.quarter) !== 'undefined') {
                            data.quarter = data.quarter.sort((a, b) => a['displayName'] - b['displayName']);
                            for (const dataMap of data.quarter) {
                                displayNameQ.push('Quý ' + parseInt(dataMap.displayName));
                                number1Q.push(parseInt(dataMap.number1));

                            }

                            this.setState({
                                displayNameQ: displayNameQ,
                                number1Q: number1Q,

                            });

                            this.setState({
                                //data: data.quarter,
                                quarter: data.quarter,
                                listLength: data.quarter.total,
                                totalPage: Math.ceil(data.quarter.length / limit),
                                isLoaded: data.isLoading, status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });

                        } else {
                            this.setState({
                                //data: data.quarter,
                                quarter: data.quarter,
                                isLoaded: data.isLoading,
                                status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });
                        }
                    }
                }
                if (typeof (data.year) !== 'undefined') {
                    //console.log(data.month)
                    if (data.year !== null) {
                        if (typeof (data.year) !== 'undefined') {
                            data.year = data.year.sort((a, b) => a['displayName'] - b['displayName']);
                            for (const dataMap of data.year) {
                                displayNameY.push(parseInt(dataMap.displayName));
                                number1Y.push(parseInt(dataMap.number1));

                            }

                            this.setState({
                                displayNameY: displayNameY,
                                number1Y: number1Y,

                            });

                            this.setState({

                                yearY: data.year,
                                listLength: data.year.total,
                                totalPage: Math.ceil(data.year.length / limit),
                                isLoaded: data.isLoading, status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });

                        } else {
                            this.setState({

                                yearY: data.year,
                                isLoaded: data.isLoading,
                                status: data.status,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });
                        }
                    }
                }
            }
        }

        if (locationData !== this.state.district) {
            if (typeof (locationData) !== 'undefined') {
                if (typeof (locationData.district) !== 'undefined') {
                    if (locationData.district !== null) {
                        this.setState({
                            district: locationData.district,
                            isLoaded: false,
                        });
                    } else {
                        this.setState({
                            district: [],
                            isLoaded: false,
                        });
                    }
                }
            }
        }

        if (locationData !== this.state.ward) {

            if (typeof (locationData) !== 'undefined') {

                // if (typeof (locationData.ward) !== 'undefined') {
                if (locationData.ward !== null) {

                    this.setState({ ward: [] })
                    this.setState({
                        ward: locationData.ward,
                    });
                } else {
                    this.setState({
                        ward: [],
                        isLoaded: false,
                    });
                }
                //}
            }
        }

    }
    componentWillMount() {

    }
    componentDidMount() {
        const { getDistrictList } = this.props;
        let { yearM, yearQ, yearfromY, yeartoY } = this.state;
        getDistrictList();
        this.fetchSummary(yearM);
        // this.fetchSummaryQuarter(yearQ);
        // this.fetchSummaryYear(yearfromY, yeartoY)
    }
    componentDidUpdate() {
        this.closeStatusModal();
    }

    closeStatusModal = () => {
        const { status } = this.state;

        if (status || !status) {
            setTimeout(() => {
                this.setState({ status: null, isLoaded: false });
            }, LOADING_TIME);
        }
    }

    handleSelectDis = (value) => {
        if (this.redSelect) {
            this.redSelect.resetValue();
        }
        const { getWardList } = this.props;
        let { yearM, yearQ, isChecked, dataYear } = this.state;
        if (value == null) { value = '' }
        if (value) {
            getWardList(value);
        }
        this.setState({ districtId: value })
        if (isChecked == 1) {
            this.fetchSummary(yearM + '&districtId=' + value);
        } else if (isChecked == 2) {
            this.fetchSummaryQuarter(yearQ + '&districtId=' + value)
        } else if (isChecked == 3) {
            this.fetchSummaryYear(dataYear.yearfromY, dataYear.yeartoY + '&districtId=' + value)
        }
    }

    handleSelectWar = (value) => {
        let { yearM, yearQ, districtId, isChecked, dataYear } = this.state;
        if (value == null) { value = '' }
        this.setState({ wardId: value })
        if (isChecked == 1) {
            this.fetchSummary(yearM + '&districtId=' + districtId + '&wardId=' + value);
        } else if (isChecked == 2) {
            this.fetchSummaryQuarter(yearQ + '&districtId=' + districtId + '&wardId=' + value);
        } else if (isChecked == 3) {
            this.fetchSummaryYear(dataYear.yearfromY, dataYear.yeartoY + '&districtId=' + districtId + '&wardId=' + value)
        }
    }

    fetchSummary = (data) => {
        const { requestMonthReport } = this.props;

        requestMonthReport(data);
    }
    fetchSummaryQuarter = (data) => {
        const { requestQuarterReport } = this.props;

        requestQuarterReport(data);
    }
    fetchSummaryYear = (fromYear, toYear) => {
        const { requestYearReport } = this.props;

        requestYearReport(fromYear, toYear);
    }
    handleChange = (event) => {
        let { yearM } = this.state;
        const ev = event.target;
        yearM = ev['value'];
        this.setState({ yearM })
        this.fetchSummary(yearM);
    }
    handleChangeQuarter = (event) => {
        const { requestQuarterReport } = this.props;
        let { yearQ } = this.state;
        const ev = event.target;
        yearQ = ev['value'];
        this.setState({ yearQ })
        requestQuarterReport(yearQ);
    }
    handleChangeYear = (event) => {
        const { requestYearReport } = this.props;
        let { dataYear } = this.state;
        const ev = event.target;
        dataYear[ev['name']] = ev['value'];
        this.setState({ dataYear })
        // console.log(dataYear)
        requestYearReport(dataYear.yearfromY, dataYear.yeartoY);
    }
    setcheck(event) {
        let { isChecked, yearQ, yearM, dataYear } = this.state;
        const ev = event.target.value;
        isChecked = ev;
        this.setState({ isChecked })
        this.setState({
            yearM: new Date().getFullYear(),
            yearQ: new Date().getFullYear(),
            dataYear: {
                yearfromY: new Date().getFullYear() - 4,
                yeartoY: new Date().getFullYear(),
            }

        })
        if (isChecked == 1) {
            this.setState({ districtId: '' })
            if (this.redSelectDis) {
                this.redSelectDis.resetValue();
            }
            if (this.redSelect) {
                this.redSelect.resetValue();
            }
            this.fetchSummary(yearM);
        } else if (isChecked == 2) {
            this.setState({ districtId: '' })
            if (this.redSelectDis) {
                this.redSelectDis.resetValue();
            }
            if (this.redSelect) {
                this.redSelect.resetValue();
            }
            this.fetchSummaryQuarter(yearQ)
        } else if (isChecked == 3) {
            this.setState({ districtId: '' })
            if (this.redSelectDis) {
                this.redSelectDis.resetValue();
            }
            if (this.redSelect) {
                this.redSelect.resetValue();
            }
            this.fetchSummaryYear(dataYear.yearfromY, dataYear.yeartoY)

        }
    }
    render() {
        const { yearM, displayName, number1 } = this.state
        return (
            <>
                <div className='header-box-list box-list-border display-box'>
                    <h4>Doanh thu đăng ký</h4>
                    <Input
                        type='number'
                        name='year'
                        defaultValue={yearM}
                        onBlur={event => {
                            this.handleChange(event);
                        }}
                        onKeyUp={event => {
                            if (event.which == 13) {
                                this.handleChange(event);
                            }
                        }}
                    />
                </div>
                <div style={{ padding: 10 }}>
                    {
                        displayName ? (<>
                            <Line
                                data={{
                                    labels: displayName,
                                    datasets: [{
                                        label: "Doanh thu",
                                        type: "line",
                                        borderColor: "#60C3FF",
                                        borderWidth: 1,
                                        backgroundColor: "#60C3FF",
                                        data: number1,
                                        fill: false
                                    }]
                                }}
                                options={{
                                    responsive: true,
                                    title: {
                                        display: true,
                                        text: 'DOANH THU TIỀN ĐĂNG KÝ NĂM ' + yearM,

                                    },
                                    legend: {
                                        display: true,
                                        position: 'bottom'
                                    },
                                    scales: {
                                        yAxes: [{
                                            display: true,
                                            ticks: {
                                                suggestedMin: 0,
                                            }
                                        }]
                                    }
                                }}
                            />
                        </>) : (<>
                            <div className="product-report-list-empty"><p className="product-report-list-empty-text">Hiện chưa có dữ liệu</p></div>
                        </>)
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        saleRegisterReport: state.SaleRegisterReportsStore,
        location: state.LocationStore,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionSaleRegisterReports, dispatch),
        ...bindActionCreators(actionLocationCreators, dispatch),
    }
}

export default compose(
    connect(

        mapStateToProps,
        mapDispatchToProps
    )
)(ChartSaleRegister)