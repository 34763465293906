import React, { Component } from "react";
import classes from './index.module.css';
import { bindActionCreators } from "redux";
import compose from 'recompose/compose';
import { connect } from "react-redux";
import { actionCompanyListDeleted } from "../../../actions/CompanyListDeletedActions";
import checkIcon from "../../../assets/img/buttons/DauCheck.svg";
import {
  EXTENSION_FILE_IMAGE
} from 'bases/helper';
import NoImg from "../../../assets/img/NoImg/NoImg.jpg"

class UpdateModal2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataCompany: {},
      dataPartners: [],
      activeSubmit: false,
      pathImageDefaul: '',
      mfileImg: '',
      ArrayFileAdd: '',
    }
    this.refFileImage = null;
  }

  async componentWillMount() {
    const { requestGetCompanyDeleted, id } = this.props;
    if (id) {
      await requestGetCompanyDeleted(id).then(
        res => {
          const data = (res || {}).data || {};

          let dataCompany = (data || {}).data || {};

          if (dataCompany.fileDeleted) {
            const pIm = dataCompany.fileDeleted;
            const spl = pIm.split(';');
            this.setState(previousState => {
              return {
                ...previousState,
                pathImageDefaul: spl
              }
            });
          }

          this.setState(previousState => {
            return {
              ...previousState,
              dataCompany,
              id,
              data,
              fileImage: dataCompany.verifiedImage
            }
          });
        }
      )
    }
  }

  render() {
    const { pathImageDefaul, dataCompany } = this.state;


    return (
      <div className={classes.formControl}>
        <div className={classes.rowItem} style={{ justifyContent: 'flex-start' }}>
          <label
            className="form-control-label width-control-label"
          >
            Các loại giấy tờ liên quan:
          </label>
        </div>

        <div className={classes.rowItem} style={{ width: '100%', justifyContent: 'flex-start' }}>
          <input onChange={this.onChangeFileImage}
            style={{ display: 'none' }} ref={ref => this.refFileImage = ref}
            multiple type='file' className='hidden' />
          <div style={{ marginLeft: 0, marginRight: 0, width: '100%' }}>
            {pathImageDefaul &&
              <div className={classes.inputArea} style={{ padding: '5px 15px' }}>
                {Array.isArray(pathImageDefaul) && (
                  pathImageDefaul.map((item, key) => {

                    let listViewAttachmentsImg = [];
                    let listViewAttachmentsFile = [];

                    if (EXTENSION_FILE_IMAGE.find(p => p == item.split('.').pop())) {
                      listViewAttachmentsImg.push(item);
                    } else {
                      listViewAttachmentsFile.push(item);
                    }

                    return <div key={key}>
                      <div style={{ textAlign: 'left' }} className='table-scale-col'>
                        {listViewAttachmentsImg ?
                          (
                            Array.isArray(listViewAttachmentsImg) && (
                              listViewAttachmentsImg.map((lst, key) => {
                                return (
                                  <>
                                    {lst ? (
                                      <>
                                        <div className="row">
                                          <img src={checkIcon} width={21} height={21} />

                                          <a key={key} target='_blank' href={lst}
                                            style={{
                                              marginRight: '3px',
                                              maxWidth: '350px',
                                              display: 'block',
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                              marginBottom: 5
                                            }}>
                                            &ensp;{lst.split("/").pop()}
                                          </a>&ensp;

                                        </div>
                                      </>) : <img width={50} height={50} src={NoImg} />}
                                  </>
                                )
                              }
                              )
                            )
                          )
                          : null
                        }

                        {listViewAttachmentsFile ?
                          (
                            Array.isArray(listViewAttachmentsFile) && (
                              listViewAttachmentsFile.map((lst, key) => (
                                <>
                                  <div className="row">
                                    <img src={checkIcon} width={21} height={21} />
                                    <a key={key} target='_blank' href={lst}
                                      style={{
                                        marginRight: '3px',
                                        maxWidth: '350px',
                                        display: 'block',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        marginBottom: 5
                                      }}>
                                      &ensp;{lst.split("/").pop()}
                                    </a>&ensp;

                                  </div>
                                </>
                              ))
                            )
                          )
                          : null
                        }
                      </div>
                    </div>
                  })
                )}
              </div>
            }
          </div>

        </div>

        <div className={classes.rowItem} style={{ justifyContent: 'flex-start' }}>
          <label
            className="form-control-label"
            style={{ minWidth: 500 }}
          >
            Lý do xoá:
          </label>
        </div>

        <div className={classes.rowItem} style={{ justifyContent: 'flex-start' }}>
          {/* <img src={checkIcon} width={21} height={21} />&ensp; */}
          <div style={{ fontWeight: 'bold', color: '#000' }}>{dataCompany.reasonDeleted}</div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    companyDelete: state.CompanyListDeletedStore,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(actionCompanyListDeleted, dispatch),
  }
}
export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UpdateModal2);
