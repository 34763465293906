import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import './DashboardChart.css';
import '../DashboardList/DashboardList.css';
import { Row, Nav, Col } from 'reactstrap';
import { actionCompanyReports } from '../../../../actions/CompanyReportsActions';
import { actionLocationCreators } from '../../../../actions/LocationListAction';
import ChartSaleRegister from './ChartSaleRegister';
import ChartCompany from './ChartCompany';

class DashboardChart extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {

        return (
            <>
                <section >
                    <Row>
                        <Col xl='6' lg="12" md='12' xs='12' className='mr-t-box'>
                            <div className='box-list'>
                                <ChartCompany />
                            </div>
                        </Col>
                        <Col xl='6' lg="12" md='12' xs='12' className='mr-t-box'>
                            <div className='box-list'>
                                <ChartSaleRegister />
                            </div>
                        </Col>
                    </Row>
                </section>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        companyReport: state.CompanyReportsStore,
        location: state.LocationStore,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCompanyReports, dispatch),
        ...bindActionCreators(actionLocationCreators, dispatch),
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(DashboardChart)