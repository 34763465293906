import {
    CONFIG_FETCH_CONFIG_SYSTEM_SUCCESS,
    CONFIG_FETCH_INFO_COMPANY_SUCCESS,
    CONFIG_FETCH_LIST_CONFIG_SERVER_SUCCESS,
    CONFIG_FETCH_LIST_PROVINCE_FOR_INFO_COMPANY_SUCCESS,
    CONFIG_FETCH_LIST_DISTRICT_FOR_INFO_COMPANY_SUCCESS,
    CONFIG_FETCH_LIST_WARD_FOR_INFO_COMPANY_SUCCESS,
    CONFIG_GET_FTP_SUCCESS
} from "../services/Common";

const initialState = { infoCompany: {}, configSystem: {}, configServers: [], provincesForInfoCompany: [], districtsForInfoCompany: [], wardsForInfoCompany: [] };

export const configSystemReducer = (state, action) => {
    state = state || initialState;

    if (action.type === CONFIG_FETCH_CONFIG_SYSTEM_SUCCESS) {
        return {
            ...state,
            configSystem: action.data
        };
    }

    if (action.type === CONFIG_FETCH_INFO_COMPANY_SUCCESS) {
        return {
            ...state,
            infoCompany: action.data
        };
    }

    if (action.type === CONFIG_FETCH_LIST_CONFIG_SERVER_SUCCESS) {
        return {
            ...state,
            configServers: action.data
        };
    }

    if (action.type === CONFIG_FETCH_LIST_PROVINCE_FOR_INFO_COMPANY_SUCCESS) {
        return {
            ...state,
            provincesForInfoCompany: action.data,
            data: action.data
        };
    }

    if (action.type === CONFIG_FETCH_LIST_DISTRICT_FOR_INFO_COMPANY_SUCCESS) {
        return {
            ...state,
            districtsForInfoCompany: action.data
        };
    }

    if (action.type === CONFIG_FETCH_LIST_WARD_FOR_INFO_COMPANY_SUCCESS) {
        return {
            ...state,
            wardsForInfoCompany: action.data
        };
    }

    if (action.type === CONFIG_GET_FTP_SUCCESS) {
        return {
            ...state,
            getFtp: action.data
        };
    }

    return state;
};