import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import compose from 'recompose/compose';
import { connect } from "react-redux";
import './DashboardList.css';


import ListCompany from './ListCompany';
import ListUser from './ListUser';

import { Row, Col } from 'reactstrap'
class DashboardList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
        }
    }
    onChooseTab = tab => () => {
        this.setState(previousState => {
            return {
                ...previousState,
                currentTab: tab
            }
        }, () => {
            if (tab == 0) {
                // this.onChangeLogoInfoCompany();
                // this.getInfoCompany();
                // this.onSaveInfoCompany()
            } else if (tab == 1) {
                // this.getInfoCompany();
            }
            // } else if (tab == 2) {
            //     this.getListConfigServer();
            // } else if (tab == 3) {
            //     this.getListStampTemplate();
            // }
            else if (tab == 4) {
                // this.getListAlert();
            }
        });
    }
    render() {
        const { currentTab } = this.state
        return (
            <>
                <section >
                    <Row>
                        <Col xl='8' lg='6' md='6' xs='12' className='mr-t-box'>
                            <div className='box-list'>
                                <ListCompany />
                            </div>

                        </Col>
                        <Col xl='4' lg='6' md='6' xs='12' className='mr-t-box'>
                            <div className='box-list'>
                                <ListUser />
                            </div>
                        </Col>
                    </Row>
                </section>
            </>
        )
    }
}

export default compose(
    connect(

    )
)(DashboardList)

