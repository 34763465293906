import React, { Component, useState } from "react";
import { bindActionCreators } from "redux";
import compose from 'recompose/compose';
import { connect } from "react-redux";
import { actionCompanyListComfirmed } from "../../../actions/CompanyListComfirmedActions";
import { actionCompanyGetDetails } from "../../../actions/CompanyGetDetailsActions";
import { actionField } from "../../../actions/FieldActions.js";
import { actionLocationCreators } from "../../../actions/LocationListAction";
import { COMPANY_COMFIRMED_HEADER } from "../../../helpers/constant";
import { PLEASE_CHECK_CONNECT, ACCOUNT_CLAIM_FF, ACCOUNT_ID, IS_ADMIN } from "../../../services/Common";
import { setAlertContext, openAlertContext } from "../../../helpers/common.js";
import './companyNotComfirm.css'
import { actionGetListNewly } from "../../../actions/NewRegBusAction";
import moment from 'moment';
import Pagination from "components/Pagination";
import HeaderTable from "components/HeaderTable";
import HeadTitleTable from "components/HeadTitleTable";
import classes from './index.module.css';
import { LIMIT_ITEM_IN_PAGE, LOADING_TIME } from "../../../helpers/constant";
import SearchModal from "./SearchModal";
import { generateStyleTableCol } from '../../../bases/controls/helper';
import '../../../assets/css/global/index.css';
import '../../../assets/css/page/user.css';
import MenuButton from "../../../assets/img/buttons/menu.png";
import SearchImg from "../../../assets/img/buttons/searchig.svg";
import ViewPopup from "../../../components/ViewPopup";
import ViewModal from "./ViewModal";
import Select from "components/Select";
import Duyet from "assets/img/buttons/Duyet.svg";
import WarningPopup from "../../../components/WarningPopup";
import PopupMessage from "../../../components/PopupMessage";
import WarningPopupDel from "../../../components/WarningPopupDel";
import DeleteConfirm from "./DeleteConfirm";
import UpdatePopup2 from "../../../components/UpdatePopup2";
import { actionGetListCompanyAwait } from "../../../actions/CompanyAwaitActions";


import {
  Card,
  Table,
  Container,
  Row,
  Spinner,
  Button,
  Input,
  InputGroup,
  ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";

class CompanyComfirmed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      fileUpdate: [],
      field: [],
      province: [],
      district: [],
      ward: [],
      errmessage: '',
      detail: null,
      update: null,
      create: null,
      delete: null,
      isLoaded: null,
      status: null,
      open: false,
      message: '',
      history: [],
      dataMaping: [
        'index', 'reason', 'fieldName', 'companyName', 'taxCode', 'phoneNumber', 'address', 'confirmedDate', 'confirmedBy',
      ],
      searchData: [],
      filterList: [],
      checkAtive: [{}],
      typeAlign: [
        {
          type: 'number', position: [4, 5]
        },
        {
          type: 'date', position: [7]
        },
        {
          type: 'hours', position: []
        }
      ],
      headerTitle: COMPANY_COMFIRMED_HEADER,
      limit: LIMIT_ITEM_IN_PAGE,
      beginItem: 0,
      endItem: LIMIT_ITEM_IN_PAGE,
      totalElement: 0,
      listLength: 0,
      currentPage: 0,
      filter: {
        "fieldID": "",
        "comapanyName": "",
        "taxCode": "",
        "phone": "",
        "email": "",
        "provinceID": "",
        "districtID": "",
        "wardID": "",
        "orderBy": "",
        "page": null,
        "limit": null,
      },
    }
  }

  componentWillReceiveProps(nextProp) {
    let { data } = nextProp.company;
    let fieldData = nextProp.field.data;
    const { limit } = this.state;
    let locationData = nextProp.location.data;
    let detailsData = nextProp.details.data;

    if (fieldData !== this.state.field) {
      if (typeof (fieldData) !== 'undefined') {
        if (fieldData.fieldCompanyComfirmed !== null) {
          if (typeof (fieldData.fieldCompanyComfirmed) !== 'undefined') {
            this.setState({ field: fieldData.fieldCompanyComfirmed.fields, isLoaded: false, status: data.status, message: PLEASE_CHECK_CONNECT(data.message) });
          } else {
            this.setState({ field: fieldData.fieldCompanyComfirmed, isLoaded: false, status: data.status, message: PLEASE_CHECK_CONNECT(data.message) });
          }
        }
      }
    }

    if (data !== this.state.data) {
      if (typeof (data) !== 'undefined') {
        if (typeof (data.company) !== 'undefined') {
          if (data.company !== null) {
            if (typeof (data.company.companies) !== 'undefined') {
              data.company.companies.map((item, key) => {
                item['index'] = key + 1;
                item['collapse'] = false;
                // item['confirmedDate'] = moment(item.confirmedDate).format('DD/MM/YYYY')
              });
              let totalElement = 0;

              if (data.company.companies.length > limit) {
                totalElement = limit;
              } else {
                totalElement = data.company.companies.length;
              }
              this.setState({
                totalElement,
                data: data.company.companies,
                history: data.company.companies,
                listLength: data.company.companies.length,
                totalPage: Math.ceil(data.company.companies.length / limit),
                isLoaded: data.isLoading,
                status: data.status,
                message: PLEASE_CHECK_CONNECT(data.message)
              });
            } else {
              this.setState({
                data: data.company.companies,
                history: data.company.companies,
                isLoaded: data.isLoading,
                status: data.status,
                message: PLEASE_CHECK_CONNECT(data.message)
              });
            }
          }
        }
      }
    }

    if (typeof (detailsData) !== 'undefined') {
      if (detailsData.details !== null) {
        if (typeof (detailsData.details) !== 'undefined') {
          this.setState({
            xem: detailsData.details,
            detail: detailsData.details,
            detailView: detailsData.details,
            isLoaded: false,
            status: data.status,
            message: PLEASE_CHECK_CONNECT(data.message)
          });
        } else {
          this.setState({
            xem: detailsData.details,
            isLoaded: false,
            status: data.status,
            message: PLEASE_CHECK_CONNECT(data.message)
          });
        }
      }
    }

    if (locationData !== this.state.province) {
      if (typeof (locationData) !== 'undefined') {
        if (typeof (locationData.province) !== 'undefined') {

          if (locationData.province !== null) {

            this.setState({
              province: locationData.province,
              isLoaded: false,
              status: data.status,
              message: PLEASE_CHECK_CONNECT(data.message)
            });
          } else {
            this.setState({
              province: [],
              isLoaded: false,
              status: data.status,
              message: PLEASE_CHECK_CONNECT(data.message)
            });
          }
        }
      }
    }

    if (locationData !== this.state.district) {
      if (typeof (locationData) !== 'undefined') {
        if (typeof (locationData.district) !== 'undefined') {
          if (locationData.district !== null) {
            this.setState({
              district: locationData.district,
              isLoaded: false,
              status: data.status,
              message: PLEASE_CHECK_CONNECT(data.message)
            });
          } else {
            this.setState({
              district: [],
              isLoaded: false,
              status: data.status,
              message: PLEASE_CHECK_CONNECT(data.message)
            });
          }
        }
      }
    }

    if (locationData !== this.state.ward) {

      if (typeof (locationData) !== 'undefined') {

        // if (typeof (locationData.ward) !== 'undefined') {
        if (locationData.ward !== null) {

          this.setState({ ward: [] })
          this.setState({
            ward: locationData.ward,
            isLoaded: false,
            status: data.status,
            message: PLEASE_CHECK_CONNECT(data.message)
          });
        } else {
          this.setState({
            ward: [],
            isLoaded: false,
            status: data.status,
            message: PLEASE_CHECK_CONNECT(data.message)
          });
        }
        //}
      }
    }
  }

  componentWillMount() {
    const { requestFieldCompanyComfirmedStore } = this.props
    const { getProvinceList } = this.props;
    const { getDistrictList } = this.props;

    requestFieldCompanyComfirmedStore(JSON.stringify({
      "search": "",
      "filter": "",
      "orderBy": "",
      "page": null,
      "limit": null
    }));
    getProvinceList();
    getDistrictList();
  }
  handleSelect = (value, name) => {
    let { filter } = this.state;
    //const { getWardList } = this.props;
    filter[name] = value;
    this.setState({ filter });
    //getWardList(filter.districtID)

  }
  handleSelectWard = (value, name) => {
    let { filter } = this.state;
    const { getWardList } = this.props;
    filter[name] = value;
    this.setState({ filter });
    getWardList(filter.districtID)

  }
  componentDidMount() {
    // This method is called when the component is first added to the document
    this.filterMapKey();

    /* Fetch Summary */
    this.fetchSummary(JSON.stringify({
      "search": "",
      "filter": "",
      "orderBy": "",
      "page": null,
      "limit": null
    }));
  }

  handleOpenEdit = (id) => {
    const { requestCompanyGetDetails, requestCompanyGetFileUpdate } = this.props;
    requestCompanyGetFileUpdate(id).then((res) => {
      if (((res || {}).data || {}).status === 200) {
        let listXXX = res.data.data;
        
        listXXX.map(list => {
          if (list.uploadDate) {
            list.sorted_file = list.uploadDate;
          } else {
            list.sorted_file = list.registeredDate;
          }
        })
        let sorted_file = listXXX.sort((a, b) => {
          return new Date(a.sorted_file).getTime() -
            new Date(b.sorted_file).getTime()
        }).reverse();
        this.setState({ fileUpdate: sorted_file })
        
      }
    })
    requestCompanyGetDetails(id);
  }

  componentDidUpdate() {
    // This method is called when the route parameters change
    this.closeStatusModal();
  }

  fetchSummary = (data) => {
    const { requestCompanyListComfirmed } = this.props;

    requestCompanyListComfirmed(data);
  }

  filterMapKey = () => {
    let { dataMaping, filterList } = this.state;
    let newFilterMap = [];

    dataMaping.filter((item, key) => {
      typeof (filterList[key]) !== 'undefined' && (
        newFilterMap.push({ index: key, value: filterList[key] })
      )
    });

    filterList = [];
    this.setState({ filterList: newFilterMap });
  }
  searchTable = (event) => {
    let { data, history } = this.state;
    let value = event.target.value.trim();

    if (value.length === 0) {
      data = data.filter(item => item['companyName'] !== null);
    }
    else {
      data = data.filter(item =>
        item['companyName'] !== null && item['companyName'].toLowerCase().includes(value)
      );
    }

    this.setState({ searchData: data });
  }
  closeStatusModal = () => {
    const { status } = this.state;

    if (status || !status) {
      setTimeout(() => {
        this.setState({ status: null, isLoaded: false });
      }, LOADING_TIME);
    }
  }

  handleSelectJob = (event) => {
    let { data, field, history } = this.state;
    let fieldNameCurrent = null;

    // Get all
    if (Number(event.target.value) === -1) {
      data = history;
    } else {
      field.filter(item => item.id === event.target.value)
        .map(item => (
          fieldNameCurrent = item.fieldName
        ));

      Array.isArray(history) ? (
        data = history.filter(item =>
          item.fieldName === fieldNameCurrent
        ).map(item => item = item)
      ) : (
        data = history
      )
    }

    this.setState({ data });
  }
  handlePageClick = (data) => {
    let { limit, beginItem, endItem } = this.state;
    let selected = data.selected;
    let offset = Math.ceil(selected * limit);
    let total = 0;

    beginItem = offset;
    endItem = offset + limit;

    this.state.data.map((item, key) => (
      key >= beginItem && key < endItem && total++
    ));

    if (selected > 0) {
      total = (selected * limit) + total;
    } else total = total;

    this.setState({ beginItem: beginItem, endItem: endItem, currentPage: selected + 1, totalElement: total });
  };
  handleChangeFilter = (event) => {
    let { filter } = this.state;
    const ev = event.target;

    filter[ev['name']] = ev['value'];

    this.setState({ filter });
  }
  handleStatus = (event) => {
    for (let i = 0; i < document.getElementsByClassName('checkbox-status').length; i++) {
      document.getElementsByClassName('checkbox-status')[i].checked = false;
    }

    event.target.checked = true;
    this.handleChangeFilter(event);
  }
  handleSubmitSearchForm = () => {
    const { filter } = this.state;
    this.clearFilter();
    this.fetchSummary(JSON.stringify(filter));
  }
  clearFilter = () => {
    const { filter } = this.state;
    let clearFilter = {
      "fieldID": "",
      "comapanyName": "",
      "taxCode": "",
      "phone": "",
      "email": "",
      "provinceID": "",
      "districtID": "",
      "wardID": "",
      "orderBy": "",
      "page": null,
      "limit": null,
    }
    this.setState({ filter: clearFilter })
  }
  toggle = (el, val) => {
    let { data } = this.state;

    data.filter(item => item.id === val)
      .map(item => item.collapse = !item.collapse);

    this.setState({ data });
  }

  toggleModal = (state, type) => {
    if (this.state[state] && type == 1) {
      return;
    } else {
      this.setState({
        [state]: !this.state[state],
        detail: null,
        errorUpdate: {},
        detailView: null,
      });
    }
  };

  handleComfirmRow = () => {
    const { requestCompanyComfirmStore, requestCompanyNotComfrim } = this.props;
    let { data, comfirmItem } = this.state;
    let newData = data.filter(item => item.id === comfirmItem).map((item, key) => {
      item.status = 0
    });


    requestCompanyComfirmStore(comfirmItem).then(res => {
      if (res.data.status === 200) {
        requestCompanyNotComfrim(JSON.stringify({
          "search": "",
          "filter": "",
          "orderBy": "",
          "page": null,
          "limit": null
        }));
        this.toggleModal('viewModal')
      }
      else {
        this.setState({
          comfirm: [],
          isLoading: true,
          status: false,

          errmessage: PLEASE_CHECK_CONNECT(res.data.message)
        });
        this.toggleModal('popupMessage')
      }
    })
  }

  handleDeleteRow = () => {
    const { deleteCompanyRegisteredStore } = this.props;
    let { deleteItem } = this.state;

    deleteCompanyRegisteredStore(deleteItem)
      .then(res => {

        if (res.data.status == 200) {
          this.fetchSummary(JSON.stringify({
            "search": "",
            "filter": "",
            "orderBy": "",
            "page": null,
            "limit": null
          }))
        } else {
          this.setState({ errmessage: res.data.message }, () => {
            this.toggleModal('popupMessage')
          })
        }
      })

  }

  handleDeleteCompanyAwait = () => {
    const { idDelete, newData, mfileImg, fileImage } = this.state;
    const { deleteCompayAwait } = this.props;
    const formData = new FormData();
    const errorUpdate = {};

    this.setState(previousState => {
      return {
        ...previousState,
        errorUpdate
      }
    });

    if (((mfileImg || []).length <= 0 || mfileImg == null) && ((fileImage || []).length <= 0 || fileImage == null)) {
      errorUpdate.file = 'Hồ sơ không được bỏ trống'
    }
    if (!newData.ReasonDeleted) {
      errorUpdate.ReasonDeleted = 'Lý do  không được bỏ trống'
    }

    formData.append('ID', idDelete);
    formData.append('ReasonDeleted', newData.ReasonDeleted ? newData.ReasonDeleted : '');
    formData.append('FileDeleted', fileImage ? fileImage : '');
    if (mfileImg) {
      for (let i = 0; i < mfileImg.length; i++) {
        formData.append(`FileDeletedFiles`, mfileImg[i])
      }
    }

    if (Object.keys(errorUpdate).length > 0) {
      this.setState(previousState => {
        return {
          ...previousState,
          errorUpdate,
        }
      });

      return;
    }

    if (idDelete) {
      deleteCompayAwait(formData).then(res => {

        if (res.data.status == 200) {
          this.fetchSummary(JSON.stringify({
            "fieldID": "",
            "comapanyName": "",
            "taxCode": "",
            "phone": "",
            "email": "",
            "provinceID": "",
            "districtID": "",
            "wardID": "",
            "orderBy": "",
            "page": null,
            "limit": null,
          }));
          this.toggleModal('updateModal2');
        } else {
          this.setState({ message: res.data.message });
          this.toggleModal('popupMessage')
        }
      })
    }
  }

  handleNewDataDelete = (data, mfileImg, fileImage) => {
    this.setState({ newData: data, mfileImg: mfileImg, fileImage: fileImage });
  }

  handleCheckValidation = (status) => {
    this.setState({ activeCreateSubmit: status });
  }

  render() {
    // const { classes } = this.props;
    const {
      isLoaded,
      status,
      message,
      data,
      searchData,
      filterList,
      dataMaping,
      checkAtive,
      field,
      typeAlign,
      beginItem,
      endItem,
      listLength,
      totalPage,
      totalElement,
      headerTitle,
      limit,
      filter,
      province,
      district,
      ward,
      warningPopupModal,
      activeCreateSubmit,
      detailView,
      viewModal,
      errmessage,
      popupMessage,
      warningPopupDelModal,
      errorUpdate,
      updateModal2,
      newData,
      fileUpdate
    } = this.state;
    const statusPopup = { status: status, message: message };
    let isDisableDelete = true;
    if (IS_ADMIN) {
      isDisableDelete = false;
    } else {
      ACCOUNT_CLAIM_FF.filter(x => x == "UnconfirmedCompanies.Delete").map(y => isDisableDelete = false)
    }

    return (
      <>
        {
          < div className={classes.wrapper} >
            <Container fluid>
              {
                isLoaded ? (
                  <div style={{ display: 'table', margin: 'auto' }}>
                    <Spinner style={{ width: '3rem', height: '3rem' }} />
                  </div>
                ) : (
                  <Row>
                    <div className="col">
                      {/* Header */}
                      <HeaderTable
                        dataReload={() => this.fetchSummary(
                          JSON.stringify({
                            "search": "",
                            "filter": "",
                            "orderBy": "",
                            "page": null,
                            "limit": null
                          })
                        )}
                        hideSearch={true}
                        hideCreate={true}
                        searchForm={
                          <SearchModal
                            filter={filter}
                            field={field}
                            district={district}
                            province={province}
                            ward={ward}
                            handleChangeFilter={this.handleChangeFilter}
                            handleStatus={this.handleStatus}
                            handleSelect={this.handleSelect}
                            handleSelectWard={this.handleSelectWard}
                          />
                        }
                        typeSearch={
                          <>
                            <div className="div_flex w_div_100 flex-div_search margin_right_div_search" >
                              <div className="mg-div-search w_input">
                                <label className="form-control-label">Ngành nghề</label>
                                <div>
                                  <Select
                                    name="fieldID"
                                    defaultValue={filter.fieldID}
                                    title='Chọn ngành nghề'
                                    data={field}
                                    labelName='fieldName'
                                    val='id'
                                    handleChange={this.handleSelect}
                                  />
                                </div>
                              </div>
                              <div className="mg-div-search w_input">
                                <label className="form-control-label">Tên doanh nghiệp</label>
                                <div>
                                  <InputGroup className="input-group-alternative css-border-input">
                                    <Input
                                      placeholder="Tên doanh nghiệp"
                                      name="comapanyName"
                                      value={filter.comapanyName}
                                      onChange={(event) => this.handleChangeFilter(event)}
                                      type="text"
                                    />
                                  </InputGroup>
                                </div>
                              </div>
                              <div className="mg-div-search w_input">
                                <label className="form-control-label">Mã số thuế</label>
                                <div>
                                  <InputGroup className="input-group-alternative css-border-input">
                                    <Input
                                      placeholder="Mã số thuế"
                                      type="number"
                                      name="taxCode"
                                      value={filter.taxCode}
                                      onChange={(event) => this.handleChangeFilter(event)}
                                    />
                                  </InputGroup>
                                </div>
                              </div>
                              <div className="mg-btn">
                                <label className="form-control-label">&nbsp;</label>
                                <Button
                                  style={{ margin: 0 }}
                                  className='btn-warning-cs'
                                  color="default" type="button" size="md"
                                  onClick={() => {
                                    this.handleSubmitSearchForm();
                                    // this.onComfirmSearch()
                                  }
                                  }
                                >
                                  <img src={SearchImg} alt='Tìm kiếm' />
                                  <span>Tìm kiếm</span>
                                </Button>
                              </div>
                            </div>
                          </>

                        }
                        handleSubmitSearchForm={() => this.handleSubmitSearchForm()}
                      />

                      {/* Table */}
                      <Card className="shadow">
                        <Table className="align-items-center tablecs table-css-companyNotComfirm" responsive>
                          <HeadTitleTable headerTitle={headerTitle} classHeaderColumns={{
                            0: 'table-scale-col table-user-col-1'
                          }} />

                          <tbody ref={ref => this.tableBody = ref}>
                            {
                              Array.isArray(data) && (
                                data
                                  .filter((item, key) => key >= beginItem && key < endItem)
                                  .map((item, key) => (
                                    <tr key={key} style={{ ...generateStyleTableCol(this.tableBody, (data || []).length) }} className="table-hover-css">
                                      <td className='table-scale-col table-user-col-1'>{item.index}</td>
                                      {/* <td className={`${item.status === 0 || item.status === null ? classes.noActiveStt : classes.activeStt}`}>{item.strStatus}</td> */}
                                      <td style={{ textAlign: 'left' }} className='table-scale-col'>

                                        {/* {
                                          item.confirmedDate ? (
                                            <>Ngày kiểm duyệt:&nbsp; {moment(item.confirmedDate).format('DD/MM/YYYY')}<br /></>
                                          ) : null
                                        } */}
                                        Ngày kiểm duyệt:&nbsp;{item.confirmedDate ? moment(item.confirmedDate).format('DD/MM/YYYY') : ''}<br />
                                        Người kiểm duyệt:&nbsp;<span>{item.confirmedBy}</span><br />
                                        {/* Lý do không duyệt:&nbsp;{item.reason} */}
                                      </td>
                                      <td style={{ textAlign: 'left' }} className='table-scale-col'>{item.fieldName}</td>
                                      {/* <td style={{ textAlign: 'left' }}>{item.companyName}</td> */}
                                      <td style={{ textAlign: 'left' }} className='table-scale-col'>
                                        <span><strong>{item.companyName}</strong></span><br />
                                        <span style={{ fontStyle: 'italic' }}>{item.address}</span>
                                      </td>
                                      <td style={{ textAlign: 'right' }} className='table-scale-col'>{item.taxCode}</td>
                                      <td style={{ textAlign: 'right' }} className='table-scale-col'>{item.phoneNumber}</td>
                                      <td style={{ textAlign: 'right' }} className='table-scale-col'>{item.registeredDate ? moment(item.registeredDate).format('DD/MM/YYYY') : ''}</td>
                                      <td>
                                        <ButtonDropdown
                                          isOpen={item.collapse}
                                          toggle={() => {
                                            this.toggle(key, item.id);
                                            this.setState({ itemIdView: item.id })
                                          }}>
                                          <DropdownToggle>
                                            <img src={MenuButton} />
                                          </DropdownToggle>
                                          <DropdownMenu>
                                            <DropdownItem
                                              onClick={() => {
                                                this.toggleModal('viewModal');
                                                this.handleOpenEdit(item.id);
                                              }}
                                            >
                                              Xem
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </ButtonDropdown>
                                      </td>
                                    </tr>
                                  ))
                              )
                            }
                          </tbody>
                        </Table>
                      </Card>

                      {/* Pagination */}
                      {
                        // Page of Table
                        Array.isArray(data) && (
                          data.length > 0 && (
                            <Pagination
                              data={data}
                              listLength={listLength}
                              totalPage={totalPage}
                              totalElement={totalElement}
                              handlePageClick={this.handlePageClick}
                            />
                          )
                        )
                      }
                    </div>
                  </Row>
                )
              }



              {
                detailView !== null && (
                  <ViewPopup
                    moduleTitle='Xem thông tin'
                    moduleBody={
                      <ViewModal
                        data={detailView}
                        fileUpdate={fileUpdate}
                      />
                    }
                    viewModal={viewModal}
                    toggleModal={this.toggleModal}
                    activeSubmit={activeCreateSubmit}
                    handleUpdateInfoData={this.componentExtend}
                  />
                )
              }
              <UpdatePopup2
                moduleTitle='Xác nhận xoá'
                resize="md"
                moduleBody={
                  <DeleteConfirm
                    errorUpdate={errorUpdate}
                    handleCheckValidation={this.handleCheckValidation}
                    handleNewData={this.handleNewDataDelete}
                  />}
                newData={newData}
                updateModal2={updateModal2}
                toggleModal={this.toggleModal}
                activeSubmit={activeCreateSubmit}
                handleUpdateInfoData={this.handleDeleteCompanyAwait}
              />
              <WarningPopupDel
                moduleTitle='Thông báo'
                moduleBody={
                  <p style={{ textAlign: 'center', fontSize: '1.2rem' }}>
                    Bạn đồng ý xóa thông tin này?
                  </p>}
                warningPopupDelModal={warningPopupDelModal}
                toggleModal={this.toggleModal}
                handleWarning={this.handleDeleteRow}
              />

              {
                <WarningPopup
                  moduleTitle='Thông báo'
                  moduleBody={
                    <p style={{ textAlign: 'center', fontSize: '1.2rem' }}>
                      Bạn đồng ý duyệt thông tin này?
                    </p>}
                  warningPopupModal={warningPopupModal}
                  toggleModal={this.toggleModal}
                  handleWarning={this.handleComfirmRow}
                />
              }
              {errmessage != '' ? (
                <PopupMessage
                  popupMessage={popupMessage}
                  moduleTitle={'Thông báo'}
                  moduleBody={errmessage}
                  toggleModal={this.toggleModal}
                />
              ) : null}
              {
                //Set Alert Context
                setAlertContext(statusPopup)
              }

              {
                //Open Alert Context
                openAlertContext(statusPopup)
              }
            </Container>
          </div >
        }
      </>


    )
  }

}
const mapStateToProps = (state) => {
  return {
    company: state.CompanyListComfirmedStore,
    field: state.FieldStore,
    location: state.LocationStore,
    details: state.CompanyGetDetailsStore,
    companyNewRegBus: state.NewRegBusStore,
    companyAwait: state.CompanyAwaitStore,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(actionCompanyListComfirmed, dispatch),
    ...bindActionCreators(actionField, dispatch),
    ...bindActionCreators(actionLocationCreators, dispatch),
    ...bindActionCreators(actionCompanyGetDetails, dispatch),
    ...bindActionCreators(actionGetListNewly, dispatch),
    ...bindActionCreators(actionGetListCompanyAwait, dispatch),
  }
}
export default compose(
  // withStyles(useStyles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(CompanyComfirmed);