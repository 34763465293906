/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useLocation, Route, Switch } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import compose from 'recompose/compose';
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { AlertContext, alertContext, MenuContext, menuContext } from "../helpers/common";
import classes from './index.module.css';
import routes, { routeComponents } from "routes.js";

import { actionMenuCreators } from "../actions/ConfigAction";
import { actionCreators } from "../actions/AuthenActions";

import "../assets/css/control/message.css";
import Message from '../components/message';
import Loading from '../components/loading';

import { NoMatch } from "../helpers/common";
// import "../assets/css/global/theme.css";
// import "../assets/css/global/index.css";

const Admin = (props) => {
	const mainContent = React.useRef(null);
	const location = useLocation();

	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		mainContent.current.scrollTop = 0;
	}, [location]);

	React.useEffect(async () => {
		const { getMenu } = props;

		if (getMenu) {
			await getMenu();
		}
	}, []);

	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.layout === "/trang_chu") {
				return (
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			} else {
				return null;
			}
		});
	};

	const getRoutesNavigation = routes => {
		const arrayRoute = [];

		let route = null;

		const routeExcludes = routeComponents.filter(p => (routes || []).filter(m => m.uniqueCode == p.key).length <= 0);

		routeExcludes.map((item, index) => {
			arrayRoute.push(
				<Route
					path={item.layout + item.url}
					component={item.component}
					key={index}
				/>
			);
		});

		routes.map(item => {
			route = routeComponents.find(p => p.key && p.key == item.uniqueCode);

			if (route) {
				//console.error(route);

				arrayRoute.push(
					<Route
						path={route.layout + item.url}
						component={route.component}
						key={item.id}
					/>
				);
			}
		});

		return arrayRoute;
	};

	const getBrandText = (path) => {
		for (let i = 0; i < routes.length; i++) {
			if (
				props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1
			) {
				return routes[i].name;
			}
		}
		// return "Brand";
		return;
	};

	const menus = (props.configState || {}).menus || [];

	return (
		// ThangContext
		// <AlertContext.Provider value={alertContext} >
		// 	<MenuContext.Provider value={menuContext}>
		// 		<Sidebar
		// 			{...props}
		// 			routes={menus}
		// 			logo={{
		// 				innerLink: "/trang_chu/dashboard",
		// 				imgSrc: require("../assets/img/brand/logo.png").default,
		// 				imgAlt: "...",
		// 			}}
		// 		/>
		// 		<div className="main-content" ref={mainContent}>
		// 			<AdminNavbar
		// 				{...props}
		// 				brandText={getBrandText(props.location.pathname)}
		// 			/>

		// 			<Switch>
		// 				{getRoutesNavigation(menus)}
		// 				{/* {getRoutes(routes)} */}
		// 				{/* <Redirect from="*" to="/trang_chu/dashboard" /> */}
		// 				<Route exact path="/" />
		// 				<Route path="*">
		// 					<NoMatch />
		// 				</Route>
		// 			</Switch>
		// 			{/* ---? Page Name? ----- */}
		// 			<MenuContext.Consumer>
		// 				{value => value.data = getBrandText(props.location.pathname)}
		// 			</MenuContext.Consumer>

		// 			<AlertContext.Consumer>{value => props.children}</AlertContext.Consumer>

		// 			<div className={classes.footerArea}>
		// 				<AdminFooter />
		// 			</div>
		// 			<Loading ref={ref => Loading.setRef(ref)} />
		// 			<Message ref={ref => Message.setRef(ref)} />
		// 		</div>
		// 	</MenuContext.Provider>
		// </AlertContext.Provider >
		<>
			<Sidebar
				{...props}
				routes={menus}
				logo={{
					innerLink: "/trang_chu/dashboard",
					//imgSrc: require("../assets/img/brand/logo.png").default,
					//Node18
					imgSrc: require("../assets/img/brand/logo.png"),
					imgAlt: "...",
				}}
			/>
			<AlertContext.Provider value={alertContext}>
				<MenuContext.Provider value={menuContext}>
					<div className="main-content" ref={mainContent}>
						<AdminNavbar
							{...props}
							brandText={getBrandText(props.location.pathname)}
						/>

						<Switch>
							{getRoutesNavigation(menus)}
							{/* {getRoutes(routes)} */}
							{/* <Redirect from="*" to="/trang_chu/dashboard" /> */}
							<Route exact path="/" />
							<Route path="*">
								<NoMatch />
							</Route>
						</Switch>
						{/* ---? Page Name? ----- */}
						<div style={{ display: 'none' }}>
							<MenuContext.Consumer>
								{value => value.data = getBrandText(props.location.pathname)}
							</MenuContext.Consumer>
						</div>

						<AlertContext.Consumer>{value => props.children}</AlertContext.Consumer>

						<div className={classes.footerArea}>
							<AdminFooter />
						</div>
						<Loading ref={ref => Loading.setRef(ref)} />
						<Message ref={ref => Message.setRef(ref)} />
					</div>
				</MenuContext.Provider>
			</AlertContext.Provider>
		</>
	);
};

const mapStateToProps = states => {
	return {
		configState: states.ConfigStore,
		account: states.AuthenStore
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		...bindActionCreators(actionMenuCreators, dispatch),
		...bindActionCreators(actionCreators, dispatch)
	}
}

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(Admin);