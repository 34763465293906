import React, { Component } from 'react';
import compose from 'recompose/compose';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Guid } from 'guid-typescript';

import { areaDataAction } from "../../../actions/AreaDataAction";
import { platingZoneAction } from "../../../actions/PlantingZoneAction";

import Select from "components/Select";

import '../../../assets/css/page/insert_or_update_planting_zone.css';
import classes from './index.module.css';

import { UNITS } from 'helpers/constant';

import { ICON_COMMONS } from '../../../assets/img';

import { MapWithASearchBox } from '../../../components/MapWithSearchBox';
import Map from '../../../components/Map';

import GoogleMapReact from 'google-map-react';

import { LOCATION_DEFAULT, ZOOM_DEFAULT } from '../../../services/Common'

import locationIcon from '../../../assets/img/locationIcon/location.png';

// const AnyReactComponent = ({ text }) => <div>{text}</div>

const AnyReactComponent = ({ text }) => <div><img width={25} src={locationIcon} /></div>

class InsertOrUpadte extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: null,
            plantingTypeName: null,
            plantingZoneName: null,
            unitRoundName: null,
            unitBadName: null,
            name: '',
            gps: '',
            round: '',
            bad: '',
            plantingTypeId: null,
            plantingZoneId: null,
            unitIdBad: null,
            unitIdRound: null,
        }

        this.refInputName = null;
    }

    componentWillUnmount() {
        this.setState(previousState => {
            return {
                ...previousState,
                id: null,
                plantingTypeName: null,
                plantingZoneName: null,
                unitRoundName: null,
                unitBadName: null,
                name: '',
                gps: '',
                round: '',
                bad: '',
                plantingTypeId: null,
                plantingZoneId: null
            }
        });
    }

    async componentDidMount() {
        const { getDetailPlantingZone, getListPlantingType, onHandleChangeValue, id, getListPlantingZoneForComboBox } = this.props;

        if (onHandleChangeValue) {
            onHandleChangeValue(this.state);
        }

        let plantingTypeId = null;
        let plantingZoneId = null;

        if (id) {
            const result = await getDetailPlantingZone({ id });

            const data = ((result || {}).data || {}).data || null;

            if (!data) {
                alert('Không tìm thấy vùng sản xuất này');

                return;
            }

            plantingTypeId = data.plantingTypeID;
            plantingZoneId = data.parentID;

            this.setState(previousState => {
                return {
                    ...previousState,
                    id: data.id,
                    name: data.name,
                    plantingTypeId,
                    plantingZoneId,
                    gps: data.gps,
                    round: data.radius,
                    bad: data.tolerance,
                    unitIdBad: data.toleranceUnit,
                    unitIdRound: data.unit,
                    unitBadName: (UNITS.find(p => p.value == data.toleranceUnit) || {}).label || '',
                    unitRoundName: (UNITS.find(p => p.value == data.unit) || {}).label || ''
                }
            }, () => {
                if (onHandleChangeValue) {
                    onHandleChangeValue(this.state);
                }
            });
        }

        getListPlantingType({ "search": "", "filter": "", "orderBy": "", "page": null, "limit": null }).then(res => {
            const data = res.data;

            if (data.status == 200) {
                const plantingTypes = (data.data || {}).plantingTypes || [];

                if (plantingTypes.length > 0) {
                    const plantingType = plantingTypes[0];

                    let plantingTypeName = '';

                    if (plantingTypeId) {
                        plantingTypeName = (plantingTypes.find(p => p.id == plantingTypeId) || {}).name;
                    } else {
                        plantingTypeName = plantingType.name;
                    }

                    if (plantingType) {
                        this.setState(previousState => {
                            return {
                                ...previousState,
                                plantingTypeId: plantingTypeId || null,
                                plantingTypeName
                            }
                        }, () => {
                            if (this.props.onHandleChangeValue) {
                                this.props.onHandleChangeValue(this.state);
                            }
                        });
                    }
                }
            }
        });

        getListPlantingZoneForComboBox({ "search": "", "filter": "", "orderBy": "", "page": null, "limit": null }).then(res => {
            const data = res.data;

            if (data.status == 200) {
                const plantingZones = (data.data || {}).plantingZones || [];
                let plantingZoneName = '';
                if (plantingZones.length > 0) {
                    if (id) {
                        plantingZoneName = (plantingZones.find(p => p.id == plantingZoneId) || {}).name;
                    } else {
                        const plantingZone = plantingZones[0];
                        if (plantingZone) {
                            plantingZoneId = plantingZone.id;
                            plantingZoneName = plantingZone.name;
                        }
                    }
                    this.setState(previousState => {
                        return {
                            ...previousState,
                            // plantingZoneId,
                            plantingZoneName
                        }
                    }, () => {
                        if (this.props.onHandleChangeValue) {
                            this.props.onHandleChangeValue(this.state);
                        }
                    });

                }
            }
        });

        this.focusInput();
    }

    focusInput = () => {
        if (this.refInputName) {
            const timeOut = setTimeout(() => {
                this.refInputName.focus();

                clearTimeout(timeOut);
            }, 100);
        }
    }

    onChangeSelect = name => value => {
        console.log(name);
        console.log(value);

        let plantingTypeName = this.state.plantingTypeName;

        if (name === 'plantingTypeId') {
            plantingTypeName = '';
        }

        let plantingZoneName = this.state.plantingZoneName;

        if (name === 'plantingZoneId') {
            plantingZoneName = '';
        }

        let unitBadName = this.state.unitBadName;

        if (name === 'unitIdBad') {
            unitBadName = '';
        }

        let unitRoundName = this.state.unitRoundName;

        if (name === 'unitIdRound') {
            unitRoundName = '';
        }

        this.setState(previousState => {
            return {
                ...previousState,
                [name]: value,
                plantingTypeName,
                plantingZoneName,
                unitBadName,
                unitRoundName
            }
        }, () => {
            if (this.props.onHandleChangeValue) {
                this.props.onHandleChangeValue(this.state);
            }
        });
    }

    onChangeValue = name => e => {
        const value = e.target.value;

        this.setState(previousState => {
            return {
                ...previousState,
                [name]: value
            }
        }, () => {
            if (this.props.onHandleChangeValue) {
                this.props.onHandleChangeValue(this.state);
            }
        });
    }

    onChangeValueGPS = name => e => {
        const value = e.target.value.replace(/[^0-9., ]/ig, "");

        this.setState(previousState => {
            return {
                ...previousState,
                [name]: value
            }
        }, () => {
            if (this.props.onHandleChangeValue) {
                this.props.onHandleChangeValue(this.state);
            }
        });
    }

    onChangeRadio = event => {
        this.state[event.target.name] = Number(event.target.value);
        this.setState({ [event.target.name]: Number(event.target.value) });

        if (this.props.onHandleChangeValue) {
            this.props.onHandleChangeValue(this.state);
        }
    }

    handleMapLocation = (gps) => {
        if (gps.length === 0) return LOCATION_DEFAULT;
        else {
            const location = gps.split(",");
            const mapLocation = {
                lat: parseFloat(location[0]),
                lng: parseFloat(location[1])
            };

            return mapLocation;
        }
    }

    handleZoomMap = (gps) => {
        if (gps.length === 0) return ZOOM_DEFAULT;
        else {
            const location = gps.split(",");

            if (typeof (location[2]) !== 'undefined') {
                const zoom = Number(location[2].replace('z', ''));

                console.log(zoom);
                return zoom;
            } else return ZOOM_DEFAULT;
        }
    }

    handleApiLoaded = (map, maps) => {
        // use map and maps objects
    };

    onCloseMapViewLocation = () => {
        this.setState(previousState => {
            return {
                ...previousState,
                isShowMapViewLocation: false
            }
        });
    }

    onOpenMaps = () => {
        this.setState(previousState => {
            return {
                ...previousState,
                isShowMapViewLocation: true
            }
        });
    }

    onChangeLocation = location => {
        this.setState(previousState => {
            return {
                ...previousState,
                locationChange: {
                    lat: location.center.lat,
                    lng: location.center.lng
                }
            }
        });
    }

    onConfirmLocation = () => {
        const locationChange = this.state.locationChange;

        if (locationChange) {
            this.setState(previousState => {
                return {
                    ...previousState,
                    // location: locationChange,
                    locationChange: null,
                    isShowMapViewLocation: false
                }
            });
        }
    }

    render() {
        const { unitIdRound, unitIdBad, gps, round, bad, plantingZoneId, plantingTypeId, unitBadName, unitRoundName, plantingZoneName, plantingTypeName, name, isShowMapViewLocation, locationChange } = this.state;
        const { errors, PlantingZoneStore, id, message } = this.props;

        const plantingTypes = PlantingZoneStore.platingTypes || [];

        let plantingZoneForComboBoxs
        let _plantingZoneForComboBoxs
        if (id) {
            _plantingZoneForComboBoxs = PlantingZoneStore.plantingZoneForComboBoxs || [];
            plantingZoneForComboBoxs = _plantingZoneForComboBoxs.filter(x => x.id != id)
        } else {
            plantingZoneForComboBoxs = PlantingZoneStore.plantingZoneForComboBoxs || [];
        }

        return (
            <div className='wrap-insert-or-update-zone'>
                <div className='wrap-insert-or-update-zone-item'>
                    <label className='wrap-insert-or-update-zone-item-label'>Loại vùng sản xuất&nbsp;<b style={{ color: 'red' }}>*</b></label>
                    <div className='wrap-insert-or-update-zone-item-box'>
                        <Select
                            value={id ? plantingTypeId : null}
                            defaultValue={id ? plantingTypeId : null}
                            labelMark={id ? plantingTypeName : null}
                            className='wrap-insert-or-update-zone-item-select'
                            name="plantingTypeId"
                            title='Chọn loại vùng sản xuất'
                            data={plantingTypes}
                            labelName='name'
                            val='id'
                            handleChange={this.onChangeSelect('plantingTypeId')}
                        />
                        <p className='form-error-message'>{errors.plantingTypeId || ''}</p>
                    </div>
                </div>
                <div className='wrap-insert-or-update-zone-item'>
                    <label className='wrap-insert-or-update-zone-item-label'>Tên vùng sản xuất&nbsp;<b style={{ color: 'red' }}>*</b></label>
                    <div className='wrap-insert-or-update-zone-item-box'>
                        <input value={name} onChange={this.onChangeValue('name')} type='text' className='wrap-insert-or-update-zone-item-input' />
                        <p className='form-error-message'>{errors.name || ''}</p>
                    </div>
                </div>
                <div className='wrap-insert-or-update-zone-item'>
                    <label className='wrap-insert-or-update-zone-item-label'>Thuộc vùng sản xuất</label>
                    <div className='wrap-insert-or-update-zone-item-box'>
                        <Select
                            value={id ? plantingZoneId : null}
                            defaultValue={id ? plantingZoneId : null}
                            labelMark={id ? plantingZoneName : null}
                            className='wrap-insert-or-update-zone-item-select'
                            name="plantingZoneId"
                            title='Chọn vùng sản xuất'
                            data={plantingZoneForComboBoxs}
                            labelName='name'
                            val='id'
                            handleChange={this.onChangeSelect('plantingZoneId')}
                        />
                        <p className='form-error-message'>{errors.plantingZoneId || ''}</p>
                    </div>
                </div>
                <div className='wrap-insert-or-update-zone-item'>
                    <label className='wrap-insert-or-update-zone-item-label'>GPS&nbsp;<b style={{ color: 'red' }}>*</b></label>
                    <div className='wrap-insert-or-update-zone-item-box'>
                        <input value={gps} onChange={this.onChangeValueGPS('gps')} type='text' className='wrap-insert-or-update-zone-item-input'
                        // pattern='[0-9]'
                        />
                        <p className='form-error-message'>{errors.gps || ''}</p>
                    </div>
                    <button className='wrap-insert-or-update-zone-item-location'>
                        <img className='wrap-insert-or-update-zone-item-location-icon' src={ICON_COMMONS.Location} onClick={this.onOpenMaps} />
                    </button>
                </div>
                {/* <div className='wrap-insert-or-update-zone-item map-area'>
                    {
                        gps.length > 0 && (
                            <GoogleMapReact 
                                bootstrapURLKeys={{ key: 'AIzaSyAz1kbpa6CB_NQh5vQNJHfqTCSH_xGZFIU' }}
                                center={this.handleMapLocation(gps)}
                                defaultZoom={this.handleZoomMap(gps)} 
                                onGoogleApiLoaded={({ map, maps }) => {
                                    console.log(this.state);
                                    const marker = new maps.Marker({
                                        position: this.handleMapLocation(gps),
                                        map
                                    });

                                    const circle = new maps.Circle({
                                        map: map,
                                        radius: round * 1000,    // 1000 = 1km
                                        fillColor: '#09b2fd',
                                        strokeColor: '#09b2fd'
                                    });

                                    circle.bindTo('center', marker, 'position');
                                }}
                            >
                            </GoogleMapReact>
                        )
                    }
                </div> */}
                {isShowMapViewLocation &&
                    <div className='wrap-manage-company-location'>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyAz1kbpa6CB_NQh5vQNJHfqTCSH_xGZFIU' }}
                            defaultCenter={{
                                lat: LOCATION_DEFAULT.lat,
                                lng: LOCATION_DEFAULT.lng
                            }}
                            yesIWantToUseGoogleMapApiInternals
                            onChange={this.onChangeLocation}
                            // onGoogleApiLoaded={(map, maps)=>this.onChangeLocation(map, maps)}
                            defaultZoom={15} >
                            <AnyReactComponent
                                lat={LOCATION_DEFAULT.lat}
                                lng={LOCATION_DEFAULT.lng}
                                text="My Marker"
                            />
                        </GoogleMapReact>
                        <div className='wrap-manage-company-location-function'>
                            <button onClick={this.onCloseMapViewLocation} className='wrap-manage-company-location-function-button wrap-manage-company-location-function-button-close'>ĐÓNG</button>
                            <button onClick={this.onConfirmLocation} className='wrap-manage-company-location-function-button wrap-manage-company-location-function-button-confirm'>CHỌN VỊ TRÍ NÀY</button>
                        </div>
                    </div>
                }
                {/* {isShowMapViewLocation &&
                    <div className='wrap-manage-company-location'>
                        <Map
                            google={this.props.google}
                            center={{ lat: 13.104140180993811, lng: 109.31284359689911 }}
                            height='300px'
                            zoom={15}
                        />
                    </div>
                } */}

                <div className='wrap-insert-or-update-zone-item'>
                    <label className='wrap-insert-or-update-zone-item-label'>Phạm vi (Bán kính)&nbsp;<b style={{ color: 'red' }}>*</b></label>
                    <div className='wrap-insert-or-update-zone-item-box'>
                        <input value={round} onChange={this.onChangeValue('round')} type='text' className='wrap-insert-or-update-zone-item-input' />
                    </div>
                    <div className='wrap-insert-or-update-zone-item-box-2'>
                        <div className={classes.radioBox}>
                            {
                                UNITS.map((item, key) => (
                                    <div key={key}>
                                        <input
                                            checked={unitIdRound === item.value ? true : false}
                                            type="radio"
                                            name="unitIdRound"
                                            id={`unitround-${key}`}
                                            value={item.value}
                                            onChange={(event) => this.onChangeRadio(event)}
                                        />
                                        <label htmlFor={`unitround-${key}`}>{item.label}</label>
                                    </div>
                                ))
                            }
                        </div>

                        {/* <Select
                            value={unitIdRound}
                            defaultValue={unitIdRound}
                            labelMark={unitRoundName}
                            className='wrap-insert-or-update-zone-item-select'
                            name="unitIdRound"
                            title='Chọn đơn vị'
                            data={UNITS}
                            labelName='label'
                            val='value'
                            handleChange={this.onChangeSelect('unitIdRound')}
                        /> */}
                    </div>
                </div>

                <div className={classes.defaultLeftSpacing}>
                    <p className='form-error-message'>{errors.round || ''}</p>
                    <p className='form-error-message'>{errors.unitIdRound || ''}</p>
                </div>

                <div className='wrap-insert-or-update-zone-item'>
                    <label className='wrap-insert-or-update-zone-item-label'>Dung sai&nbsp;<b style={{ color: 'red' }}>*</b></label>
                    <div className='wrap-insert-or-update-zone-item-box'>
                        <input value={bad} onChange={this.onChangeValue('bad')} type='text' className='wrap-insert-or-update-zone-item-input' />
                        {/* <p className='form-error-message'>{errors.bad || ''}</p> */}
                    </div>
                    <div className='wrap-insert-or-update-zone-item-box-2'>
                        <div className={classes.radioBox}>
                            {
                                UNITS.map((item, key) => (
                                    <div key={key}>
                                        <input
                                            checked={unitIdBad === item.value ? true : false}
                                            type="radio"
                                            name="unitIdBad"
                                            id={`unitbad-${key}`}
                                            value={item.value}
                                            onChange={(event) => this.onChangeRadio(event)}
                                        />
                                        <label htmlFor={`unitbad-${key}`}>{item.label}</label>
                                    </div>
                                ))
                            }
                        </div>

                        {/* <Select
                            value={unitIdBad}
                            defaultValue={unitIdBad}
                            labelMark={unitBadName}
                            className='wrap-insert-or-update-zone-item-select'
                            name="unitIdBad"
                            title='Chọn đơn vị'
                            data={UNITS}
                            labelName='label'
                            val='value'
                            handleChange={this.onChangeSelect('unitIdBad')}
                        /> */}
                        {/* <p className='form-error-message'>{errors.unitIdBad || ''}</p> */}
                    </div>
                </div>

                <div className={classes.defaultLeftSpacing}>
                    <p className='form-error-message'>{errors.bad || ''}</p>
                    <p className='form-error-message'>{errors.unitIdBad || ''}</p>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { PlantingZoneStore: state.PlantingZoneStore }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(areaDataAction, dispatch),
        ...bindActionCreators(platingZoneAction, dispatch)
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(InsertOrUpadte);