import {
    MASTERSERVER_LIST,
    MASTERSERVER_DELETE,
    MASTERSERVER_CREATE,
    MASTERSERVER_UPDATE,
    MASTERSERVER_GET
} from "../apis";
import {
    get, post, del, postFormData, put
} from "../services/Dataservice";
import {
    SUCCESS_CODE,
    MASTERSERVER_LIST_FAIL_TYPE,
    MASTERSERVER_LIST_TYPE,
    MASTERSERVER_LIST_SUCCESS_TYPE,
    MASTERSERVER_DELETE_TYPE,
    MASTERSERVER_DELETE_FAIL_TYPE,
    MASTERSERVER_DELETE_SUCCESS_TYPE,
    MASTERSERVER_CREATE_FAIL_TYPE,
    MASTERSERVER_CREATE_SUCCESS_TYPE,
    MASTERSERVER_CREATE_TYPE,
    MASTERSERVER_UPDATE_TYPE,
    MASTERSERVER_UPDATE_FAIL_TYPE,
    MASTERSERVER_UPDATE_SUCCESS_TYPE,
    MASTERSERVER_GET_FAIL_TYPE,
    MASTERSERVER_GET_SUCCESS_TYPE,
    MASTERSERVER_GET_TYPE

} from "../services/Common";

const initialState = { data: [], isLoading: false, status: false, message: '' };

export const actionMasterServer = {
    requestMasterServerList: (data) => async (dispatch, getState) => {
        return new Promise(async resolve => {
            dispatch({
                type: MASTERSERVER_LIST_TYPE, data: initialState
            });

            await get(MASTERSERVER_LIST, data)
                .then(res => {
                    if (res.status === SUCCESS_CODE) {
                        dispatch({
                            type: MASTERSERVER_LIST_SUCCESS_TYPE, data: { data: res.data, isLoading: true, status: true, message: res.message }
                        });

                    } else {
                        dispatch({
                            type: MASTERSERVER_LIST_FAIL_TYPE, data: { data: [], isLoading: true, status: false, message: res.message }
                        });

                    }
                    resolve({
                        status: true,
                        data: res
                    });
                })
                .catch(err => {
                    dispatch({ type: MASTERSERVER_LIST_FAIL_TYPE, data: { data: [], isLoading: true, status: false, message: err.message } });
                    resolve({
                        status: false,
                        error: err
                    });
                })
        });
    },

    deleteMasterServer: (id) => async (dispatch, getState) => {
        return new Promise(async resolve => {
            dispatch({
                type: MASTERSERVER_DELETE_TYPE, data: initialState
            });
            await del(MASTERSERVER_DELETE + id)
                .then(res => {
                    if (res.status === SUCCESS_CODE) {
                        dispatch({
                            type: MASTERSERVER_DELETE_SUCCESS_TYPE, data: { delete: res.data, isLoading: true, status: true, message: res.message }
                        });

                    } else {
                        dispatch({
                            type: MASTERSERVER_DELETE_FAIL_TYPE, data: { delete: [], isLoading: true, status: false, message: res.message }
                        });

                    }
                    resolve({
                        status: true,
                        data: res
                    });
                })
                .catch(err => {
                    dispatch({ type: MASTERSERVER_DELETE_FAIL_TYPE, data: { delete: [], isLoading: true, status: false, message: err.message } });
                    resolve({
                        status: false,
                        error: err
                    });
                })
        });

    },
    createMasterServer: (data) => async (dispatch, getState) => {
        return new Promise(async resolve => {
            dispatch({
                type: MASTERSERVER_CREATE_TYPE, data: initialState
            });

            await post(MASTERSERVER_CREATE, data)
                .then(res => {
                    if (res.status === SUCCESS_CODE) {
                        dispatch({
                            type: MASTERSERVER_CREATE_SUCCESS_TYPE, data: { create: res.data, isLoading: true, status: true, message: res.message }
                        });

                    } else {
                        dispatch({
                            type: MASTERSERVER_CREATE_FAIL_TYPE, data: { create: [], isLoading: true, status: false, message: res.message }
                        });

                    }
                    resolve({
                        status: true,
                        data: res
                    });
                })
                .catch(err => {
                    dispatch({ type: MASTERSERVER_CREATE_FAIL_TYPE, data: { create: [], isLoading: true, status: false, message: err.message } });
                    resolve({
                        status: false,
                        error: err
                    });
                })
        });
    },

    getIdMasterServerUpdate: (id) => async (dispatch, getState) => {
        return new Promise(async resolve => {
            dispatch({
                type: MASTERSERVER_GET_TYPE, data: initialState
            });

            await get(MASTERSERVER_GET + id)
                .then(res => {
                    if (res.status === SUCCESS_CODE) {
                        dispatch({
                            type: MASTERSERVER_GET_SUCCESS_TYPE, data: { get: res.data, isLoading: true, status: true, message: res.message }
                        });

                    } else {
                        dispatch({
                            type: MASTERSERVER_GET_FAIL_TYPE, data: { get: [], isLoading: true, status: false, message: res.message }
                        });

                    }
                    resolve({
                        status: true,
                        data: res
                    });
                })
                .catch(err => {
                    dispatch({ type: MASTERSERVER_GET_FAIL_TYPE, data: { get: [], isLoading: true, status: false, message: err.message } });
                    resolve({
                        status: false,
                        error: err
                    });
                })
        });
    },

    updateMasterServer: (data) => async (dispatch, getState) => {
        return new Promise(async resolve => {
            dispatch({
                type: MASTERSERVER_UPDATE_TYPE, data: initialState
            });

            await put(MASTERSERVER_UPDATE, data)
                .then(res => {
                    if (res.status === SUCCESS_CODE) {
                        dispatch({
                            type: MASTERSERVER_UPDATE_SUCCESS_TYPE, data: { update: res.data, isLoading: true, status: true, message: res.message }
                        });

                    } else {
                        dispatch({
                            type: MASTERSERVER_UPDATE_FAIL_TYPE, data: { update: [], isLoading: true, status: false, message: res.message }
                        });

                    }
                    resolve({
                        status: true,
                        data: res
                    });
                })
                .catch(err => {
                    dispatch({ type: MASTERSERVER_UPDATE_FAIL_TYPE, data: { update: [], isLoading: true, status: false, message: err.message } });
                    resolve({
                        status: false,
                        error: err
                    });
                })
        });
    },

}