import React, { Component } from "react";
import classes from './index.module.css';
import CloseIcon from "../../assets/img/buttons/DONG.png";


// reactstrap components
import {
  Button,
  Modal,
} from "reactstrap";

class ViewPopup2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewModal2: false
    }
  }

  render() {
    const {
      viewModal2, toggleModal, moduleTitle, moduleBody, activeSubmit, handleUpdateInfoData, newData, componentFooter,
      resize
    } = this.props;

    return (
      <>
        <Modal
          className="modal-dialog-centered"
          isOpen={viewModal2}
          size={resize ? resize : "xl"}
          autoFocus={false}
        >
          <div className={`modal-header ${classes.moduleHeaderArea}`}>
            <h5 className="modal-title" id="updateModalLabel">
              {moduleTitle}
            </h5>
          </div>

          <div className="modal-body">
            {moduleBody}
          </div>

          <div className={`modal-footer ${classes.modalButtonArea}`}>
            {/* <Button 
              color={activeSubmit ? "success" : ''} 
              type="button"
              className={!activeSubmit && classes.disbaleBtn}
              disabled={activeSubmit ? false : true}
              onClick={() => {
                toggleModal('updateModal');
                
                handleUpdateInfoData(newData);
              }}
            >
              <img className={classes.iconButton} src={SaveIcon} alt="Tạo mới" title="Tạo mới" />
              <span>Lưu</span>
              
            </Button> */}

            {/* <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={() => toggleModal('updateModal')}
            >
              <img className={classes.iconButton} src={CloseIcon} alt="Đóng" title="Đóng" />
              <span>Đóng</span>
            </Button> */}

            {/* <img className={classes.iconButton}
              src={btnLuu} alt="Lưu" title="Lưu"
              width="25%"
              height="25%"
              onClick={() => {
                toggleModal('viewModal');
                handleUpdateInfoData(newData);
              }}
            /> */}

            {/* <img className={classes.iconButton}
              src={btnDong} alt="Đóng" title="Đóng"
              width="115"
              height="29.17"
              onClick={() => toggleModal('viewModal')}
              /> */}
            {componentFooter}
            <Button
              color="default"
              data-dismiss="modal"
              type="button"
              className={`btn-danger-cs`}
              onClick={() => toggleModal('viewModal2')}
            >
              <img src={CloseIcon} alt='Thoát ra' />
              <span>Thoát ra</span>
            </Button>

          </div>
        </Modal>
      </>
    );
  }
};

export default ViewPopup2;
