import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import compose from 'recompose/compose';
import { connect } from "react-redux";
import { actionCompanyListAwaitExpired } from "../../../../../actions/CompanyListAwaitExpiredAction";
import { actionField } from "../../../../../actions/FieldActions.js";
import { actionPriceCreators } from "../../../../../actions/PricesListActions";
import { actionCompanyGetDetails } from "../../../../../actions/CompanyGetDetailsActions";
import { actionLocationCreators } from "../../../../../actions/LocationListAction";
import { NavLink } from 'react-router-dom';
import { Spinner } from 'reactstrap'
import { PLEASE_CHECK_CONNECT } from "../../../../../services/Common";
import { LIMIT_ITEM_IN_PAGE, LOADING_TIME } from "../../../../../helpers/constant";
import moment from 'moment';


class ListExpiring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: null,
            limit: 10
        }
    }
    componentWillReceiveProps(nextProp) {
        //console.log(nextProp);
        let { ngayhethan } = this.state;
        let { data } = nextProp.company;
        let fieldData = nextProp.field.data;
        let priceDate = nextProp.price.data;
        let detailsData = nextProp.details.data;
        const { limit } = this.state;
        let locationData = nextProp.location.data;
        const { fetchingUnExtend } = this.state;
        const { requestCompanyListAwaitExpired } = nextProp;

        if (fieldData !== this.state.field) {
            if (typeof (fieldData) !== 'undefined') {
                if (fieldData.field !== null) {
                    if (typeof (fieldData.field) !== 'undefined') {
                        this.setState({
                            field: fieldData.field.fields,
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    } else {
                        this.setState({
                            field: fieldData.field,
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    }
                }
            }
        }

        if (data !== this.state.data) {
            if (typeof (data) !== 'undefined') {
                if (typeof (data.company) !== 'undefined') {
                    if (data.company !== null) {
                        if (typeof (data.company.companies) !== 'undefined') {
                            data.company.companies.map((item, key) => {
                                item['index'] = key + 1;
                                item['collapse'] = false;
                                // item['registeredDate'] = moment(item.registeredDate).format('DD/MM/YYYY');
                                // item['expiredDays'] = moment(item.expiredDate).format('DD/MM/YYYY');
                            });

                            this.setState({
                                data: data.company.companies,
                                history: data.company.companies,
                                listLength: data.company.companies.length,
                                totalPage: Math.ceil(data.company.companies.length / limit),
                                isLoaded: data.isLoading,
                                status: data.status,
                                totalElement: data.company.companies.length > limit ? limit : data.company.companies.length,
                                message: PLEASE_CHECK_CONNECT(data.message)
                            });
                        } else {
                            this.setState({ data: data.company.companies, history: data.company.companies, isLoaded: data.isLoading, status: data.status, message: PLEASE_CHECK_CONNECT(data.message) });
                        }
                    }
                }
            }
        }

        if (typeof (data.extend) !== 'undefined') {
            if (data.status && !fetchingUnExtend) {
                this.setState({ data: [] });
                requestCompanyListAwaitExpired(JSON.stringify({
                    "fieldID": "",
                    "comapanyName": "",
                    "taxCode": "",
                    "phone": "",
                    "email": "",
                    "provinceID": "",
                    "districtID": "",
                    "wardID": "",
                    "orderBy": "",
                    "page": null,
                    "limit": null,
                }))
                this.setState({ fetchingUnExtend: true });
            }
        }

        if (typeof (detailsData) !== 'undefined') {
            if (detailsData.details !== null) {
                if (typeof (detailsData.details) !== 'undefined') {
                    this.setState({
                        xem: detailsData.details,
                        detail: detailsData.details,
                        detailView: detailsData.details,
                        isLoaded: false,
                        status: data.status,
                        message: PLEASE_CHECK_CONNECT(data.message)
                    });
                } else {
                    this.setState({
                        xem: detailsData.details,
                        isLoaded: false,
                        status: data.status,
                        message: PLEASE_CHECK_CONNECT(data.message)
                    });
                }
            }
        }

        if (locationData !== this.state.province) {
            if (typeof (locationData) !== 'undefined') {
                if (typeof (locationData.province) !== 'undefined') {

                    if (locationData.province !== null) {
                        //console.log(locationData.province.data)
                        this.setState({
                            province: locationData.province,
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    } else {
                        this.setState({
                            province: [],
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    }
                }
            }
        }

        if (locationData !== this.state.district) {
            if (typeof (locationData) !== 'undefined') {
                if (typeof (locationData.district) !== 'undefined') {
                    if (locationData.district !== null) {
                        this.setState({
                            district: locationData.district,
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    } else {
                        this.setState({
                            district: [],
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    }
                }
            }
        }

        if (locationData !== this.state.ward) {

            if (typeof (locationData) !== 'undefined') {

                // if (typeof (locationData.ward) !== 'undefined') {
                if (locationData.ward !== null) {

                    this.setState({ ward: [] })
                    this.setState({
                        ward: locationData.ward,
                        isLoaded: false,
                        status: data.status,
                        message: PLEASE_CHECK_CONNECT(data.message)
                    });
                } else {
                    this.setState({
                        ward: [],
                        isLoaded: false,
                        status: data.status,
                        message: PLEASE_CHECK_CONNECT(data.message)
                    });
                }
                //}
            }
        }

        if (priceDate !== this.state.price) {
            if (typeof (priceDate) !== 'undefined') {
                if (typeof (priceDate.prices) !== 'undefined') {
                    if (priceDate.prices !== null) {
                        this.setState({
                            price: priceDate.prices.prices,
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    } else {
                        this.setState({
                            price: [],
                            isLoaded: false,
                            status: data.status,
                            message: PLEASE_CHECK_CONNECT(data.message)
                        });
                    }
                }
            }
        }

    }
    componentDidMount() {
        // This method is called when the component is first added to the document
        // this.filterMapKey();

        /* Fetch Summary */
        this.fetchSummary(JSON.stringify({
            "search": "",
            "filter": "",
            "orderBy": "",
            "page": null,
            "limit": null
        }));
    }
    componentDidUpdate() {
        // This method is called when the route parameters change
        this.closeStatusModal();
    }
    fetchSummary = (data) => {
        const { requestCompanyListAwaitExpired } = this.props;

        requestCompanyListAwaitExpired(data);
    }
    closeStatusModal = () => {
        const { status } = this.state;

        if (status || !status) {
            setTimeout(() => {
                this.setState({ status: null, isLoaded: false });
            }, LOADING_TIME);
        }
    }
    render() {
        const { data, isLoaded } = this.state
        // console.log(data);
        return (
            <>
                <div className='header-box-list box-list-border display-box'>
                    <h4>Sắp hết hạn</h4>
                </div>
                {
                    isLoaded ? (<>
                        <div style={{ display: 'table', margin: 'auto' }}>
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                    </>) : (<>
                        <div className='body-box-list' style={{ padding: 10 }}>
                            <table className='table-box-list-3'>
                                <thead>
                                    <tr className='table-tr-box-list'>
                                        <th>Doanh nghiệp</th>
                                        {/* <th>Ngành nghề</th> */}
                                        {/* <th>Dải tem</th> */}
                                        <th>Ngày còn lại</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {
                                        Array.isArray(data) && (
                                            data.map((item, key) => (
                                                <tr className="table-hover-css" key={key}>
                                                    <td className='text-algin-text'>
                                                        <p className='text-td-3'>
                                                            {item.companyName}
                                                        </p>
                                                    </td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        <strong style={{ color: 'red' }}>
                                                            {item.daysLeft}
                                                        </strong>
                                                    </td>
                                                    {/* <td className='text-td-3' style={{ width: 161 }}>Nhân và chăm sóc cây giống nông nghiệp;Trồng rừng, chăm sóc rừng và ươm giống cây lâm nghiệp</td> */}

                                                </tr>
                                            ))
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                    </>)
                }
                <div className='footer-box-list'>
                    <NavLink to="/trang_chu/danh_sach_sap_het_han">Xem thêm</NavLink>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        company: state.CompanyListAwaitExpiredStore,
        field: state.FieldStore,
        price: state.PriceStore,
        location: state.LocationStore,
        details: state.CompanyGetDetailsStore,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCompanyListAwaitExpired, dispatch),
        ...bindActionCreators(actionField, dispatch),
        ...bindActionCreators(actionPriceCreators, dispatch),
        ...bindActionCreators(actionLocationCreators, dispatch),
        ...bindActionCreators(actionCompanyGetDetails, dispatch),
    }
}

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(ListExpiring)