import { validExtensionFileImage, EXTENSION_FILE_IMAGE, EXTENSION_FILE_PDF, EXTENSION_FILE_WORD, EXTENSION_FILE_EXCEL, EXTENSION_FILE_TXT } from 'bases/helper';
import React, { Component } from 'react';
import {
    Button,
    Modal,
    Input
} from "reactstrap";

import '../../../assets/css/page/popup_detail_stamp_provide.css';

class PopupDetail extends Component {
    constructor(props) {
        super(props);
    }

    renderIconFile = fileName => {
        let icon = '';

        const extension = (fileName || '').split('.').pop();

        if (EXTENSION_FILE_IMAGE.find(p => p == extension) ? true : false) {
            icon = null;
        } else if (EXTENSION_FILE_PDF.find(p => p == extension) ? true : false) {
            icon = '/cores/imgs/ics/pdf.png';
        } else if (EXTENSION_FILE_WORD.find(p => p == extension) ? true : false) {
            icon = '/cores/imgs/ics/word.png';
        } else if (EXTENSION_FILE_EXCEL.find(p => p == extension) ? true : false) {
            icon = '/cores/imgs/ics/excel.png';
        } else if (EXTENSION_FILE_TXT.find(p => p == extension) ? true : false) {
            icon = '/cores/imgs/ics/txt.png';
        } else {
            icon = '/cores/imgs/ics/file.png';
        }

        if (!icon) {
            return null;
        }

        return <img className='modal-body-item-file-item-icon' src={icon} />;
    }

    render() {
        const { data, onConfirm, onUnConfirm, isShow, onClose, onChangeReason, reason } = this.props;

        if (!isShow) {
            return null;
        }

        console.log(data);

        const fileUpload = data.FileUpload || '';
        const fileUploads = (fileUpload.split(';') || []).filter(p => p);

        return (
            <Modal
                className="modal-dialog-centered"
                isOpen={isShow ? true : false}
                autoFocus={false}
            >
                <div className='modal-header CreateNewPopup_moduleHeaderArea__dcIuH'>
                    <h5 className="modal-title text-default-custom" id="warningPopupModalLabel">
                        YÊU CẦU CẤP PHÁT TEM
                    </h5>
                </div>
                <div className="modal-body text-default-custom">
                    <div className='modal-body-item'>
                        <label className='modal-body-item-label'>Đơn vị yêu cầu</label>
                        <p className='modal-body-item-value'>{data.CompanyName}</p>
                    </div>
                    <div className='modal-body-item'>
                        <label className='modal-body-item-label'>Sản phẩm</label>
                        <p className='modal-body-item-value'>{data.ProductName}</p>
                    </div>
                    <div className='modal-body-item'>
                        <label className='modal-body-item-label'>Số lượng mã QR</label>
                        <p className='modal-body-item-value'>{data.Quantity}</p>
                    </div>
                    <div className='modal-body-item'>
                        <label className='modal-body-item-label'>Số lượng tem mỗi mã QR</label>
                        <p className='modal-body-item-value'>{data.Requested}</p>
                    </div>
                    <div className='modal-body-item-2'>
                        <label className='modal-body-item-label'>Mẫu in tem</label>
                        <img className='modal-body-item-image' src={data.StampTemplateLink} />
                    </div>
                    <div className='modal-body-item-2'>
                        <label className='modal-body-item-label'>Hồ sơ đính kèm</label>
                        <label className='modal-body-item-label'>- Hóa đơn thanh toán</label>
                        <label className='modal-body-item-label'>- Phiếu yêu cầu cấp phát tem có chữ ký của giám đốc (nếu là doanh nghiệp), hoặc chữ ký cá nhân</label>
                        <div className='modal-body-item-file'>
                            {fileUploads.map((item, index) => {
                                return validExtensionFileImage(item) ? <div key={`f-${index}`} className='modal-body-item-file-item'>
                                    <img className='modal-body-item-file-item-image' src={item} />
                                    {/* <p className='modal-body-item-file-item-text'>{item}</p> */}
                                </div> : <div key={`f-${index}`} className='modal-body-item-file-item'>
                                    {this.renderIconFile(item)}
                                    <a href={item} target='_blank' className='modal-body-item-file-item-text'>{item}</a>
                                </div>
                            })}
                        </div>
                    </div>
                    {(data.Status == 1 || data.Status == 3) ? <div className='modal-body-item-2'>
                        <label className='modal-body-item-label'>Lý do không duyệt</label>
                        <Input
                            type="textarea"
                            className='modal-body-item-textarea'
                            onChange={onChangeReason}
                            value={reason}
                        />
                    </div> : null}
                </div>
                <div className='modal-footer CreateNewPopup_modalButtonArea__3_n_q'>
                    {data.Status == 1 ? <>
                        <Button
                            color="default"
                            className='btn-success-cs'
                            type="button"
                            onClick={onConfirm}
                        >
                            <span>Duyệt</span>
                        </Button>
                        <Button
                            color="default"
                            className='btn-danger-cs modal-footer-unconfirm'
                            type="button"
                            onClick={onUnConfirm}
                        >
                            <span>Không duyệt</span>
                        </Button>
                    </> : null}
                    <Button
                        color="default"
                        className='btn-danger-cs'
                        type="button"
                        onClick={onClose}
                    >
                        <span>Đóng</span>
                    </Button>
                </div>
            </Modal>
        )
    }
}

export default PopupDetail;