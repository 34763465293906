import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import compose from 'recompose/compose';
import { connect } from "react-redux";
import './DashboardList.css';
import ItemProduct from './ListItem/ItemProduct';
import ItemManuface from './ListItem/ItemManuface';
import ItemTransport from './ListItem/itemTransport';
import ItemCompany from './ListItem/ItemCompany';
import ItemPartner from './ListItem/ItemPartner';
import { NavLink } from 'react-router-dom';


class ListCompany extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: 0,
        }
    }
    onChooseTab = tab => () => {
        this.setState(previousState => {
            return {
                ...previousState,
                currentTab: tab
            }
        }, () => {
            if (tab == 0) {
                // this.onChangeLogoInfoCompany();
                // this.getInfoCompany();
                // this.onSaveInfoCompany()
            } else if (tab == 1) {
                // this.getInfoCompany();
            }
            // } else if (tab == 2) {
            //     this.getListConfigServer();
            // } else if (tab == 3) {
            //     this.getListStampTemplate();
            // }
            else if (tab == 4) {
                // this.getListAlert();
            }
        })
    }
    render() {
        const { currentTab } = this.state
        return (<>
            <div className='header-box-list box-list-border'>
                <h4>Danh sách </h4>
            </div>
            <div className='config-system-chart'>
                <div className='config-system-tab'>
                    <div onClick={this.onChooseTab(0)} className={`chart-tab-item ${currentTab === 0 ? 'active' : ""}`}>Sản phẩm </div>
                    <div onClick={this.onChooseTab(1)} className={`chart-tab-item ${currentTab === 1 ? 'active' : ""}`}>Nhà cung cấp</div>
                    <div onClick={this.onChooseTab(2)} className={`chart-tab-item ${currentTab === 2 ? 'active' : ""}`}>Vận chuyển</div>
                    <div onClick={this.onChooseTab(3)} className={`chart-tab-item ${currentTab === 3 ? 'active' : ""}`}>Nhà sản xuất</div>
                    <div onClick={this.onChooseTab(4)} className={`chart-tab-item ${currentTab === 4 ? 'active' : ""}`}>Nhà sản xuất</div>
                </div>
                <div className='config-system-content'>
                    {
                        currentTab === 0 ? (<>
                            <div className='chart-item-info'>
                                <ItemProduct />
                                <div className='footer-box-list'>
                                    <NavLink to='/trang_chu/xacthucsanpham'>Xem thêm</NavLink>
                                </div>
                            </div>
                        </>) : null
                    }
                    {
                        currentTab === 1 ? (<>
                            <div className='chart-item-info'>
                                <ItemManuface />
                                <div className='footer-box-list'>
                                    <NavLink to='/trang_chu/xacthucnhacungcap'>Xem thêm</NavLink>
                                </div>
                            </div>
                        </>) : null
                    }
                    {
                        currentTab === 2 ? (<>
                            <div className='chart-item-info'>
                                <ItemTransport />
                                <div className='footer-box-list'>
                                    <NavLink to='/trang_chu/xacthucvanchuyen'>Xem thêm</NavLink>
                                </div>
                            </div>
                        </>) : null
                    }
                    {
                        currentTab === 3 ? (<>
                            <div className='chart-item-info'>
                                <ItemCompany />
                                <div className='footer-box-list'>
                                    <NavLink to='trang_chu/xacthucnhasanxuat'>Xem thêm</NavLink>
                                </div>
                            </div>
                        </>) : null
                    }
                    {
                        currentTab === 4 ? (<>
                            <div className='chart-item-info'>
                                <ItemPartner />
                                <div className='footer-box-list'>
                                    <NavLink to='/trang_chu/xacthucdoanhnghiep'>Xem thêm</NavLink>
                                </div>
                            </div>
                        </>) : null
                    }
                </div>
            </div>
        </>)
    }
}

export default compose(
    connect()
)(ListCompany)