/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import BackGroundImg from "../assets/img/BackGround/BackgroundLogin.jpg"
import { getCookie, deleteCookie } from "../helpers/cookie";
import { DOMAIN_PROVINCE } from "../services/Common";
import DashboardBox from "./pages/Dashboard/DashboardBox/DashboardBox";
import DashboardChart from "./pages/Dashboard/DashboardChart/DashboardChart";
import DashboardList from "./pages/Dashboard/DashboardList/DashboardList";
import DashboardCompanyManager from "./pages/Dashboard/DashboardCompanyManager/DashboardCompanyManager";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Spinner,
  Input
} from "reactstrap";

import Header from "components/Headers/Header.js";

import compose from 'recompose/compose';
import { PLEASE_CHECK_CONNECT } from "../services/Common";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionDashboardCreators } from "../actions/DashboardActions";
import CompanyAwait from "../views/pages/CompanyAwait";
import DashboardReportByMonth from "../views/pages/DashboardReportByMonth";
import DashboardHistory from "../views/pages/DashboardHistory";
import DashboardRegisterList from "../views/pages/DashboardRegisterList";
import DashboardLibilitiesStamp from "../views/pages/DashboardLibilitiesStamp";
import { LOADING_TIME } from "../helpers/constant";

import classes from "./index.module.css";
import axios from "axios";
class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      registerList: null,
      stampList: null,
      isLoaded: false,
      status: null,
      message: '',
      dataListServer: []
    };
  }

  componentWillReceiveProps(nextProp) {
    const { data } = nextProp.dashboard;
    let detail = [];
    let register = [];
    let stamp = [];

    if (data !== this.state.data) {
      if (typeof (data) !== 'undefined') {
        if (typeof (data.data) !== 'undefined') {
          if (data.data !== null) {
            data.data.map(item => {
              typeof (item.data) !== 'undefined' && (
                detail.push(item.data.data)
              )
            });

            if (Array.isArray(detail)) {
              register = detail[1];
              stamp = detail[2];
              detail = detail[0];

              if (typeof (detail) !== 'undefined' && typeof (register) !== 'undefined' && typeof (stamp) !== 'undefined') {
                this.setState({
                  data: detail[0],
                  registerList: register,
                  stampList: stamp,
                  isLoaded: data.isLoading,
                  status: data.status,
                  message: PLEASE_CHECK_CONNECT(data.message)
                });
              }
            } else {
              this.setState({ data: null, isLoaded: data.isLoading, status: data.status, message: PLEASE_CHECK_CONNECT(data.message) });
            }
          }
        }
        if (typeof (data.infoDashboard) !== 'undefined') {
          if (data.infoDashboard !== null) {
            this.setState({
              infoDashboard: data.infoDashboard[0],
              isLoaded: data.isLoading,
              status: data.status,
              message: PLEASE_CHECK_CONNECT(data.message)
            });
          } else {
            this.setState({ infoDashboard: null, isLoaded: data.isLoading, status: data.status, message: PLEASE_CHECK_CONNECT(data.message) });
          }

        }
      }
    }

    if (data.getListServer !== this.state.dataListServer) {
      if (typeof (data.getListServer) !== 'undefined') {
        if (typeof (data.getListServer) !== 'undefined') {
          if (data.getListServer !== null) {
            this.setState(previousState => {
              return {
                ...previousState,
                dataListServer: data.getListServer
              }
            })
          }
        }
      }
    }
  }

  componentWillMount() {
    const { getDashboardDetail, getListServer, getInfoDashboard } = this.props;
    getInfoDashboard();
    getListServer();

    // getDashboardDetail();
  }

  componentDidUpdate() {
    // This method is called when the route parameters change
    this.closeStatusModal();
  }

  closeStatusModal = () => {
    const { status } = this.state;

    if (status || !status) {
      setTimeout(() => {
        this.setState({ status: null, isLoaded: false });
      }, LOADING_TIME);
    }
  }

  setServer = (item) => {
    localStorage.setItem('DOMAIN_PROVINCE', item.urlAdmin)
    localStorage.setItem('ID_DOMAIN_PROVINCE', item.id)
    localStorage.setItem('NAME_DOMAIN_PROVINCE', item.description)
  }

  render() {
    const { data, status, message, isLoaded, dataListServer, infoDashboard } = this.state;
    const statusPopup = { status: status, message: message };

    const domainProvince = localStorage.getItem('DOMAIN_PROVINCE');
    const idDomainProvince = localStorage.getItem('ID_DOMAIN_PROVINCE');
    return (
      <>
        {/* <Header data={data} status={status} message={message} isLoaded={isLoaded} statusPopup={statusPopup} infoDashboard={infoDashboard} /> */}
        {/* Page content */}
        {/* <div className={classes.dashboardArea}>
          <Container className={`mt-4 ${classes.backgroundDash}`} fluid >
            <img src={BackGroundImg} className="reponsiveImgIndex" />
          </Container>
        </div> */}
        <div style={{ marginTop: 20 }}>
          <Container fluid>
            <DashboardBox
              data={data}
              isLoaded={isLoaded}
              statusPopup={statusPopup}
              infoDashboard={infoDashboard}
            />
            <DashboardChart />
            <DashboardList />
            <DashboardCompanyManager />
          </Container>
        </div>


        {/* <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Container className={`mt-4 ${classes.aligServer} row`}>
            <div style={{ textAlign: 'center', fontWeight: 'bold', color: '#09b2fd', fontSize: 20 }}>CHỌN SERVER</div>
          </Container>
          <Container className={`mt-4 ${classes.aligServer} row`}>
            {Array.isArray(dataListServer) && (
              dataListServer
                .map((item, key) => (
                  
                  <div className={classes.contentDashBoard}
                    style={{
                      color: ((domainProvince == item.urlAdmin && idDomainProvince == item.id) ||
                        ((domainProvince == 'null' && idDomainProvince == item.id) ? null : domainProvince) == item.urlAdmin) ? '#02A7F0' : '#505050',
                      borderColor: ((domainProvince == item.urlAdmin && idDomainProvince == item.id) ||
                        ((domainProvince == 'null' && idDomainProvince == item.id) ? null : domainProvince) == item.urlAdmin) ? '#02A7F0' : '#d1d1d1'
                    }}>
                    <input type="checkbox"
                      checked={
                        ((domainProvince == item.urlAdmin && idDomainProvince == item.id) ||
                          ((domainProvince == 'null' && idDomainProvince == item.id) ? null : domainProvince) == item.urlAdmin) ? true : false}
                      onClick={() => { this.setServer(item) }} />
                    &nbsp;{item.description}
                  </div>
                ))
            )
            }
          </Container>
        </div> */}
      </>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    dashboard: state.DashboardStore
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(actionDashboardCreators, dispatch)
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Index);
