import {
    USERPROVINCE_LIST,
    USERPROVINCE_DELETE,
    USERPROVINCE_CREATE,
    USERPROVINCE_UPDATE,
    USERPROVINCE_GET
} from "../apis";
import {
    get, post, del, postFormData, put
} from "../services/Dataservice";
import {
    SUCCESS_CODE,
    USERPROVINCE_LIST_FAIL_TYPE,
    USERPROVINCE_LIST_TYPE,
    USERPROVINCE_LIST_SUCCESS_TYPE,
    USERPROVINCE_DELETE_TYPE,
    USERPROVINCE_DELETE_FAIL_TYPE,
    USERPROVINCE_DELETE_SUCCESS_TYPE,
    USERPROVINCE_CREATE_FAIL_TYPE,
    USERPROVINCE_CREATE_SUCCESS_TYPE,
    USERPROVINCE_CREATE_TYPE,
    USERPROVINCE_UPDATE_TYPE,
    USERPROVINCE_UPDATE_FAIL_TYPE,
    USERPROVINCE_UPDATE_SUCCESS_TYPE,
    USERPROVINCE_GET_FAIL_TYPE,
    USERPROVINCE_GET_SUCCESS_TYPE,
    USERPROVINCE_GET_TYPE

} from "../services/Common";

const initialState = { data: [], isLoading: false, status: false, message: '' };

export const actionUserProvince = {
    requestUserProvinceList: (data) => async (dispatch, getState) => {
        return new Promise(async resolve => {
            dispatch({
                type: USERPROVINCE_LIST_TYPE, data: initialState
            });

            await post(USERPROVINCE_LIST, data)
                .then(res => {
                    if (res.status === SUCCESS_CODE) {
                        dispatch({
                            type: USERPROVINCE_LIST_SUCCESS_TYPE, data: { list: res.data, isLoading: true, status: true, message: res.message }
                        });

                    } else {
                        dispatch({
                            type: USERPROVINCE_LIST_FAIL_TYPE, data: { list: [], isLoading: true, status: false, message: res.message }
                        });

                    }
                    resolve({
                        status: true,
                        data: res
                    });
                })
                .catch(err => {
                    dispatch({ type: USERPROVINCE_LIST_FAIL_TYPE, data: { list: [], isLoading: true, status: false, message: err.message } });
                    resolve({
                        status: false,
                        error: err
                    });
                })
        });
    },

    deleteUserProvince: (id) => async (dispatch, getState) => {
        return new Promise(async resolve => {
            dispatch({
                type: USERPROVINCE_DELETE_TYPE, data: initialState
            });
            await del(USERPROVINCE_DELETE + id)
                .then(res => {
                    if (res.status === SUCCESS_CODE) {
                        dispatch({
                            type: USERPROVINCE_DELETE_SUCCESS_TYPE, data: { delete: res.data, isLoading: true, status: true, message: res.message }
                        });

                    } else {
                        dispatch({
                            type: USERPROVINCE_DELETE_FAIL_TYPE, data: { delete: [], isLoading: true, status: false, message: res.message }
                        });

                    }
                    resolve({
                        status: true,
                        data: res
                    });
                })
                .catch(err => {
                    dispatch({ type: USERPROVINCE_DELETE_FAIL_TYPE, data: { delete: [], isLoading: true, status: false, message: err.message } });
                    resolve({
                        status: false,
                        error: err
                    });
                })
        });

    },
    createUserProvinceList: (id, data) => async (dispatch, getState) => {
        return new Promise(async resolve => {
            dispatch({
                type: USERPROVINCE_CREATE_TYPE, data: initialState
            });

            await post(USERPROVINCE_CREATE + id, data)
                .then(res => {
                    if (res.status === SUCCESS_CODE) {
                        dispatch({
                            type: USERPROVINCE_CREATE_SUCCESS_TYPE, data: { create: res.data, isLoading: true, status: true, message: res.message }
                        });

                    } else {
                        dispatch({
                            type: USERPROVINCE_CREATE_FAIL_TYPE, data: { create: [], isLoading: true, status: false, message: res.message }
                        });

                    }
                    resolve({
                        status: true,
                        data: res
                    });
                })
                .catch(err => {
                    dispatch({ type: USERPROVINCE_CREATE_FAIL_TYPE, data: { create: [], isLoading: true, status: false, message: err.message } });
                    resolve({
                        status: false,
                        error: err
                    });
                })
        });
    },

    getIdUserProvinceUpdate: (id) => async (dispatch, getState) => {
        return new Promise(async resolve => {
            dispatch({
                type: USERPROVINCE_GET_TYPE, data: initialState
            });

            await get(USERPROVINCE_GET + id)
                .then(res => {
                    if (res.status === SUCCESS_CODE) {
                        dispatch({
                            type: USERPROVINCE_GET_SUCCESS_TYPE, data: { get: res.data, isLoading: true, status: true, message: res.message }
                        });

                    } else {
                        dispatch({
                            type: USERPROVINCE_GET_FAIL_TYPE, data: { get: [], isLoading: true, status: false, message: res.message }
                        });

                    }
                    resolve({
                        status: true,
                        data: res
                    });
                })
                .catch(err => {
                    dispatch({ type: USERPROVINCE_GET_FAIL_TYPE, data: { get: [], isLoading: true, status: false, message: err.message } });
                    resolve({
                        status: false,
                        error: err
                    });
                })
        });
    },

    updateUserProvinceList: (id, data) => async (dispatch, getState) => {
        return new Promise(async resolve => {
            dispatch({
                type: USERPROVINCE_UPDATE_TYPE, data: initialState
            });

            await put(USERPROVINCE_UPDATE +id, data)
                .then(res => {
                    if (res.status === SUCCESS_CODE) {
                        dispatch({
                            type: USERPROVINCE_UPDATE_SUCCESS_TYPE, data: { update: res.data, isLoading: true, status: true, message: res.message }
                        });

                    } else {
                        dispatch({
                            type: USERPROVINCE_UPDATE_FAIL_TYPE, data: { update: [], isLoading: true, status: false, message: res.message }
                        });

                    }
                    resolve({
                        status: true,
                        data: res
                    });
                })
                .catch(err => {
                    dispatch({ type: USERPROVINCE_UPDATE_FAIL_TYPE, data: { update: [], isLoading: true, status: false, message: err.message } });
                    resolve({
                        status: false,
                        error: err
                    });
                })
        });
    },

}