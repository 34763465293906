import React, { Component } from 'react';
import { Row, Col } from 'reactstrap'
import './DashboardCompanyManager.css'
import ListAwait from './ListManager/ListAwait';
import ListExpiring from './ListManager/ListExpiring';
import ListAwaitExpired from './ListManager/ListAwaitExpired';
import ListRequestExtend from './ListManager/ListRequestExtend';

class DashboardCompanyManager extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <>
                <section style={{ marginBottom: 100 }}>
                    <Row>
                        <Col xl='4' lg='6' md='6' xs='12' className='mr-t-box'>
                            <div className='box-list'>
                                <ListAwait />
                            </div>
                        </Col>
                        <Col lg='6' xl='4' md='6' xs='12' className='mr-t-box'>
                            <div className='box-list'>
                                <ListExpiring />
                            </div>
                        </Col>
                        <Col lg='6' xl='4' md='6' xs='12' className='mr-t-box'>
                            <div className='box-list'>
                                <ListAwaitExpired />
                            </div>
                        </Col>
                        <Col lg='6' xl='4' md='6' xs='12' className='mr-t-box' >
                            <div className='box-list'>
                                <ListRequestExtend />
                            </div>
                        </Col>
                    </Row>
                </section>
            </>
        )
    }

}
export default DashboardCompanyManager;