import {
    DOMAIN,
    DOMAIN_LACO,
    API,
    AREA,
    USER,
    ROLE,
    PRICE,
    ZONE,
    LOCATION,
    LOG,
    COMPANY,
    FIELD,
    INFORMATION,
    ACCESS,
    ZONE_ROLE,
    PERMISSION,
    REGISTEREDFEE,
    STAMPFEE,
    BLOG,
    MENU,
    NEWS,
    STAMP,
    STAMPPROVIDE,
    PLANTINGTYPE,
    DASHBOARD,
    CONFIG,
    MATERIAL_GROUP,
    TYPE_ZONE_PROPERTY,
    COMPANY_REPORT,
    GROWTH_STAMP_REPORT,
    SALE_REGISTER_REPORT,
    SALE_STAMP_REPORT,
    PLANTING_ZONE,
    CONFIG_WEBSITE,
    PRODUCT_GROUP,
    PRODUCT_REPORT,
    UNIT,
    STAMPTEMPLATE,
    TRACE,
    PRODUCTS,
    USERPROVINCE,
    ABOUTUS,
    SERVER,
    MASTERSERVER,
    PARTNER
} from "../services/Common";

// USER API
export const USER_LOGIN = DOMAIN_LACO + API + USER + 'login';
export const USER_LOGOUT = DOMAIN_LACO + API + USER + 'logout';
export const USER_LIST_ACCOUNT_LIST = DOMAIN_LACO + API + USER + 'getall';
export const USER_GET_INFO = DOMAIN_LACO + API + USER + 'get/';
export const USER_UPDATE_INFO = DOMAIN_LACO + API + USER + 'update';
export const USER_CREATE_INFO = DOMAIN_LACO + API + USER + 'create';
export const USER_DELETE_INFO = DOMAIN_LACO + API + USER + 'delete/';
export const USER_SEND_FORGOT_PASSWORD = DOMAIN_LACO + API + USER + 'sendforgotpassword';
export const USER_CHECK_FORGOT_PASSWORD = DOMAIN_LACO + API + USER + 'checkforgotpassword';
export const USER_CHANGE_PASS_FORGOEt_PASSWORD = DOMAIN_LACO + API + USER + 'changepassforforgotpassword';
export const USER_UPDATE_ME = DOMAIN_LACO + API + USER + 'updateme';
export const USER_LIST_LACO = DOMAIN_LACO + API + USER + 'getalluserlaco';

// ROLE API
export const ROLE_LIST = DOMAIN_LACO + API + ROLE + 'getall';
export const ROLE_LIST_BY_ID = DOMAIN_LACO + API + ROLE + 'get/';
export const ROLE_CREATE_NEW = DOMAIN_LACO + API + ROLE + 'create';
export const ROLE_UPDATE_INFO = DOMAIN_LACO + API + ROLE + 'update';
export const ROLE_DELETE = DOMAIN_LACO + API + ROLE + 'delete/';

// PRICES API
export const PRICE_LIST = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PRICE + 'getall';
export const PRICE_GET_INFO = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PRICE + 'get/';
export const PRICE_CREATE_NEW = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PRICE + 'create';
export const PRICE_UPDATE_INFO = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PRICE + 'update';
export const PRICE_DELETE = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PRICE + 'delete/';

// ZONE API
export const ZONE_LIST = DOMAIN + API + ZONE + 'getall';
export const ZONE_LIST_BY_ID = DOMAIN + API + ZONE + 'get/';
export const CREATE_ZONE = DOMAIN + API + ZONE + 'create';
export const UPDATE_ZONE = DOMAIN + API + ZONE + 'update';
export const DELETE_ZONE = DOMAIN + API + ZONE + 'delete/';

// ZONE ROLE API
export const ZONE_ROLE_LIST = DOMAIN + API + ZONE_ROLE + 'getall';

// LOCATION API
export const GET_ALL_PROVINCE = DOMAIN_LACO + API + LOCATION + 'getprovince';
export const GET_DISTRICT_BY_ROLE = DOMAIN + API + LOCATION + 'getdistrictsbyrole';
export const GET_WARD = DOMAIN + API + LOCATION + 'getwardsbyrole?districtId=';

//LOG API
export const LOG_LIST = DOMAIN_LACO + API + LOG + 'getallbydate';

//COMPANY API
export const COMPANY_GET_ALL = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'getallcompanies';
export const COMPANY_GET_DETAILS = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'get?id=';
export const COMPANY_GET_DELETED = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'getdeleted?id=';
export const COMPANY_CHECK_DAY = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'checkexpireddate?id=';
export const COMPANY_GET_DETAILS_LACO = DOMAIN_LACO + API + COMPANY + 'get?id=';
export const COMPANY_GET_FILEUPDATE = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'getfileupdate?companyId=';
export const COMPANY_LIST = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'getlistnewlysignup';
export const COMPANY_GET_LIST_CERTIFIED = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'getlistcertified';
export const CREATE_COMPANY = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'create';
export const DELETE_COMPANY = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'delete?id=';
export const COMPANY_AWAIT = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'getlistawaitcomfirm';
export const COMPANY_COMFIRM = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'comfirm?id=';
export const COMPANY_UNCOMFIRM = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'uncomfirm';
export const COMPANY_NOT_COMFIRM = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'getlistuncomfirm';
export const COMPANY_LIST_REGISTERED = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'getlistregistered';
export const COMPANY_VERIFY = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'verify?id=';
export const COMPANY_GET_LIST_AWAIT_EXPIRED = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'getlistexpiring';
export const COMPANY_GET_LIST_EXPIRING = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'getlistawaitextend';
export const COMPANY_GET_LIST_LOCK = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'getlistlock';
export const COMPANY_GET_LIST_COMFIRMED = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'getlistcomfirmed';
export const COMPANY_GET_LIST_DELETED = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'getlistdeleted';
export const COMPANY_EXTEND = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'extend';
export const COMPANY_LOCK = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'lock?id=';
export const COMPANY_UNLOCK = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'unlock?id=';
export const COMPANY_GET_LIST_REQUEST_EXTEND = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'getlistrequestextend';
export const COMPANY_COMFIRM_REQUEST_EXTEND = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'comfirmrequestextend';
export const COMPANY_UNCOMFIRM_REQUEST_EXTEND = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'uncomfirmrequestextend';
export const COMPANY_GET_LIST_REQUEST_UNLOCK = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'getlistrequestunlock';
export const COMPANY_COMFIRM_REQUEST_UNLOCK = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'comfirmrequestunlock?id=';
export const COMPANY_UNCOMFIRM_REQUEST_UNLOCK = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'uncomfirmrequestunlock';
export const DELETE_COMPANY_REGISTERED = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'deletecompanyregistered?id=';
export const DELETE_COMPANY_AWAIT = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'deletecompanyawait/';

export const COMPANY_LIST_VERIFY = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'getallverify';
export const COMPANY_VERIFY_IMAGE = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'verifyimage?id=';
export const COMPANY_UPLOAD_VERIFY = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'uploadverifiedfiles';
export const COMPANY_DELETE_UPLOAD_VERIFY = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY + 'deleteverifiedfiles?id=';

// FIELD API
export const FIELD_LIST = DOMAIN_LACO + API + FIELD + 'getall';
export const FIELD_CREATE = DOMAIN_LACO + API + FIELD + 'create';
export const FIELD_UPDATE = DOMAIN_LACO + API + FIELD + 'update';
export const FIELD_DELETE = DOMAIN_LACO + API + FIELD + 'delete/';
export const FIELD_LOCK = DOMAIN_LACO + API + FIELD + 'lock?id=';
export const FIELD_BY_COMPANY_FIELD_LIST = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + FIELD + 'getallbycompanyfield';

export const FIELD_LIST_COMPANYAWAIT = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + FIELD + 'GetAllForCompanyAwait';
export const FIELD_LIST_COMPANYNOTCOMFIRM = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + FIELD + 'GetAllForCompanyNotComfirm';
export const FIELD_LIST_COMPANYAWAITEXTEND = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + FIELD + 'GetAllForCompanyAwaitExtend';
export const FIELD_LIST_COMPANYEXPIRING = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + FIELD + 'GetAllForCompanyExpiring';
export const FIELD_LIST_COMPANYLOCK = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + FIELD + 'GetAllForCompanyLock';
export const FIELD_LIST_COMPANYREQUESTEXTEND = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + FIELD + 'GetAllForCompanyRequestExtend';
export const FIELD_LIST_COMPANYCOMFIRMED = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + FIELD + 'getallforcompanycomfirmed';
export const FIELD_LIST_COMPANYDELETED = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + FIELD + 'getallforcompanydeleted';

// INFORMATION API
export const INFORMATION_LIST = DOMAIN + API + INFORMATION + 'getall';
export const INFORMATION_LIST_BY_ID = DOMAIN + API + INFORMATION + 'get/';
export const INFORMATION_CREATE = DOMAIN + API + INFORMATION + 'create';
export const INFORMATION_UPDATE = DOMAIN + API + INFORMATION + 'update';
export const INFORMATION_DELETE = DOMAIN + API + INFORMATION + 'delete/';

// ACCESS API
export const ACCESS_LIST = DOMAIN + API + ACCESS + 'getall';
export const ACCESS_LIST_BY_ID = DOMAIN + API + ACCESS + 'get/';
export const ACCESS_CREATE = DOMAIN + API + ACCESS + 'create';
export const ACCESS_UPDATE = DOMAIN + API + ACCESS + 'update';
export const ACCESS_DELETE = DOMAIN + API + ACCESS + 'delete/';

// PERMISSION
export const GET_PERMISSION_LIST_BY_ID = DOMAIN_LACO + API + PERMISSION + 'getgridview/';
export const UPDATE_PERMISSION = DOMAIN_LACO + API + PERMISSION + 'checkfunc';

//REGISTEREDFEE
export const REGISTERED_FEE_LIST = DOMAIN + API + REGISTEREDFEE + 'getall';
export const REGISTERED_FEE_COMFIRM = DOMAIN + API + REGISTEREDFEE + 'confirm/';

//STAMPFEE
export const STAMPFEE_FEE_LIST = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + STAMPFEE + 'getall';
export const STAMPFEE_FEE_COMFIRM = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + STAMPFEE + 'confirm/';

//BLOG
export const BLOG_LIST = DOMAIN_LACO + API + BLOG + 'getall';
export const BLOG_LIST_GETID = DOMAIN_LACO + API + BLOG + 'get?id=';
export const BLOG_LIST_CREATE = DOMAIN_LACO + API + BLOG + 'create';
export const BLOG_LIST_DELETE = DOMAIN_LACO + API + BLOG + 'delete?id=';
export const BLOG_LIST_UPDATE = DOMAIN_LACO + API + BLOG + 'update';
export const BLOG_UPDATE_IMG = DOMAIN_LACO + API + BLOG + 'upcontentimg';

//NEWS
export const NEWS_LIST = DOMAIN + API + NEWS + 'getall';
export const NEWS_LIST_GETID = DOMAIN + API + NEWS + 'get?id=';
export const NEWS_LIST_CREATE = DOMAIN + API + NEWS + 'create';
export const NEWS_LIST_DELETE = DOMAIN + API + NEWS + 'delete/';
export const NEWS_LIST_UPDATE = DOMAIN + API + NEWS + 'update';

//MENU
export const MENU_LIST = DOMAIN_LACO + API + MENU + 'getall';
export const MENU_LIST_GET = DOMAIN_LACO + API + MENU + 'get?id=';
export const MENU_LIST_CREATE = DOMAIN_LACO + API + MENU + 'create';
export const MENU_LIST_DELETE = DOMAIN_LACO + API + MENU + 'delete?id=';
export const MENU_LIST_UPDATE = DOMAIN_LACO + API + MENU + 'update';

//STAMP
export const STAMP_LIST = DOMAIN + API + STAMP + 'getall';
export const STAMP_LIST_CREATE = DOMAIN + API + STAMP + 'create';
export const STAMP_LIST_DELETE = DOMAIN + API + STAMP + 'delete/';

//STAMPPROVIDE
export const STAMPPROVIDE_LIST = DOMAIN + API + STAMPPROVIDE + 'getall';
export const STAMPPROVIDE_LIST_COMFIRM = DOMAIN + API + STAMPPROVIDE + 'confirmed/';
export const STAMPPROVIDE_LIST_UNCOMFIRM = DOMAIN + API + STAMPPROVIDE + 'unconfirm';
export const QRCODE_STAMPID = DOMAIN + API + STAMPPROVIDE + 'qrcodestampid?';
export const STAMPPROVIDE_DETAIL = DOMAIN + API + STAMPPROVIDE + 'detail?id={0}';

//PLANTINGTYPE
export const PLANTINGTYPE_LIST = DOMAIN + API + PLANTINGTYPE + 'getall';
export const PLANTINGTYPE_LIST_CREATE = DOMAIN + API + PLANTINGTYPE + 'create';
export const PLANTINGTYPE_LIST_DELETE = DOMAIN + API + PLANTINGTYPE + 'delete?id=';

//DASHBOARD
export const DASHBOARD_DETAIL = DOMAIN_LACO + API + DASHBOARD;
export const DASHBOARD_DEBT_COLLECT_OF_REGISTRASTION_OF_USE = DOMAIN_LACO + API + DASHBOARD + '/debtcollectofregistrastionofuse';
export const DASHBOARD_LIABILITIES_STAPM = DOMAIN_LACO + API + DASHBOARD + '/liabilitiesstamp';
export const DASHBOARD_GET_ALERTS = DOMAIN_LACO + API + DASHBOARD + '/getalerts';
export const DASHBOARD_GET_INFO = DOMAIN_LACO + API + DASHBOARD + '/getHeaderDashboard';
export const DASHBOARD_GET_TOTAL_ALERTS = DOMAIN_LACO + API + DASHBOARD + '/gettotalalerts';
export const DASHBOARD_READ = DOMAIN_LACO + API + DASHBOARD + '/read?alertId=';
export const DASHBOARD_READ_ALL = DOMAIN_LACO + API + DASHBOARD + '/readall';

// CONFIG
export const CONFIG_MENU = DOMAIN_LACO + API + CONFIG + 'getmenu';

// LOCATION
export const LOCATION_PROVINCE = DOMAIN + API + LOCATION + 'getprovince';
export const LOCATION_DISTRICT_BY_PROVINCE = DOMAIN + API + LOCATION + 'getdistrictsbyrole';
export const LOCATION_WARD_BY_DISTRICT = DOMAIN + API + LOCATION + 'getwardsbyrole?districtId={0}';

// COMPANY
export const COMPANY_ADD = DOMAIN + API + COMPANY + 'create';
export const COMPANY_UPDATE = DOMAIN + API + COMPANY + 'update';
export const COMPANY_DETAIL = DOMAIN + API + COMPANY + 'get?id={0}';

// AREA CODE
export const AREA_CODE_PROVINCE = DOMAIN + API + LOCATION + 'getprovince';
export const AREA_CODE_DISTRICT_BY_PROVINCE = DOMAIN + API + LOCATION + 'getdistrictsbyrole';
export const AREA_CODE_WARD_BY_DISTRICT = DOMAIN + API + LOCATION + 'getwardsbyrole?districtId={0}';
export const AREA_CODE_ADD = DOMAIN + API + ZONE + 'create';
export const AREA_CODE_EDIT = DOMAIN + API + ZONE + 'update';
export const AREA_CODE_DELETE = DOMAIN + API + ZONE + 'delete/{0}';
export const AREA_CODE_DETAIL = DOMAIN + API + ZONE + 'get/{0}'

// AREA ROLE
export const AREA_ROLE_ROLE = DOMAIN + API + ROLE + 'getallnozone';
export const AREA_ROLE_AREA = DOMAIN + API + ZONE + 'GetAllByLevel';
export const AREA_ROLE_ADD = DOMAIN + API + ZONE_ROLE + 'create';
export const AREA_ROLE_EDIT = DOMAIN + API + ZONE_ROLE + 'update';
export const AREA_ROLE_DELETE = DOMAIN + API + ZONE_ROLE + 'delete/{0}';
export const AREA_ROLE_DETAIL = DOMAIN + API + ZONE_ROLE + 'get/{0}'
export const AREA_ROLE_ROLE_ALL = DOMAIN + API + ROLE + 'getall';
export const AREA_ROLE_ROLE_ALL_EXCLUDE = DOMAIN + API + ZONE_ROLE + 'getlistrolecreate';

// FIELD
export const FIELD_GET_ALL = DOMAIN + API + FIELD + 'getall';

// TYPE ZONE PROPERTY
export const TYPE_ZONE_PROPERTY_LIST = DOMAIN + API + TYPE_ZONE_PROPERTY + 'getall';
export const TYPE_ZONE_PROPERTY_ADD = DOMAIN + API + TYPE_ZONE_PROPERTY + 'create';
export const TYPE_ZONE_PROPERTY_EDIT = DOMAIN + API + TYPE_ZONE_PROPERTY + 'update';
export const TYPE_ZONE_PROPERTY_DELETE = DOMAIN + API + TYPE_ZONE_PROPERTY + 'delete?id={0}';
export const TYPE_ZONE_PROPERTY_DETAIL = DOMAIN + API + TYPE_ZONE_PROPERTY + 'get?id={0}'

//COMPANY REPORT
export const COMPANY_MONTH_REPORT = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY_REPORT + 'getmonthreport?year=';
export const COMPANY_QUARTER_REPORT = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY_REPORT + 'getquarterreport?year=';
export const COMPANY_YEAR_REPORT = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + COMPANY_REPORT + 'getyearreport?';

//GROWTH STAMP REPORT
export const GROWTH_STAMP_MONTH_REPORT = DOMAIN + API + GROWTH_STAMP_REPORT + 'getmonthreport?year=';
export const GROWTH_STAMP_QUARTER_REPORT = DOMAIN + API + GROWTH_STAMP_REPORT + 'getquarterreport?year=';
export const GROWTH_STAMP_YEAR_REPORT = DOMAIN + API + GROWTH_STAMP_REPORT + 'getyearreport?';

//SALE REGISTER REPORT
export const SALE_REGISTER_MONTH_REPORT = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + SALE_REGISTER_REPORT + 'getmonthreport?year=';
export const SALE_REGISTER_QUARTER_REPORT = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + SALE_REGISTER_REPORT + 'getquarterreport?year=';
export const SALE_REGISTER_YEAR_REPORT = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + SALE_REGISTER_REPORT + 'getyearreport?';

//SALE STAMP REPORT
export const SALE_STAMP_MONTH_REPORT = DOMAIN + API + SALE_STAMP_REPORT + 'getmonthreport?year=';
export const SALE_STAMP_QUARTER_REPORT = DOMAIN + API + SALE_STAMP_REPORT + 'getquarterreport?year=';
export const SALE_STAMP_YEAR_REPORT = DOMAIN + API + SALE_STAMP_REPORT + 'getyearreport?';

// PLATING ZONE
export const PLANTING_ZONE_GET_LIST = DOMAIN + API + PLANTING_ZONE + 'getall';
export const PLANTING_ZONE_GET_LIST_PLANTING_TYPE = DOMAIN + API + PLANTINGTYPE + 'getall';
export const PLANTING_ZONE_CREATE = DOMAIN + API + PLANTING_ZONE + 'create';
export const PLANTING_ZONE_UPDATE = DOMAIN + API + PLANTING_ZONE + 'update';
export const PLANTING_ZONE_DELETE = DOMAIN + API + PLANTING_ZONE + 'delete?id={0}';
export const PLANTING_ZONE_DETAIL = DOMAIN + API + PLANTING_ZONE + 'get?id={0}';

// CONFIG SYSTEM
export const CONFIG_GET_INFO_COMPANY = DOMAIN + API + CONFIG + 'getsysteminfo';
export const CONFIG_GET_CONFIG_SYSTEM = DOMAIN + API + CONFIG + 'getsystemsetting';
export const CONFIG_GET_LIST_CONFIG_SERVER = DOMAIN + API + CONFIG + 'getserversetting?companyId={0}';
export const CONFIG_GET_LIST_PROVINCE_FOR_INFO_COMPANY = DOMAIN + API + LOCATION + 'getprovince';
export const CONFIG_GET_LIST_DISTRICT_FOR_INFO_COMPANY = DOMAIN + API + LOCATION + 'getdistrictsbyrole';
export const CONFIG_GET_LIST_WARD_FOR_INFO_COMPANY = DOMAIN + API + LOCATION + 'getwardsbyrole?districtId={0}';
export const CONFIG_UPDATE_INFO_COMPANY = DOMAIN + API + CONFIG + 'updatesysteminfo';
export const CONFIG_UPDATE_CONFIG_SYSTEM = DOMAIN + API + CONFIG + 'updatesystemsetting';
export const CONFIG_GET_FTP = DOMAIN + API + CONFIG + 'getftp?id={0}';
export const CONFIG_CREATE_FTP = DOMAIN + API + CONFIG + 'createftp';
export const CONFIG_UPDATE_FTP = DOMAIN + API + CONFIG + 'updateftp';
export const CONFIG_DELETE_FTP = DOMAIN + API + CONFIG + 'deleteftp';
export const CONFIG_UPDATE_IMG = DOMAIN + API + CONFIG + 'upcontentimg';

//MATERIAL GROUP
export const MATERIAL_GROUP_GET = DOMAIN_LACO + API + MATERIAL_GROUP + 'get?id=';
export const MATERIAL_GROUP_LIST = DOMAIN_LACO + API + MATERIAL_GROUP + 'getall';
export const MATERIAL_GROUP_LOG_LIST = DOMAIN_LACO + API + MATERIAL_GROUP + 'getalllock';
export const MATERIAL_GROUP_CREATE = DOMAIN_LACO + API + MATERIAL_GROUP + 'create';
export const MATERIAL_GROUP_LOCK = DOMAIN_LACO + API + MATERIAL_GROUP + 'lock?id=';
export const MATERIAL_GROUP_UPDATE = DOMAIN_LACO + API + MATERIAL_GROUP + 'update';
export const MATERIAL_GROUP_DELETE = DOMAIN_LACO + API + MATERIAL_GROUP + 'delete?id=';

//CONFIG WEBSITE
export const CONFIG_WEBSITE_GET = DOMAIN_LACO + API + CONFIG_WEBSITE + 'get';
export const CONFIG_WEBSITE_UPDATE = DOMAIN_LACO + API + CONFIG_WEBSITE + 'update';
export const CONFIG_WEBSITE_UPDATE_IMG = DOMAIN_LACO + API + CONFIG_WEBSITE + 'upcontentimg';

//PRODUCT GROUP
export const PRODUCT_GROUP_GET = DOMAIN_LACO + API + PRODUCT_GROUP + 'get?id=';
export const PRODUCT_GROUP_LIST = DOMAIN_LACO + API + PRODUCT_GROUP + 'getall';
export const PRODUCT_GROUP_CREATE = DOMAIN_LACO + API + PRODUCT_GROUP + 'create';
export const PRODUCT_GROUP_LOCK = DOMAIN_LACO + API + PRODUCT_GROUP + 'lock?id=';
export const PRODUCT_GROUP_UPDATE = DOMAIN_LACO + API + PRODUCT_GROUP + 'update';
export const PRODUCT_GROUP_DELETE = DOMAIN_LACO + API + PRODUCT_GROUP + 'delete?id=';

//AREA
export const AREA_GET = DOMAIN + API + AREA + 'get';
export const AREA_GET_LIST_COMPANY_PLANNING_AREA = DOMAIN + API + AREA + 'getlistcompanyplanningarea';
export const AREA_GET_LIST_COMPANY_OUTER_PLANNING_AREA = DOMAIN + API + AREA + 'getlistcompanyouterplanningarea';

//PRODUCT REPORT
export const PRODUCT_REPORT_GET = DOMAIN + API + PRODUCT_REPORT;
export const PRODUCT_REPORT_GET_BY_GROUP = DOMAIN + API + PRODUCT_REPORT + 'getbygroup';
export const PRODUCT_REPORT_GET_LIST_FIELD_COMBOBOX = DOMAIN + API + FIELD + 'getall';
// export const PRODUCT_REPORT_GET_PRODUCT_REPORT_COMBOBOX = DOMAIN + API + PRODUCT_REPORT + 'getbygroup?materialGroupId={materialGroupId}&districtId={districtId}&wardId={wardId}&page={page}&limit={limit}&reportFilterType={reportFilterType}&year={year}&month={month}';
export const PRODUCT_REPORT_GET_PRODUCT_REPORT = DOMAIN + API + PRODUCT_REPORT;
export const PRODUCT_REPORT_GET_PRODUCT_REPORT_BY_GROUP = DOMAIN + API + PRODUCT_REPORT + 'getbygroup';
export const PRODUCT_REPORT_GET_PRODUCT_REPORT_BY_TYPE = DOMAIN + API + PRODUCT_REPORT + 'getbytype';

//UNIT
export const UNIT_GET = DOMAIN_LACO + API + UNIT + 'get?id=';
export const UNIT_LIST = DOMAIN_LACO + API + UNIT + 'getall';
export const UNIT_CREATE = DOMAIN_LACO + API + UNIT + 'create';
export const UNIT_UPDATE = DOMAIN_LACO + API + UNIT + 'update';
export const UNIT_DELETE = DOMAIN_LACO + API + UNIT + 'delete/';
export const UNIT_LOCK = DOMAIN_LACO + API + UNIT + 'lock?id=';

//STAMPTEMPLATE
export const STAMPTEMPLATE_LIST = DOMAIN + API + STAMPTEMPLATE + 'getall';
export const STAMPTEMPLATE_GET = DOMAIN + API + STAMPTEMPLATE;
export const STAMPTEMPLATE_CREATE = DOMAIN + API + STAMPTEMPLATE;
export const STAMPTEMPLATE_UPDATE = DOMAIN + API + STAMPTEMPLATE;
export const STAMPTEMPLATE_DELETE = DOMAIN + API + STAMPTEMPLATE;

//TRACE
export const TRACE_LIST = DOMAIN + API + TRACE + 'getall';
export const TRACE_GET = DOMAIN + API + TRACE + 'get?id=';
export const TRACE_GET_HISTORY = DOMAIN + API + TRACE + 'gethistory';

//PRODUCTS
export const PRODUCTS_LIST = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PRODUCTS + 'getall';
export const PRODUCTS_GET = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PRODUCTS + 'get?id=';
export const PRODUCTS_LIST_LOCK = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PRODUCTS + 'getalllock';
export const PRODUCTS_CONFIRM = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PRODUCTS + 'confirm?id=';
export const PRODUCTS_UNCONFIRM = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PRODUCTS + 'unconfirm?id=';
export const PRODUCTS_LIST_VERIFY = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PRODUCTS + 'getallverify';
export const PRODUCTS_VERIFY = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PRODUCTS + 'verify?id=';
export const PRODUCTS_UPLOAD_VERIFY = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PRODUCTS + 'uploadverifiedfiles';
export const PRODUCTS_DELETE_UPLOAD_VERIFY = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PRODUCTS + 'deleteverifiedfiles?id=';

// USERPROVINCE
export const USERPROVINCE_LIST = DOMAIN_LACO + API + USERPROVINCE + '/getall';
export const USERPROVINCE_GET = DOMAIN_LACO + API + USERPROVINCE + '?userId='
export const USERPROVINCE_DELETE = DOMAIN_LACO + API + USERPROVINCE + '?userId=';
export const USERPROVINCE_CREATE = DOMAIN_LACO + API + USERPROVINCE + '?userId=';
export const USERPROVINCE_UPDATE = DOMAIN_LACO + API + USERPROVINCE + '?userId=';

//ABOUTUS
export const ABOUTUS_LIST_INTRODUCE = DOMAIN_LACO + API + ABOUTUS + 'getintroduce';
export const ABOUTUS_LIST = DOMAIN_LACO + API + ABOUTUS + 'get';
export const ABOUTUS_UPDATE = DOMAIN_LACO + API + ABOUTUS + 'update';
export const ABOUTUS_GET_LIST_ALERT_ROLES = DOMAIN_LACO + API + ABOUTUS + 'getallalertroles';
export const ABOUTUS_CREATE_ALERT_ROLES = DOMAIN_LACO + API + ABOUTUS + 'createalertroles';
export const ABOUTUS_DELETE_ALERT_ROLES = DOMAIN_LACO + API + ABOUTUS + 'deletealertroles?roleId=';
export const ABOUTUS_GET_LIST_ALERT_ROLES_BY_SELECT = DOMAIN_LACO + API + ABOUTUS + 'getallalertrolesbyselect';
export const ABOUTUS_GET_LIST_ROLES_BY_SELECT = DOMAIN_LACO + API + ABOUTUS + 'getallrolesbyselect';

//SERVER
export const SERVER_LIST = DOMAIN_LACO + API + SERVER + 'getall';

//MASTERSERVER
export const MASTERSERVER_LIST = DOMAIN_LACO + API + MASTERSERVER + '/getall';
export const MASTERSERVER_DELETE = DOMAIN_LACO + API + MASTERSERVER + '?id=';
export const MASTERSERVER_CREATE = DOMAIN_LACO + API + MASTERSERVER;
export const MASTERSERVER_UPDATE = DOMAIN_LACO + API + MASTERSERVER;
export const MASTERSERVER_GET = DOMAIN_LACO + API + MASTERSERVER + '/get/';

//PARTNER
export const PARTNER_LIST = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PARTNER + 'getall';
export const PARTNER_GET = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PARTNER + 'get?id=';
export const PARTNER_CREATE = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PARTNER + 'create';
export const PARTNER_UPDATE = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PARTNER + 'update';
export const PARTNER_DELETE = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PARTNER + 'delete/';
export const PARTNER_LIST_VERIFY = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PARTNER + 'getallverify';
export const PARTNER_LIST_MANIFEST_VERIFY = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PARTNER + 'getallmanifestverify';
export const PARTNER_LIST_TRANSPORTS_VERIFY = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PARTNER + 'getalltransportverify';
export const PARTNER_VERIFY = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PARTNER + 'verify?id=';
export const PARTNER_UPLOAD_VERIFY = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PARTNER + 'uploadverifiedfiles';
export const PARTNER_DELETE_UPLOAD_VERIFY = localStorage.getItem('DOMAIN_PROVINCE') + '/' + API + PARTNER + 'deleteverifiedfiles?id=';
