import React, { Component } from "react";
import classes from './index.module.css';
import InputCurrency from '../../../components/InputCurrency';
import Validate from "react-validate-form";
import { rules, validations, checkPasswordConfirm } from "../../../helpers/validation";
import { bindActionCreators } from "redux";
import compose from 'recompose/compose';
import { connect } from "react-redux";
import Select from "components/Select";
import SelectTree from "components/SelectTree";
import { actionMaterialGroup } from "../../../actions/MaterialGroupActions";
// reactstrap components
import {
    Input,
    InputGroup
} from "reactstrap";

class UpdateModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            activeSubmit: false
        }
    }

    async componentWillMount() {
        const { requestGetMaterialGroup, id } = this.props;
        if (id) {
            await requestGetMaterialGroup(id).then(
                res => {
                    this.setState(previousState => {
                        return {
                            ...previousState,
                            data: res.data
                        }
                    });
                }
            )
        }
        this.handleCheckValidation();
    }

    handleChange = (event) => {
        let { data } = this.state;
        const ev = event.target;
        data[ev['name']] = ev['value'];
        this.setState({ data });

        // Check Validation 
        this.handleCheckValidation();
    }

    handleSelect = (value, name) => {
        let { data } = this.state;

        if (value === null) value = "";


        if (name === 'unitID') {
            let valsortOrder = value;
            if (valsortOrder == null) {
                this.setState({ sortOrderChange: null })
            }
        }

        data[name] = value;

        this.setState({ data });

        // Check Validation 
        this.handleCheckValidation();
    }

    handleCheckValidation = () => {
        const { handleCheckValidation, handleNewData } = this.props;
        let { data } = this.state;
        this.setState({ activeSubmit: true });
        // Check Validation 
        handleCheckValidation(true);
        // Handle New Data
        handleNewData(data);

    }

    render() {
        const { data } = this.state;
        const { errorUpdate, sortOrderChange, dataUnit, fieldData, handleOpenSelectTree } = this.props;

        let _dataUnit
        let _dataUnitName

        if (data.unitID) {
            _dataUnit = dataUnit.filter(x => data.unitID == x.id)
            if (sortOrderChange === null) {
                _dataUnitName = null
            } else {
                _dataUnitName = _dataUnit[0].unitName
            }
        }

        return (
            <div className={classes.formControl}>
                <div className={classes.rowItem}>
                    <label
                        className="form-control-label"
                    >
                        Ngành nghề&nbsp;<b style={{ color: 'red' }}>*</b>
                    </label>
                    <div className={classes.inputArea}>
                        <SelectTree
                            name="fieldID"
                            title='Chọn ngành nghề'
                            data={fieldData}
                            selected={data.fieldID}
                            labelName='fieldName'
                            fieldName='fieldName'
                            val='id'
                            handleChange={this.handleSelect}
                            handleOpenSelectTree={handleOpenSelectTree}
                        />
                        <p className='form-error-message margin-bottom-0'>{errorUpdate['fieldID'] || ''}</p>
                    </div>
                </div>
                {data.name ? (
                    <div className={classes.rowItem}>
                        <label
                            className="form-control-label"
                        >
                            Tên nhóm NVL&nbsp;<b style={{ color: 'red' }}>*</b>
                        </label>

                        <div className={classes.inputArea}>
                            <InputGroup className="input-group-alternative">
                                <Input
                                    name='name'
                                    required
                                    autoFocus={true}
                                    defaultValue={data.name}
                                    onKeyUp={(event) => this.handleChange(event)}
                                />
                            </InputGroup>
                            <p className='form-error-message margin-bottom-0'>{errorUpdate['name'] || ''}</p>
                        </div>
                    </div>
                ) : null}
                <div className={classes.rowItem}>
                    <label
                        className="form-control-label"
                    >
                        ĐVT&nbsp;<b style={{ color: 'red' }}>*</b>
                    </label>
                    <div className={classes.inputArea}>
                        <Select
                            name='unitID'
                            labelName='unitName'
                            data={dataUnit}
                            defaultValue={data.unitID}
                            labelMark={_dataUnitName}
                            val='id'
                            title='Chọn ĐVT'
                            handleChange={this.handleSelect}
                        />
                        <p className='form-error-message margin-bottom-0'>{errorUpdate['unitID'] || ''}</p>
                    </div>
                </div>
                <div className={classes.rowItem}>
                    <label
                        className="form-control-label"
                    >
                        Ghi chú
                    </label>
                    <div className={classes.inputArea}>
                        <InputGroup className="input-group-alternative">
                            <Input
                                name='note'
                                type='textarea'
                                //placeholder='Số lượng'
                                defaultValue={data.note}
                                //required
                                //autoFocus={true}
                                onKeyUp={(event) => this.handleChange(event)}
                            />
                        </InputGroup>
                    </div>

                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        materialGroup: state.MaterialGroupStore,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionMaterialGroup, dispatch),
    }
}
export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(UpdateModal);
