import {
  GET_FIELD_SUCCESS_TYPE,
  GET_FIELD_FAIL_TYPE,
  GET_CREATE_FIELD_SUCCESS_TYPE,
  GET_CREATE_FIELD_FAIL_TYPE,
  GET_UPDATE_FIELD_SUCCESS_TYPE,
  GET_UPDATE_FIELD_FAIL_TYPE,
  GET_DELETE_FIELD_SUCCESS_TYPE,
  GET_DELETE_FIELD_FAIL_TYPE,
  GET_FIELD_SELECT_SUCCESS_TYPE,
  GET_FIELD_SELECT_FAIL_TYPE,
  FIELD_LOCK_SUCCESS_TYPE,
  FIELD_LOCK_FAIL_TYPE,
  GET_FIELD_COMPANYAWAIT_SUCCESS_TYPE,
  GET_FIELD_COMPANYAWAIT_FAIL_TYPE,
  GET_FIELD_COMPANYNOTCOMFIRM_SUCCESS_TYPE,
  GET_FIELD_COMPANYNOTCOMFIRM_FAIL_TYPE,
  GET_FIELD_COMPANYAWAITEXTEND_FAIL_TYPE,
  GET_FIELD_COMPANYAWAITEXTEND_SUCCESS_TYPE,
  GET_FIELD_COMPANYEXPIRING_SUCCESS_TYPE,
  GET_FIELD_COMPANYEXPIRING_FAIL_TYPE,
  GET_FIELD_COMPANYLOCK_SUCCESS_TYPE,
  GET_FIELD_COMPANYLOCK_FAIL_TYPE,
  GET_FIELD_COMPANYREQUESTEXTEND_FAIL_TYPE,
  GET_FIELD_COMPANYREQUESTEXTEND_SUCCESS_TYPE,
  GET_FIELD_COMPANYDELETED_SUCCESS_TYPE,
  GET_FIELD_COMPANYDELETED_FAIL_TYPE,
  GET_FIELD_COMPANYCOMFIRMED_SUCCESS_TYPE,
  GET_FIELD_COMPANYCOMFIRMED_FAIL_TYPE

} from "../services/Common";

const initialState = { data: {}, isLoading: false, status: null, message: '' };

export const fieldReducer = (state, action) => {
  state = state || initialState;

  if (action.type === GET_FIELD_SELECT_SUCCESS_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_SELECT_FAIL_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_COMPANYAWAIT_SUCCESS_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_COMPANYAWAIT_FAIL_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_COMPANYNOTCOMFIRM_SUCCESS_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_COMPANYNOTCOMFIRM_FAIL_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_COMPANYAWAITEXTEND_SUCCESS_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_COMPANYAWAITEXTEND_FAIL_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_COMPANYEXPIRING_SUCCESS_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_COMPANYEXPIRING_FAIL_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_COMPANYLOCK_SUCCESS_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_COMPANYLOCK_FAIL_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_COMPANYREQUESTEXTEND_SUCCESS_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_COMPANYREQUESTEXTEND_FAIL_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_COMPANYCOMFIRMED_SUCCESS_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_COMPANYCOMFIRMED_FAIL_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_COMPANYDELETED_SUCCESS_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_COMPANYDELETED_FAIL_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_SUCCESS_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_FIELD_FAIL_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_CREATE_FIELD_SUCCESS_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_CREATE_FIELD_FAIL_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_UPDATE_FIELD_SUCCESS_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_UPDATE_FIELD_FAIL_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_DELETE_FIELD_SUCCESS_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === GET_DELETE_FIELD_FAIL_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  if (action.type === FIELD_LOCK_SUCCESS_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }

  if (action.type === FIELD_LOCK_FAIL_TYPE) {
    return {
      data: {
        ...state.data,
        ...action.data
      },
      isLoading: action.isLoading,
      status: action.status,
      message: action.message,
    };
  }
  return state;
};