import {
    COMPANY_GET_ALL,
    
} from "../apis";
import {
    get, post, del
} from "../services/Dataservice";
import {
    SUCCESS_CODE,
    COMPANY_GET_ALL_TYPE,
    COMPANY_GET_ALL_SUCCESS_TYPE,
    COMPANY_GET_ALL_FAIL_TYPE
} from "../services/Common";

const initialState = { data: [], isLoading: false, status: false, message: '' };

export const actionCompanyGetAll = {
    requestCompanyGetAll: (data) => async (dispatch, getState) => {
        dispatch({
            type: COMPANY_GET_ALL_TYPE, data: initialState
        });

        await post(COMPANY_GET_ALL, data)
            .then(res => {
                if (res.status === SUCCESS_CODE) {
                    dispatch({ type: COMPANY_GET_ALL_SUCCESS_TYPE, data: { companyAll: res.data, isLoading: true, status: true, message: res.message } });
                } else {
                    dispatch({ type: COMPANY_GET_ALL_FAIL_TYPE, data: { companyAll: [], isLoading: true, status: false, message: res.message } });
                }
            })
            .catch(err => {
                dispatch({ type: COMPANY_GET_ALL_FAIL_TYPE, data: { companyAll: [], isLoading: true, status: false, message: err.message } });
            })
    }
}